<ng-container *ngFor="let breadcrumb of breadcrumbs; let isLast = last">
    <a
        *ngIf="breadcrumb.link; else breadcrumbSpan"
        class="breadcrumb"
        [ngClass]="{isLast, overflow: breadcrumb.overflow}"
        [routerLink]="breadcrumb.link"
        [matTooltip]="breadcrumb.text"
        [matTooltipDisabled]="!breadcrumb.overflow"
        [appTypography]="isLast ? 'SECONDARY_BOLD' : 'SECONDARY'">
        {{ breadcrumb.text | translate }}
    </a>
    <ng-template #breadcrumbSpan>
        <span
            [ngClass]="{isLast, overflow: breadcrumb.overflow}"
            [matTooltip]="breadcrumb.text"
            [matTooltipDisabled]="!breadcrumb.overflow"
            class="breadcrumb"
            [appTypography]="isLast ? 'SECONDARY_BOLD' : 'SECONDARY'">
            {{ breadcrumb.text | translate }}
        </span>
    </ng-template>
    <span
        appTypography="SECONDARY"
        *ngIf="!isLast"
        class="separator">
        >
    </span>
</ng-container>
