export const FIELD_MAX_LENGTH: { [fieldName: string]: number } = {
    name: 80,
    slogan: 250,
    locationDescription: 50,
    recommendedTimeNeeded: 80,
    description: 20000,
    accessibilityDescription: 10000,
    additionalInformationTitle: 120,
    additionalInformationContent: 70000,
    latitude: 11,
    longitude: 11,
    topicTitle: 120,
    topicContent: 70000,
    keywords: 100,
    keyword: 50,
    message: 500,
    radius: 300
};

export const FIELD_MIN_LENGTH: { [fieldName: string]: number } = {
    media: 5000,
    radius: 40
};

export const FIELD_PATTERN: { [fieldName: string]: string } = {
    coordinateLat:
        '^(\\+|-)?(?:90(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,9})?))$',
    coordinateLon:
        '^(\\+|-)?(?:180(?:(?:\\.0{1,9})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,9})?))$',
    dateMonthDay: '^(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])$',
    tags: '^[^<>%$!@#]*$'
};
