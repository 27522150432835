import { createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import {
    GameChallengesSection,
    GameChallengesSectionData,
    getChallengeDialogDefaultState
} from '../../models/game-wizard-challenges.model';
import {
    AddGameChallengeMedia,
    AddGamePostChallengeMedia,
    DeleteChallenge,
    DeleteGameChallengeMedia,
    DeleteGamePostChallengeMedia,
    OrderChallenges,
    OrderGameChallengeMedia,
    OrderGamePostChallengeMedia,
    SelectChallenge,
    setChallengeDetailsSelectedLangTab,
    SetChallengeDialogStage,
    SetChallengeType,
    SetGameChallenges,
    setPostChallengeSelectedLangTab,
    UnSelectChallenge,
    UpdateChallengeAnswers,
    UpdateChallengeDescription,
    UpdateChallengeNavigationMode,
    UpdateChallengeQuestion,
    UpdateChallengeRadius,
    UpdateChallengeTitle,
    UpdatePostChallengeDescription
} from '../actions/game-wizard-challenges.actions';
import {
    Challenge,
    ChallengeTextTypes,
    ChallengeTypes
} from '../../../games/models/challenge.interface';
import {
    AddGameLanguage,
    RemoveGameLanguage
} from '../actions/game-wizard-languages.actions';
import { ChallengesDialogStages } from '../../pages/game-challenge-dialog/game-challenges-dialog.const';
import { LocalesKeysObject, LocaleType } from '../../../shared/locales.models';
import { orderGameMediaItems } from '../../constants/game-wizard.const';
import { GameWizardSectionStatus } from '../../game-wizard.model';

export const gameChallengesReducer = createReducer(
    {} as GameChallengesSection,
    immerOn(AddGameLanguage, (state, { langToAdd, defaultLang }) => {
        state.data = {
            ...(state.data || {}),
            [langToAdd]: {
                ...Object.values(
                    state.data?.[
                        defaultLang || Object.keys(state.data || {})[0]
                    ] || {}
                )!.reduce(
                    (accumulator, challenge) => ({
                        ...accumulator,
                        [challenge.soiId]: {
                            ...challenge,
                            title: '',
                            description: '',
                            question: '',
                            answers: challenge?.answers?.length
                                ? challenge.type ===
                                  ChallengeTypes.MULTIPLECHOICE
                                    ? [1, 2, 3, 4].map((num) => ({
                                          value: '',
                                          isCorrect: num === 1
                                      }))
                                    : [{ isCorrect: true, value: '' }]
                                : undefined,
                            postChallengeDescription: '',
                            detailsDescription: '',
                            media: state.sectionStatus.dialogSteps[
                                challenge.soiId
                            ][ChallengesDialogStages.CHALLENGE_DETAILS]
                                .sectionStatus.isMediaChanged
                                ? []
                                : challenge.media,
                            postChallengeMedia: state.sectionStatus.dialogSteps[
                                challenge.soiId
                            ][ChallengesDialogStages.POST_CHALLENGE]
                                .sectionStatus.isMediaChanged
                                ? []
                                : challenge.postChallengeMedia
                        }
                    }),
                    {}
                )
            }
        } as LocalesKeysObject<{
            [spotId: string]: Challenge;
        }>;
        if (Object.values(state.data).length === 1) {
            Object.values(state.sectionStatus.dialogSteps).forEach(
                (challenge) => {
                    challenge.challengeDetails.sectionStatus.selectedLangTab =
                        langToAdd;
                    challenge.postChallenge.sectionStatus.selectedLangTab =
                        langToAdd;
                }
            );
        }
        state.sectionStatus.invalid = checkIfChallengeSectionInvalid(
            state.data!
        );
        state.sectionStatus.complete = checkIfChallengeSectionComplete(
            state.data!
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(RemoveGameLanguage, (state, { langToRemove }) => {
        delete state.data?.[langToRemove];
        state.sectionStatus.invalid = checkIfChallengeSectionInvalid(
            state.data!
        );
        state.sectionStatus.complete = checkIfChallengeSectionComplete(
            state.data!
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        Object.values(state.sectionStatus.dialogSteps).forEach((challenge) => {
            if (
                challenge.challengeDetails.sectionStatus.selectedLangTab ===
                langToRemove
            ) {
                challenge.challengeDetails.sectionStatus.selectedLangTab =
                    Object.keys(state.data || {})[0] as LocaleType;
            }
            if (
                challenge.postChallenge.sectionStatus.selectedLangTab ===
                langToRemove
            ) {
                challenge.postChallenge.sectionStatus.selectedLangTab =
                    Object.keys(state.data || {})[0] as LocaleType;
            }
        });
    }),
    immerOn(SetGameChallenges, (state, { challenges }) => {
        state.data = challenges;
    }),
    immerOn(SelectChallenge, (state, { spotId }) => {
        (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
            if (!state.data?.[lang]?.[spotId]) {
                state.data![lang][spotId] = {
                    soiId: spotId,
                    index: Object.values(state.data![lang])?.length + 1
                } as Challenge;
            }
        });
        state.sectionStatus.selectedChallengeSpotId = spotId;
        state.sectionStatus.dialogSteps[
            state.sectionStatus.selectedChallengeSpotId
        ] = {
            ...state.sectionStatus.dialogSteps[
                state.sectionStatus.selectedChallengeSpotId
            ],
            ...getChallengeDialogDefaultState(
                Object.values(state.data || {}).map(
                    (langChallenges) =>
                        langChallenges[
                            state.sectionStatus.selectedChallengeSpotId!
                        ]
                )
            )
        };
    }),
    immerOn(UnSelectChallenge, (state) => {
        state.sectionStatus.selectedChallengeSpotId = undefined;
        state.sectionStatus.invalid = checkIfChallengeSectionInvalid(
            state.data!
        );
        state.sectionStatus.complete = checkIfChallengeSectionComplete(
            state.data!
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(DeleteChallenge, (state, { spotId }) => {
        (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
            delete state.data?.[lang]?.[spotId];
            Object.values(state.data?.[lang] || {}).forEach(
                (challenge, index) => {
                    challenge.index = index + 1;
                }
            );
        });
        state.sectionStatus.selectedChallengeSpotId = undefined;
    }),
    immerOn(OrderChallenges, (state, { previousIndex, currentIndex }) => {
        (Object.keys(state.data!) as LocaleType[])?.forEach((lang) => {
            Object.keys(state.data![lang]).forEach((spotId) => {
                if (state.data![lang][spotId].index === previousIndex) {
                    state.data![lang][spotId].index = currentIndex;
                } else if (state.data![lang][spotId].index === currentIndex) {
                    state.data![lang][spotId].index = previousIndex;
                }
            });
        });
    }),
    immerOn(SetChallengeDialogStage, (state, { challengeDialogStage }) => {
        state.sectionStatus.dialogSteps[
            state.sectionStatus.selectedChallengeSpotId!
        ].selectedChallengeStage = challengeDialogStage;
    }),
    immerOn(SetChallengeType, (state, { challengeDisplayType }) => {
        (Object.keys(state.data!) as LocaleType[])?.forEach((lang) => {
            if (
                !state.data?.[lang][
                    state.sectionStatus.selectedChallengeSpotId!
                ]
            ) {
                return;
            }
            state.sectionStatus.isChallengeTextWithCorrectAnswer =
                challengeDisplayType === ChallengeTextTypes.TEXT_CORRECT;

            state.data[lang][
                state.sectionStatus.selectedChallengeSpotId!
            ].type = Object.values(ChallengeTextTypes).includes(
                challengeDisplayType as ChallengeTextTypes
            )
                ? ChallengeTypes.TEXT
                : (challengeDisplayType as ChallengeTypes);

            // setting up answers array by the current type
            state.data[lang][
                state.sectionStatus.selectedChallengeSpotId!
            ].answers =
                challengeDisplayType === ChallengeTypes.MULTIPLECHOICE
                    ? [1, 2, 3, 4].map((num) => ({
                          value: '',
                          isCorrect: num === 1
                      }))
                    : challengeDisplayType === ChallengeTextTypes.TEXT_CORRECT
                    ? [
                          {
                              isCorrect: true,
                              value: ''
                          }
                      ]
                    : undefined;

            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
                getDialogStepSectionStatus(
                    state,
                    ChallengesDialogStages.CHALLENGE_DETAILS,
                    ['title', 'description'],
                    ['media'],
                    ['title'],
                    ['media']
                );
        });

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.SELECT_TYPE]!.sectionStatus = {
            ...state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.SELECT_TYPE]!.sectionStatus,
            invalid: !challengeDisplayType,
            complete: !!challengeDisplayType,
            disableDependants: !challengeDisplayType
        };
    }),
    immerOn(UpdateChallengeTitle, (state, { title, local }) => {
        state.data![local][state.sectionStatus.selectedChallengeSpotId!].title =
            title;

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
            getDialogStepSectionStatus(
                state,
                ChallengesDialogStages.CHALLENGE_DETAILS,
                ['title', 'description'],
                ['media'],
                ['title'],
                ['media']
            );
    }),
    immerOn(UpdateChallengeDescription, (state, { description, local }) => {
        state.data![local][
            state.sectionStatus.selectedChallengeSpotId!
        ].description = description;

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
            getDialogStepSectionStatus(
                state,
                ChallengesDialogStages.CHALLENGE_DETAILS,
                ['title', 'description'],
                ['media']
            );
    }),
    immerOn(AddGameChallengeMedia, (state, { path, local, defaultLang }) => {
        if (
            local === defaultLang &&
            !state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus
                .isMediaChanged
        ) {
            (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                state.data![lang][
                    state.sectionStatus.selectedChallengeSpotId!
                ].media = [
                    ...(state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].media || []),
                    {
                        path,
                        index:
                            state.data![lang][
                                state.sectionStatus.selectedChallengeSpotId!
                            ].media?.length || 0
                    }
                ];
            });
        } else {
            state.data![local][
                state.sectionStatus.selectedChallengeSpotId!
            ].media = [
                ...(state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].media || []),
                {
                    path,
                    index:
                        state.data![local][
                            state.sectionStatus.selectedChallengeSpotId!
                        ].media?.length || 0
                }
            ];
            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][
                ChallengesDialogStages.CHALLENGE_DETAILS
            ]!.sectionStatus.isMediaChanged = true;
        }

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
            getDialogStepSectionStatus(
                state,
                ChallengesDialogStages.CHALLENGE_DETAILS,
                ['title', 'description'],
                ['media'],
                ['title'],
                ['media']
            );
    }),
    immerOn(OrderGameChallengeMedia, (state, { media, local, defaultLang }) => {
        if (
            local === defaultLang &&
            !state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus
                .isMediaChanged
        ) {
            (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                state.data![lang][
                    state.sectionStatus.selectedChallengeSpotId!
                ].media = orderGameMediaItems(media);
            });
        } else {
            state.data![local][
                state.sectionStatus.selectedChallengeSpotId!
            ].media = orderGameMediaItems(media);
            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][
                ChallengesDialogStages.CHALLENGE_DETAILS
            ]!.sectionStatus.isMediaChanged = true;
        }
    }),
    immerOn(
        DeleteGameChallengeMedia,
        (state, { index, local, defaultLang }) => {
            if (
                local === defaultLang &&
                !state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus
                    .isMediaChanged
            ) {
                (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                    const filteredMedia = state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].media?.filter((postChallengeMedia, i) => i !== index);
                    state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].media = orderGameMediaItems(filteredMedia!);
                });
            } else {
                const filteredMedia = state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].media?.filter((postChallengeMedia, i) => i !== index);
                state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].media = orderGameMediaItems(filteredMedia!);
                state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][
                    ChallengesDialogStages.CHALLENGE_DETAILS
                ]!.sectionStatus.isMediaChanged = true;
            }
            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
                getDialogStepSectionStatus(
                    state,
                    ChallengesDialogStages.CHALLENGE_DETAILS,
                    ['title', 'description'],
                    ['media'],
                    ['title'],
                    ['media']
                );
        }
    ),
    immerOn(UpdateChallengeAnswers, (state, { answers, local }) => {
        state.data![local][
            state.sectionStatus.selectedChallengeSpotId!
        ].answers = answers!;

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.CHALLENGE_DETAILS]!.sectionStatus =
            getDialogStepSectionStatus(
                state,
                ChallengesDialogStages.CHALLENGE_DETAILS,
                ['title', 'description'],
                ['media'],
                ['title'],
                ['media']
            );
    }),
    immerOn(UpdateChallengeQuestion, (state, { question, local }) => {
        state.data![local][
            state.sectionStatus.selectedChallengeSpotId!
        ].question = question!;
    }),
    immerOn(
        UpdatePostChallengeDescription,
        (state, { postChallengeDescription, local }) => {
            state.data![local][
                state.sectionStatus.selectedChallengeSpotId!
            ].postChallengeDescription = postChallengeDescription!;

            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus =
                getDialogStepSectionStatus(
                    state,
                    ChallengesDialogStages.POST_CHALLENGE,
                    ['postChallengeDescription'],
                    ['postChallengeMedia'],
                    ['postChallengeDescription'],
                    ['postChallengeMedia']
                );
        }
    ),
    immerOn(
        AddGamePostChallengeMedia,
        (state, { path, local, defaultLang }) => {
            if (
                local === defaultLang &&
                !state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus
                    .isMediaChanged
            ) {
                (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                    state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].postChallengeMedia = [
                        ...(state.data![lang][
                            state.sectionStatus.selectedChallengeSpotId!
                        ].postChallengeMedia || []),
                        {
                            path,
                            index:
                                state.data![lang][
                                    state.sectionStatus.selectedChallengeSpotId!
                                ].postChallengeMedia?.length || 0
                        }
                    ];
                });
            } else {
                state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].postChallengeMedia = [
                    ...(state.data![local][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].postChallengeMedia || []),
                    {
                        path,
                        index:
                            state.data![local][
                                state.sectionStatus.selectedChallengeSpotId!
                            ].postChallengeMedia?.length || 0
                    }
                ];
                state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][
                    ChallengesDialogStages.POST_CHALLENGE
                ]!.sectionStatus.isMediaChanged = true;
            }
            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus =
                getDialogStepSectionStatus(
                    state,
                    ChallengesDialogStages.POST_CHALLENGE,
                    ['postChallengeDescription'],
                    ['postChallengeMedia'],
                    ['postChallengeDescription'],
                    ['postChallengeMedia']
                );
        }
    ),
    immerOn(
        OrderGamePostChallengeMedia,
        (state, { media, local, defaultLang }) => {
            if (
                local === defaultLang &&
                !state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus
                    .isMediaChanged
            ) {
                (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                    state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].postChallengeMedia = orderGameMediaItems(media);
                });
            } else {
                state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].postChallengeMedia = orderGameMediaItems(media);
                state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][
                    ChallengesDialogStages.POST_CHALLENGE
                ]!.sectionStatus.isMediaChanged = true;
            }
        }
    ),
    immerOn(
        DeleteGamePostChallengeMedia,
        (state, { index, local, defaultLang }) => {
            if (
                local === defaultLang &&
                !state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus
                    .isMediaChanged
            ) {
                (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
                    const filteredMedia = state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].postChallengeMedia?.filter(
                        (postChallengeMedia, i) => i !== index
                    );
                    state.data![lang][
                        state.sectionStatus.selectedChallengeSpotId!
                    ].postChallengeMedia = orderGameMediaItems(filteredMedia!);
                });
            } else {
                const filteredMedia = state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].postChallengeMedia?.filter(
                    (postChallengeMedia, i) => i !== index
                );
                state.data![local][
                    state.sectionStatus.selectedChallengeSpotId!
                ].postChallengeMedia = orderGameMediaItems(filteredMedia!);
                state.sectionStatus.dialogSteps![
                    state.sectionStatus.selectedChallengeSpotId!
                ][
                    ChallengesDialogStages.POST_CHALLENGE
                ]!.sectionStatus.isMediaChanged = true;
            }
            state.sectionStatus.dialogSteps![
                state.sectionStatus.selectedChallengeSpotId!
            ][ChallengesDialogStages.POST_CHALLENGE]!.sectionStatus =
                getDialogStepSectionStatus(
                    state,
                    ChallengesDialogStages.POST_CHALLENGE,
                    ['postChallengeDescription'],
                    ['postChallengeMedia'],
                    ['postChallengeDescription'],
                    ['postChallengeMedia']
                );
        }
    ),
    immerOn(UpdateChallengeRadius, (state, { radius }) => {
        (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
            state.data![lang][
                state.sectionStatus.selectedChallengeSpotId!
            ].radius = radius;
        });

        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.SETTINGS]!.sectionStatus =
            getDialogStepSectionStatus(state, ChallengesDialogStages.SETTINGS, [
                'radius',
                'navigationMode'
            ]);
    }),
    immerOn(UpdateChallengeNavigationMode, (state, { navigationMode }) => {
        (Object.keys(state.data!) as LocaleType[]).forEach((lang) => {
            state.data![lang][
                state.sectionStatus.selectedChallengeSpotId!
            ].navigationMode = navigationMode;
        });
        state.sectionStatus.dialogSteps![
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.SETTINGS]!.sectionStatus =
            getDialogStepSectionStatus(state, ChallengesDialogStages.SETTINGS, [
                'radius',
                'navigationMode'
            ]);
    }),
    immerOn(
        setChallengeDetailsSelectedLangTab,
        (state, { selectedLangTab }) => {
            state.sectionStatus.dialogSteps[
                state.sectionStatus.selectedChallengeSpotId!
            ][
                ChallengesDialogStages.CHALLENGE_DETAILS
            ].sectionStatus.selectedLangTab = selectedLangTab;
        }
    ),
    immerOn(setPostChallengeSelectedLangTab, (state, { selectedLangTab }) => {
        state.sectionStatus.dialogSteps[
            state.sectionStatus.selectedChallengeSpotId!
        ][ChallengesDialogStages.POST_CHALLENGE].sectionStatus.selectedLangTab =
            selectedLangTab;
    })
);

function getDialogStepSectionStatus(
    state: GameChallengesSection,
    stage: ChallengesDialogStages,
    fieldsToComplete: (keyof Challenge)[],
    fieldsLengthToComplete?: (keyof Challenge)[],
    fieldsToValidate?: (keyof Challenge)[],
    fieldsLengthToValidate?: (keyof Challenge)[]
) {
    const currentDialogStepStatus =
        state.sectionStatus.dialogSteps[
            state.sectionStatus.selectedChallengeSpotId!
        ][stage].sectionStatus;
    const newStatus: GameWizardSectionStatus = {
        ...currentDialogStepStatus,
        invalid:
            fieldsToValidate || fieldsLengthToValidate
                ? !Object.values(state.data!).every(
                      (langChallenges) =>
                          (!fieldsToValidate ||
                              fieldsToValidate.every(
                                  (field) =>
                                      !!langChallenges[
                                          state.sectionStatus
                                              .selectedChallengeSpotId!
                                      ][field]
                              )) &&
                          (!fieldsLengthToValidate ||
                              fieldsLengthToValidate?.every(
                                  (field) =>
                                      !!(
                                          langChallenges[
                                              state.sectionStatus
                                                  .selectedChallengeSpotId!
                                          ][field] as []
                                      )?.length
                              )) &&
                          (!(
                              stage === ChallengesDialogStages.CHALLENGE_DETAILS
                          ) ||
                              checkIfChallengeAnswersIsExistAndIsValid(
                                  langChallenges[
                                      state.sectionStatus
                                          .selectedChallengeSpotId!
                                  ]
                              ))
                  )
                : currentDialogStepStatus.invalid,
        complete:
            fieldsToComplete || fieldsLengthToComplete
                ? Object.values(state.data!).every(
                      (langChallenges) =>
                          (!fieldsToComplete ||
                              fieldsToComplete.every(
                                  (field) =>
                                      !!langChallenges[
                                          state.sectionStatus
                                              .selectedChallengeSpotId!
                                      ][field]
                              )) &&
                          (!fieldsLengthToComplete ||
                              fieldsLengthToComplete.every(
                                  (field) =>
                                      !!(
                                          langChallenges[
                                              state.sectionStatus
                                                  .selectedChallengeSpotId!
                                          ][field] as []
                                      )?.length
                              )) &&
                          (!(
                              stage === ChallengesDialogStages.CHALLENGE_DETAILS
                          ) ||
                              checkIfChallengeAnswersIsExistAndIsValid(
                                  langChallenges[
                                      state.sectionStatus
                                          .selectedChallengeSpotId!
                                  ]
                              ))
                  )
                : currentDialogStepStatus.complete
    };
    newStatus.disableDependants = !!newStatus.invalid;
    return newStatus;
}

function checkIfChallengeSectionInvalid(stateData: GameChallengesSectionData) {
    return (
        !Object.keys(stateData || {}).length ||
        !(Object.keys(stateData || {}) as LocaleType[]).every(
            (lang) =>
                Object.keys(stateData[lang])?.length &&
                Object.keys(stateData[lang]).every((challengeSpotId) =>
                    checkIfChallengeValid(stateData?.[lang][challengeSpotId])
                )
        )
    );
}

export function checkIfChallengeValid(challenge: Challenge) {
    return (
        challenge?.type &&
        challenge?.title &&
        challenge?.media?.length &&
        challenge?.postChallengeMedia?.length &&
        challenge?.postChallengeDescription &&
        checkIfChallengeAnswersIsExistAndIsValid(challenge)
    );
}

function checkIfChallengeSectionComplete(stateData: GameChallengesSectionData) {
    return (
        !!Object.keys(stateData || {}).length &&
        (Object.keys(stateData || {}) as LocaleType[]).every(
            (lang) =>
                Object.keys(stateData[lang])?.length &&
                Object.keys(stateData![lang]).every((challengeSpotId) =>
                    checkIfChallengeCompleted(
                        stateData?.[lang][challengeSpotId]
                    )
                )
        )
    );
}

export function checkIfChallengeCompleted(challenge: Challenge) {
    return (
        challenge?.type &&
        challenge.title &&
        challenge.description &&
        challenge.media?.length &&
        challenge.postChallengeDescription &&
        challenge.postChallengeMedia?.length &&
        challenge.radius &&
        challenge.navigationMode &&
        checkIfChallengeAnswersIsExistAndIsValid(challenge)
    );
}

function checkIfChallengeAnswersIsExistAndIsValid(
    challengeState: Challenge
): boolean {
    return (
        !challengeState.answers?.length ||
        (challengeState?.answers?.some((answer) => answer.isCorrect) &&
            challengeState.answers?.every((answer) => answer.value))
    );
}
