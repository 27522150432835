import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutePaths } from '../app-routing.module';
import { AppStoreService } from '../store/app-store.service';

export const IsLoggedInGuard: CanActivateFn = () => {
    const appStoreService = inject(AppStoreService);
    const router = inject(Router);
    const user = appStoreService.state.auth.user;
    const isNavigateBeforeLogout =
        router.getCurrentNavigation()?.extras.state?.navigateBeforeLogout;
    if (user?.siteIdForLiteWizard) {
        router.navigate([AppRoutePaths.LIZARD]);
        return false;
    } else if (user && !isNavigateBeforeLogout) {
        router.navigate([AppRoutePaths.SITES]);
        return false;
    } else {
        return true;
    }
};
