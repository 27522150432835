import {
    createAction,
    createReducer,
    createSelector,
    on,
    props
} from '@ngrx/store';
import { AppState } from '../../../store/app-store.model';
import { SiteSettings } from '../../models/site-settings.model';

export enum SiteSettingsActionType {
    SET_SETTINGS = '[SiteSettings] Set Settings'
}

export const setSiteSettings = createAction(
    SiteSettingsActionType.SET_SETTINGS,
    props<SiteSettings>()
);

export const getSiteSettings = createSelector(
    (state: AppState) => state.siteSettings,
    (state) => state
);

export const getSiteCurrency = createSelector(
    getSiteSettings,
    (state) => state.currency
);
export const getSiteDateFormat = createSelector(
    getSiteSettings,
    (state) => state.dateFormat
);

export const getSiteTimeFormat = createSelector(
    getSiteSettings,
    (state) => state.timeFormat!
);

export const getSiteLengthType = createSelector(
    getSiteSettings,
    (state) => state.lengthType
);

export const SiteSettingsReducer = createReducer(
    {} as SiteSettings,
    on(setSiteSettings, (state, siteSettings) => siteSettings)
);
