import { createAction, props } from '@ngrx/store';
import {
    SiteHoursAdvance,
    SiteHoursDay,
    SiteHoursException,
    SiteHoursSettings
} from '../../models/site-wizard-hours.model';

export enum HoursActionTypes {
    UPDATE_HOURS_SETTINGS = '[SiteWizard] Hours Update Settings',
    BASIC_UPDATE_DATE_RANGE = '[SiteWizard] Hours Basic Update Date Range',
    EXCEPTIONS_ADD_DATE_RANGE = '[SiteWizard] Hours Exception Add Date Range',
    EXCEPTIONS_DELETE_DATE_RANGE = '[SiteWizard] Hours Exception Delete Date Range',
    EXCEPTIONS_UPDATE_DATE_RANGE = '[SiteWizard] Hours Exception Update Date Range',
    ADVANCED_ADD_DATE_RANGE = '[SiteWizard] Hours Advanced Add Date Range',
    ADVANCED_DELETE_DATE_RANGE = '[SiteWizard] Hours Advanced Delete Date Range',
    ADVANCED_UPDATE_DATE_RANGE = '[SiteWizard] Hours Advanced Update Date Range',
    HOURS_COMPLETE = '[SiteWizard] Hours Complete'
}

export const UpdateHoursSettings = createAction(
    HoursActionTypes.UPDATE_HOURS_SETTINGS,
    props<{ settings: SiteHoursSettings }>()
);

export const UpdateBasicDateRange = createAction(
    HoursActionTypes.BASIC_UPDATE_DATE_RANGE,
    props<{ basicRange: SiteHoursDay; day: number; invalid: boolean }>()
);

export const AddExceptionsDateRange = createAction(
    HoursActionTypes.EXCEPTIONS_ADD_DATE_RANGE,
    props<{ invalid: boolean }>()
);

export const DeleteExceptionsDateRange = createAction(
    HoursActionTypes.EXCEPTIONS_DELETE_DATE_RANGE,
    props<{ index: number; invalid: boolean }>()
);

export const UpdateExceptionsDateRange = createAction(
    HoursActionTypes.EXCEPTIONS_UPDATE_DATE_RANGE,
    props<{
        dateRangeException: SiteHoursException;
        index: number;
        invalid: boolean;
    }>()
);

export const AddAdvancedDateRange = createAction(
    HoursActionTypes.ADVANCED_ADD_DATE_RANGE,
    props<{ invalid: boolean }>()
);

export const DeleteAdvancedDateRange = createAction(
    HoursActionTypes.ADVANCED_DELETE_DATE_RANGE,
    props<{ index: number; invalid: boolean }>()
);

export const UpdateAdvancedDateRange = createAction(
    HoursActionTypes.ADVANCED_UPDATE_DATE_RANGE,
    props<{
        dateRangeAdvanced: SiteHoursAdvance;
        index: number;
        invalid: boolean;
    }>()
);

export const HoursComplete = createAction(HoursActionTypes.HOURS_COMPLETE);
