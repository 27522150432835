import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { SnackbarService } from '../../../services/snackbar.service';
import { PasswordService } from '../../services/password.service';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { noWhitespaceValidator } from '../../../shared/shared-utils';
import { AppRoutePaths } from '../../../app-routing.module';
import { AuthRoutePaths } from '../../auth-routing.module';
import { concatMap, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    protected readonly AuthRoutePaths = AuthRoutePaths;
    imgReset = `${environment.ASSETS_BASE_URL}/assets/images/icon-reset.svg`;
    imgSuccess = `${environment.ASSETS_BASE_URL}/assets/images/icon-success.svg`;
    imgToken = `${environment.ASSETS_BASE_URL}/assets/images/icon-token.svg`;
    expiredToken$!: Observable<boolean>;
    resetPassword!: boolean;
    passwordSuccess$ = of(false);
    token!: string;
    email!: string;
    resetForm!: UntypedFormGroup;
    inputType!: 'password' | 'text';

    constructor(
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private passwordService: PasswordService,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.inputType = 'password';
        this.expiredToken$ = this.activatedRoute.queryParams.pipe(
            tap((params) => {
                this.token = params.token;
                this.email = params.email;
            }),
            concatMap((params) =>
                this.passwordService.validateToken(params.email, params.token)
            ),
            map(() => {
                this.setPasswordForm();
                this.resetPassword = true;
                return false;
            }),
            catchError(() => of(true))
        );
    }

    setPasswordForm(): void {
        this.resetForm = this.fb.group({
            password: new UntypedFormControl('', [
                noWhitespaceValidator,
                Validators.required,
                Validators.minLength(8)
            ])
        });
    }

    submitPassword(): void {
        this.passwordSuccess$ = this.passwordService
            .resetPassword(
                this.token,
                this.email,
                this.resetForm.value.password
            )
            .pipe(
                map(() => {
                    this.resetPassword = false;
                    return true;
                }),
                catchError(() => {
                    this.snackbarService.open(
                        this.translate.instant(
                            'THERE_WAS_AN_ERROR_RESETTING_PASSWORD'
                        )
                    );
                    return of(false);
                })
            );
    }

    toggleInputType(): void {
        this.inputType = this.inputType === 'password' ? 'text' : 'password';
    }
}
