import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { noWhitespaceValidator } from '../../../shared/shared-utils';
import { environment } from '@env/environment';
import { PasswordService } from '../../services/password.service';
import { AppRoutePaths } from '../../../app-routing.module';
import { AuthRoutePaths } from '../../auth-routing.module';
import { Observable, of, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    protected readonly AuthRoutePaths = AuthRoutePaths;
    imgSuccessReset = `${environment.ASSETS_BASE_URL}/assets/images/success-icon.svg`;
    resetForm!: UntypedFormGroup;
    isSuccess$: Observable<boolean | undefined> = of(undefined);

    constructor(
        private fb: UntypedFormBuilder,
        private passwordService: PasswordService
    ) {}

    ngOnInit(): void {
        this.resetForm = this.fb.group({
            email: new UntypedFormControl('', [
                noWhitespaceValidator,
                Validators.email,
                Validators.required
            ])
        });
    }

    resetPassword(): void {
        this.isSuccess$ = this.passwordService
            .sendEmail(this.resetForm.value.email)
            .pipe(
                map(() => true),
                catchError(() => of(false)),
                shareReplay()
            );
    }
}
