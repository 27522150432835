import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MapboxService } from '../../../mapbox/services/mapbox.service';
import { MapboxMap } from '../../../mapbox/utils/MapboxMap';
import { ActivatedRoute } from '@angular/router';
import { LiveService } from '../../services/live.service';
import { takeUntil } from 'rxjs/operators';
import { liveStats, SOCKET_EVENTS } from '../../sites.constants';
import { Subject } from 'rxjs';
import { locToLngLat } from '../../../mapbox/utils/helpers';
import { LOCALES } from '../../../shared/locales.constants';
import { MapboxEventsService } from '../../../mapbox/services/mapbox-events.service';
import { NewAlertComponent } from '../../components/alerts-dialog/new-alert/new-alert.component';
import { Position } from 'geojson';
import { KeyValue } from '@angular/common';
import { SiteLocalStorageService } from '../../services/site-local-storage.service';
import { LocaleType } from '../../../shared/locales.models';
import { MatDialog } from '@angular/material/dialog';
import { SiteCard } from '../../models/site.model';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LiveComponent implements OnInit, AfterViewInit, OnDestroy {
    LOCALES: { [key: string]: string } = LOCALES;
    liveStats = liveStats;
    map!: MapboxMap;
    site!: SiteCard;
    stats: any;
    localeStats!: { [key: string]: any };
    coords!: Position[];
    @ViewChild('map', { static: false }) mapContainer!: ElementRef;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private mapboxService: MapboxService,
        private mapboxEventsService: MapboxEventsService,
        private activatedRouter: ActivatedRoute,
        private liveService: LiveService,
        private matDialog: MatDialog,
        private localStorageService: SiteLocalStorageService
    ) {}

    ngOnInit(): void {
        this.site = this.activatedRouter.parent?.snapshot.data.siteInfo;
    }

    ngAfterViewInit(): void {
        this.map = this.mapboxService.createMap(
            this.mapContainer.nativeElement,
            true,
            true
        );
        this.map.drawPolygon('polygon', this.site.map?.polygon?.data!);
        this.coords = this.site.map?.polygon?.data?.coordinates[0]!;
        this.fitBounds();
        const filteredSpots = this.site.spots?.filter(
            (spot) => spot?.settings?.isActive !== false
        );
        Object.entries(filteredSpots!)?.forEach(([spotId, spot]) => {
            this.map.drawSpotMarker(
                spot.coordinate?.lat!,
                spot.coordinate?.lon!,
                spot.id!,
                spot.general?.[this.site?.defaultLanguage as LocaleType]?.name
            );
        });

        this.liveService.init(this.site.id!);
        this.liveService.liveEvent$
            ?.pipe(takeUntil(this.unsubscribe$))
            .subscribe((eventData) => {
                switch (eventData[0]) {
                    case SOCKET_EVENTS.CONNECT:
                        break;
                    case SOCKET_EVENTS.DISCONNECT:
                        break;
                    case SOCKET_EVENTS.STATS_SPLIT:
                        this.localeStats = eventData[1][0];
                        this.stats = eventData[1][1];
                        break;
                    case SOCKET_EVENTS.USER_LOCATION:
                        this.handleUserLocationUpdate(eventData[1]);
                        break;
                }
            });

        this.mapboxEventsService.init();
        this.mapboxEventsService.mapboxEvent$
            ?.pipe(takeUntil(this.unsubscribe$))
            .subscribe((eventData) => {
                if (eventData[0] === this.map.EVENTS.SPOT_CLICK) {
                    this.openAlertsDialog(eventData[1]);
                }
            });
        this.localStorageService.isMinimizedNavigation$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                setTimeout(() => this.map.invalidateSize());
            });
    }

    ngOnDestroy(): void {
        this.liveService.disconnectSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    fitBounds(): void {
        if (!this.coords) {
            return;
        }
        this.map.fitBounds(this.coords, {
            padding: 40,
            duration: 1000,
            pitch: 0,
            bearing: 0
        });
    }

    handleUserLocationUpdate(updatedInfo: any): void {
        const userId = updatedInfo.user_id;
        const loc = updatedInfo.loc;
        if (loc) {
            const LngLat = locToLngLat(loc);
            this.map.drawLiveUserMarker(LngLat, userId);
        } else {
            this.map.removeLiveUserMarker(userId);
        }
    }

    openAlertsDialog(soiId: string): void {
        const dialogRef = this.matDialog.open(NewAlertComponent, {
            data: {
                defaultLanguage: this.site.defaultLanguage,
                selectedSoi: soiId,
                spots: this.site.spots?.filter(
                    (spot) => spot?.settings?.isActive !== false
                )
            }
        });

        const sub = dialogRef.componentInstance.addAlert.subscribe((event) => {
            this.liveService.sendNewAlert({
                msg: event.text,
                soi_id: event.spot
            });
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }

    originalOrder = (
        a: KeyValue<string, string>,
        b: KeyValue<string, string>
    ): number => 0;
}
