import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../../standalone/components/wizard/wizars-template/wizard-template.model';
import {
    ChallengeDisplayTypes,
    ChallengeTextTypes,
    ChallengeTypes
} from '../../../games/models/challenge.interface';

export enum ChallengesDialogStages {
    SELECT_TYPE = 'selectType',
    CHALLENGE_DETAILS = 'challengeDetails',
    POST_CHALLENGE = 'postChallenge',
    SETTINGS = 'settings'
}

export const challengesWizardStageDetails: MapWizardStageDetails<ChallengesDialogStages> =
    new Map<
        ChallengesDialogStages,
        WizardSectionDetails<ChallengesDialogStages>
    >([
        [
            ChallengesDialogStages.SELECT_TYPE,
            {
                stage: ChallengesDialogStages.SELECT_TYPE,
                titleKey: 'SELECT_YOUR_CHALLENGE_TYPE',
                descriptionKey: 'CHALLENGE_LINKED_TO_SPOT',
                genieTextKey: 'GAME_GENIE_CHALLENGE_SELECT_TYPE',
                genieVideoUrl: 'eGTXy-0J4XQ',
                navBarTextKey: 'SELECT_TYPE',
                icon: 'WTicon-Choose-the-challenge',
                pageLayoutStatus: WizardPageLayout.FULL_ON_DIALOG,
                hidePreviousBtn: true
            }
        ],
        [
            ChallengesDialogStages.CHALLENGE_DETAILS,
            {
                stage: ChallengesDialogStages.CHALLENGE_DETAILS,
                titleKey: 'CHALLENGE',
                descriptionKey: '',
                genieTextKey:
                    'GAME_GENIE_CHALLENGE_DETAILS_ALL_WITHOUT_ANSWERS',
                genieVideoUrl: 'eGTXy-0J4XQ',
                navBarTextKey: 'CHALLENGE',
                icon: 'WTicon-Challanges',
                pageLayoutStatus: WizardPageLayout.FULL_ON_DIALOG,
                dependantOn: [ChallengesDialogStages.SELECT_TYPE]
            }
        ],
        [
            ChallengesDialogStages.POST_CHALLENGE,
            {
                stage: ChallengesDialogStages.POST_CHALLENGE,
                titleKey: 'POST_CHALLENGE',
                descriptionKey: '',
                genieTextKey: 'GAME_GENIE_CHALLENGE_POST_CHALLENGE',
                genieVideoUrl: 'eGTXy-0J4XQ',
                navBarTextKey: 'POST_CHALLENGE',
                icon: 'WTicon-Post-challenge',
                pageLayoutStatus: WizardPageLayout.FULL_ON_DIALOG,
                dependantOn: [ChallengesDialogStages.SELECT_TYPE]
            }
        ],
        [
            ChallengesDialogStages.SETTINGS,
            {
                stage: ChallengesDialogStages.SETTINGS,
                titleKey: 'SETTINGS',
                descriptionKey: '',
                genieTextKey: 'GAME_GENIE_CHALLENGE_SETTINGS',
                genieVideoUrl: 'ONKVQ5hCqgc',
                navBarTextKey: 'SETTINGS',
                icon: 'WTicon-sm_settings',
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS_ON_DIALOG,
                dependantOn: [ChallengesDialogStages.SELECT_TYPE],
                hideNextBtn: true
            }
        ]
    ]);

export const challengeDetailsGenieTextKey = new Map<
    ChallengeDisplayTypes,
    string
>([
    [
        ChallengeTypes.MULTIPLECHOICE,
        'GAME_GENIE_CHALLENGE_DETAILS_MULTIPLE_CHOICE_TYPE'
    ],
    [
        ChallengeTextTypes.TEXT_CORRECT,
        'GAME_GENIE_CHALLENGE_DETAILS_FREE_TEXT_WITH_CORRECT_ANSWER_TYPE'
    ]
]);
