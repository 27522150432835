import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    OnInit
} from '@angular/core';
import * as AuthSelectors from '../../../auth/store/auth-store.selectors';
import { Observable } from 'rxjs';
import { AppRoutePaths } from '../../../app-routing.module';
import { AppStoreService } from '../../../store/app-store.service';
import {
    ActivatedRoute,
    RouterLink,
    RouterOutlet,
    UrlSegmentGroup
} from '@angular/router';
import { SiteWizardRoutePaths } from '../../../site-wizard/site-wizard.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { TypographyDirective } from '../../directives/typography/typography.directive';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, NgIf, RouterOutlet, AsyncPipe, TypographyDirective],
    standalone: true
})
export class MainComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    businessLogoUrl$!: Observable<string | undefined>;
    businessName$!: Observable<string | undefined>;
    @HostBinding('class.is-in-dialog') isInsideDialog =
        ((this.activatedRoute.snapshot as any)._urlSegment as UrlSegmentGroup)
            .segments[4]?.path === SiteWizardRoutePaths.MANAGE_TREKS;

    constructor(
        private activatedRoute: ActivatedRoute,
        private appStoreService: AppStoreService
    ) {}

    ngOnInit(): void {
        this.businessLogoUrl$ = this.appStoreService.store.select(
            AuthSelectors.getUserBusinessLogoUrl
        );
        this.businessName$ = this.appStoreService.store.select(
            AuthSelectors.getUserBusinessName
        );
    }
}
