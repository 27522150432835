import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

import { environment } from '@env/environment';
import { getFileExtension } from '../../../shared/shared-utils';
import { MEDIA_VIDEO_TYPES } from '../../../shared/shared.constants';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
    placeholder = `${environment.ASSETS_BASE_URL}/assets/images/wizard/placeholder.jpg`;

    startedPlaying?: boolean;
    player: any;
    isNotSupportedFormat?: boolean;

    @Input() source?: string;
    @Input() thumbnail?: string;
    @Input() thumbnailFrame = 0.1;
    @Input() videoId?: string;
    @Input() preload?: string;
    @Input() videoType?: string;
    @Input() hidePlay?: boolean;

    @ViewChild('videoPlayer', { static: false }) videoPlayer?: ElementRef;

    constructor() {}

    ngOnInit(): void {
        if (!this.thumbnail) {
            const extension = getFileExtension(this.source!);
            if (
                MEDIA_VIDEO_TYPES.includes(extension as string) &&
                !this.source!.includes('sites-under-construction')
            ) {
                this.thumbnail = this.source!.replace(
                    extension as string,
                    'jpg'
                );
            } else if (
                extension === 'avi' &&
                this.source!.includes('sites-under-construction')
            ) {
                this.isNotSupportedFormat = true;
                this.thumbnail = this.placeholder;
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.isNotSupportedFormat) {
            return;
        }
        // this.player = videojs(this.videoPlayer.nativeElement, {
        //   nativeControlsForTouch: true
        // });
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }

    playVideo(play: boolean): void {
        (this.videoPlayer!.nativeElement as HTMLVideoElement).currentTime = 0;
        if (this.hidePlay) {
            return;
        }
        this.startedPlaying = play;
        this.videoPlayer!.nativeElement.setAttribute('controls', 'true');
        this.videoPlayer!.nativeElement.play();
    }
}
