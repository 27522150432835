import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';
import { ContactStandaloneComponent } from '../contact/contact-standalone.component';
import { SCREEN_BREAK_POINTS, WT_LOGO } from '../../../shared/shared.constants';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutePaths } from '../../../app-routing.module';
import { AppStoreService } from '../../../store/app-store.service';
import { HeaderService } from '../../../services/header.service';
import { AuthRoutePaths } from '../../../auth/auth-routing.module';
import { getUser } from '../../../auth/store/auth-store.selectors';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MobileSidenavComponent } from './components/mobile-sidenav/mobile-sidenav.component';
import { AuthService } from '../../../services/auth.service';

@Component({
    standalone: true,
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        CommonModule,
        RouterLink,
        AsyncPipe,
        NgOptimizedImage,
        BreadcrumbsComponent,
        RouterLinkActive,
        TypographyDirective,
        TranslateModule,
        MatMenuModule,
        MobileSidenavComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
    breadcrumbs$ = this.headerService.breadcrumbs$;
    protected readonly AppRoutePaths = AppRoutePaths;
    protected readonly AuthRoutePaths = AuthRoutePaths;
    WT_LOGO = WT_LOGO;
    user$ = this.appStoreService.store.select(getUser);
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
    isTablet = window.innerWidth < SCREEN_BREAK_POINTS.laptop;
    @HostBinding('class.on-dialog')
    @Input()
    isWithinSiteWizard = false;

    constructor(
        private appStoreService: AppStoreService,
        private matDialog: MatDialog,
        private headerService: HeaderService,
        private authService: AuthService
    ) {}

    onLogout(): void {
        this.authService.logout().subscribe();
    }

    openContact(): void {
        this.matDialog.open(ContactStandaloneComponent);
    }
}
