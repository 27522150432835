import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    MatExpansionModule,
    MatExpansionPanel
} from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CustomButtonStandaloneComponent } from '../buttons/custom-button/custom-button-standalone.component';
import { TypographyDirective } from '../../directives/typography/typography.directive';

@Component({
    standalone: true,
    imports: [
        MatExpansionModule,
        TranslateModule,
        CommonModule,
        CustomButtonStandaloneComponent,
        TypographyDirective
    ],
    selector: 'app-map-panel',
    templateUrl: './map-panel.component.html',
    styleUrls: ['./map-panel.component.scss']
})
export class MapPanelComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() id!: string;
    @Input() containerTitle!: string;
    @Input() open!: boolean;
    @Input() invalid!: boolean;
    @Input() hideEdit = false;
    @Output() nextStage: EventEmitter<{}> = new EventEmitter();
    @Output() openStage: EventEmitter<{}> = new EventEmitter();
    @Output() toggled: EventEmitter<{}> = new EventEmitter();

    @ViewChild(MatExpansionPanel) panel!: MatExpansionPanel;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => this.togglePanel());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.open && !changes?.open?.isFirstChange()) {
            setTimeout(() => this.togglePanel());
        }
    }

    togglePanel(): void {
        if (this.open) {
            this.panel.open();
        } else {
            this.panel.close();
        }
    }
}
