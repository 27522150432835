import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSize, ButtonStyle } from '../buttons.model';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-standalone-custom-button',
    standalone: true,
    imports: [CommonModule, MatButtonModule, RouterLink],
    templateUrl: './custom-button-standalone.component.html',
    styleUrls: ['./custom-button-standalone.component.scss']
})
export class CustomButtonStandaloneComponent {
    @Input() buttonStyle: keyof typeof ButtonStyle = ButtonStyle.PRIMARY;
    @Input() buttonSize: keyof typeof ButtonSize = ButtonSize.LARGE;
    @Input() isDisabled: boolean | undefined = false;
    @Input() isContainsIcon = false;
    @Input() hrefLink?: string;
    @Input() link?: any[] | string;
    @Input() fragment?: string;
    @Input() buttonType?: 'submit' | 'reset' | 'button';
    @Input() routerState?: { [k: string]: any };
    @Input() routerTarget?: '_self' | '_blank' | '_parent' | '_top';
    @Input() routerReplaceUrl?: boolean | string;
    @Input() overrideColor?: string;
    @Output() clicked = new EventEmitter();
}
