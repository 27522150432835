import * as mapboxgl from 'mapbox-gl';
import { SourceInfo } from '../typings/mapbox-utils';
import { FeatureCollection } from 'geojson';
import { AnySourceData } from 'mapbox-gl';

export class CustomLayersRef {
    private sources: { [sourceId: string]: AnySourceData } = {};
    private layers: Array<mapboxgl.Layer>;

    constructor() {
        this.layers = [];
    }

    addSource(source: SourceInfo) {
        this.sources[source.id] = source.source;
    }

    addLayer(layer: mapboxgl.Layer) {
        this.layers.push(layer);
    }

    removeLayer(layer: mapboxgl.Layer) {
        for (let i = 0; i < this.layers.length; i++) {
            if (this.layers[i].id === layer.id) {
                this.layers.splice(i, 1);
                return;
            }
        }
    }

    removeSource(source: SourceInfo) {
        delete this.sources[source.id];
    }

    getSources(): { [sourceId: string]: AnySourceData } {
        return this.sources;
    }

    getLayers(): Array<mapboxgl.Layer> {
        return this.layers;
    }

    setSourceData(id: string, data: FeatureCollection) {
        (this.sources[id] as mapboxgl.GeoJSONSourceRaw).data = data;
    }
}
