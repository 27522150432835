import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot
} from '@angular/router';
import { MetaUtilsService } from '../services/meta.utils.service';

@Injectable({
    providedIn: 'root'
})
export class AddNoindexTagGuard implements CanActivate {
    constructor(private metaUtilsService: MetaUtilsService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        this.metaUtilsService.addTag({ name: 'robots', content: 'noindex' });
        return true;
    }
}
