import { Locales, LocaleType } from './locales.models';

export const DEFAULT_LOCALE: LocaleType = 'en';

export const LOCALES: Locales = {
    af: 'Afrikaans',
    agq: 'Aghem',
    ak: 'Akan',
    am: 'Amharic',
    ar: 'Arabic',
    as: 'Assamese',
    asa: 'Asu',
    az: 'Azerbaijani',
    bas: 'Basaa',
    be: 'Belarusian',
    bem: 'Bemba',
    bez: 'Bena',
    bg: 'Bulgarian',
    bm: 'Bambara',
    bn: 'Bengali',
    bo: 'Tibetan',
    br: 'Breton',
    brx: 'Bodo',
    bs: 'Bosnian',
    ca: 'Catalan',
    cgg: 'Chiga',
    chr: 'Cherokee',
    cs: 'Czech',
    cy: 'Welsh',
    da: 'Danish',
    dav: 'Taita',
    de: 'German',
    dje: 'Zarma',
    dua: 'Duala',
    dyo: 'Jola-Fonyi',
    dz: 'Dzongkha',
    ebu: 'Embu',
    ee: 'Ewe',
    el: 'Greek',
    en: 'English',
    eo: 'Esperanto',
    es: 'Spanish',
    et: 'Estonian',
    eu: 'Basque',
    ewo: 'Ewondo',
    fa: 'Persian',
    ff: 'Fulah',
    fi: 'Finnish',
    fil: 'Filipino',
    fo: 'Faroese',
    fr: 'Français',
    ga: 'Irish',
    gl: 'Galician',
    gsw: 'Swiss German',
    gu: 'Gujarati',
    guz: 'Gusii',
    gv: 'Manx',
    ha: 'Hausa',
    haw: 'Hawaiian',
    // iw: 'עברית',
    he: 'עברית',
    hi: 'Hindi',
    hr: 'Croatian',
    hu: 'Hungarian',
    hy: 'Armenian',
    in: 'Indonesian',
    ig: 'Igbo',
    ii: 'Sichuan Yi',
    is: 'Icelandic',
    it: 'Italian',
    ja: 'Japanese',
    jgo: 'Ngomba',
    kkj: 'Kako',
    kl: 'Kalaallisut',
    kln: 'Kalenjin',
    km: 'Khmer',
    kn: 'Kannada',
    ko: 'Korean',
    kok: 'Konkani',
    ks: 'Kashmiri',
    ksb: 'Shambala',
    ksf: 'Bafia',
    kw: 'Cornish',
    ky: 'Kyrgyz',
    lag: 'Langi',
    lg: 'Ganda',
    lkt: 'Lakota',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    luo: 'Luo',
    luy: 'Luyia',
    lv: 'Latvian',
    mas: 'Masai',
    mer: 'Meru',
    mfe: 'Morisyen',
    mg: 'Malagasy',
    mgo: 'Meta',
    mk: 'Macedonian',
    ml: 'Malayalam',
    mn: 'Mongolian',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltese',
    mua: 'Mundang',
    my: 'Burmese',
    naq: 'Nama',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    nl: 'Dutch',
    nmg: 'Kwasio',
    nn: 'Norwegian Nynorsk',
    nnh: 'Ngiemboon',
    nus: 'Nuer',
    nyn: 'Nyankole',
    om: 'Oromo',
    or: 'Oriya',
    pa: 'Punjabi',
    pl: 'Polish',
    ps: 'Pashto',
    pt: 'Portuguese',
    pt_BR: 'Portuguese',
    rm: 'Romansh',
    rn: 'Rundi',
    ro: 'Romanian',
    rof: 'Rombo',
    ru: 'Pусский',
    rw: 'Kinyarwanda',
    rwk: 'Rwa',
    saq: 'Samburu',
    sbp: 'Sangu',
    seh: 'Sena',
    ses: 'Koyraboro Senni',
    sg: 'Sango',
    shi: 'Tachelhit',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovenian',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albanian',
    sr: 'Serbian',
    sv: 'Swedish',
    sw: 'Swahili',
    swc: 'Congo Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    teo: 'Teso',
    th: 'Thai',
    ti: 'Tigrinya',
    to: 'Tongan',
    tr: 'Turkish',
    twq: 'Tasawaq',
    uk: 'Yкраїнський',
    vi: 'Vietnamese',
    zh: '简体中文',
    zh_CN: '简体中文',
    zh_TW: '繁體中文'
};

export const LOCALES_REVERSE = (Object.keys(LOCALES) as LocaleType[]).reduce(
    (acc, key) => {
        acc[LOCALES[key]] = key;
        return acc;
    },
    {} as Record<string, LocaleType>
);

export const RTL_LOCALES: string[] = [
    'ar', // 'العربية', Arabic
    'arc', // Aramaic
    'bcc', // 'بلوچی مکرانی', Southern Balochi
    'bqi', // 'بختياري', Bakthiari
    'ckb', // 'Soranî / کوردی', Sorani
    'dv', // Dhivehi
    'fa', // 'فارسی', Persian
    'glk', // 'گیلکی', Gilaki
    'he', // 'עברית', Hebrew
    'iw', // 'עברית', Hebrew
    'ku', // 'Kurdî / كوردی', Kurdish
    'mzn', // 'مازِرونی', Mazanderani
    'pnb', // 'پنجابی', Western Punjabi
    'ps', // 'پښتو', Pashto,
    'sd', // 'سنڌي', Sindhi
    'ug', // 'Uyghurche / ئۇيغۇرچە', Uyghur
    'ur', // 'اردو', Urdu
    'yi' // 'ייִדיש', Yiddish
];

export const OPERATIONAL_SITE_SUPPORTED_LANGUAGES: LocaleType[] = [
    'de',
    'en',
    'es',
    'fr',
    'he',
    'pl',
    'pt_BR',
    'ru',
    'zh_CN',
    'zh_TW'
];

export type OperationalSiteSupportedLanguages = Extract<
    LocaleType,
    (typeof OPERATIONAL_SITE_SUPPORTED_LANGUAGES)[number]
>;
