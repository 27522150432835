<div
    class="trek-card"
    (click)="cardClick()"
    [id]="trek.id">
    <app-base-card
        [name]="trek.name || 'TREK_NAME' | translate"
        [imageUrl]="backgroundImage">
        <app-card-label
            *ngIf="trek.mode"
            [status]="statusLabelColor"
            >{{ trek.mode | translate }}
        </app-card-label>
    </app-base-card>
    <div class="card-actions">
        <a
            *ngIf="trek.mode !== TrekWizardMode.CREATED"
            (click)="$event.stopPropagation()"
            [href]="trekUrl"
            target="_blank"
            class="open-new-tab"
            ><i class="WTicon-round-new-window"
                ><span class="path1"></span><span class="path2"></span></i
        ></a>
        <i
            class="WTicon-round-delete"
            (click)="deleteTrek($event)"
            ><span class="path1"></span><span class="path2"></span
        ></i>
    </div>
    <div class="trek-card__icon">
        <i class="WTicon-trek-icon"></i>
    </div>
</div>
