import { createReducer } from '@ngrx/store';
import * as MapActions from '../actions/map.actions';
import * as StationActions from '../actions/stations.actions';
import { immerOn } from 'ngrx-immer/store';
import * as turfHelpers from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { initialTrekState } from '../../constants/trek-wizard.const';

export const trekMapReducer = createReducer(
    initialTrekState.map,
    immerOn(MapActions.CreateRoute, (draft, { route }) => {
        if (!draft.data) {
            draft.data = {};
        }
        draft.data.route = route;
        draft.sectionStatus.invalid = false;
        draft.sectionStatus.disableDependants = false;
        draft.sectionStatus.complete = !!draft.sectionStatus.complete;
    }),
    immerOn(MapActions.UpdateRoute, (draft, { route }) => {
        if (!draft.data) {
            draft.data = {};
        }
        draft.data.route = route;
    }),
    immerOn(MapActions.DeleteRoute, (draft) => {
        draft.data!.route = undefined;
        draft.sectionStatus.invalid = true;
        draft.sectionStatus.disableDependants = true;
        draft.sectionStatus.complete = false;
    }),
    immerOn(MapActions.UpdateSegmentType, (draft, { segmentType }) => {
        draft.data!.settings!.segmentType = segmentType;
    }),
    immerOn(MapActions.UpdateTrekType, (draft, { trekType }) => {
        draft.data!.settings!.type = trekType;
    }),
    immerOn(MapActions.UpdateDuration, (draft, { duration }) => {
        draft.data!.settings!.duration = duration;
    }),
    immerOn(MapActions.MapComplete, (draft) => {
        draft.sectionStatus.complete = true;
    }),
    immerOn(StationActions.AddStation, (draft, { id, coordinate }) => {
        const line = turfHelpers.lineString(
            (draft.data!.route!.geometry as any).coordinates
        );
        const pt = turfHelpers.point([coordinate.lon!, coordinate.lat!]);
        const snapped = nearestPointOnLine(line, pt);
        (draft.data!.route!.geometry as any).coordinates.splice(
            snapped.properties.index! + 1,
            0,
            [coordinate.lon, coordinate.lat]
        );
    })
);
