import { Injectable } from '@angular/core';
import { USER_ACTIONS } from '../../users/users.model';
import { TranslateService } from '@ngx-translate/core';
import { WizardMode } from '../../site-wizard/store/site-wizard-store.model';
import { PermissionsService } from '../../services/permissions.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { SiteCard, SiteMenu } from '../models/site.model';
import { AppRoutePaths } from '../../app-routing.module';
import { SitesRoutePaths } from '../sites-routing.module';

@Injectable()
export class SiteMenuService {
    actions: any;
    site!: SiteCard;

    constructor(
        private translate: TranslateService,
        private permissionsService: PermissionsService,
        private router: Router,
        private snackbarService: SnackbarService
    ) {}

    getSiteMenu(site: SiteCard): SiteMenu {
        this.site = site;
        return [
            {
                sectionTitle: 'SITE_SECTION_TITLE_1',
                items: [
                    {
                        title: 'SITE_EDIT_SITE',
                        description: 'SITE_EDIT_SITE_DESCRIPTION',
                        icon: 'WTicon-edit',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.EDIT_SITE
                        ),
                        disabled: !!this.site.isExpired,
                        clickAction: this.goToWizard.bind(this)
                    },
                    {
                        title: 'SITE_VIEW_SITE',
                        description: 'SITE_VIEW_SITE_DESCRIPTION',
                        icon: 'WTicon-preview',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.VIEW_SITE
                        ),
                        url: SitesRoutePaths.PREVIEW_SITE,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.viewSite.bind(this)
                    }
                ]
            },
            {
                sectionTitle: 'SITE_SECTION_TITLE_2',
                items: [
                    {
                        title: 'SITE_LIVE_MAP',
                        description: 'SITE_LIVE_MAP_DESCRIPTION',
                        icon: 'WTicon-map',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.LIVE_MAP
                        ),
                        url: SitesRoutePaths.LIVE,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_DASHBOARD',
                        description: 'SITE_DASHBOARD_DESCRIPTION',
                        icon: 'WTicon-dashboard',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.STATISTICS
                        ),
                        url: SitesRoutePaths.DASHBOARD,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_RATING',
                        description: 'SITE_RATING_DESCRIPTION',
                        icon: 'WTicon-rating',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.VIEW_RATES
                        ),
                        url: SitesRoutePaths.RATING,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    }
                ]
            },
            {
                sectionTitle: 'SITE_SECTION_TITLE_3',
                items: [
                    {
                        title: 'SITE_GAME_BUILDER',
                        description: 'SITE_GAME_BUILDER_DESCRIPTION',
                        icon: 'WTicon-game',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.EDIT_GAME
                        ),
                        url: SitesRoutePaths.GAMES,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_DYNAMIC_CROWDFUNDING',
                        description: 'SITE_DYNAMIC_CROWDFUNDING_DESCRIPTION',
                        icon: 'WTicon-crowdfunding',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.CREATE_CAMPAIGN
                        ),
                        url: SitesRoutePaths.CAMPAIGNS,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW ||
                            this.site.freeTrial ||
                            !!this.site.isPrivate,
                        disabledText:
                            (this.site.freeTrial || !!this.site.isPrivate) &&
                            !this.site.isExpired &&
                            !(this.site.mode === WizardMode.CREATE) &&
                            !(this.site.mode === WizardMode.REVIEW)
                                ? 'MAKE_SITE_PUBLIC_TO_UNLOCK'
                                : '',
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_TIMED_ANNOUNCEMENTS',
                        description: 'SITE_TIMED_ANNOUNCEMENTS_SUB_TITLE',
                        icon: 'WTicon-scheduled',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.VIEW_RATES
                        ),
                        url: SitesRoutePaths.SCHEDULED_ANNOUNCEMENTS,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    }
                ]
            },
            {
                sectionTitle: 'SITE_SECTION_TITLE_4',
                items: [
                    {
                        title: 'SITE_SETTINGS',
                        icon: 'WTicon-setting',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.EDIT_SETTINGS
                        ),
                        url: SitesRoutePaths.SETTINGS,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_SHARE_PRIVATELY',
                        description: 'SITE_SHARE_PRIVATELY_DESCRIPTION',
                        icon: 'WTicon-attachment',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.CREATE_SHARE_LINK
                        ),
                        url: SitesRoutePaths.PRIVATELY,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    }
                ]
            },
            {
                sectionTitle: 'IMPLEMENTATION',
                items: [
                    {
                        title: 'SITE_SMS_INVITE',
                        description: 'SITE_SMS_INVITE_DESCRIPTION',
                        icon: 'WTicon-sharing',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.SEND_SMS
                        ),
                        url: SitesRoutePaths.SMS_INVITE,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'SITE_PROMO_VIDEO',
                        description: 'SITE_PROMO_VIDEO_DESTINATION',
                        icon: 'WTicon-video',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.CREATE_SHARE_LINK
                        ),
                        url: SitesRoutePaths.VIDEO,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'MY_QR',
                        description: 'MY_QR_DESCRIPTION',
                        icon: 'WTicon-QR',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.CREATE_SHARE_LINK
                        ),
                        url: SitesRoutePaths.MY_QR,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    },
                    {
                        title: 'IMPLEMENTATION_BOX_TITLE',
                        description: 'IMPLEMENTATION_SUB_TITLE',
                        icon: 'WTicon-resources',
                        hasPermission: this.checkIfHavePermission(
                            USER_ACTIONS.CREATE_SHARE_LINK
                        ),
                        url: SitesRoutePaths.IMPLEMENTATION,
                        disabled:
                            this.site.isExpired ||
                            this.site.mode === WizardMode.CREATE ||
                            this.site.mode === WizardMode.REVIEW,
                        clickAction: this.navigateTo.bind(this)
                    }
                ]
            }
        ];
    }

    checkIfHavePermission(permission: USER_ACTIONS): boolean {
        return this.permissionsService.checkIfUserHasPermission(
            permission,
            this.site.id
        );
    }

    goToWizard(url: string, disabled: boolean, hasPermission: boolean): void {
        if (disabled || !hasPermission) {
            return this.getDisabledToast(hasPermission, disabled);
        }
        this.router.navigate([
            '/',
            AppRoutePaths.SITES,
            this.site.id,
            SitesRoutePaths.SITE_WIZARD
        ]);
    }

    viewSite(url: string, disabled: boolean, hasPermission: boolean): void {
        if (disabled || !hasPermission) {
            return this.getDisabledToast(hasPermission, disabled);
        }
        if (
            this.site.mode === WizardMode.CREATE ||
            this.site.mode === WizardMode.REVIEW ||
            this.site.isExpired
        ) {
            return;
        }
        this.router.navigate([
            '/',
            AppRoutePaths.SITES,
            this.site.id,
            SitesRoutePaths.PREVIEW_SITE
        ]);
    }

    navigateTo(url: string, disabled: boolean, hasPermission: boolean): void {
        if (disabled || !hasPermission) {
            return this.getDisabledToast(hasPermission, disabled);
        }
        this.router.navigate([`/${AppRoutePaths.SITES}`, this.site.id, url]);
    }

    getDisabledToast(hasPermission: boolean, disabled: boolean): any {
        if (!hasPermission && !disabled) {
            return this.snackbarService.open(
                this.translate.instant('ACCESS_RESTRICTED')
            );
        }
        if (disabled && this.site.isExpired) {
            return this.snackbarService.open(
                this.translate.instant('SUBSCRIPTION_EXPIRED')
            );
        }
        if (disabled && !this.site.isExpired) {
            return this.snackbarService.open(
                this.translate.instant('PUBLISH_TO_UNLOCK')
            );
        }
    }
}
