import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TypographyDirective } from '../../directives/typography/typography.directive';

@Component({
    selector: 'app-base-card',
    standalone: true,
    imports: [CommonModule, TranslateModule, TypographyDirective],
    templateUrl: './base-card.component.html',
    styleUrls: ['./base-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseCardComponent implements OnInit {
    @Input() imageUrl!: string;
    @Input() name!: string;
    @Input() subName?: string;
    @HostBinding('style.background-image') background!: string;

    ngOnInit() {
        this.background = `url(${this.imageUrl})`;
    }
}
