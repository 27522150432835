import { createAction, props } from '@ngrx/store';
import { Feature } from 'geojson';
import { NavigationModeTypes } from '../../../standalone/components/navigation-settings/navigation-settings.model';

export enum MapActionTypes {
    CREATE_ROUTE = '[trekWizard] Create Route',
    UPDATE_ROUTE = '[trekWizard] Update Route',
    DELETE_ROUTE = '[trekWizard] Delete Route',
    UPDATE_MAP_STAGE = '[trekWizard] Update Map Stage',
    UPDATE_SEGMENT_TYPE = '[trekWizard] Update Segment Type',
    UPDATE_TREK_TYPE = '[trekWizard] Update Trek Type',
    UPDATE_DURATION = '[trekWizard] Update Duration',
    MAP_COMPLETE = '[trekWizard] Map Complete'
}

export const CreateRoute = createAction(
    MapActionTypes.CREATE_ROUTE,
    props<{ route: Feature }>()
);

export const UpdateRoute = createAction(
    MapActionTypes.UPDATE_ROUTE,
    props<{ route: Feature }>()
);

export const DeleteRoute = createAction(MapActionTypes.DELETE_ROUTE);

export const UpdateSegmentType = createAction(
    MapActionTypes.UPDATE_SEGMENT_TYPE,
    props<{ segmentType: NavigationModeTypes }>()
);

export const UpdateTrekType = createAction(
    MapActionTypes.UPDATE_TREK_TYPE,
    props<{ trekType: string }>()
);

export const UpdateDuration = createAction(
    MapActionTypes.UPDATE_DURATION,
    props<{ duration: number }>()
);

export const MapComplete = createAction(MapActionTypes.MAP_COMPLETE);
