<div class="new-alert-dialog">
    <div
        (click)="closeNewAlert.emit()"
        class="close-dialog"
        mat-dialog-close>
        <i class="WTicon-round-close"
            ><span class="path1"></span><span class="path2"></span
        ></i>
    </div>
    <div class="content">
        <h3 appTypography="PRIMARY_BOLD">
            {{ 'NEW_ANNOUNCEMENT' | translate }}
        </h3>
        <form [formGroup]="newAlertForm">
            <div class="form-block">
                <app-custom-textarea-standalone
                    [label]="'TEXT'"
                    formControlName="text"
                    [rows]="15"
                    [maxLength]="messageMaxLength"
                    [hint]="
                        (newAlertForm.controls.text.value?.length || 0) +
                        '/' +
                        messageMaxLength
                    "></app-custom-textarea-standalone>
                <span
                    *ngIf="newAlertForm.controls.text.errors"
                    class="required absolute top"></span>
            </div>
            <app-custom-input-standalone
                class="spot-select"
                [label]="'RELATED_SPOT' | translate"
                [autoCompleteOptions]="
                    [{ name: 'NOTHING_SELECTED' | translate, id: null }].concat(
                        spotOptionList
                    )
                "
                formControlName="spot"
                [autoCompleteDisplayWithFn]="
                    displaySpotOptionFunc
                "></app-custom-input-standalone>
            <app-standalone-buttons-container mat-dialog-actions>
                <app-standalone-custom-button
                    (clicked)="submit()"
                    [isDisabled]="newAlertForm.invalid"
                    id="new-alert-submit"
                    mat-dialog-close
                    buttonType="submit">
                    {{ 'SEND' | translate }}
                </app-standalone-custom-button>
                <app-standalone-custom-button
                    buttonStyle="SECONDARY"
                    id="new-alert-cancel"
                    mat-dialog-close>
                    {{ 'CANCEL' | translate }}
                </app-standalone-custom-button>
            </app-standalone-buttons-container>
        </form>
    </div>
</div>
