import { createReducer } from '@ngrx/store';
import { DcfWizardDonationsSection } from '../../models/dcf-wizard-donations.model';
import { immerOn } from 'ngrx-immer/store';
import {
    addDonation,
    removeDonation,
    setDcfDonationsSelectedLangTab,
    updateDcfDonations,
    updateDcfDonationsDefaultPayment
} from '../actions/dcf-wizard-donations.action';
import {
    CampaignPaymentVariationData,
    CampaignVariationsLanguage
} from '../../dcf-wizard.model';
import {
    AddDcfLanguage,
    RemoveDcfLanguage
} from '../actions/dcf-wizard-languages.actions';
import { LocaleType } from '../../../shared/locales.models';

export const dcfWizardDonationsReducer = createReducer(
    {} as DcfWizardDonationsSection,
    immerOn(AddDcfLanguage, (state, { langToAdd }) => {
        const langDonationsData: CampaignPaymentVariationData[] =
            state.data!.languages[0]?.data.map((donation) =>
                getEmptyDonationData(donation.index)
            ) || [getEmptyDonationData()];
        state.data!.languages.push({
            language: langToAdd,
            data: langDonationsData
        });
        if (state.data!.languages.length === 1) {
            state.sectionStatus.selectedLangTab = langToAdd;
        }
        state.sectionStatus.invalid = true;
        state.sectionStatus.disableDependants = true;
        state.sectionStatus.complete = false;
    }),
    immerOn(RemoveDcfLanguage, (state, { langToRemove }) => {
        const langDonationsIndex = state.data?.languages.findIndex(
            (donationsLang) => donationsLang.language === langToRemove
        );
        delete state.data?.languages[langDonationsIndex!];
        state.data!.languages =
            state.data?.languages.filter((item) => !!item) || [];
        const isInvalid = checkIfDonationStateInvalid(
            state.data?.languages || []
        );
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = state.sectionStatus.invalid
            ? false
            : checkIfDonationStateComplete(state.data?.languages || []);
        if (state.sectionStatus.selectedLangTab === langToRemove) {
            state.sectionStatus.selectedLangTab = state.data?.languages[0]
                ?.language as LocaleType;
        }
    }),
    immerOn(updateDcfDonations, (store, { donations, invalid, complete }) => {
        store.data!.languages = donations;
        store.sectionStatus.invalid = invalid;
        store.sectionStatus.disableDependants = invalid;
        store.sectionStatus.complete = complete;
    }),
    immerOn(setDcfDonationsSelectedLangTab, (store, { selectedLangTab }) => {
        store.sectionStatus.selectedLangTab = selectedLangTab;
    }),
    immerOn(updateDcfDonationsDefaultPayment, (store, { donationIndex }) => {
        store.data!.defaultPayment = donationIndex;
    }),
    immerOn(addDonation, (store) => {
        store.data!.languages = store.data!.languages.map((donations) => {
            const index =
                Math.max(
                    ...donations.data.map((donation) => donation.index || 0)
                ) + 1;
            return {
                language: donations.language,
                data: [
                    ...donations.data,
                    {
                        description: '',
                        index: index as 0 | 1 | 2 | 3,
                        thankYouMessage: ''
                    }
                ]
            };
        });
        store.sectionStatus.invalid = true;
        store.sectionStatus.disableDependants = true;
        store.sectionStatus.complete = false;
    }),
    immerOn(removeDonation, (state, { donationIndex }) => {
        state.data!.languages = state.data!.languages.map((donations) => ({
            language: donations.language,
            data: donations.data.filter(
                (donation) => donation.index !== donationIndex
            )!
        }));
        const isInvalid = checkIfDonationStateInvalid(
            state.data?.languages || []
        );
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = state.sectionStatus.invalid
            ? false
            : checkIfDonationStateComplete(state.data?.languages || []);
    })
);

export const checkIfDonationStateInvalid = (
    donations: CampaignVariationsLanguage[]
) =>
    donations.some((donationsLang) =>
        donationsLang.data.some(
            (donation) =>
                !donation.amount ||
                !donation.description ||
                !donation.thankYouMessage
        )
    );

export const checkIfDonationStateComplete = (
    donations: CampaignVariationsLanguage[]
) =>
    !!donations.length &&
    donations.every((donationsLang) =>
        donationsLang.data.every(
            (donation) =>
                donation.amount &&
                donation.description &&
                donation.thankYouMessage
        )
    );

export const getEmptyLangDonations = (
    lang: LocaleType[]
): CampaignVariationsLanguage[] =>
    lang.map((language) => ({
        language,
        data: [getEmptyDonationData()]
    }));

const getEmptyDonationData = (
    index: 0 | 1 | 2 | 3 = 0
): CampaignPaymentVariationData => ({
    amount: null,
    index,
    thankYouMessage: '',
    description: ''
});
