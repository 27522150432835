import { SiteGeneralDetails } from './site-wizard-general-details.model';
import { LatLon } from '../../mapbox/typings/mapbox-utils';
import { SiteMedia } from './site-wizard-media.model';
import { ContactInformation } from './site-wizard-contact.model';
import {
    SiteAdvancedHoursMapped,
    SiteDayHoursMapped,
    SiteHours,
    SiteHoursSettings
} from './site-wizard-hours.model';
import { SiteTags } from './site-wizard-tags.model';
import { NavigationModeTypes } from '../../standalone/components/navigation-settings/navigation-settings.model';
import {
    SiteWizardSectionWithLang,
    SiteWizardStepWithLang
} from '../store/site-wizard-store.model';
import { LocaleType } from '../../shared/locales.models';
import { SpotDialogStages } from '../pages/spot-dialog/spot-dialog.const';
import {
    WizardSection,
    WizardSectionStatus
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import { getContactFormGroup } from '../../standalone/components/contact-form/contacts-form.const';
import { getGeneralDetailsFormArray } from '../components/general-form/general-form.const';

export type SpotGeneral = Record<LocaleType, SiteGeneralDetails>;

export class SiteSpot {
    id?: string;
    tempId?: string;
    coordinate: LatLon;
    general: SiteWizardSectionWithLang<SpotGeneral>;
    media: WizardSection<SiteMedia[]>;
    contact: WizardSection<ContactInformation>;
    hours: WizardSection<SiteHours>;
    tags: WizardSection<SiteTags>;
    settings: WizardSection<SoiSettings>;
    complete?: boolean;
    selectedStage?: SpotDialogStages;

    constructor(
        coordinate: LatLon,
        languages: LocaleType[],
        contact?: ContactInformation,
        settings?: SoiSettings,
        generalDetails?: Record<LocaleType, SiteGeneralDetails>
    ) {
        this.tempId = `${coordinate.lat}-${coordinate.lon}`;
        this.id = `${coordinate.lat}-${coordinate.lon}`;
        this.coordinate = coordinate;

        this.general = {
            sectionStatus: new SiteWizardStepWithLang(true, false),
            data: languages.reduce(
                (acc: Record<LocaleType, SiteGeneralDetails>, lang) => {
                    acc[lang] =
                        generalDetails?.[lang] || ({} as SiteGeneralDetails);
                    return acc;
                },
                {} as Record<LocaleType, SiteGeneralDetails>
            )
        };
        if (generalDetails) {
            const generalDetailsFormArray =
                getGeneralDetailsFormArray(generalDetails);
            this.general.sectionStatus.invalid =
                generalDetailsFormArray.invalid;
        }
        this.media = { sectionStatus: new WizardSectionStatus(true, false) };
        this.contact = { sectionStatus: new WizardSectionStatus(false, false) };
        if (contact) {
            const contactFormGroup = getContactFormGroup(contact);
            this.contact.data = contactFormGroup.value;
            this.contact.sectionStatus.invalid = contactFormGroup.invalid;
        }
        this.hours = {
            sectionStatus: new WizardSectionStatus(false, false),
            data: {
                settings: new SiteHoursSettings()
            }
        };
        this.tags = { sectionStatus: new WizardSectionStatus(false, false) };
        this.settings = {
            sectionStatus: new WizardSectionStatus(false, false),
            data: {
                navigationMode:
                    settings?.navigationMode || NavigationModeTypes.DRIVE,
                notificationMode:
                    settings?.notificationMode || NotificationMode.ALWAYS,
                isActive:
                    settings && 'isActive' in settings
                        ? settings.isActive
                        : true
            }
        };
        this.complete = false;
    }
}

export type SiteSpotMappedHours = Omit<SiteSpot, 'hours'> & {
    hours: WizardSection<
        Omit<SiteHours, 'basics' | 'advanced'> & {
            basics: SiteDayHoursMapped;
            advanced: SiteAdvancedHoursMapped[];
        }
    >;
};

export class SpotsMappedByLocation {
    [location: string]: SiteSpot;
}

export interface SoiSettings {
    navigationMode?: NavigationModeTypes;
    radius?: number;
    notificationMode?: NotificationMode;
    isActive?: boolean;
}

export enum NotificationMode {
    SILENT = 'SILENT',
    SILENT_ON_TREK = 'SILENT_ON_TREK',
    ALWAYS = 'ALWAYS'
}

export interface SiteSpotPublished {
    id?: string;
    tempId?: string;
    coordinate: LatLon;
    general: SpotGeneral;
    media: SiteMedia[];
    contact?: ContactInformation;
    hours: SiteHours;
    tags: SiteTags;
    settings: SoiSettings;
}

export class SiteWizardSpotsStep extends WizardSectionStatus {
    selectedSpotId?: string;
}

export interface SiteWizardSpotsSection<T> extends WizardSection<T> {
    sectionStatus: SiteWizardSpotsStep;
}

export type SpotMarkerOptions = {
    spotStatus?: SpotStatus;
    draggable?: boolean;
    clickable?: boolean;
    index?: number;
};

export enum SpotStatus {
    COMPLETE = 'COMPLETE',
    UNFINISHED = 'UNFINISHED',
    EMPTY = 'EMPTY'
}
