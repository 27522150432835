import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { BreadcrumbsStage } from './breadcrumbs.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-breadcrumbs',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatTooltipModule,
        TypographyDirective,
        TranslateModule
    ],
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    @Input() breadcrumbs!: BreadcrumbsStage[];

    constructor() {}

    ngOnInit(): void {}
}
