import { environment } from '@env/environment';

export const POI_FREE_TEXT_ID = '900900000120126';

export const TAG_CATEGORY_ID = {
    season: '900900000200009',
    generalInfo: '900900000200012',
    friendliness: '900900000200010'
};

export const TAG_ID = {
    showers: '900900000190037',
    airConditioner: '900900000190038',
    kitchen: '900900000190039',
    meals: '900900000190040',
    publicTransportationTrekStart: '900900000190021',
    publicTransportationTrekEnd: '900900000190022',
    publicTransportationSite: '900900000190045'
};

export const EXCLUDE_CATEGORIES_FOR_SITE = [
    TAG_CATEGORY_ID.season,
    TAG_CATEGORY_ID.generalInfo,
    TAG_CATEGORY_ID.friendliness
];

export const EXCLUDE_TAGS_FOR_SITE = [
    TAG_ID.showers,
    TAG_ID.airConditioner,
    TAG_ID.kitchen,
    TAG_ID.meals,
    TAG_ID.publicTransportationTrekStart,
    TAG_ID.publicTransportationTrekEnd
];

export const EXCLUDE_CATEGORIES_FOR_TREK = [
    TAG_CATEGORY_ID.season,
    TAG_CATEGORY_ID.generalInfo,
    TAG_CATEGORY_ID.friendliness
];

export const EXCLUDE_TAGS_FOR_TREK = [
    TAG_ID.showers,
    TAG_ID.airConditioner,
    TAG_ID.kitchen,
    TAG_ID.meals,
    TAG_ID.publicTransportationSite
];

export const DIMENS = {
    screen_XL: 1400,
    screen_L: 1240,
    screen_M: 992,
    screen_S: 760,
    screen_XS: 600,
    new_BP_3: 768
};

export const SCREEN_BREAK_POINTS = {
    laptop_L: 1440,
    laptop: 1024,
    tablet: 768,
    mobile_L: 425,
    mobile_M: 375,
    mobile_S: 320
};

export const TREK_PATH_TYPE = {
    TREK: 'trek',
    TREK_ROUTES: 'trek_routes',
    DYNAMIC: 'dynamic'
};

export const DEFAULT_OOI_CATEGORY = 'NOT_CATEGORIZED';

export const MEDIA_VIDEO_TYPES = ['avi', 'mkv', 'mp4', 'mov'];

export const WT_LOGO = `${environment.ASSETS_BASE_URL}/assets/images/logo.svg`;

export const WT_LOGO_TRANSPARENT = `${environment.ASSETS_BASE_URL}/assets/images/logo-transparent.png`;

export const WT_TRANSLATE_TOS_URL =
    'https://www-wishtrip-com.translate.goog/enterprise/terms.html';
export const WT_TOS_URL = `${environment.BUSINESS_URL_WEBSITE_NEW}/terms.html`;
export const WT_TRANSLATE_PRIVACY_POLICY_URL =
    'https://www-wishtrip-com.translate.goog/privacy_policy.html';
export const WT_PRIVACY_POLICY_URL = `${environment.WEBSITE_URL}/privacy_policy.html`;
export const WT_TRANSLATE_COOKIES_POLICY_URL =
    'https://www-wishtrip-com.translate.goog/cookies_policy.html';
export const WT_COOKIES_POLICY_URL = `${environment.WEBSITE_URL}/cookies_policy.html`;
export const FREE_TRIAL_PLAN = 'TIER_0';
