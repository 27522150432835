import { createReducer } from '@ngrx/store';
import * as SpotsActions from '../actions/spots.actions';
import { initialState } from '../site-wizard-store.constants';
import * as LanguageActions from '../actions/language.actions';
import { getSpotTempId } from '../../services/state-parser.service';
import { orderItems } from '../site-wizard-store.utils';
import { immerOn } from 'ngrx-immer/store';
import {
    SiteHoursAdvance,
    SiteHoursDay,
    SiteHoursException
} from '../../models/site-wizard-hours.model';
import {
    NotificationMode,
    SiteSpot,
    SoiSettings,
    SpotGeneral,
    SpotsMappedByLocation
} from '../../models/site-wizard-spots.model';
import { LocaleType } from '../../../shared/locales.models';
import { SiteGeneralDetails } from '../../models/site-wizard-general-details.model';
import { spotWizardStageDetails } from '../../pages/spot-dialog/spot-dialog.const';
import {
    LANGUAGE_HEADERS,
    SETTINGS_HEADERS
} from '../../components/spots-sheet-dialog/spots-sheet.const';
import { getContactFormGroup } from '../../../standalone/components/contact-form/contacts-form.const';
import { ContactInformation } from '../../models/site-wizard-contact.model';
import { SheetSpot } from '../../components/spots-sheet-dialog/spots-sheet.model';
import { getGeneralDetailsFormArray } from '../../components/general-form/general-form.const';
import { NavigationModeTypes } from '../../../standalone/components/navigation-settings/navigation-settings.model';

export const spotsReducer = createReducer(
    initialState.spots,
    immerOn(
        SpotsActions.AddSpot,
        (draft, { coordinates: coordinates, languages }) => {
            const spotId = getSpotTempId(coordinates);
            draft.data = draft.data || {};
            draft.data[spotId] = JSON.parse(
                JSON.stringify(new SiteSpot(coordinates, languages))
            );
            draft.sectionStatus.selectedSpotId = spotId;
            draft.sectionStatus.disableDependants = false;
        }
    ),
    immerOn(SpotsActions.DeleteSpot, (draft, { id }) => {
        if (!draft.data?.[id]) {
            return;
        }
        delete draft.data[id];
        draft.sectionStatus.invalid =
            !draft.data ||
            !Object.keys(draft.data).length ||
            !checkIfAllSpotsComplete(draft.data);
        draft.sectionStatus.selectedSpotId = undefined;
    }),
    immerOn(SpotsActions.SelectSpot, (draft, { id }) => {
        draft.sectionStatus = { ...draft.sectionStatus };
        draft.sectionStatus.selectedSpotId = id;
        if (
            draft.data![id!]?.settings.data &&
            draft.data![id!]?.settings.data!.isActive === undefined
        ) {
            draft.data![id!].settings.data = {
                ...draft.data![id!].settings.data,
                isActive: true,
                notificationMode: NotificationMode.ALWAYS
            };
        }
    }),
    immerOn(SpotsActions.UnselectSpot, (draft) => {
        if (!draft.sectionStatus.selectedSpotId) {
            return;
        }
        const selectedSpot = draft.data?.[draft.sectionStatus.selectedSpotId];
        selectedSpot!.complete = checkIfSpotComplete(selectedSpot!);
        draft.sectionStatus.invalid =
            !draft.data ||
            !Object.keys(draft.data).length ||
            !checkIfAllSpotsComplete(draft.data);
        draft.sectionStatus.complete =
            draft.sectionStatus.complete && !draft.sectionStatus.invalid;
        draft.sectionStatus.selectedSpotId = undefined;
    }),
    immerOn(SpotsActions.UpdateSpotLocation, (draft, { id, coordinate }) => {
        const newSpotId = getSpotTempId(coordinate);
        draft.data![newSpotId] = {
            ...draft.data![id],
            coordinate
        };
        if (draft.data?.[newSpotId].tempId) {
            draft.data[newSpotId].tempId = newSpotId;
            draft.data[newSpotId].id = newSpotId;
        }
        if (newSpotId !== id) {
            delete draft.data![id];
        }
    }),
    immerOn(
        SpotsActions.ChangeSelectSpotStage,
        (draft, { stage, completedSection }) => {
            const selectedSpotId = draft.sectionStatus.selectedSpotId;
            if (completedSection) {
                (draft.data![selectedSpotId!] as any)[
                    completedSection
                ].sectionStatus.complete = true;
            }
            const stageInfo = spotWizardStageDetails.get(stage);
            const newState = stageInfo?.children
                ? stageInfo.children[0]
                : stage;
            draft.data![draft.sectionStatus.selectedSpotId!].selectedStage =
                newState;
        }
    ),
    immerOn(SpotsActions.SpotsComplete, (draft) => {
        draft.sectionStatus.complete = true;
    }),
    immerOn(SpotsActions.UpdateMedia, (draft, { media }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].media.data = orderItems(media);
        draft.data![selectedSpotId!].media.sectionStatus.invalid =
            media?.length < 1;
    }),
    immerOn(
        SpotsActions.UpdateDetails,
        (draft, { language, data, invalid }) => {
            if (!language || !draft.sectionStatus.selectedSpotId) {
                return;
            }
            const selectedSpotId = draft.sectionStatus.selectedSpotId;
            draft.data![selectedSpotId!].general.data![language] = {
                ...draft.data?.[selectedSpotId!].general.data![language],
                ...data
            };
            draft.data![selectedSpotId!].general.sectionStatus.complete =
                draft.data![selectedSpotId!].general.sectionStatus.complete &&
                !invalid;
            draft.data![selectedSpotId!].general.sectionStatus.invalid =
                invalid;
        }
    ),
    immerOn(SpotsActions.AddAudio, (draft, { language, audioUrl }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].general.data![language].audioUrl =
            audioUrl;
    }),
    immerOn(SpotsActions.DeleteAudio, (draft, { language }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        delete draft.data![selectedSpotId!].general.data![language].audioUrl;
    }),
    immerOn(SpotsActions.UpdateNavigationMode, (draft, { mode }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].settings.data!.navigationMode = mode;
    }),
    immerOn(SpotsActions.UpdateRadius, (draft, { radius }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].settings.data!.radius = radius;
    }),
    immerOn(SpotsActions.UpdateContact, (draft, { contactInfo, invalid }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].contact!.data = contactInfo;
        draft.data![selectedSpotId!].contact!.sectionStatus.invalid = invalid;
    }),
    immerOn(SpotsActions.UpdateHoursSettings, (draft, { settings }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].hours.data!.settings = settings;
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // !(!settings.hasOpeningHours
        // ||
        // settings.alwaysOpen);
    }),
    immerOn(SpotsActions.AddExceptionsDateRange, (draft) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        if (!draft.data![selectedSpotId!].hours?.data?.exceptions?.length) {
            draft.data![selectedSpotId!].hours.data!.exceptions = [];
        }
        draft.data?.[selectedSpotId!].hours.data?.exceptions?.push(
            new SiteHoursException()
        );
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
    }),
    immerOn(SpotsActions.DeleteExceptionsDateRange, (draft, { index }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].hours.data!.exceptions = draft.data?.[
            selectedSpotId!
        ].hours.data?.exceptions?.filter((lang, i) => i !== index);
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
    }),
    immerOn(
        SpotsActions.UpdateExceptionsDateRange,
        (draft, { dateRangeException, index }) => {
            const selectedSpotId = draft.sectionStatus.selectedSpotId;
            draft.data![selectedSpotId!].hours.data!.exceptions![index] =
                dateRangeException;
            draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
        }
    ),
    immerOn(SpotsActions.UpdateBasicDateRange, (draft, { basicRange, day }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        const isExistDay = draft.data![
            selectedSpotId!
        ].hours.data!.basics?.find((basic) => basic.dayInWeek === day);
        if (!isExistDay) {
            if (!draft.data![selectedSpotId!].hours.data!.basics?.length) {
                draft.data![selectedSpotId!].hours.data!.basics = [];
            }
            draft.data![selectedSpotId!].hours.data?.basics?.push(
                new SiteHoursDay(day)
            );
        }
        draft.data![selectedSpotId!].hours.data!.basics = draft.data![
            selectedSpotId!
        ].hours.data!.basics!.map((basic) => {
            const newDay: SiteHoursDay = {
                dayInWeek: basic.dayInWeek,
                openingHours: [
                    ...((basic.dayInWeek === day
                        ? basicRange.openingHours
                        : basic.openingHours) || [])
                ]
            };
            return newDay;
        });
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
    }),
    immerOn(SpotsActions.AddAdvancedDateRange, (draft) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        if (!draft.data![selectedSpotId!].hours?.data?.advanced?.length) {
            draft.data![selectedSpotId!].hours.data!.advanced = [];
        }
        draft.data![selectedSpotId!].hours.data?.advanced?.push(
            new SiteHoursAdvance()
        );
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
    }),
    immerOn(SpotsActions.DeleteAdvancedDateRange, (draft, { index }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].hours.data!.advanced = draft.data?.[
            selectedSpotId!
        ].hours.data?.advanced?.filter((lang, i) => i !== index);
        draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
    }),
    immerOn(
        SpotsActions.UpdateAdvancedDateRange,
        (draft, { dateRangeAdvanced, index }) => {
            const selectedSpotId = draft.sectionStatus.selectedSpotId;
            draft.data![selectedSpotId!].hours.data!.advanced![index] =
                dateRangeAdvanced;
            draft.data![selectedSpotId!].hours.sectionStatus.invalid = false; // invalid
        }
    ),
    immerOn(SpotsActions.AddTag, (draft, { tagId }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].tags.data =
            draft.data![selectedSpotId!].tags.data || {};
        if (!draft.data![selectedSpotId!].tags.data?.ids) {
            draft.data![selectedSpotId!].tags.data!.ids = [];
        }
        draft.data?.[selectedSpotId!].tags.data?.ids?.push(tagId);
    }),
    immerOn(SpotsActions.DeleteTag, (draft, { tagId }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].tags.data!.ids = draft.data?.[
            selectedSpotId!
        ].tags.data?.ids?.filter((tag) => tag !== tagId);
    }),
    immerOn(SpotsActions.UpdateKeyword, (draft, { keywordString }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        if (!draft.data?.[selectedSpotId!].tags.data?.searchKeywords) {
            draft.data![selectedSpotId!].tags.data!.searchKeywords = [];
        }
        draft.data![selectedSpotId!].tags.data!.searchKeywords = [
            ...keywordString.split(',')
        ];
    }),
    immerOn(LanguageActions.addSiteLanguage, (draft, { language }) => {
        draft.data = !draft?.data
            ? undefined
            : Object.entries(draft?.data)?.reduce(
                  (acc: { [key: string]: SiteSpot }, [spotId, spot]) => {
                      acc[spotId] = {
                          ...spot,
                          general: {
                              ...spot.general,
                              data: {
                                  ...((spot.general?.data ||
                                      {}) as SpotGeneral),
                                  [language]: {} as SiteGeneralDetails
                              },
                              sectionStatus: {
                                  ...(spot.general?.sectionStatus || {}),
                                  invalid: true,
                                  complete: false
                              }
                          },
                          complete: false
                      };
                      return acc;
                  },
                  {}
              );
        Object.values(draft.data || {}).forEach((spot) => {
            if (Object.values(spot.general.data || {}).length === 1) {
                spot.general.sectionStatus.selectedLangTab = language;
            }
        });
        draft.sectionStatus.invalid = true;
        draft.sectionStatus.complete = false;
    }),
    immerOn(LanguageActions.deleteSiteLanguage, (draft, { language }) => {
        if (!draft.data) {
            return;
        }
        draft.data = Object.entries(draft.data)?.reduce(
            (acc: { [key: string]: SiteSpot }, [spotId, spot]) => {
                if (!spot?.general?.data?.[language]) {
                    acc[spotId] = spot;
                } else {
                    const { [language]: value, ...withoutLang } =
                        spot?.general?.data;
                    acc[spotId] = {
                        ...spot,
                        general: {
                            ...spot.general,
                            data: { ...withoutLang } as Record<
                                LocaleType,
                                SiteGeneralDetails
                            >
                        }
                    };
                }
                return acc;
            },
            {}
        );
        Object.values(draft.data || {}).forEach((spot) => {
            if (spot.general.sectionStatus.selectedLangTab === language) {
                spot.general.sectionStatus.selectedLangTab = Object.keys(
                    draft.data || {}
                )[0] as LocaleType;
            }
        });
    }),
    immerOn(SpotsActions.UpdateAlertOptions, (draft, { notificationMode }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].settings.data!.notificationMode =
            notificationMode;
    }),
    immerOn(SpotsActions.UpdateIsActiveSpot, (draft, { isActiveSpot }) => {
        const selectedSpotId = draft.sectionStatus.selectedSpotId;
        draft.data![selectedSpotId!].settings.data!.isActive = isActiveSpot;
    }),
    immerOn(
        SpotsActions.SetSpotGeneralSelectedLangTab,
        (draft, { selectedLangTab }) => {
            const selectedSpotId = draft.sectionStatus.selectedSpotId;
            draft.data![selectedSpotId!].general.sectionStatus.selectedLangTab =
                selectedLangTab;
        }
    ),
    immerOn(
        SpotsActions.HandleSpotsFromSheet,
        (draft, { sheetSpotsList, languagesList }) => {
            sheetSpotsList.forEach((sheetSpot) => {
                const {
                    phone,
                    email,
                    website,
                    facebook,
                    instagram,
                    whatsapp,
                    tripadvisor,
                    expedia
                } = sheetSpot;
                const contactInformation: ContactInformation = {
                    phone,
                    email,
                    website,
                    facebook,
                    instagram,
                    whatsapp,
                    tripadvisor,
                    expedia
                };
                const soiGeneralDetails: SpotGeneral = languagesList.reduce(
                    (acc, lang) => {
                        const langGeneralDetails = (
                            Object.keys(sheetSpot) as (keyof SheetSpot)[]
                        ).reduce((langAcc, key) => {
                            const [keyLang, header] = key.split('_');
                            if (keyLang === lang) {
                                langAcc[header as keyof SiteGeneralDetails] =
                                    sheetSpot[key] as any;
                            }

                            return langAcc;
                        }, {} as SiteGeneralDetails);
                        acc[lang] = langGeneralDetails;
                        return acc;
                    },
                    {} as Record<LocaleType, SiteGeneralDetails>
                );

                const existedSpot: SiteSpot | undefined = Object.values(
                    draft.data || {}
                ).find(
                    (spot) =>
                        spot.coordinate.lat === sheetSpot.lat &&
                        spot.coordinate.lon === sheetSpot.lon
                );
                if (existedSpot) {
                    const contactFormGroup =
                        getContactFormGroup(contactInformation);
                    existedSpot.contact.data = contactFormGroup.value;
                    existedSpot.contact.sectionStatus.invalid =
                        contactFormGroup.invalid;
                    if (contactFormGroup.invalid) {
                        existedSpot.contact.sectionStatus.complete = false;
                    }
                    const soiSettings: SoiSettings = {
                        navigationMode:
                            sheetSpot.navigationMode ||
                            NavigationModeTypes.DRIVE,
                        notificationMode:
                            sheetSpot.notificationMode ||
                            NotificationMode.ALWAYS,
                        isActive: !(sheetSpot.isActive === 'FALSE')
                    };
                    SETTINGS_HEADERS.forEach((header) => {
                        if (header in sheetSpot) {
                            existedSpot.settings.data![header] = soiSettings[
                                header
                            ] as any;
                        }
                    });
                    languagesList.forEach((lang) => {
                        LANGUAGE_HEADERS.forEach((header) => {
                            if (header in soiGeneralDetails[lang]) {
                                existedSpot.general.data![lang][header] =
                                    soiGeneralDetails[lang][header] as any;
                            }
                        });
                    });
                    const generalDetailsFormArray = getGeneralDetailsFormArray(
                        existedSpot.general.data!
                    );
                    existedSpot.general.sectionStatus.invalid =
                        generalDetailsFormArray.invalid;
                    if (generalDetailsFormArray.invalid) {
                        existedSpot.general.sectionStatus.complete = false;
                    }
                    existedSpot.complete = checkIfSpotComplete(existedSpot!);
                } else {
                    draft.data = draft.data || {};
                    const { navigationMode, notificationMode, isActive } =
                        sheetSpot;
                    const soiSettings: SoiSettings = {
                        navigationMode,
                        notificationMode,
                        isActive: !(isActive === 'FALSE')
                    };

                    const spotId = getSpotTempId({
                        lat: +(+sheetSpot.lat).toFixed(6),
                        lon: +(+sheetSpot.lon).toFixed(6)
                    });

                    draft.data[spotId] = JSON.parse(
                        JSON.stringify(
                            new SiteSpot(
                                {
                                    lat: +(+sheetSpot.lat).toFixed(6),
                                    lon: +(+sheetSpot.lon).toFixed(6)
                                },
                                languagesList,
                                contactInformation,
                                soiSettings,
                                soiGeneralDetails
                            )
                        )
                    );
                }
            });
            draft.sectionStatus.invalid =
                !draft.data ||
                !Object.keys(draft.data).length ||
                !checkIfAllSpotsComplete(draft.data);
            draft.sectionStatus.complete =
                draft.sectionStatus.complete && !draft.sectionStatus.invalid;
        }
    )
);

function checkIfSpotComplete(spot: SiteSpot): boolean {
    let complete = true;
    for (const i of Object.values(spot)) {
        if ((i as any)?.sectionStatus?.invalid) {
            return (complete = false);
        }
    }
    return complete;
}

function checkIfAllSpotsComplete(spots: SpotsMappedByLocation): boolean {
    let complete = true;
    for (const i of Object.values(spots)) {
        if (!i?.complete) {
            return (complete = false);
        }
    }
    return complete;
}
