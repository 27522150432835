import { GameWizardState } from '../store/game-wizard-store.model';
import { Game, GameMedia } from '../../games/models/game.interface';
import { GameWizardSectionStatus } from '../game-wizard.model';
import { LocaleType } from '../../shared/locales.models';
import { GameWizardChallengesStep } from '../models/game-wizard-challenges.model';
import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';

export enum GameWizardStages {
    LANGUAGES = 'languages',
    GENERAL_DETAILS = 'generalDetails',
    INTRODUCTION = 'introduction',
    CHALLENGES = 'challenges',
    COMPLETION = 'completion',
    SUMMARY = 'summary'
}

export interface GameGenieInfo {
    genieText: string;
    genieVideo: string;
}

export const gameWizardStageDetails: MapWizardStageDetails<GameWizardStages> =
    new Map<GameWizardStages, WizardSectionDetails<GameWizardStages>>([
        [
            GameWizardStages.LANGUAGES,
            {
                stage: GameWizardStages.LANGUAGES,
                titleKey: 'CHOOSE_YOUR_LANGUAGE',
                descriptionKey: 'GAME_LANGUAGES_DESCRIPTION',
                genieTextKey: 'GAME_GENIE_LANGUAGE',
                genieVideoUrl: 'Y_BtjnrW38E',
                navBarTextKey: 'LANGUAGES',
                icon: 'WTicon-Langueges',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                hidePreviousBtn: true
            }
        ],
        [
            GameWizardStages.GENERAL_DETAILS,
            {
                stage: GameWizardStages.GENERAL_DETAILS,
                titleKey: 'GAME_WIZARD_GENERAL_DETAILS',
                descriptionKey: 'GAME_WIZARD_GENERAL_DETAILS_DESCRIPTION',
                genieTextKey: 'GAME_GENIE_BASIC_DETAILS',
                genieVideoUrl: 'C4iOHhxAok4',
                navBarTextKey: 'GENERAL_DETAILS',
                icon: 'WTicon-Basic-details',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                dependantOn: [GameWizardStages.LANGUAGES]
            }
        ],
        [
            GameWizardStages.INTRODUCTION,
            {
                stage: GameWizardStages.INTRODUCTION,
                titleKey: 'GAME_WIZARD_INTRODUCTION_MESSAGE',
                descriptionKey: 'GAME_WIZARD_INTRODUCTION_MESSAGE_DESCRIPTION',
                genieTextKey: 'GAME_GENIE_INTRODUCTION',
                genieVideoUrl: 'C4iOHhxAok4',
                navBarTextKey: 'INTRODUCTION',
                icon: 'WTicon-Introduction',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [GameWizardStages.LANGUAGES]
            }
        ],
        [
            GameWizardStages.CHALLENGES,
            {
                stage: GameWizardStages.CHALLENGES,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'GAME_GENIE_CHALLENGES',
                genieVideoUrl: 'q-3M1mf_XsQ',
                navBarTextKey: 'CHALLENGES',
                icon: 'WTicon-Challanges',
                pageLayoutStatus: WizardPageLayout.DIALOG_ON_HALF_SCREEN,
                dependantOn: [GameWizardStages.LANGUAGES]
            }
        ],
        [
            GameWizardStages.COMPLETION,
            {
                stage: GameWizardStages.COMPLETION,
                titleKey: 'GAME_COMPLETION_TITLE',
                descriptionKey: 'GAME_COMPLETION_DESCRIPTION',
                genieTextKey: 'GAME_GENIE_COMPLETION',
                genieVideoUrl: 'oYxX2xM16pU',
                navBarTextKey: 'COMPLETION',
                icon: 'WTicon-Completion',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [GameWizardStages.LANGUAGES]
            }
        ],
        [
            GameWizardStages.SUMMARY,
            {
                stage: GameWizardStages.SUMMARY,
                titleKey: 'GAME_WIZARD_SUMMARY_TITLE',
                descriptionKey: 'GAME_WIZARD_SUMMARY_DESCRIPTION',
                genieTextKey: 'GAME_GENIE_SUMMARY',
                genieVideoUrl: 'oYxX2xM16pU',
                navBarTextKey: 'SUMMARY',
                icon: 'WTicon-Summary',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                hideNextBtn: true,
                dependantOn: [
                    GameWizardStages.LANGUAGES,
                    GameWizardStages.CHALLENGES,
                    GameWizardStages.INTRODUCTION,
                    GameWizardStages.GENERAL_DETAILS,
                    GameWizardStages.COMPLETION
                ]
            }
        ]
    ]);

export const mapGameObjectIntoGameState = (game: Game): GameWizardState => {
    const languagesList = game.data?.map((langData) => langData.locale) || [];
    const languageSectionStatus = new GameWizardSectionStatus(
        !languagesList.length,
        !!languagesList.length,
        !languagesList.length
    );
    const generalDetailsSectionStatus = new GameWizardSectionStatus(
        !game.data?.every((langData) => langData.name && langData.subtitle) &&
            !game.icon,
        !!game.data?.every(
            (langData) => !!langData.name && !!langData.subtitle
        ) && !!game.icon
    );
    const introductionSectionStatus = new GameWizardSectionStatus(
        !game.data?.every(
            (langData) =>
                langData.introductionMedia.length &&
                langData.introductionDescription
        ),
        !!game.data?.every(
            (langData) =>
                langData.introductionMedia.length &&
                langData.introductionDescription
        )
    );
    const challengesSectionStatus = new GameWizardChallengesStep(true, false);
    const completionSectionStatus = new GameWizardSectionStatus(
        !game.data?.every(
            (lang) => lang.endMedia.length && lang.endDescription
        ),
        !!game.data?.every(
            (lang) => lang.endMedia.length && lang.endDescription
        )
    );
    const summarySectionStatus = new GameWizardSectionStatus(
        false,
        false,
        false
    );
    return {
        activeStage:
            game.gameWizardState?.activeStage || GameWizardStages.LANGUAGES,
        id: game.id,
        siteId: game.siteId,
        recommendedLanguages: game.recommendedLanguages || [],
        languages: game.gameWizardState?.languages || {
            data: {
                languagesList,
                defaultLanguage: game.defaultLocale || ('' as LocaleType)
            },
            sectionStatus: { ...languageSectionStatus }
        },
        generalDetails: game.gameWizardState?.generalDetails || {
            data: {
                details:
                    game.data?.reduce(
                        (langAccumulator, langValue) => ({
                            ...langAccumulator,
                            [langValue.locale]: {
                                name: langValue.name,
                                subtitle: langValue.subtitle
                            }
                        }),
                        {}
                    ) || {},
                icon: game.icon
            },
            sectionStatus: { ...generalDetailsSectionStatus }
        },
        introduction: game.gameWizardState?.introduction || {
            data: {
                details:
                    game.data?.reduce(
                        (langAccumulator, langValue) => ({
                            ...langAccumulator,
                            [langValue.locale]: {
                                introductionDescription:
                                    langValue.introductionDescription,
                                introductionMedia: langValue.introductionMedia
                            }
                        }),
                        {}
                    ) || {}
            },
            sectionStatus: { ...introductionSectionStatus }
        },
        challenges: game.gameWizardState?.challenges || {
            data:
                game.data?.reduce(
                    (langAccumulator, langValue) => ({
                        ...langAccumulator,
                        [langValue.locale]: langValue.challenges.reduce(
                            (challengesAccumulator, challenge) => ({
                                ...challengesAccumulator,
                                [challenge.soiId]: challenge
                            }),
                            {}
                        )
                    }),
                    {}
                ) || {},
            sectionStatus: { ...challengesSectionStatus }
        },
        completion: game.gameWizardState?.completion || {
            data: {
                details:
                    game.data?.reduce(
                        (langAccumulator, langValue) => ({
                            ...langAccumulator,
                            [langValue.locale]: {
                                endDescription: langValue.endDescription,
                                endMedia: langValue.endMedia
                            }
                        }),
                        {}
                    ) || {}
            },
            sectionStatus: { ...completionSectionStatus }
        },
        summary: game.gameWizardState?.summary || {
            data: { active: game.active },
            sectionStatus: { ...summarySectionStatus }
        }
    };
};

export const mapGameStateIntoGameObject = (state: GameWizardState): Game => {
    const game: Game = {
        id: state.id,
        siteId: state.siteId,
        isEdit: true,
        isCreate: true,
        defaultLocale: state.languages.data?.defaultLanguage,
        active: state.summary.data?.active,
        icon: state.generalDetails.data?.icon,
        data: state.languages.data?.languagesList?.map((lang) => ({
            locale: lang || 'en',
            name: state.generalDetails.data?.details?.[lang]?.name || '',
            subtitle:
                state.generalDetails.data?.details?.[lang]?.subtitle || '',
            introductionDescription:
                state.introduction.data?.details?.[lang]
                    ?.introductionDescription || '',
            introductionMedia:
                state.introduction.data?.details?.[lang]?.introductionMedia ||
                [],
            endDescription:
                state.completion.data?.details?.[lang]?.endDescription || '',
            endMedia: state.completion.data?.details?.[lang]?.endMedia || [],
            challenges: Object.values(state.challenges.data?.[lang] || {}) || []
        })),
        gameWizardState: state
    };
    if (!game.data?.length) {
        delete game.data;
    }
    return game;
};

export function orderGameMediaItems(items: GameMedia[]) {
    return items.map((item, i) => ({
        ...item,
        index: i
    }));
}
