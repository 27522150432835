import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { User } from '../auth/auth.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthRoutePaths } from '../auth/auth-routing.module';
import { Router } from '@angular/router';
import { Login } from '../auth/store/auth-store.actions';
import { ResetAppState } from '../store/app-store.actions';
import { AppStoreService } from '../store/app-store.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private baseUrl = `${environment.BUSINESS_URL}/api/auth`;

    constructor(
        private http: HttpClient,
        private router: Router,
        private appStoreService: AppStoreService
    ) {}

    login(email: string, password: string): Observable<User> {
        const data = {
            email,
            password,
            remember_me: true
        };
        return this.http.post<User>(`${this.baseUrl}/login`, data).pipe(
            map((result: any) => ({
                ...result,
                actions: result.actions.reduce(
                    (actionsMap: any, action: any) => {
                        actionsMap[action.resource_id] = action.actions;
                        return actionsMap;
                    },
                    {}
                )
            }))
        );
    }

    isLoggedIn(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}/is_logged_in`).pipe(
            map((result: any) => ({
                ...result,
                actions: result.actions.reduce(
                    (actionsMap: any, action: any) => {
                        actionsMap[action.resource_id] = action.actions;
                        return actionsMap;
                    },
                    {}
                )
            }))
        );
    }

    logout() {
        return from(
            this.router.navigate([`/${AuthRoutePaths.LOGIN}`], {
                state: { navigateBeforeLogout: true }
            })
        ).pipe(
            switchMap((success) =>
                success
                    ? this.http.post(`${this.baseUrl}/logout`, {}).pipe(
                          tap(() => this.clearSession()),
                          catchError(() => this.router.navigate(['..']))
                      )
                    : of(false)
            )
        );
    }

    startSession(user: User): void {
        this.appStoreService.store.dispatch(Login({ user }));
    }

    clearSession(): void {
        this.appStoreService.store.dispatch(ResetAppState());
        localStorage.removeItem(environment.LOCALSTORAGE_USER_KEY);
    }

    getSession(): Promise<User | null> | void {
        const user = localStorage.getItem(environment.LOCALSTORAGE_USER_KEY)!;
        if (user) {
            return lastValueFrom(
                this.isLoggedIn().pipe(
                    tap((u) => this.startSession(u)),
                    catchError(() => {
                        this.clearSession();
                        this.router.navigate([`/${AuthRoutePaths.LOGIN}`]);
                        return of(null);
                    })
                )
            );
        }
    }
}
