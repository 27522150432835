export interface WizardSection<T> {
    data?: T;
    sectionStatus: WizardSectionStatus;
}

export enum WizardPageLayout {
    THREE_QUARTERS_SCREEN = 'THREE_QUARTERS_SCREEN',
    WHOLE_SCREEN = 'WHOLE_SCREEN',
    HALF_SCREEN = 'HALF_SCREEN',
    TWO_COLUMNS = 'TWO_COLUMNS',
    FULL_SCREEN = 'FULL_SCREEN',
    DIALOG_ON_HALF_SCREEN = 'DIALOG_ON_HALF_SCREEN',
    FULL_ON_DIALOG = 'FULL_ON_DIALOG',
    HALF_ON_DIALOG = 'HALF_ON_DIALOG',
    TWO_COLUMNS_ON_DIALOG = 'TWO_COLUMNS_ON_DIALOG'
}

export const WizardPageLayoutCssClassMap = new Map<WizardPageLayout, string>([
    [WizardPageLayout.THREE_QUARTERS_SCREEN, 'Wizard-relative-screen'],
    [
        WizardPageLayout.WHOLE_SCREEN,
        'Wizard-relative-screen Wizard-whole-screen'
    ],
    [
        WizardPageLayout.HALF_SCREEN,
        'Wizard-relative-screen Wizard-full-header Wizard-half-screen'
    ],
    [
        WizardPageLayout.TWO_COLUMNS,
        'Wizard-relative-screen Wizard-full-header Wizard-two-columns'
    ],
    [WizardPageLayout.FULL_SCREEN, 'Wizard-full-screen'],
    [
        WizardPageLayout.DIALOG_ON_HALF_SCREEN,
        'Wizard-full-screen dialog-on-half-screen'
    ],
    [WizardPageLayout.FULL_ON_DIALOG, 'Wizard-on-dialog full-on-dialog'],
    [
        WizardPageLayout.HALF_ON_DIALOG,
        'Wizard-on-dialog Wizard-full-header Wizard-half-dialog dialog half-on-dialog'
    ],
    [
        WizardPageLayout.TWO_COLUMNS_ON_DIALOG,
        'Wizard-on-dialog two-columns-on-dialog'
    ]
]);

export class WizardSectionStatus {
    invalid: boolean;
    complete: boolean;
    disableDependants?: boolean;

    constructor(
        invalid: boolean,
        complete: boolean,
        disableDependant?: boolean
    ) {
        this.invalid = invalid;
        this.complete = complete;
        this.disableDependants = disableDependant || invalid;
    }
}

export interface WizardSectionDetails<WizardStages = string> {
    titleKey: string;
    descriptionKey: string;
    genieTextKey: string;
    genieVideoUrl: string;
    isAChild?: boolean;
    pageLayoutStatus?: WizardPageLayout;
    navBarTextKey: string;
    stage: WizardStages;
    icon: string;
    showAppPreview?: boolean;
    children?: WizardStages[];
    dependantOn?: WizardStages[];
    hideNextBtn?: boolean;
    hidePreviousBtn?: boolean;
    isLastOnViewLessMode?: boolean;
}

export type MapWizardStageDetails<WizardStages> = Map<
    WizardStages,
    WizardSectionDetails<WizardStages>
>;

export interface WizardMobile {
    messageKey: string;
    link: string[];
    buttonTextKey: string;
}
