import { createAction, props } from '@ngrx/store';
import { User, UserActions } from '../auth.model';
import { LocaleType } from '../../shared/locales.models';

export enum AuthActionTypes {
    LOGIN = '[Auth] Login',
    UPDATE_USER_DETAILS = '[Auth] Update User Details',
    UPDATE_USER_NAME = '[Auth] Update User Name',
    UPDATE_USER_PROFILE_IMAGE = '[Auth] Update User Profile Image',
    UPDATE_USER_ACTIONS = '[Auth] Update User Actions',
    UPDATE_USER_BUSINESS_NAME = '[Auth] Update User Business Name',
    UPDATE_USER_BUSINESS_LOGO_URL = '[Auth] Update User Business Logo',
    UPDATE_USER_BUSINESS_IS_COMPLETE = '[Auth] Update User Business Is Complete'
}

export const Login = createAction(
    AuthActionTypes.LOGIN,
    props<{ user: User }>()
);

export const UpdateUserDetails = createAction(
    AuthActionTypes.UPDATE_USER_DETAILS,
    props<{ user: User }>()
);

export const UpdateUserName = createAction(
    AuthActionTypes.UPDATE_USER_NAME,
    props<{ name: string }>()
);

export const UpdateUserProfileImage = createAction(
    AuthActionTypes.UPDATE_USER_PROFILE_IMAGE,
    props<{ image: string }>()
);

export const UpdateUserActions = createAction(
    AuthActionTypes.UPDATE_USER_ACTIONS,
    props<{ userActions: UserActions }>()
);

export const UpdateBusinessName = createAction(
    AuthActionTypes.UPDATE_USER_BUSINESS_NAME,
    props<{ businessName: { [locale in LocaleType]?: string } }>()
);

export const UpdateUserBusinessLogoUrl = createAction(
    AuthActionTypes.UPDATE_USER_BUSINESS_LOGO_URL,
    props<{ logo: string }>()
);

export const UpdateUserBusinessIsComplete = createAction(
    AuthActionTypes.UPDATE_USER_BUSINESS_IS_COMPLETE,
    props<{ isDataComplete: boolean }>()
);
