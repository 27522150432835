import { createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { LocaleType } from '../../../shared/locales.models';
import {
    AddDcfLanguage,
    RemoveDcfLanguage,
    SetDefaultDcfLanguage
} from '../actions/dcf-wizard-languages.actions';
import { DcfWizardLanguagesSection } from '../../models/dcf-wizard-languages.model';

export const dcfLanguagesReducer = createReducer(
    {} as DcfWizardLanguagesSection,
    immerOn(AddDcfLanguage, (state, { langToAdd }) => {
        state.data?.languagesList.push(langToAdd);
        state.data!.defaultLanguage = state.data?.defaultLanguage || langToAdd;
        state.sectionStatus.invalid = false;
        state.sectionStatus.complete = !!state.data?.languagesList?.length;
        state.sectionStatus.disableDependants = false;
    }),
    immerOn(RemoveDcfLanguage, (state, { langToRemove }) => {
        const langDetailsIndex =
            state.data?.languagesList?.indexOf(langToRemove);
        state.data?.languagesList?.splice(langDetailsIndex!, 1);
        if (!state.data?.languagesList?.length) {
            state.data!.defaultLanguage = '' as LocaleType;
        } else if (state.data?.defaultLanguage === langToRemove) {
            state.data!.defaultLanguage = state.data.languagesList[0];
        }
        state.sectionStatus.invalid = !state.data?.languagesList?.length;
        state.sectionStatus.complete = !!state.data?.languagesList?.length;
        state.sectionStatus.disableDependants =
            !state.data?.languagesList?.length;
    }),
    immerOn(SetDefaultDcfLanguage, (state, { language }) => {
        state.data!.defaultLanguage = language;
    })
);
