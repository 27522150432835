<p
    class="label"
    *ngIf="label.length"
    [appTypography]="'SMALL'"
    [color]="
        _value || (isInputFocus$ | async)
            ? 'DEFAULT_COLOR'
            : 'DEFAULT_COLOR_DISABLED'
    "
    (click)="inputElement.focus()"
    [ngClass]="{
        'has-content': _value,
        selected: (isInputFocus$ | async)
    }">
    {{ label | translate }}
</p>
<textarea
    #inputElement
    [appTypography]="'SECONDARY'"
    matInput
    (focusin)="isInputFocus$.next(true)"
    (focusout)="isInputFocus$.next(!!placeholder.length)"
    [placeholder]="placeholder | translate"
    [(ngModel)]="_value"
    [rows]="rows"
    [maxlength]="maxLength"></textarea>

<div
    *ngIf="hint"
    class="hint"
    appTypography="TINY">
    {{ hint | translate }}
</div>
