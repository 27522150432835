import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { finalize, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackbarService } from '../../../services/snackbar.service';
import { setSiteSettings } from '../../store/selections/site-settings.store';
import {
    dateFormatOptionsMap,
    lengthTypeOptionsMap,
    timeFormatOptionsMap,
    weekStartOptionsMap
} from './locale-settings.const';
import {
    DateFormat,
    LengthType,
    SetSiteSetting,
    SiteSettings,
    TimeFormat,
    WeekStart
} from '../../models/site-settings.model';
import { AppStoreService } from '../../../store/app-store.service';
import { SiteCard } from '../../models/site.model';

@Component({
    selector: 'app-locale-settings',
    templateUrl: './locale-settings.component.html',
    styleUrls: ['./locale-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleSettingsComponent implements OnInit {
    siteSettings!: SiteSettings;
    siteId?: string;
    localeSettingsForm!: FormGroup<{
        timeFormat: FormControl<TimeFormat>;
        dateFormat: FormControl<DateFormat>;
        lengthType: FormControl<LengthType>;
        weekStart: FormControl<WeekStart>;
    }>;

    timeFormatOptions = timeFormatOptionsMap;
    dateFormatOptions = dateFormatOptionsMap;
    lengthTypeOptions = lengthTypeOptionsMap;
    weekStartOptionsMap = weekStartOptionsMap;

    constructor(
        private translate: TranslateService,
        private fb: NonNullableFormBuilder,
        private toggleSpinnerService: ToggleSpinnerService,
        private siteService: SiteService,
        private activatedRoute: ActivatedRoute,
        private snackbarService: SnackbarService,
        private appStoreService: AppStoreService
    ) {}

    ngOnInit(): void {
        this.initData();
    }

    initData(): void {
        (
            this.activatedRoute.parent?.data as Observable<{
                siteInfo: SiteCard;
            }>
        )
            .pipe(
                tap((routerData) => {
                    this.siteId = routerData.siteInfo.id;
                }),
                take(1)
            )
            .subscribe();
        this.siteSettings = this.appStoreService.state.siteSettings;
        this.initLocaleSettingsForm(this.siteSettings);
    }

    initLocaleSettingsForm(settingsData: SiteSettings) {
        this.localeSettingsForm = this.fb.group({
            timeFormat: this.fb.control(settingsData.timeFormat!),
            dateFormat: this.fb.control(settingsData.dateFormat!),
            lengthType: this.fb.control(settingsData.lengthType!),
            weekStart: this.fb.control(settingsData.weekStart!)
        });
    }

    saveLocaleSettings(formValue: SetSiteSetting): void {
        this.localeSettingsForm.markAsPristine();
        this.toggleSpinnerService.toggle(true);
        this.siteService
            .updateSiteSettings(this.siteId!, formValue)
            .pipe(
                finalize(() => {
                    this.toggleSpinnerService.toggle(false);
                })
            )
            .subscribe({
                next: (result) => {
                    this.snackbarService.open(
                        this.translate.instant(
                            'PREFERENCES_SETTINGS_SAVED_SUCCESSFULLY'
                        )
                    );
                    this.appStoreService.store.dispatch(
                        setSiteSettings(result)
                    );
                },
                error: (error) => {
                    console.log('error', error);
                }
            });
    }
}
