import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonStandaloneComponent } from '../../../buttons/custom-button/custom-button-standalone.component';
import { SafePipe } from '../../../../pipes/safe/safe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TypographyDirective } from '../../../../directives/typography/typography.directive';
import {
    SCREEN_BREAK_POINTS,
    WT_LOGO_TRANSPARENT
} from '../../../../../shared/shared.constants';
import { GenieStateTypes } from '../../wizars-template/components/wizard-genie/wizard-genie.model';
import { User } from '../../../../../auth/auth.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app-store.model';
import * as AuthSelectors from '../../../../../auth/store/auth-store.selectors';
import { take } from 'rxjs/operators';
import { WizardMobile } from '../../wizars-template/wizard-template.model';

@Component({
    selector: 'app-create-entity-genie',
    standalone: true,
    imports: [
        CommonModule,
        CustomButtonStandaloneComponent,
        SafePipe,
        TranslateModule,
        TypographyDirective
    ],
    templateUrl: './create-entity-genie.component.html',
    styleUrls: ['./create-entity-genie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEntityGenieComponent implements OnInit, AfterViewInit {
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
    genieState = new BehaviorSubject<GenieStateTypes>(GenieStateTypes.CLOSED);
    protected readonly WT_LOGO_TRANSPARENT = WT_LOGO_TRANSPARENT;
    @Input() titleTextKey!: string;
    @Input() contentTextKey!: string;
    @Input() buttonTextKey!: string;
    @Input() genieVideoUrl?: string;
    @Input() wizardMobileData?: WizardMobile;
    @Output() createClicked = new EventEmitter<void>();

    user!: Observable<User | null>;

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.user = this.store.select(AuthSelectors.getUser).pipe(take(1));
    }

    ngAfterViewInit() {
        setTimeout(() => this.genieState.next(GenieStateTypes.OPENING), 50);
        setTimeout(() => this.genieState.next(GenieStateTypes.OPENED), 2000);
    }
}
