import {
    Directive,
    inject,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { NgStyle } from '@angular/common';
import {
    OperativeTypographyColorName,
    OperativeTypographyTypes
} from './typography.model';
import {
    getOperativeTypographyColorMap,
    getOperativeTypographyDesktopStyleMap,
    getOperativeTypographyMobileStyleMap
} from './typography.const';
import { SCREEN_BREAK_POINTS } from '../../../shared/shared.constants';

@Directive({
    selector: '[appTypography]',
    standalone: true,
    hostDirectives: [NgStyle]
})
export class TypographyDirective implements OnChanges {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appTypography') type:
        | keyof typeof OperativeTypographyTypes
        | undefined = OperativeTypographyTypes.SECONDARY;
    @Input() color: keyof typeof OperativeTypographyColorName =
        OperativeTypographyColorName.DEFAULT_COLOR;

    @Input() additionStyle?: { [klass: string]: any };
    @Input() isPlaceholder = false;
    ngStyleDirective = inject(NgStyle);

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        const isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
        this.ngStyleDirective.ngStyle = {
            ...(isMobile
                ? getOperativeTypographyMobileStyleMap().get(this.type!)
                : getOperativeTypographyDesktopStyleMap().get(this.type!)),
            ...getOperativeTypographyColorMap().get(this.color),
            ...(this.additionStyle || {})
        };
        this.ngStyleDirective.ngDoCheck();
    }
}
