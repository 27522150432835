import { createAction, props } from '@ngrx/store';
import { MessageAppearance } from '../../models/site-wizard-greeting.model';
import { LocaleType } from '../../../shared/locales.models';

export enum GreetingActionTypes {
    GREETING_TOGGLE_WELCOME_MESSAGE = '[SiteWizard] Greeting Toggle Welcome Message',
    GREETING_TOGGLE_EXIT_MESSAGE = '[SiteWizard] Greeting Toggle Exit Message',
    GREETING_CHANGE_MESSAGE_APPEARANCE = '[SiteWizard] Greeting Change Message Appearance',
    GREETING_UPDATE_MESSAGES = '[SiteWizard] Greeting Update Messages',
    GREETING_COMPLETE = '[SiteWizard] Greeting Complete',
    GREETING_SET_SELECTED_LANG_TAB = '[SiteWizard] Greeting Set Selected Lang Tab'
}

export const ToggleWelcomeMessage = createAction(
    GreetingActionTypes.GREETING_TOGGLE_WELCOME_MESSAGE
);

export const ToggleExitMessage = createAction(
    GreetingActionTypes.GREETING_TOGGLE_EXIT_MESSAGE
);

export const UpdateMessages = createAction(
    GreetingActionTypes.GREETING_UPDATE_MESSAGES,
    props<{
        language: string;
        fieldName: string;
        value: string;
        invalid: boolean;
    }>()
);

export const SetGreetingSelectedLangTab = createAction(
    GreetingActionTypes.GREETING_SET_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);

export const ChangeMessageAppearance = createAction(
    GreetingActionTypes.GREETING_CHANGE_MESSAGE_APPEARANCE,
    props<{ messageAppearance: MessageAppearance }>()
);

export const GreetingComplete = createAction(
    GreetingActionTypes.GREETING_COMPLETE
);
