import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState } from '../../../store/app-store.model';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../auth/store/auth-store.selectors';
import { finalize, take } from 'rxjs/operators';
import { ContactService } from '../../../auth/services/contact.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import {
    FacebookPixelService,
    FbqEventName,
    FbqEventType,
    FbqInteractionType
} from '../../../services/facebook-pixel.service';
import { HubspotService } from '../../../services/hubspot.service';
import { HUBSPOT_CONTACT_US_SUPPORT_FORM } from '../../../shared/hubspot.constants';
import { CountryISO } from 'ngx-intl-tel-input/lib/enums/country-iso.enum';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CustomInputStandaloneComponent } from '../custom-input/custom-input-standalone.component';
import { ErrorDotDirective } from '../../directives/error-dot.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CustomTextareaStandaloneComponent } from '../custom-textarea/custom-textarea-standalone.component';
import { ButtonsContainerStandaloneComponent } from '../buttons/buttons-container/buttons-container-standalone.component';
import { CustomButtonStandaloneComponent } from '../buttons/custom-button/custom-button-standalone.component';
import { TypographyDirective } from '../../directives/typography/typography.directive';

@Component({
    standalone: true,
    selector: 'app-contact-standalone',
    templateUrl: './contact-standalone.component.html',
    styleUrls: ['./contact-standalone.component.scss'],
    imports: [
        ReactiveFormsModule,
        CustomInputStandaloneComponent,
        ErrorDotDirective,
        NgxIntlTelInputModule,
        CustomTextareaStandaloneComponent,
        ButtonsContainerStandaloneComponent,
        MatDialogModule,
        CustomButtonStandaloneComponent,
        TranslateModule,
        TypographyDirective
    ],
    providers: [ContactService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactStandaloneComponent implements OnInit {
    public contactForm!: UntypedFormGroup;
    messageMaxLength = 5000;
    countryCode: CountryISO = 'us' as CountryISO;

    constructor(
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private contactService: ContactService,
        private snackbarService: SnackbarService,
        private dialog: MatDialog,
        private store: Store<AppState>,
        private toggleSpinnerService: ToggleSpinnerService,
        private facebookPixelService: FacebookPixelService,
        private hubspotService: HubspotService
    ) {}

    ngOnInit(): void {
        let loggedInUser: any;
        this.store
            .select(AuthSelectors.getUser)
            .pipe(take(1))
            .subscribe((user) => (loggedInUser = user));
        this.contactForm = this.fb.group({
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl(loggedInUser?.email, [
                Validators.required,
                Validators.email,
                Validators.pattern(
                    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
                )
            ]),
            phone: new UntypedFormControl(loggedInUser?.phone, []),
            message: new UntypedFormControl(null, [
                Validators.required,
                Validators.maxLength(this.messageMaxLength)
            ])
        });
        this.emitFbqInputEvents();
    }

    onSubmit(): void {
        this.emitFbqEvent('track', 'Contact', 'dialog_send', 'click');
        this.toggleSpinnerService.toggle(true);
        const contactFormValue = this.contactForm.value;

        // TODO remove when API ready
        const contactData = { ...contactFormValue };
        contactData.name = contactData.firstName + ' ' + contactData.lastName;
        delete contactData.firstName;
        delete contactData.lastName;

        contactFormValue.message = `${this.contactForm.value.message} \n Sent from ${location.href}`;

        const hubSpotData = {
            ...contactFormValue,
            wishtrip_url: window.location.pathname
        };
        if (!hubSpotData.phone) {
            delete hubSpotData.phone;
        }
        console.log(hubSpotData);
        this.contactService
            .requestSupport(contactData)
            .pipe(finalize(() => this.toggleSpinnerService.toggle(false)))
            .subscribe({
                next: () => {
                    this.snackbarService.open(
                        this.translate.instant('THANK_YOU')
                    );
                    this.dialog.closeAll();
                    this.hubspotService.sendHubspotForm(
                        HUBSPOT_CONTACT_US_SUPPORT_FORM,
                        hubSpotData
                    );
                    this.contactForm.reset();
                },
                error: (error) => console.log('error sending contact', error)
            });
    }

    emitFbqInputEvents(): void {
        Object.keys(this.contactForm.value).forEach((controlName) => {
            this.contactForm
                .get(controlName)!
                .valueChanges.pipe(take(1))
                .subscribe(() => {
                    this.emitFbqEvent(
                        'trackCustom',
                        'wtInteraction',
                        controlName,
                        'dataEntry'
                    );
                });
        });
    }

    emitFbqEvent(
        type: FbqEventType,
        name: FbqEventName,
        elementId: string,
        interactionType: FbqInteractionType
    ): void {
        this.facebookPixelService.fireFbqEvent(
            type,
            name,
            elementId,
            interactionType
        );
    }
}
