import { createAction, props } from '@ngrx/store';
export enum TagsActionTypes {
    ADD_TAG = '[trekWizard] Add Tag',
    DELETE_TAG = '[trekWizard] Delete Tag',
    ADD_LABEL = '[trekWizard] Add Label',
    DELETE_LABEL = '[trekWizard] Delete Label',
    TAGS_COMPLETE = '[trekWizard] Tags Complete'
}

export const AddTag = createAction(
    TagsActionTypes.ADD_TAG,
    props<{ tagId: string }>()
);

export const DeleteTag = createAction(
    TagsActionTypes.DELETE_TAG,
    props<{ tagId: string }>()
);

export const AddLabel = createAction(
    TagsActionTypes.ADD_LABEL,
    props<{ labelId: string }>()
);

export const DeleteLabel = createAction(
    TagsActionTypes.DELETE_LABEL,
    props<{ labelId: string }>()
);

export const TagsComplete = createAction(TagsActionTypes.TAGS_COMPLETE);
