import { createReducer } from '@ngrx/store';
import { DcfWizardTypeSection } from '../../models/dcf-wizard-type.model';
import { immerOn } from 'ngrx-immer/store';
import {
    updateDcfScheduledTypeDetails,
    updateDcfTargetAmount,
    updateDcfTargetShowToUser,
    updateDcfType
} from '../actions/dcf-wizard-type.actions';
import { CampaignTypeDetails, CampaignTypes } from '../../dcf-wizard.model';

export const dcfWizardTypeReducer = createReducer(
    {} as DcfWizardTypeSection,
    immerOn(updateDcfType, (state, { campaignType }) => {
        state.data!.name = campaignType;
        if (campaignType === 'TIME_TYPE' && !state.data!.campaignTimeType) {
            state.data!.campaignTimeType = [];
        } else if (
            campaignType === 'TARGET_TYPE' &&
            !state.data!.campaignTargetType
        ) {
            state.data!.campaignTargetType = {
                showToUser: true
            };
        }
        const isInvalid = checkIfTypeSectionInvalid(state.data!);
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = checkIfTypeSectionCompleted(state.data!);
    }),
    immerOn(updateDcfTargetAmount, (state, { amount }) => {
        state.data!.campaignTargetType!.amountTarget =
            amount > 0 ? amount : undefined;
        const isInvalid = checkIfTypeSectionInvalid(state.data!);
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = checkIfTypeSectionCompleted(state.data!);
    }),
    immerOn(updateDcfTargetShowToUser, (state, { showToUser }) => {
        state.data!.campaignTargetType!.showToUser = showToUser;
    }),
    immerOn(updateDcfScheduledTypeDetails, (state, { campaignTypeDetails }) => {
        state.data!.campaignTimeType = campaignTypeDetails;
        const isInvalid = checkIfTypeSectionInvalid(state.data!);
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = checkIfTypeSectionCompleted(state.data!);
    })
);

export const checkIfTypeSectionInvalid = (
    campaignTypeDetails: CampaignTypeDetails<CampaignTypes>
): boolean => {
    if (!campaignTypeDetails.name) {
        return true;
    } else if (campaignTypeDetails.name === 'TIME_TYPE') {
        const scheduledCampaignDetails = (
            campaignTypeDetails as CampaignTypeDetails<'TIME_TYPE'>
        ).campaignTimeType;
        return (
            !scheduledCampaignDetails.length ||
            !scheduledCampaignDetails.every((day) => !!day.hours.length)
        );
    } else {
        const targetCampaignDetails = (
            campaignTypeDetails as CampaignTypeDetails<'TARGET_TYPE'>
        ).campaignTargetType;
        return !targetCampaignDetails.amountTarget;
    }
};

export const checkIfTypeSectionCompleted = (
    campaignTypeDetails: CampaignTypeDetails<CampaignTypes>
): boolean => {
    if (!campaignTypeDetails.name) {
        return false;
    } else if (campaignTypeDetails.name === 'TIME_TYPE') {
        const scheduledCampaignDetails = (
            campaignTypeDetails as CampaignTypeDetails<'TIME_TYPE'>
        ).campaignTimeType;
        return (
            !!scheduledCampaignDetails.length &&
            !!scheduledCampaignDetails.every((day) => !!day.hours.length)
        );
    } else {
        const targetCampaignDetails = (
            campaignTypeDetails as CampaignTypeDetails<'TARGET_TYPE'>
        ).campaignTargetType;
        return !!targetCampaignDetails.amountTarget;
    }
};
