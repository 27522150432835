import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbsStage } from '../standalone/components/breadcrumbs/breadcrumbs.model';

@Injectable({ providedIn: 'root' })
export class HeaderService {
    private _breadcrumbs$ = new BehaviorSubject<BreadcrumbsStage[]>([]);

    get breadcrumbs$() {
        return this._breadcrumbs$.asObservable();
    }

    set breadcrumbs(breadcrumbs: BreadcrumbsStage[]) {
        setTimeout(() => {
            this._breadcrumbs$.next(breadcrumbs);
        });
    }
}
