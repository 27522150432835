import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MapboxEventsService {
    mapboxEvent$?: Observable<any>;
    private mapboxEvent?: BehaviorSubject<any>;

    init(): void {
        this.mapboxEvent = new BehaviorSubject<any>([]);
        this.mapboxEvent$ = this.mapboxEvent.asObservable();
    }

    changeEvent(data: any[]): void {
        this.mapboxEvent?.next(data);
    }

    complete(): void {
        this.mapboxEvent?.complete();
        this.mapboxEvent = undefined;
        this.mapboxEvent$ = undefined;
    }
}
