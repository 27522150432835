import { createReducer, on } from '@ngrx/store';
import {
    dcfWizardStageDetails,
    DcfWizardStages
} from '../../constants/dcf-wizard.const';
import {
    ChangeDcfStage,
    NextDcfStage,
    PrevDcfStage
} from '../actions/dcf-wizard-stages.actions';

export const dcfStagesReducer = createReducer(
    DcfWizardStages.LANGUAGES,
    on(ChangeDcfStage, (state, { stage }) => stage),
    on(NextDcfStage, (state) => {
        const arrayStages = Array.from(dcfWizardStageDetails.keys());
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex + 1];
    }),
    on(PrevDcfStage, (state) => {
        const arrayStages = Array.from(dcfWizardStageDetails.keys());
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex - 1];
    })
);
