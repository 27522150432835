import { createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import {
    setDcfDetailsSelectedLangTab,
    updateDcfDetails
} from '../actions/dcf-wizard-details.actions';
import { DcfWizardDetailsSection } from '../../models/dcf-wizard-details.model';
import {
    AddDcfLanguage,
    RemoveDcfLanguage
} from '../actions/dcf-wizard-languages.actions';
import { CampaignDetails } from '../../dcf-wizard.model';
import { LocaleType } from '../../../shared/locales.models';

export const dcfWizardDetailsReducer = createReducer(
    {} as DcfWizardDetailsSection,
    immerOn(AddDcfLanguage, (state, { langToAdd }) => {
        state.data?.generalDetails.push({
            language: langToAdd,
            title: '',
            description: ''
        });
        if (state.data!.generalDetails.length === 1) {
            state.sectionStatus.selectedLangTab = langToAdd;
        }
        state.sectionStatus.invalid = true;
        state.sectionStatus.disableDependants = true;
        state.sectionStatus.complete = false;
        state.sectionStatus.selectedLangTab =
            state.sectionStatus.selectedLangTab || langToAdd;
    }),
    immerOn(RemoveDcfLanguage, (state, { langToRemove }) => {
        const langDetailsIndex = state.data!.generalDetails.findIndex(
            (langDetails) => langDetails.language === langToRemove
        );
        delete state.data!.generalDetails[langDetailsIndex];
        state.data!.generalDetails = state.data!.generalDetails.filter(
            (item) => !!item
        );
        const isInvalid =
            state.data!.internalName.length > 0 &&
            checkIfDetailsStateInvalid(state.data!);
        state.sectionStatus.invalid = isInvalid;
        state.sectionStatus.disableDependants = isInvalid;
        state.sectionStatus.complete = !state.sectionStatus.invalid
            ? false
            : checkIfDetailsStateComplete(state.data!);
        if (state.sectionStatus.selectedLangTab === langToRemove) {
            state.sectionStatus.selectedLangTab = state.data?.generalDetails[0]
                .language as LocaleType;
        }
    }),
    immerOn(setDcfDetailsSelectedLangTab, (state, { selectedLangTab }) => {
        state.sectionStatus.selectedLangTab = selectedLangTab;
    }),
    immerOn(updateDcfDetails, (state, { details, invalid, complete }) => {
        state.data = details;
        state.sectionStatus.invalid = invalid;
        state.sectionStatus.disableDependants = invalid;
        state.sectionStatus.complete = complete;
    })
);

export const checkIfDetailsStateInvalid = (detailsState: CampaignDetails) =>
    !detailsState.internalName ||
    detailsState.generalDetails.some(
        (langDetails) => !langDetails.title || !langDetails.description
    );
export const checkIfDetailsStateComplete = (detailsState: CampaignDetails) =>
    !!detailsState.internalName &&
    detailsState.generalDetails.every(
        (langDetails) => langDetails.title && langDetails.description
    );
