import * as mapboxgl from 'mapbox-gl';
import { MarkerLayer } from './MarkerLayer';
import { StyleData } from '../typings/mapbox-utils';

export class LayerControl implements mapboxgl.IControl {
    private map?: mapboxgl.Map;
    private container!: HTMLElement;
    private stylesContainer!: HTMLElement;
    private layersContainer!: HTMLElement;
    private markerLayersContainer!: HTMLElement;
    private divider!: HTMLElement;
    private radioBtnGroup = 'styleRadio';
    public styles: Array<StyleData>;
    private layers: Array<string[]>;
    private markerLayers: Array<MarkerLayer>;
    private selectedStyle!: StyleData;
    private styleCtrlIdPrefix = 'mapbox-style-control-';

    constructor(title?: string) {
        this.styles = [];
        this.layers = [];
        this.markerLayers = [];
        this.createContainer(title);
    }

    getSelectedStyle(): StyleData {
        return this.selectedStyle;
    }

    changeSelectedStyle(givenStyle: string, map: mapboxgl.Map) {
        let index = this.styles.findIndex(
            (modeObj, i) => modeObj.label.toUpperCase() === givenStyle
        );
        if (index < 0) {
            index = 0;
        }
        if (this.styles[index] !== this.selectedStyle) {
            this.setStyleAsChecked(map, index);
        }
    }

    addStyles(styles: Array<StyleData>, title?: string, note?: string): void {
        if (title) {
            const styleTitleContainer = document.createElement('div');
            styleTitleContainer.className = 'section-title';
            styleTitleContainer.innerText = title;
            this.stylesContainer.appendChild(styleTitleContainer);
        }
        styles.forEach((style) => this.addStyle(style));

        if (note) {
            const noteContainer = document.createElement('div');
            noteContainer.className = 'note';
            noteContainer.innerHTML = note;
            this.stylesContainer.appendChild(noteContainer);
        }
    }

    addStyle({ styleUrl, label, name, icon }: StyleData): void {
        if (this.isStyleAdded(styleUrl)) {
            return;
        }

        const styleContainer = this.createStyleContainer(
            label,
            icon,
            !this.styles.length,
            this.styles.length
        );
        if (this.layers.length || this.markerLayers.length) {
            this.divider.style.display = '';
        }

        this.styles.push({ styleUrl, label, name, icon });
        this.stylesContainer.appendChild(styleContainer);

        // Set initial selected style.
        if (this.styles.length === 1) {
            this.selectedStyle = this.styles[0];
        }
    }

    removeMarkerLayer(layer: MarkerLayer): void {
        const index = this.markerLayers.indexOf(layer);

        if (index < 0) {
            return;
        }

        this.markerLayers.splice(index, 1);
        const layerEl: any = this.markerLayersContainer.querySelectorAll(
            'input[type="checkbox"]'
        )[index];
        this.markerLayersContainer.removeChild(layerEl.parentNode);

        if (!this.layers.length && !this.markerLayers.length) {
            this.divider.style.display = 'none';
        }
    }

    onAdd(map: mapboxgl.Map): HTMLElement {
        this.map = map;
        return this.container;
    }

    onRemove() {
        this.container.parentNode!.removeChild(this.container);
        this.map = undefined;
    }

    getDefaultPosition(): string {
        return 'top-right';
    }

    private isStyleAdded(styleUrl: string): any {
        return !this.styles.every((style) => style.styleUrl !== styleUrl);
    }

    private createContainer(title?: string): void {
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapbox-layer-control';

        const btn = document.createElement('div');
        btn.className = 'mapbox-layer-control__btn';

        this.stylesContainer = document.createElement('div');
        this.stylesContainer.className = 'styles-container';
        this.divider = document.createElement('hr');
        this.divider.style.display = 'none';
        this.layersContainer = document.createElement('div');
        this.markerLayersContainer = document.createElement('div');

        const controlsContainer = document.createElement('div');
        controlsContainer.className = 'mapbox-layer-control__controls';
        if (title) {
            const titleDiv = document.createElement('div');
            titleDiv.className = 'mapbox-layer-control__controls__title';
            titleDiv.innerHTML = title;
            controlsContainer.appendChild(titleDiv);
        }
        controlsContainer.appendChild(this.stylesContainer);
        controlsContainer.appendChild(this.divider);
        controlsContainer.appendChild(this.markerLayersContainer);
        controlsContainer.appendChild(this.layersContainer);

        this.container.appendChild(btn);
        this.container.appendChild(controlsContainer);
    }

    private createStyleContainer(
        label: string,
        icon: string,
        isSelected: boolean,
        index: number
    ): HTMLElement {
        const styleContainer = document.createElement('div');
        styleContainer.className = 'style-radio';
        styleContainer.id = `${this.styleCtrlIdPrefix}${index}`;

        const [styleEl, styleLabel] = this.createRadioButton(
            label,
            icon,
            isSelected,
            index
        );

        styleContainer.appendChild(styleEl);
        styleContainer.appendChild(styleLabel);
        styleLabel.addEventListener('click', this.onStyleChange);
        styleEl.addEventListener('click', this.onStyleChange);

        return styleContainer;
    }

    private createRadioButton(
        label: string,
        icon: string,
        selected: boolean,
        index: number
    ): any {
        const styleEl = document.createElement('input');
        styleEl.type = 'radio';
        styleEl.checked = selected; // Set first element as checked.
        styleEl.setAttribute('name', this.radioBtnGroup);
        styleEl.dataset.index = `${index}`;

        const styleLabel = document.createElement('label');
        styleLabel.innerHTML = `<span class="label-image"><i class="WTicon-style-${icon}"></i></span> ${label}`;
        styleLabel.dataset.index = `${index}`;

        return [styleEl, styleLabel];
    }

    private onStyleChange = (event: any) => {
        const styleIndex = event.currentTarget.dataset.index;

        if (this.styles[styleIndex] !== this.selectedStyle) {
            this.setStyleAsChecked(this.map!, styleIndex);
        }
    };

    private setStyleAsChecked(map: mapboxgl.Map, styleIndex: number) {
        const styleEl: any = this.stylesContainer.querySelectorAll(
            'input[type="radio"]'
        )[styleIndex];
        styleEl.checked = true;
        if (map?.isStyleLoaded()) {
            map?.setStyle(this.styles[styleIndex].styleUrl);
            this.selectedStyle = this.styles[styleIndex];
        } else {
            // In order to avoid Error: The user aborted a request error.
            // If you have another solution, go for it! :(
            setTimeout(() => {
                map?.setStyle(this.styles[styleIndex].styleUrl);
                this.selectedStyle = this.styles[styleIndex];
            }, 2000);
        }
    }
}
