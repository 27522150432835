import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, forkJoin, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import {
    TrekInfo,
    TrekWizardMode,
    TrekWizardState
} from '../../trek-wizard/store/trek-wizard.model';
import { MEDIA_VIDEO_TYPES } from '../../shared/shared.constants';
import { getFileExtension } from '../../shared/shared-utils';
import { TrekStationMap } from '../../trek-wizard/models/trek-wizard-stations.model';
import { TrekCard } from '../../standalone/components/trek-card/trek-card.model';

@Injectable()
export class TrekService implements OnDestroy {
    private baseTrekUrlV1 = `${environment.BUSINESS_URL}/api/v1/treks`;
    private baseBusinessUrl = `${environment.BUSINESS_URL}/api/v1/business`;
    private trekIdAndModeList: Pick<TrekCard, 'id' | 'mode'>[] = [];
    treksPaginationIndex = 0;

    constructor(private http: HttpClient) {}

    ngOnDestroy() {
        this.trekIdAndModeList = [];
    }

    getTreksList(businessId?: string): Observable<TrekCard[]> {
        const listToGet$ = this.trekIdAndModeList.length
            ? of(this.trekIdAndModeList)
            : this.http
                  .get<Pick<TrekCard, 'id' | 'mode'>[]>(
                      `${this.baseBusinessUrl}/${businessId}/treks`
                  )
                  .pipe(tap((fullList) => (this.trekIdAndModeList = fullList)));

        return listToGet$.pipe(
            map((fullList) => {
                if (fullList.length === this.treksPaginationIndex) {
                    return [];
                }
                const cornetList = fullList.slice(
                    this.treksPaginationIndex,
                    this.treksPaginationIndex + 10
                );
                this.treksPaginationIndex += cornetList.length;
                return cornetList;
            }),
            concatMap((data) => this.forkStreamBasedOnSiteType(data)),
            catchError(() => of([])),
            filter((res) => !!res),
            map(
                ([trekStates, trekHeaders, wizardModeMap]: [
                    TrekWizardState[],
                    TrekInfo[],
                    { [id: string]: TrekWizardMode }
                ]) => {
                    const trekStatesWithMode: TrekCard[] = trekStates.map(
                        (state: TrekWizardState) => ({
                            id: state.id,
                            media: state?.coverMedia?.data?.coverMediaUrl
                                ? state?.coverMedia?.data?.coverMediaUrl
                                : state?.stations?.data
                                ? this.getStateMedia(state?.stations.data)
                                : '',
                            name: state?.details?.data?.name,
                            difficulty: state?.details?.data?.difficulty,
                            duration: state?.map?.data?.settings?.duration,
                            mode: wizardModeMap[state.id!]
                        })
                    );
                    const trekHeadersWithMode: TrekCard[] = trekHeaders.map(
                        (trekHeader) => ({
                            id: trekHeader.id,
                            media: this.getInfoMedia(trekHeader),
                            name: trekHeader.details?.name,
                            difficulty: trekHeader?.details?.difficulty,
                            duration: trekHeader?.map?.settings?.duration,
                            mode: wizardModeMap[trekHeader.id!]
                        })
                    );
                    return [...trekStatesWithMode, ...trekHeadersWithMode];
                }
            ),
            take(1)
        );
    }

    getStateMedia(stations: TrekStationMap): string {
        let featuredMedia = '';

        for (const station of Object.values(stations)) {
            const path = station.media?.data?.find(
                (media) =>
                    !MEDIA_VIDEO_TYPES.includes(
                        getFileExtension(media.path) as string
                    )
            )?.path;
            if (path) {
                featuredMedia = path;
                break;
            }
        }
        return featuredMedia;
    }

    getInfoMedia(trekInfo: TrekInfo): string {
        let coverMediaPath = '';
        if (trekInfo.coverInnerMedia) {
            for (const station of trekInfo.stations || []) {
                const path = station.media?.find(
                    (media) => media.id === trekInfo.coverInnerMedia
                )?.path;
                if (path) {
                    coverMediaPath = path;
                    break;
                }
            }
            return coverMediaPath;
        } else {
            let featuredMedia = '';
            for (const station of trekInfo.stations || []) {
                const path = station.media?.find(
                    (media) =>
                        media &&
                        !MEDIA_VIDEO_TYPES.includes(
                            getFileExtension(media.path) as string
                        )
                )?.path;
                if (path) {
                    featuredMedia = path;
                    break;
                }
            }
            return featuredMedia;
        }
    }

    private forkStreamBasedOnSiteType(
        TreksWizardMode: Pick<TrekCard, 'id' | 'mode'>[]
    ): Observable<any> {
        if (!TreksWizardMode) {
            return of(null);
        }
        const approvedTreks: string[] = [];
        const wizardTreks: string[] = [];
        const wizardModeMap: TrekInfo = {};

        TreksWizardMode.forEach((trekMode: Pick<TrekCard, 'id' | 'mode'>) => {
            if (
                trekMode.mode === TrekWizardMode.PUBLISHED ||
                trekMode.mode === TrekWizardMode.EDITED
            ) {
                approvedTreks.push(trekMode.id!);
            } else {
                wizardTreks.push(trekMode.id!);
            }

            (wizardModeMap as any)[trekMode.id!] = trekMode.mode;
        });

        return forkJoin([
            wizardTreks.length ? this.getTrekStates(wizardTreks) : of([]),
            approvedTreks.length ? this.getTrekHeaders(approvedTreks) : of([]),
            of(wizardModeMap)
        ]);
    }

    getTrekStates(trekIds: string[]): Observable<TrekWizardState[]> {
        const config = {
            params: {
                trekIds: trekIds.join()
            }
        };
        return this.http.get<TrekWizardState[]>(
            `${this.baseTrekUrlV1}`,
            config
        );
    }

    getTrekHeaders(trekIds: string[]): Observable<TrekInfo[]> {
        const config = {
            params: {
                trekIds: trekIds.join(),
                fields: 'id,mode,details,stations,coverInnerMedia'
            }
        };
        return this.http.get<TrekInfo[]>(`${this.baseTrekUrlV1}/info`, config);
    }

    createTrek(businessId: string): Observable<any> {
        return this.http.post(
            `${this.baseBusinessUrl}/${businessId}/treks`,
            {}
        );
    }

    deleteTrek(trekId: string): Observable<any> {
        return this.http.delete(`${this.baseTrekUrlV1}/${trekId}`);
    }
}
