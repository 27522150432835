export const environment = {
    production: true,
    test: false,
    dev: false,
    ASSETS_BASE_URL: '',
    GATE_URL: 'https://api.wishtrip.com',
    GATE_URL_2: 'https://api2.wishtrip.com',
    MEDIA_URL: 'https://media.wishtrip.com',
    LIVE_URL: 'https://live.wishtrip.com/loc',
    LIVESHARE_URL: 'https://liveshare.wishtrip.com/loc',
    LIVE_DYNAMIC_MAP_URL: 'https://live.wishtrip.com/dyn_site',
    BUSINESS_URL: 'https://api-b2b-server-prd-ext.wishtrip.com',
    WEBSITE_URL: 'https://www.wishtrip.com',
    BUSINESS_URL_WEBSITE_NEW: 'https://www.wishtrip.com/enterprise',
    CATEGORIES_DATA_URL:
        'https://wishtrip-com-assets.s3-eu-west-1.amazonaws.com/environment/prd/ooi-categories',
    CRISP_WEBSITE_ID: 'abb1608d-2ffa-4d3e-83dd-0ee80354caf6',
    MOUSEFLOW_PROJECT_ID: '5398624d-d9cf-441d-835b-659ee54c4d41',
    HELP_DESK_URL: 'https://help.wishtrip.com/en/',
    IMPLEMENTATION_S3_BASE_URL:
        'https://wishtrip-com-assets.s3.eu-west-1.amazonaws.com/environment/prd/b2b-implementation/',
    GTM_CONTAINER_ID: 'GTM-PMF9378K',
    LOCALSTORAGE_USER_KEY: 'business_user_prod'
};
