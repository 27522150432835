import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    if (window) {
        window.console.log =
            window.console.warn =
            window.console.info =
                () => {
                    // Don't log anything.
                };
    }
}

if (!top || top === self) {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
} else {
    self.document.body.style.display = 'none';
    top.location = self.location;
}
