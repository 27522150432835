import { createAction, props } from '@ngrx/store';
import { DcfWizardState } from './dcf-wizard-store.model';

export enum DcfWizardActionTypes {
    SET_WIZARD_STATE = '[DcfWizard] Set Wizard State',
    RESET_WIZARD_STATE = '[DcfWizard] Reset Wizard State',
    UNDO = '[DcfWizard] Undo',
    REDO = '[DcfWizard] Redo'
}

export const setDcfWizardState = createAction(
    DcfWizardActionTypes.SET_WIZARD_STATE,
    props<{ state: DcfWizardState }>()
);

export const resetDcfWizardState = createAction(
    DcfWizardActionTypes.RESET_WIZARD_STATE
);

export const dcfWizardUndo = createAction(DcfWizardActionTypes.UNDO);
export const dcfWizardRedo = createAction(DcfWizardActionTypes.REDO);
