import { createReducer, on } from '@ngrx/store';
import * as LanguageActions from '../actions/language.actions';
import { initialState } from '../site-wizard-store.constants';

export const languageReducer = createReducer(
    initialState.languages,
    on(LanguageActions.addSiteLanguage, (state, { language }) => ({
        ...state,
        data: {
            data: state.data
                ? state.data.data.includes(language)
                    ? state.data.data
                    : [...state.data.data, language]
                : [language],
            defaultLanguage: state.data?.defaultLanguage || language
        },
        sectionStatus: {
            ...state.sectionStatus,
            invalid: false,
            disableDependants: false
        }
    })),
    on(LanguageActions.deleteSiteLanguage, (state, { language }) => ({
        ...state,
        data: {
            data: state?.data?.data.filter((lang) => lang !== language)!,
            defaultLanguage:
                state?.data?.defaultLanguage !== language
                    ? state?.data?.defaultLanguage!
                    : state?.data?.data.find((lang) => lang !== language)
        },
        sectionStatus: {
            ...state?.sectionStatus,
            complete:
                state?.sectionStatus?.complete && state.data?.data?.length! > 1,
            invalid: state.data?.data?.length! <= 1,
            disableDependants: state.data?.data?.length! <= 1
        }
    })),
    on(LanguageActions.setDefaultLanguage, (state, { language }) => ({
        ...state,
        data: {
            ...state?.data!,
            defaultLanguage: language
        }
    })),
    on(LanguageActions.languagesComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus,
            complete: true
        }
    }))
);
