import { UpdateGameSummary } from '../actions/game-wizard-summary.actions';
import { GameSummarySection } from '../../models/game-wizard-summary.model';
import { immerOn } from 'ngrx-immer/store';
import { createReducer } from '@ngrx/store';

export const gameWizardSummaryReducer = createReducer(
    {} as GameSummarySection,
    immerOn(UpdateGameSummary, (state) => {
        state.data!.active = !state.data!.active;
    })
);
