<div
    class="indicator-content"
    *ngIf="daysLeft$ | async as difference">
    <div
        *ngIf="difference.status !== 'FREE' && difference.days <= infoStatus"
        class="indicator-info">
        <p appTypography="SECONDARY">
            {{ 'INDICATOR_FREE' | translate }} {{ difference.days }}
            {{
                (difference.days === 1 ? 'DAY' : 'INDICATOR_FREE_DAYS')
                    | translate
            }}
        </p>
        <mat-progress-bar
            [ngClass]="{ info: difference.days > warnStatus }"
            [color]="'warn'"
            mode="determinate"
            [value]="
                100 - (100 / infoStatus) * difference.days
            "></mat-progress-bar>
    </div>
</div>
