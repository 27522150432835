import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GamesComponent } from './games.component';
import { CreateGameComponent } from './components/create-game/create-game.component';

export enum GamesRoutePaths {
    CREATE_GAME = 'create-game'
}

const GAMES_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: GamesComponent
    },
    {
        path: GamesRoutePaths.CREATE_GAME,
        component: CreateGameComponent
    },
    {
        path: ':gameId',
        data: {
            breadcrumb: 'SITE_GAME_BUILDER'
        },
        loadChildren: () =>
            import('./../game-wizard/game-wizard.module').then(
                (m) => m.GameWizardModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forChild(GAMES_ROUTES)],
    exports: [RouterModule]
})
export class GamesRoutingModule {}
