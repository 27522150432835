import { Pipe, PipeTransform } from '@angular/core';
import { Game, GameCardData } from '../models/game.interface';

@Pipe({
    name: 'filterGamesOnSearch'
})
export class FilterGamesOnSearchPipe implements PipeTransform {
    transform(games: Game[], gameSearchTerm: string): GameCardData[] {
        return games
            ?.map<GameCardData>((game) => ({
                id: game.id,
                icon: game.icon,
                active: game.active,
                isEdit: game.isEdit,
                data: game?.data?.find(
                    (gameData) => gameData?.locale === game?.defaultLocale
                )
            }))
            .filter(
                (gameData) =>
                    !gameSearchTerm ||
                    gameData.data?.name
                        ?.toLowerCase()
                        ?.includes(gameSearchTerm?.toLowerCase())
            );
    }
}
