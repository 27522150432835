import { createAction, props } from '@ngrx/store';
import { SiteWizardState } from './site-wizard-store.model';

export enum SiteWizardActionTypes {
    SET_WIZARD_STATE = '[SiteWizard] Set Wizard State',
    RESET_WIZARD_STATE = '[SiteWizard] Reset Wizard State',
    UNDO = '[SiteWizard] Undo',
    REDO = '[SiteWizard] Redo'
}

export const setSiteWizardState = createAction(
    SiteWizardActionTypes.SET_WIZARD_STATE,
    props<{ state: SiteWizardState }>()
);

export const resetSiteWizardState = createAction(
    SiteWizardActionTypes.RESET_WIZARD_STATE
);

export const siteWizardUndo = createAction(SiteWizardActionTypes.UNDO);
export const siteWizardRedo = createAction(SiteWizardActionTypes.REDO);
