<footer
    class="app-footer"
    [ngClass]="{ minimized: isMinimizedFooter }">
    <div class="language-area">
        <a
            [matMenuTriggerFor]="language"
            class="app-footer__language"
            [appTypography]="'FOOTER'">
            {{ websiteLocales[siteLang] }}
        </a>
        <mat-menu
            #language="matMenu"
            class="wt-menu language-menu">
            <button
                mat-menu-item
                *ngFor="let item of websiteLocales | keyvalue"
                (click)="changeWebsiteLanguage(item.key)"
                appTypography="FOOTER">
                <span>{{ item.value }}</span>
            </button>
        </mat-menu>
        <ng-container *ngIf="!isMinimizedFooter">
            <span>|</span>
            <a
                appTypography="FOOTER"
                (click)="openContact()"
                translate="CONTACT_US"></a>
        </ng-container>
        <span>|</span>
        <a
            target="_blank"
            [href]="socialLinksMap.get(SocialLinks.INSTAGRAM)!.link"
            (click)="
                emitFbqEvent(socialLinksMap.get(SocialLinks.INSTAGRAM)!.id)
            "
            class="WTicon-instagram1 social"
            ><span class="path1"></span><span class="path2"></span
            ><span class="path3"></span><span class="path4"></span
        ></a>
        <a
            target="_blank"
            [href]="socialLinksMap.get(SocialLinks.LINKEDIN)!.link"
            (click)="emitFbqEvent(socialLinksMap.get(SocialLinks.LINKEDIN)!.id)"
            class="WTicon-linkedin social"
            ><span class="path1"></span><span class="path2"></span
        ></a>
        <a
            target="_blank"
            [href]="socialLinksMap.get(SocialLinks.FACEBOOK)!.link"
            (click)="emitFbqEvent(socialLinksMap.get(SocialLinks.FACEBOOK)!.id)"
            class="WTicon-icon-fb social"></a>
        <a
            target="_blank"
            [href]="socialLinksMap.get(SocialLinks.TWITTER)!.link"
            (click)="emitFbqEvent(socialLinksMap.get(SocialLinks.TWITTER)!.id)"
            class="WTicon-twitter social"
            ><span class="path1"></span><span class="path2"></span
        ></a>
    </div>
    <div class="link">
        <ng-container *ngIf="!isMinimizedFooter">
            <a
                appTypography="FOOTER"
                [href]="websiteUrl"
                target="_blank"
                rel="noopener"
                >WishTrip</a
            >
            <span>|</span>
            <a
                appTypography="FOOTER"
                [href]="businessUrl"
                target="_blank"
                rel="noopener"
                >WishTrip Enterprise</a
            >
        </ng-container>
        <span>|</span>
        <span appTypography="FOOTER"> © Rec ‘n’ Trek {{ currentYear }}</span>
    </div>
    <div class="privacy-area">
        <ng-container *ngIf="!isMinimizedFooter">
            <a
                appTypography="FOOTER"
                [href]="privacyPolicyUrl"
                target="_blank"
                rel="noopener"
                translate="PRIVACY_POLICY">
            </a>
            <span>|</span>
            <a
                appTypography="FOOTER"
                [href]="tosUrl"
                target="_blank"
                rel="noopener"
                translate="TERMS_AND_CONDITIONS">
            </a>
        </ng-container>
    </div>
</footer>
