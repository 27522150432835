import { ActionReducer } from '@ngrx/store';
import { UndoableStore } from './undoable-store.model';

export function getUndoableWizardStore<T>(
    reducer: ActionReducer<T>,
    wizardActionsType: Record<'UNDO' | 'REDO' | string, string>,
    ignoredActions: string[] = [],
    resetHistoryActions: string[] = []
): () => ActionReducer<UndoableStore<T>> {
    // Call the reducer with empty action to populate the initial state
    const initialState = {
        past: [],
        present: reducer(undefined, { type: 'INIT' }),
        future: []
    };
    // Return a reducer that handles undo and redo
    return () =>
        (state = initialState, action: any) => {
            const { present } = state;
            let { past, future } = state;

            const isResetHistoryAction = resetHistoryActions.includes(
                action.type
            );
            if (isResetHistoryAction) {
                past = [];
                future = [];
            }

            // If ignored action, don't save it to history.
            if (ignoredActions.indexOf(action.type) >= 0) {
                return {
                    past,
                    present: reducer(present, action),
                    future
                };
            }

            // Handle action
            switch (action.type) {
                case wizardActionsType.UNDO: {
                    if (!state.past?.length) {
                        return state;
                    }
                    const previous = past[past.length - 1];
                    const newPast = past.slice(0, past.length - 1);
                    return {
                        past: newPast,
                        present: previous,
                        future: [present, ...future]
                    };
                }

                case wizardActionsType.REDO: {
                    if (!state.future?.length) {
                        return state;
                    }
                    const next = future[0];
                    const newFuture = future.slice(1);
                    return {
                        past: [...past, present],
                        present: next,
                        future: newFuture
                    };
                }

                default: {
                    // Delegate handling the action to the passed reducer
                    const newPresent = reducer(present, action);
                    if (present === newPresent) {
                        return state;
                    }
                    return {
                        past: [
                            ...past,
                            isResetHistoryAction ? undefined : present
                        ].filter((v) => !!v) as T[],
                        present: newPresent,
                        future: []
                    };
                }
            }
        };
}
