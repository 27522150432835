<h2
    class="settings-title"
    appTypography="SUBTITLE">
    {{ 'SETTINGS' | translate }}
</h2>
<mat-tab-group
    [selectedIndex]="selectedIndex"
    #settingsTabs>
    <mat-tab>
        <ng-template mat-tab-label>
            <span
                appTypography="SMALL"
                class="text">
                {{ 'TAB_PAYMENT_SETTINGS' | translate }}</span
            >
        </ng-template>
        <p appTypography="SECONDARY">
            {{ 'SUB_TITLE_PAYMENT' | translate }}
        </p>
        <form
            class="settings-payments-form"
            [formGroup]="paymentSettingsForm">
            <div class="settings-payments-label">
                <div
                    appTypography="SECONDARY"
                    class="label-text">
                    {{ 'SETTINGS_LABEL_CURRENCY' | translate }}
                </div>
                <app-custom-input-standalone
                    formControlName="currency"
                    [autoCompleteOptions]="currencyOptionList"
                    [autoCompleteDisplayWithFn]="
                        currencyOptionDisplayWithFn
                    "></app-custom-input-standalone>
            </div>
            <div class="settings-payments-label">
                <div
                    appTypography="SECONDARY"
                    class="label-text">
                    {{ 'SETTINGS_LABEL_CLIENT_ID' | translate }}
                </div>
                <app-custom-input-standalone
                    type="password"
                    formControlName="clientId"></app-custom-input-standalone>
            </div>
            <div class="settings-payments-label">
                <div
                    appTypography="SECONDARY"
                    class="label-text">
                    {{ 'SETTINGS_LABEL_SECRET' | translate }}
                </div>
                <app-custom-input-standalone
                    type="password"
                    formControlName="secret"></app-custom-input-standalone>
            </div>
            <div class="settings-payments-button">
                <app-standalone-custom-button
                    [isDisabled]="
                        !paymentSettingsForm.dirty ||
                        paymentSettingsForm.invalid
                    "
                    (clicked)="savePaymentSettings()"
                    buttonType="submit">
                    {{ 'SAVE' | translate }}
                </app-standalone-custom-button>
                <app-standalone-custom-button
                    *ngIf="
                        paymentSettingsForm.controls.clientId.dirty ||
                        paymentSettingsForm.controls.secret.dirty
                    "
                    (clicked)="initPaymentsData()"
                    id="payment-cancel"
                    buttonStyle="SECONDARY_OUTLINED">
                    {{ 'CANCEL' | translate }}
                </app-standalone-custom-button>
            </div>
        </form>
    </mat-tab>

    <mat-tab class="sms-settings-tab">
        <ng-template mat-tab-label>
            <span
                appTypography="SMALL"
                class="text">
                {{ 'TAB_SMS_SETTINGS' | translate }}</span
            >
        </ng-template>

        <div class="sms-invite-form">
            <p appTypography="SECONDARY">
                {{ 'SUB_TITLE_SMS' | translate }}
            </p>
            <p
                appTypography="SECONDARY_BOLD"
                class="bold edit-msg">
                {{ 'SMS_SETTINGS_EDIT' | translate }}
            </p>
            <form [formGroup]="smsSettingsForm">
                <div class="form-block">
                    <app-custom-textarea-standalone
                        [maxLength]="250"
                        formControlName="message"></app-custom-textarea-standalone>
                </div>

                <label appTypography="SECONDARY">
                    {{ 'SELECT_A_LINK' | translate }}
                </label>
                <mat-radio-group
                    formControlName="shareLink"
                    class="link-list">
                    <mat-radio-button
                        *ngFor="let link of shareLinks"
                        [value]="link">
                        <div>
                            <span appTypography="SECONDARY_BOLD">
                                {{ link.name || 'DEFAULT' | translate }}:&nbsp;
                            </span>
                            <a
                                appTypography="SECONDARY_UNDERLINE"
                                class="download-link"
                                [href]="link.url"
                                target="_blank">
                                {{ link.url }}
                            </a>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>

                <app-standalone-custom-button
                    (clicked)="saveSmsMessage()"
                    [isDisabled]="smsSettingsForm.pristine"
                    buttonType="submit">
                    {{ 'SAVE' | translate }}
                </app-standalone-custom-button>
            </form>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span
                appTypography="SMALL"
                class="text">
                {{ 'LOCALE_SETTINGS_TAB_TITLE' | translate }}</span
            >
        </ng-template>
        <app-locale-settings></app-locale-settings>
    </mat-tab>
</mat-tab-group>

<div
    @displayPhonePreview
    *ngIf="
        isPhonePreviewDisplayed &&
        (settingsTabs.selectedIndexChange | async) === 1
    "
    class="phone-preview-wrapper">
    <div class="message-content">
        <div
            appTypography="SECONDARY"
            [innerText]="smsSettingsForm.controls.message.value"></div>
        <a
            appTypography="SECONDARY_UNDERLINE"
            [href]="smsSettingsForm.controls.shareLink.value.url"
            target="_blank">
            {{ smsSettingsForm.controls.shareLink.value.url }}
        </a>
    </div>
    <div class="pointer"></div>
</div>
