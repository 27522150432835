import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';
import { CampaignVariationsLanguage } from '../../dcf-wizard.model';

export enum DcfWizardDonationsActionTypes {
    UPDATE_DCF_DONATIONS = '[DcfWizard]: Donation Update',
    ADD_DONATION_AMOUNT = '[DcfWizard]: Donation Add amount',
    REMOVE_DONATION_AMOUNT = '[DcfWizard]: Donation Remove amount',
    UPDATE_DCF_DONATIONS_DEFAULT_PAYMENT = '[DcfWizard]: Donation Update default payment',
    SET_DCF_DONATIONS_LANG_TAB = '[DcfWizard]: Donation Set Lang Tab'
}

export const updateDcfDonations = createAction(
    DcfWizardDonationsActionTypes.UPDATE_DCF_DONATIONS,
    props<{
        donations: CampaignVariationsLanguage[];
        invalid: boolean;
        complete: boolean;
    }>()
);

export const updateDcfDonationsDefaultPayment = createAction(
    DcfWizardDonationsActionTypes.UPDATE_DCF_DONATIONS_DEFAULT_PAYMENT,
    props<{
        donationIndex: number;
    }>()
);

export const addDonation = createAction(
    DcfWizardDonationsActionTypes.ADD_DONATION_AMOUNT
);

export const removeDonation = createAction(
    DcfWizardDonationsActionTypes.REMOVE_DONATION_AMOUNT,
    props<{ donationIndex: number }>()
);

export const setDcfDonationsSelectedLangTab = createAction(
    DcfWizardDonationsActionTypes.SET_DCF_DONATIONS_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
