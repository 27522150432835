import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CustomInputStandaloneComponent } from '../custom-input/custom-input-standalone.component';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        CustomInputStandaloneComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    searchCtrl = new FormControl<string>('', { nonNullable: true });
    @Input() id!: string;
    @Output() searchTerm: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {
        this.searchCtrl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(250),
                startWith('')
            )
            .subscribe((term) => {
                this.searchTerm.emit(term);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
