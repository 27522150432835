import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatDateFormats,
    MatNativeDateModule
} from '@angular/material/core';
import {
    DATE_RANGE_FORMATS,
    DateRangeAdapter
} from './date-range-picker.consts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypographyDirective } from '../../directives/typography/typography.directive';

@Component({
    selector: 'app-date-range-picker',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        TypographyDirective
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_RANGE_FORMATS },
        { provide: DateAdapter, useClass: DateRangeAdapter },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        }
    ],
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent
    implements OnInit, ControlValueAccessor, OnDestroy
{
    @Input() dateFormatDisplay = 'yy-MM-dd';
    @Input() label?: string;
    @Input() index?: number;
    @Input() max: Date | null = null;
    @Output() dateRangeChanged: EventEmitter<{
        startDate: Date;
        endDate: Date;
    }> = new EventEmitter<{ startDate: Date; endDate: Date }>();
    today = new Date();
    dateRangeForm = new FormGroup({
        start: new FormControl<Date | null>(null, Validators.required),
        end: new FormControl<Date | null>(null, Validators.required)
    });
    unSubscribe$ = new Subject<void>();

    constructor(
        @Inject(MAT_DATE_FORMATS) public matDateFormats: MatDateFormats
    ) {}

    ngOnInit(): void {
        this.matDateFormats.display.dateInput = this.dateFormatDisplay;
        this.dateRangeForm
            .get('end')
            ?.valueChanges.pipe(takeUntil(this.unSubscribe$))
            .subscribe((end) => {
                if (end) {
                    this.dateRangeChanged.emit({
                        startDate: this.convertToLocalTime(
                            this.dateRangeForm.get('start')?.value!
                        ),
                        endDate: this.convertToLocalTime(
                            this.dateRangeForm.get('end')?.value!
                        )
                    });
                }
            });
    }

    ngOnDestroy() {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }

    convertToLocalTime(date: Date): Date {
        const newDate = new Date(date);
        const offset = newDate.getTimezoneOffset();
        return new Date(newDate.getTime() - offset * 60 * 1000);
    }

    writeValue(value: any): void {
        this.dateRangeForm.patchValue(value, { emitEvent: false });
    }

    registerOnChange(fn: (value: any) => {}): void {
        this.dateRangeForm.valueChanges
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe(fn);
    }

    registerOnTouched(fn: () => void): void {
        this.dateRangeForm.valueChanges
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe(fn);
    }
}
