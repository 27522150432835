<div class="page-wrapper">
    <div class="auth-container">
        <div class="logo">
            <a [routerLink]="['']">
                <img
                    [ngSrc]="WT_LOGO"
                    height="96"
                    width="250"
                    alt="logo" />
            </a>
        </div>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
