import { TrekWizardMode, TrekWizardState } from './trek-wizard.model';
import { initialTrekState } from '../constants/trek-wizard.const';
import { StatusLabel } from '../../standalone/components/card-label/card-label.consts';

export function CreateNewTrekState(
    siteId: string,
    businessId: string
): TrekWizardState {
    return {
        ...initialTrekState,
        id: siteId,
        businessId,
        mode: TrekWizardMode.CREATED
    };
}

export function orderItems(items: any[]): any[] {
    return items.map((item, i) => ({
        ...item,
        order: i
    }));
}

export function getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const trekStatusLabelMap = new Map<TrekWizardMode, StatusLabel>([
    [TrekWizardMode.CREATED, StatusLabel.INACTIVE],
    [TrekWizardMode.EDITED, StatusLabel.IS_EDIT],
    [TrekWizardMode.PUBLISHED, StatusLabel.ACTIVE],
    [TrekWizardMode.IN_PROGRESS, StatusLabel.PENDING]
]);
