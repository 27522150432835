<div class="search">
    <app-search-input (searchTerm)="filerSites($event)"></app-search-input>
</div>

<div
    *ngIf="!(showSpinner$ | async) && !(displaySites$ | async)!.length"
    appTypography="PRIMARY">
    {{ 'NO_ITEMS_PLACEHOLDER' | translate }}
</div>
<div
    class="cards"
    infiniteScroll
    (scrolled)="onScrollDown()"
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="0">
    <app-create-entity-card
        (cardClicked)="createSite()"
        [hasPermission]="hasAccessCreateSite!">
        <h3 appTypography="PRIMARY_BOLD">
            {{ 'CREATE_NEW_SITE' | translate }}
        </h3>
    </app-create-entity-card>

    <app-site-card
        *ngFor="let site of displaySites$ | async"
        [site]="site">
    </app-site-card>
    <div
        *ngIf="showSpinner$ | async"
        class="inline-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
