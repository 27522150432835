import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';
import { CampaignDetails } from '../../dcf-wizard.model';

export enum DcfWizardDetailsActionTypes {
    UPDATE_DCF_DETAILS = '[DcfWizard]: Details Update Details',
    SET_DCF_DETAILS_LANG_TAB = '[DcfWizard]: Details Set Lang Tab'
}

export const updateDcfDetails = createAction(
    DcfWizardDetailsActionTypes.UPDATE_DCF_DETAILS,
    props<{
        details: CampaignDetails;
        invalid: boolean;
        complete: boolean;
    }>()
);

export const setDcfDetailsSelectedLangTab = createAction(
    DcfWizardDetailsActionTypes.SET_DCF_DETAILS_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
