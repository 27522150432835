<div class="video-player">
    <div
        *ngIf="!startedPlaying && !isNotSupportedFormat"
        class="video-play"
        [style.background-image]="'url(' + thumbnail || '' + ')'">
        <span
            class="play"
            (click)="playVideo(true)"
            [style.cursor]="hidePlay ? 'inherit' : 'pointer'"></span>
    </div>
    <video
        class="video"
        #videoPlayer
        [poster]="thumbnail"
        disablePictureInPicture
        controlsList="nodownload">
        <source
            src="{{ source }}#t={{ thumbnailFrame }}"
            type="video/mp4" />
    </video>
</div>
