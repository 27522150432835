import { ActionReducer, combineReducers } from '@ngrx/store';
import { GameWizardState } from './game-wizard-store.model';
import { gameStagesReducer } from './reducers/game-wizard-stages.reducer';
import { GameWizardActionTypes } from './game-wizard.actions';
import { gameLanguagesReducer } from './reducers/game-wizard-languages.reducer';
import { gameGeneralDetailsReducer } from './reducers/game-wizard-general-details.reducer';
import { gameWizardSummaryReducer } from './reducers/game-wizard-summary.reducer';
import { gameChallengesReducer } from './reducers/game-wizard-challenges.reducer';
import { gameIntroductionReducer } from './reducers/game-wizard-introduction.reducer';
import { gameCompletionReducer } from './reducers/game-wizard-completion.reducer';
import { AppActionTypes } from '../../store/app-store.actions';
import { getUndoableWizardStore } from '../../store/undoable/undoable-store.reducer';

export const gameWizardMainReducer = combineReducers<GameWizardState>({
    activeStage: gameStagesReducer,
    id: (state) => state!,
    siteId: (state) => state!,
    recommendedLanguages: (state) => state!,
    languages: gameLanguagesReducer,
    generalDetails: gameGeneralDetailsReducer,
    introduction: gameIntroductionReducer,
    challenges: gameChallengesReducer,
    completion: gameCompletionReducer,
    summary: gameWizardSummaryReducer
});

const _gameWizardRootReducer: ActionReducer<GameWizardState> = (
    state: GameWizardState | undefined,
    action: any
) => {
    switch (action.type) {
        case AppActionTypes.RESET_APP_STATE:
        case GameWizardActionTypes.RESET_WIZARD_STATE:
            return undefined as unknown as GameWizardState;
        case GameWizardActionTypes.SET_WIZARD_STATE:
            return gameWizardMainReducer(action.state, action);
        default:
            return gameWizardMainReducer(state, action);
    }
};

const undoableIgnoredActions = [
    AppActionTypes.RESET_APP_STATE,
    GameWizardActionTypes.SET_WIZARD_STATE,
    GameWizardActionTypes.RESET_WIZARD_STATE
];

const resetHistoryActions = [
    AppActionTypes.RESET_APP_STATE,
    GameWizardActionTypes.RESET_WIZARD_STATE
];

export const GameWizardReducers = getUndoableWizardStore<GameWizardState>(
    _gameWizardRootReducer,
    GameWizardActionTypes,
    undoableIgnoredActions,
    resetHistoryActions
)();
