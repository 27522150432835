import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { noWhitespaceValidator } from '../../../shared/shared-utils';
import { User } from '../../auth.model';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ContactStandaloneComponent } from '../../../standalone/components/contact/contact-standalone.component';
import { HUBSPOT_LOGIN_FORM } from '../../../shared/hubspot.constants';
import { HubspotService } from '../../../services/hubspot.service';
import { environment } from '@env/environment';
import { CrispLiveChatService } from '../../../services/crisp-live-chat.service';
import { MetaService } from '../../../services/meta.service';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutePaths } from '../../../app-routing.module';
import { AuthRoutePaths } from '../../auth-routing.module';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm!: UntypedFormGroup;
    loginError = false;
    protected readonly AppRoutePaths = AppRoutePaths;
    protected readonly AuthRoutePaths = AuthRoutePaths;

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private matDialog: MatDialog,
        private hubspotService: HubspotService,
        private crispLiveChatService: CrispLiveChatService,
        private metaService: MetaService
    ) {}

    ngOnInit(): void {
        this.setPageTags();
        this.loginForm = this.fb.group({
            email: new UntypedFormControl('', [
                noWhitespaceValidator,
                Validators.email,
                Validators.required
            ]),
            password: ['', [noWhitespaceValidator, Validators.required]]
        });
    }

    logIn(): void {
        this.authService
            .login(this.loginForm.value.email, this.loginForm.value.password)
            .subscribe({
                next: (result: User) => {
                    this.authService.startSession(result);
                    const hubspotData = {
                        email: this.loginForm.value.email
                    };
                    this.hubspotService.sendHubspotForm(
                        HUBSPOT_LOGIN_FORM,
                        hubspotData
                    );
                    this.crispLiveChatService.setUserDetails(result);
                    if (result.siteIdForLiteWizard) {
                        this.router.navigate([`/${AppRoutePaths.LIZARD}`]);
                    } else {
                        this.router.navigate([`/${AppRoutePaths.SITES}`]);
                    }
                },
                error: (error) => {
                    console.log('error', error);
                    this.loginError = true;
                }
            });
    }

    openContact(): void {
        this.matDialog.open(ContactStandaloneComponent);
    }

    setPageTags(): void {
        this.metaService.setRobotTag();
        this.metaService.setTags(
            'Account Login | WishTrip Enterprise',
            'Log in to your WishTrip Enterprise account',
            `${environment.ASSETS_BASE_URL}/assets/images/WishTrip logo.jpg`,
            undefined,
            'https://www.wishtrip.com/enterprise/login'
        );
    }
}
