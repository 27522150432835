import { GameMedia } from './game.interface';
import { NavigationModeTypes } from '../../standalone/components/navigation-settings/navigation-settings.model';
import { SpotStatus } from '../../site-wizard/models/site-wizard-spots.model';

export interface Challenge {
    index: number;
    type: ChallengeTypes;
    soiId: string;
    title?: string;
    description?: string;
    media?: GameMedia[];
    question?: string;
    answers?: ChallengeAnswer[];
    postChallengeDescription?: string;
    postChallengeMedia?: GameMedia[];
    detailsDescription?: string;
    radius?: number;
    navigationMode?: NavigationModeTypes;
    challengeStatus: SpotStatus;
}

export interface ChallengeAnswer {
    value: string;
    isCorrect: boolean;
}

export enum ChallengeTypes {
    MULTIPLECHOICE = 'MULTIPLECHOICE',
    VIDEO = 'VIDEO',
    PHOTO = 'PHOTO',
    AUDIO = 'AUDIO',
    LOCATION = 'LOCATION',
    TEXT = 'TEXT'
}

export enum ChallengeTextTypes {
    TEXT_CORRECT = 'FREE_TEXT_CORRECT',
    TEXT_NO_CORRECT = 'FREE_TEXT_NO_CORRECT'
}

export type ChallengeDisplayTypes = ChallengeTypes | ChallengeTextTypes;
