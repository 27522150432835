export const HUBSPOT_PORTAL_ID = '4561053';

export const HUBSPOT_REGISTRATION_FORM = '538bf358-98ff-4418-983e-e8c5b48ed8dc';
export const HUBSPOT_LOGIN_FORM = 'dc4a1faf-7f00-496a-9c3a-75d13fe38865';
export const HUBSPOT_PAYMENT_FORM = '6be0f679-c33f-451a-b101-2ffbc6fc5a97';

export const HUBSPOT_COMPLETE_DETAILS_FORM =
    '626ef82f-c01e-40d6-99d5-99b1410f643f';
export const HUBSPOT_COMPLETE_POLYGON_FORM =
    '3df1a091-3d9d-4ee6-abf2-3743605800ce';
export const HUBSPOT_COMPLETE_ENGAGEMENT_FORM =
    '42ff5d24-6097-4b1b-ad73-76a7d62dabfd';
export const HUBSPOT_SITE_CREATED_FORM = '4f827f24-3afc-4ade-9c71-88ebb39a10b8';
export const HUBSPOT_SITE_REQUEST_APPROVAL_FORM =
    '76466237-1ac8-496d-855d-95cd5bfa52d6';
export const HUBSPOT_PUBLISH_SITE_FORM = 'c0cb9fea-11b6-4416-b0bf-ed0ca68f7acc';
export const HUBSPOT_SITE_FIRST_EDIT = '819d5c9f-b814-4581-8a7f-bb65b4964f2c';
export const HUBSPOT_PUBLISH_TREK = '607b3ba3-4079-4268-8fc1-ddaa9df42876';
export const HUBSPOT_CONTACT_US_MARKETING_FORM =
    'a6555910-48b6-49f2-99b4-e2cc0f40a187';
export const HUBSPOT_CONTACT_US_SUPPORT_FORM =
    'c072a883-ccde-4523-bf48-0c8be903ae3a';

export const HUBSPOT_VERTICAL_COMPANIES = [
    'Municipalities & Tourism Boards',
    'Parks & Attractions',
    'Tourist Accommodations',
    'Tour Operators/Travel Agents',
    'Other'
];
