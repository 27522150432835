import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService } from '../../services/games.service';
import { SiteInfo } from '../../../site-wizard/site-wizard.model';
import { AppRoutePaths } from '../../../app-routing.module';
import { SitesRoutePaths } from '../../../sites/sites-routing.module';
import { WizardMobile } from '../../../standalone/components/wizard/wizars-template/wizard-template.model';

@Component({
    selector: 'app-create-game',
    templateUrl: './create-game.component.html',
    styleUrls: ['./create-game.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateGameComponent implements OnInit {
    protected readonly SitesRoutePaths = SitesRoutePaths;
    protected readonly AppRoutePaths = AppRoutePaths;
    genieVideo = 'xEDvNSBgbqE';
    youtubeUrl = 'https://www.youtube.com/embed/';
    siteInfo: SiteInfo | null =
        this.activatedRoute.snapshot.parent?.parent?.data.siteInfo;
    wizardMobileData!: WizardMobile;

    constructor(
        private gamesService: GamesService,
        private toggleSpinnerService: ToggleSpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.wizardMobileData = {
            link: [
                '/',
                AppRoutePaths.SITES,
                this.siteInfo?.id!,
                SitesRoutePaths.GAMES
            ],
            messageKey: 'MOBILE_TEXT_GAME',
            buttonTextKey: 'BACK_TO_WISHTRIP_GAMES'
        };
    }

    createGame(): void {
        this.toggleSpinnerService.toggle(true);
        this.gamesService
            .createGame(this.siteInfo?.id!)
            .pipe(
                map((createGameRes) =>
                    this.router.navigate([createGameRes?.id], {
                        relativeTo: this.activatedRoute.parent
                    })
                ),
                finalize(() => this.toggleSpinnerService.toggle(false))
            )
            .subscribe();
    }
}
