import * as mapboxgl from 'mapbox-gl';

export class EventedMarker extends mapboxgl.Marker {
    protected _element!: HTMLElement;
    private _popup!: mapboxgl.Popup;
    private _map!: mapboxgl.Map;
    private properties: { [name: string]: any } = {};
    private popupClasses = ['evented-marker__animate-popup'];

    onAdd(type: string, listener: EventListenerOrEventListenerObject) {
        this._element.addEventListener(type, listener);

        return this;
    }

    offAdd(type: string, listener: EventListenerOrEventListenerObject) {
        this._element.removeEventListener(type, listener);

        return this;
    }

    addProperty(key: string, value: any) {
        this.properties[key] = value;

        return this;
    }

    getProperty(key: string): any | undefined {
        return this.properties[key];
    }

    addPopupClass(className: string) {
        this.popupClasses.push(className);

        return this;
    }

    //==============================
    //===== Overridden Methods =====
    //==============================

    addTo(map: mapboxgl.Map) {
        super.addTo(map);

        if (
            !this.hasClass(
                (this._map as any)._container,
                'mapbox--evented-marker'
            )
        ) {
            this.addClass(
                (this._map as any)._container,
                'mapbox--evented-marker'
            );
        }

        return this;
    }

    /**
     * Opens or closes the bound popup, depending on the current state
     *
     * @returns `this`
     */
    togglePopup() {
        const popup = this._popup;

        if (!popup) {
            return this;
        } else if (popup.isOpen()) {
            this.removeClass((popup as any)._container, this.popupClasses[0]);
            setTimeout(() => popup.remove(), 200);
        } else {
            popup.addTo(this._map);
            setTimeout(
                () =>
                    this.popupClasses.forEach((className) =>
                        this.addClass((popup as any)._container, className)
                    ),
                100
            );
        }
        return this;
    }

    //==========================
    //===== Helper Methods =====
    //==========================

    private hasClass(el: any, className: string) {
        return el.classList
            ? el.classList.contains(className)
            : new RegExp('\\b' + className + '\\b').test(el.className);
    }

    private addClass(el: any, className: string) {
        if (!el) {
            return;
        }
        if (el.classList) {
            el.classList.add(className);
        } else if (!this.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    }

    private removeClass(el: any, className: string) {
        if (el.classList) {
            el.classList.remove(className);
        } else {
            el.className = el.className.replace(
                new RegExp('\\b' + className + '\\b', 'g'),
                ''
            );
        }
    }
}
