import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as io from 'socket.io-client';
import { SOCKET_EVENTS } from '../sites.constants';
import {
    Alert,
    AlertMap,
    LiveAlert,
    LiveAlertData
} from '../models/site.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LiveService {
    liveEvent$?: Observable<any>;
    liveUrl = environment.LIVE_DYNAMIC_MAP_URL;
    siteId!: string;
    socket?: SocketIOClient.Socket;
    alertsMap: AlertMap = {};
    historyRequestLimit = 20;
    private liveEvent?: BehaviorSubject<any>;

    constructor() {}

    init(siteId: string): void {
        if (this.socket) {
            return;
        }
        this.siteId = siteId;
        this.liveEvent = new BehaviorSubject<any>([]);
        this.liveEvent$ = this.liveEvent.asObservable();
        this.socket = io(this.liveUrl);
        this.socket.on(SOCKET_EVENTS.CONNECT, this.initSocket.bind(this));
        this.socket.on(SOCKET_EVENTS.DISCONNECT, this.destroySocket.bind(this));
    }

    initSocket(): void {
        this.socket?.emit(SOCKET_EVENTS.SITE_ID, this.siteId);
        this.registerListeners();
        this.notify(SOCKET_EVENTS.CONNECT);
        this.requestAlertHistory(undefined);
    }

    disconnectSocket(): void {
        if (this.socket) {
            this.socket.disconnect();
        }
        this.socket = undefined;
    }

    updateStatsLanguageSplit(locales: any, stats: any): void {
        this.notify(SOCKET_EVENTS.STATS_SPLIT, [locales, stats]);
    }

    notifyUserLocationUpdate(data: any): void {
        this.notify(SOCKET_EVENTS.USER_LOCATION, data);
    }

    addAlertsToHistory(alerts: LiveAlert[]): void {
        if (!alerts.length) {
            return;
        }

        const latestAlerts: Alert[] = alerts.map((alert) => ({
            id: alert.id,
            title: alert.msg,
            text: alert.soi_id ? alert.soi_name : 'No Spot',
            date: new Date(alert.ts * 1000), // Convert timestamp in seconds to
            // date object
            alertType: alert.alert_type,
            extendedMsg: alert.extended_msg
        }));

        const lastAlert = latestAlerts[latestAlerts.length - 1];
        const areAlreadyInHistory =
            typeof this.alertsMap[lastAlert.id] !== 'undefined';

        if (areAlreadyInHistory) {
            return;
        }

        for (const alert of latestAlerts) {
            this.alertsMap[alert.id] = alert;
        }
        this.notify(SOCKET_EVENTS.ALERT_HISTORY_ADD, latestAlerts);
    }

    sendNewAlert(alert: LiveAlertData): void {
        this.socket?.emit(SOCKET_EVENTS.ALERT_NEW, alert);

        this.socket?.once(SOCKET_EVENTS.ALERT_CONFIRM, (error: any) => {
            this.notify(SOCKET_EVENTS.ALERT_CONFIRM);
        });

        this.socket?.once(SOCKET_EVENTS.ALERT_ERROR, (error: any) => {
            this.notify(SOCKET_EVENTS.ALERT_ERROR);
        });
    }

    requestAlertHistory(lastAlertId: string | undefined): void {
        this.socket?.emit(
            SOCKET_EVENTS.ALERT_HISTORY_REQUEST,
            this.historyRequestLimit,
            Number(lastAlertId)
        );
    }

    private destroySocket(): void {
        this.alertsMap = {};
        this.resetListeners();
        this.notify(SOCKET_EVENTS.DISCONNECT);
        this.liveEvent?.complete();
        this.liveEvent = undefined;
        this.liveEvent$ = undefined;
    }

    private registerListeners(): void {
        this.socket?.on(
            SOCKET_EVENTS.ALERT_HISTORY_ADD,
            this.addAlertsToHistory.bind(this)
        );
        this.socket?.on(
            SOCKET_EVENTS.STATS_SPLIT,
            this.updateStatsLanguageSplit.bind(this)
        );
        this.socket?.on(
            SOCKET_EVENTS.USER_LOCATION,
            this.notifyUserLocationUpdate.bind(this)
        );
    }

    private resetListeners(): void {
        this.socket?.removeAllListeners();
        this.socket?.on(SOCKET_EVENTS.CONNECT, this.initSocket.bind(this));
        this.socket?.on(
            SOCKET_EVENTS.DISCONNECT,
            this.destroySocket.bind(this)
        );
    }

    private notify(event: string, ...eventData: any[]): void {
        eventData.unshift(event);
        this.liveEvent?.next(eventData);
    }
}
