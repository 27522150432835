<div class="contact-dialog">
    <div
        class="close-dialog"
        mat-dialog-close>
        <i class="WTicon-round-close">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
    </div>
    <div>
        <div class="titles">
            <h3
                appTypography="SECONDARY_BOLD"
                translate="CONTACT_US"></h3>
            <p
                appTypography="SECONDARY"
                translate="CONTACT_US_DESCRIPTION"></p>
        </div>

        <form
            [formGroup]="contactForm"
            name="ContactForm"
            novalidate>
            <app-custom-input-standalone
                [appErrorDot]="!!contactForm.controls.firstName.errors"
                [label]="'FIRST_NAME' | translate"
                formControlName="firstName"
                id="contact-first-name"
                type="text"></app-custom-input-standalone>
            <app-custom-input-standalone
                [appErrorDot]="!!contactForm.controls.lastName.errors"
                [label]="'LAST_NAME' | translate"
                formControlName="lastName"
                id="contact-last-name"
                type="text"></app-custom-input-standalone>
            <app-custom-input-standalone
                [appErrorDot]="!!contactForm.controls.email.errors"
                [label]="'EMAIL' | translate"
                formControlName="email"
                id="contact-email"
                type="email"></app-custom-input-standalone>
            <div class="tel-input-wrapper">
                <div [appErrorDot]="!!contactForm.controls.phone.errors">
                    <ngx-intl-tel-input
                        [dir]="'ltr'"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true"
                        [phoneValidation]="true"
                        [searchCountryFlag]="true"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="countryCode"
                        cssClass="tel-input"
                        formControlName="phone"
                        inputId="tel-input-field"
                        [maxLength]="15"
                        name="phone"></ngx-intl-tel-input>
                </div>
            </div>

            <div class="form-block">
                <app-custom-textarea-standalone
                    [label]="'MESSAGE'"
                    [appErrorDot]="!!contactForm.controls.message.errors"
                    dotTop="39%"
                    [maxLength]="messageMaxLength"
                    formControlName="message"
                    [hint]="
                        (contactForm.controls.message.value?.length || 0) +
                        '/' +
                        messageMaxLength
                    "></app-custom-textarea-standalone>
            </div>
            <app-standalone-buttons-container mat-dialog-actions>
                <app-standalone-custom-button
                    buttonStyle="SECONDARY"
                    (clicked)="
                        emitFbqEvent(
                            'trackCustom',
                            'wtInteraction',
                            'cancel',
                            'click'
                        )
                    "
                    id="cancel_contact"
                    mat-dialog-close>
                    {{ 'CANCEL' | translate }}
                </app-standalone-custom-button>
                <app-standalone-custom-button
                    (clicked)="onSubmit()"
                    [isDisabled]="contactForm.invalid"
                    id="send_contact">
                    {{ 'SEND' | translate }}
                </app-standalone-custom-button>
            </app-standalone-buttons-container>
        </form>
    </div>
</div>
