import { environment } from '@env/environment';
import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import { SiteWizardStages } from '../store/site-wizard-store.model';

export const SUPPORTED_VIDEO_FILES = ['avi', 'mp4', 'mov', 'mkv'];
export const SUPPORTED_IMAGE_FILES = ['jpg', 'jpeg', 'png'];
export const SUPPORTED_IMAGE_CROPPER_FILES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'image/gif',
    'image/tiff',
    'image/webp',
    'image/x-icon',
    'image/vnd.microsoft.icon'
];
export const SUPPORTED_AUDIO_FILES = ['wav', 'mp3', 'aac', 'flac'];
export const SUPPORTED_AUDIO_FILES_TYPE = [
    'audio/x-wav',
    'audio/mpeg',
    'audio/aac',
    'audio/flac',
    'audio/wav'
];
export const SUPPORTED_MAP_FILES = ['geojson'];
export const SUPPORTED_ROUTE_FILES = ['gpx', 'kml', 'crs', 'tcx'];
export const SUPPORTED_VIDEO_FILES_AND_IMAGE_FILES = [
    'video/x-matroska',
    'video/quicktime',
    'video/avi',
    'video/x-msvideo',
    'video/msvideo',
    'video/vnd.avi',
    'video/mp4',
    'video/mov',
    'video/mkv',
    'image/mpeg4',
    'image/jpg',
    'image/jpeg',
    'image/png'
];
export const DEFAULT_ROUTE_PATH_COLOR = '#FF4081';
export const DEFAULT_ROUTE_PATH_THICKNESS = 4;

export const DEFAULT_MAP_CATEGORY = 'NOT_CATEGORIZED';

export const SITE_MINIMIZED_KEY = environment.production
    ? 'SiteMinimizedProd'
    : 'SiteMinimizedStg';
export const SPOT_MINIMIZED_KEY = environment.production
    ? 'SpotMinimizedProd'
    : 'SpotMinimizedStg';
export const SPOT_NAVIGATION_SHOW_MORE_KEY = environment.production
    ? 'SpotNavigationShowMoreProd'
    : 'SpotNavigationShowMoreStg';

export const ACCESSIBILITY_TAG_ID = '900900000200010';

export enum MessageType {
    WELCOME = 'welcome',
    EXIT = 'exit'
}

export const siteWizardStageDetails: MapWizardStageDetails<SiteWizardStages> =
    new Map<SiteWizardStages, WizardSectionDetails<SiteWizardStages>>([
        [
            SiteWizardStages.LANGUAGES,
            {
                stage: SiteWizardStages.LANGUAGES,
                titleKey: 'WIZARD_TITLE_LANGUAGES',
                descriptionKey: 'WIZARD_DESCRIPTION_LANGUAGES',
                genieTextKey: 'WIZARD_GENIE_LANGUAGES',
                genieVideoUrl: 'ilh0rBJmbwk',
                showAppPreview: true,
                navBarTextKey: 'LANGUAGES',
                icon: 'WTicon-menu-languages1',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                hidePreviousBtn: true
            }
        ],
        [
            SiteWizardStages.DETAILS,
            {
                stage: SiteWizardStages.DETAILS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                showAppPreview: false,
                navBarTextKey: 'DETAILS',
                icon: ' WTicon-menu-details1',
                children: [
                    SiteWizardStages.GENERAL,
                    SiteWizardStages.MEDIA,
                    SiteWizardStages.CONTACT,
                    SiteWizardStages.HOURS,
                    SiteWizardStages.TAGS
                ],
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.GENERAL,
            {
                stage: SiteWizardStages.GENERAL,
                titleKey: 'WIZARD_TITLE_GENERAL',
                descriptionKey: 'WIZARD_DESCRIPTION_GENERAL',
                genieTextKey: 'WIZARD_GENIE_GENERAL',
                genieVideoUrl: 'NgYZppLpnuM',
                showAppPreview: true,
                navBarTextKey: 'GENERAL',
                icon: ' WTicon-meni-general',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.MEDIA,
            {
                stage: SiteWizardStages.MEDIA,
                titleKey: 'WIZARD_TITLE_MEDIA',
                descriptionKey: 'WIZARD_DESCRIPTION_MEDIA',
                genieTextKey: 'WIZARD_GENIE_MEDIA',
                genieVideoUrl: '12xsxd7afVc',
                showAppPreview: true,
                navBarTextKey: 'MEDIA',
                icon: 'WTicon-menu-media1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.CONTACT,
            {
                stage: SiteWizardStages.CONTACT,
                titleKey: 'WIZARD_TITLE_CONTACT',
                descriptionKey: 'WIZARD_DESCRIPTION_CONTACT',
                genieTextKey: 'WIZARD_GENIE_CONTACT',
                genieVideoUrl: '',
                showAppPreview: true,
                navBarTextKey: 'CONTACT',
                icon: ' WTicon-menu-contact1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.HOURS,
            {
                stage: SiteWizardStages.HOURS,
                titleKey: 'WIZARD_TITLE_OPENING_HOURS',
                descriptionKey: 'WIZARD_DESCRIPTION_OPENING_HOURS',
                genieTextKey: 'WIZARD_GENIE_OPENING_HOURS',
                genieVideoUrl: 'YR6AQjwwhrM',
                showAppPreview: false,
                navBarTextKey: 'OPENING_HOURS',
                icon: 'WTicon-menu-hours',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.TAGS,
            {
                stage: SiteWizardStages.TAGS,
                titleKey: 'WIZARD_TITLE_TAGS',
                descriptionKey: 'WIZARD_DESCRIPTION_TAGS',
                genieTextKey: 'WIZARD_GENIE_TAGS',
                genieVideoUrl: 'LUX93_1XN2I',
                showAppPreview: true,
                navBarTextKey: 'TAGS',
                icon: ' WTicon-menu-tags1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.MAP_DETAILS,
            {
                stage: SiteWizardStages.MAP_DETAILS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                showAppPreview: false,
                navBarTextKey: 'MAP',
                icon: 'WTicon-menu-map',
                children: [
                    SiteWizardStages.MAP,
                    SiteWizardStages.SITE_ROUTES,
                    SiteWizardStages.SPOTS,
                    SiteWizardStages.CATEGORIES
                ],
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.MAP,
            {
                stage: SiteWizardStages.MAP,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'WIZARD_GENIE_SETUP',
                genieVideoUrl: 'IoZRxB0V-rw',
                showAppPreview: false,
                navBarTextKey: 'SETUP',
                icon: ' WTicon-menu-setup1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.FULL_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.SITE_ROUTES,
            {
                stage: SiteWizardStages.SITE_ROUTES,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'WIZARD_GENIE_TREKS',
                genieVideoUrl: 'vLAAF0mOJi0 ',
                showAppPreview: false,
                navBarTextKey: 'TREKS',
                icon: 'WTicon-menu-routes1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.DIALOG_ON_HALF_SCREEN,
                dependantOn: [SiteWizardStages.MAP]
            }
        ],
        [
            SiteWizardStages.SPOTS,
            {
                stage: SiteWizardStages.SPOTS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'WIZARD_GENIE_SPOTS',
                genieVideoUrl: 'H3ViznPeYxU ',
                showAppPreview: false,
                navBarTextKey: 'SPOTS',
                icon: ' WTicon-menu-spots',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.DIALOG_ON_HALF_SCREEN,
                dependantOn: [SiteWizardStages.MAP]
            }
        ],
        [
            SiteWizardStages.CATEGORIES,
            {
                stage: SiteWizardStages.CATEGORIES,
                titleKey: 'WIZARD_TITLE_CATEGORIES',
                descriptionKey: 'WIZARD_DESCRIPTION_CATEGORIES',
                genieTextKey: 'WIZARD_GENIE_CATEGORIES',
                genieVideoUrl: '_ZlLLk-fFEA',
                showAppPreview: false,
                navBarTextKey: 'CATEGORIES',
                icon: ' WTicon-menu-categories',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.WHOLE_SCREEN,
                dependantOn: [
                    SiteWizardStages.SITE_ROUTES,
                    SiteWizardStages.SPOTS
                ]
            }
        ],
        [
            SiteWizardStages.ENGAGEMENT,
            {
                stage: SiteWizardStages.ENGAGEMENT,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                showAppPreview: true,
                navBarTextKey: 'ENGAGEMENT',
                icon: ' WTicon-menu-engagement1',
                children: [
                    SiteWizardStages.GREETINGS,
                    SiteWizardStages.PROMOTED_TREKS,
                    SiteWizardStages.BRANDING
                ],
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.GREETINGS,
            {
                stage: SiteWizardStages.GREETINGS,
                titleKey: 'WIZARD_TITLE_GREETINGS',
                descriptionKey: 'WIZARD_DESCRIPTION_GREETINGS',
                genieTextKey: 'WIZARD_GENIE_GREETINGS',
                genieVideoUrl: '1aG99XDyW_M',
                showAppPreview: true,
                navBarTextKey: 'GREETINGS',
                icon: 'WTicon-menu-greetings',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.PROMOTED_TREKS,
            {
                stage: SiteWizardStages.PROMOTED_TREKS,
                titleKey: 'WIZARD_TITLE_PROMOTE_TREKS',
                descriptionKey: 'WIZARD_DESCRIPTION_PROMOTE_TREKS',
                genieTextKey: 'WIZARD_GENIE_PROMOTE_TREKS',
                genieVideoUrl: 'CAkOVtU8TjU',
                showAppPreview: true,
                navBarTextKey: 'PROMOTE_TREKS',
                icon: 'WTicon-menu-promote',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.BRANDING,
            {
                stage: SiteWizardStages.BRANDING,
                titleKey: 'WIZARD_TITLE_VIDEO_BRANDING',
                descriptionKey: 'WIZARD_DESCRIPTION_VIDEO_BRANDING',
                genieTextKey: 'WIZARD_GENIE_VIDEO_BRANDING',
                genieVideoUrl: '',
                showAppPreview: true,
                navBarTextKey: 'VIDEO_BRANDING',
                icon: 'WTicon-menu-branding1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ],
        [
            SiteWizardStages.PUBLISH,
            {
                stage: SiteWizardStages.PUBLISH,
                titleKey: 'WIZARD_TITLE_PUBLISH',
                descriptionKey: 'WIZARD_DESCRIPTION_VIDEO_BRANDING',
                genieTextKey: 'WIZARD_GENIE_PUBLISH',
                genieVideoUrl: 'vyedEw1YUnA',
                showAppPreview: true,
                navBarTextKey: 'PUBLISH',
                icon: 'WTicon-menu-publish',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                hideNextBtn: true,
                dependantOn: [SiteWizardStages.LANGUAGES]
            }
        ]
    ]);
