<div
    class="site-card"
    [routerLink]="['/', AppRoutePaths.SITES, this.site.id]"
    [ngClass]="{ expired: site.isExpired }"
    [id]="site.id"
    *ngIf="site">
    <app-base-card
        [name]="site.name || 'SITE_NAME' | translate"
        [imageUrl]="backgroundImage">
        <app-card-label *ngIf="site.isPrivate">
            <img
                [ngSrc]="iconsBaseUrl + 'lock.svg'"
                height="16"
                width="16"
                alt="lock" />
        </app-card-label>
        <app-card-label
            [status]="statusLabel"
            *ngIf="site.mode && site.mode !== WizardMode.APPROVED"
            >{{ site.mode! | translate }}
        </app-card-label>
    </app-base-card>

    <div
        class="overlay"
        *ngIf="site.isExpired">
        <img
            [ngSrc]="iconsBaseUrl + 'warn.svg'"
            height="26"
            width="26"
            alt="lock" />
        <h3
            appTypography="PRIMARY_BOLD"
            translate="TRIAL_EXPIRED"></h3>
    </div>
</div>
