<app-standalone-custom-button
    class="support-btn"
    (clicked)="onSetupMeetingClick()"
    buttonStyle="SECONDARY_OUTLINED">
    {{ 'GAMES_PAGE_CALENDLY' | translate }}
</app-standalone-custom-button>
<app-search-input #searchGame></app-search-input>
<div class="cards">
    <app-create-entity-card
        (cardClicked)="onCreateGameClicked()"
        [hasPermission]="true">
        <h3 appTypography="PRIMARY_BOLD">
            {{ 'CREATE_NEW_GAME' | translate }}
        </h3>
    </app-create-entity-card>

    <app-game-card
        *ngFor="
            let game of (gamesList$ | async) || []
                | filterGamesOnSearch : (searchGame.searchTerm | async) || ''
        "
        (cardClicked)="onGameCardClick(game.id)"
        (deleteGame)="onDeleteGame($event, game.id)"
        (duplicateCardClicked)="onDuplicateGameClick(game.id)"
        [gameData]="game"></app-game-card>
</div>
