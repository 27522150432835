import { Component, Inject, OnInit } from '@angular/core';
import {
    FormArray,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { SendAlertTime } from '../../models/site.model';
import { KeyValue } from '@angular/common';
import {
    AnnouncementAlert,
    AnnouncementDays,
    AnnouncementDialogData,
    AnnouncementDialogResult,
    AnnouncementTime,
    getAnnouncementDaysName
} from '../../models/announcement.model';
import { TimeFormat, WeekStart } from '../../models/site-settings.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppStoreService } from '../../../store/app-store.service';
import { TranslateService } from '@ngx-translate/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

type announcementFormType = {
    name: FormControl<string>;
    message: FormControl<string>;
    soiId: FormControl<string>;
    everyDay: FormControl<string | undefined>;
    days: FormArray<FormControl<string | undefined>>;
};

@Component({
    selector: 'app-scheduled-announcement-dialog',
    templateUrl: './scheduled-announcement-dialog.component.html',
    styleUrls: ['./scheduled-announcement-dialog.component.scss']
})
export class scheduledAnnouncementDialogComponent implements OnInit {
    announcementForm!: FormGroup<announcementFormType>;
    SendAlertTime = SendAlertTime;
    selectedTabIndex = this.data.alert?.days ? 1 : 0;
    days = this.initDaysList();
    daysNames = getAnnouncementDaysName();
    everyDay: AnnouncementAlert['everyDay'];
    NOTHING_SELECTED = 'allSpots';

    displaySpotOptionFunc = (spotId: string) =>
        spotId === this.NOTHING_SELECTED
            ? this.translationService.instant('NOTHING_SELECTED')
            : this.data.spots?.find((spotItem) => spotItem.id === spotId)
                  ?.general[this.data?.defaultLanguage]?.name || spotId;

    spotOptionList = [
        this.NOTHING_SELECTED,
        ...this.data.spots!.map((spot) => spot.id!)
    ];

    siteTimeFormat: 12 | 24 =
        this.appStoreService.state.siteSettings.timeFormat === TimeFormat.HR_12
            ? 12
            : 24;

    constructor(
        private translationService: TranslateService,
        private fB: NonNullableFormBuilder,
        public dialogRef: MatDialogRef<
            scheduledAnnouncementDialogComponent,
            AnnouncementDialogResult
        >,
        @Inject(MAT_DIALOG_DATA) public data: AnnouncementDialogData,
        private appStoreService: AppStoreService
    ) {}

    ngOnInit(): void {
        this.initAnnouncementForm();
    }

    initDaysList() {
        const days = Object.keys(AnnouncementDays) as AnnouncementDays[];
        if (
            this.appStoreService.state.siteSettings.weekStart ===
            WeekStart.MONDAY
        ) {
            moveItemInArray(days, 0, days.length - 1);
        }
        return days;
    }

    initAnnouncementForm(): void {
        this.announcementForm = this.fB.group(
            {
                name: this.fB.control(this.data.alert?.name || '', [
                    Validators.required
                ]),
                message: this.fB.control(this.data.alert?.message || '', [
                    Validators.required
                ]),
                soiId: this.fB.control(this.data.alert?.soiId || ''),
                everyDay: this.fB.control(this.data.alert?.everyDay?.time),
                days: this.fB.array(
                    this.days?.map((dayName) =>
                        this.fB.control(
                            this.data.alert?.days?.find(
                                (d) => d.day === dayName
                            )?.time
                        )
                    )
                )
            },
            { validators: this.validateRequiredTime }
        );
    }

    validateRequiredTime: ValidatorFn = () => {
        const isEveryDayControlFilled =
            this.announcementForm?.get('everyDay')?.value &&
            !this.selectedTabIndex;
        const isDaysControlFilled =
            (this.announcementForm?.get('days') as FormArray)?.controls.find(
                (control) => control.value
            ) && this.selectedTabIndex;
        return isEveryDayControlFilled || isDaysControlFilled
            ? null
            : { error: true };
    };

    originalOrderOption = (
        a: KeyValue<string, SendAlertTime>,
        b: KeyValue<string, SendAlertTime>
    ): number => 0;

    onDeleteAnnouncement(): void {
        this.dialogRef.close({ deletedAlert: this.data.alert });
    }

    onSaveAnnouncement(): void {
        const formControls = this.announcementForm.controls;
        const alertData: AnnouncementAlert = {
            soiId:
                formControls.soiId.value === this.NOTHING_SELECTED
                    ? undefined
                    : formControls.soiId.value,
            name: formControls.name.value,
            message: formControls.message.value,
            everyDay: this.selectedTabIndex
                ? undefined
                : {
                      time: formControls.everyDay.value
                  },
            days: this.selectedTabIndex
                ? ((this.announcementForm.get('days') as FormArray).controls
                      .map((control, index) =>
                          control.value
                              ? {
                                    day: this.days[index],
                                    time: control.value
                                }
                              : undefined
                      )
                      .filter((el) => el) as AnnouncementTime[])
                : undefined
        };
        const isNewAlert = !this.data.alert;
        if (!isNewAlert) {
            alertData.id = this.data.alert?.id;
        }
        const dialogResult: AnnouncementDialogResult = {
            newAlert: isNewAlert ? alertData : undefined,
            updatedAlert: isNewAlert ? undefined : alertData
        };
        this.dialogRef.close(dialogResult);
    }

    deleteDayAlert(isChecked: boolean, index: number) {
        if (!isChecked) {
            this.announcementForm.get(['days', index])?.setValue(undefined);
        }
    }
}
