import { MapboxMap } from './MapboxMap';
import {
    InSiteNavigation,
    LatLon,
    SpotNavigationInfo
} from '../typings/mapbox-utils';
import { environment } from '@env/environment';
import { Feature, FeatureCollection, LineString } from 'geojson';
import { getNavigationLayer } from './layers';
import { forkJoin, map, Observable, of } from 'rxjs';
import { NavigationModeTypes } from '../../standalone/components/navigation-settings/navigation-settings.model';
import { SiteSpotPublished } from '../../site-wizard/models/site-wizard-spots.model';

export class NavigationTools {
    navigationList: SpotNavigationInfo[] = [];

    private NAVIGATION_SOURCE_ID = 'NAVIGATION_SOURCE';
    private NAVIGATION_LAYER_ID = 'NAVIGATION_LAYER';
    private map!: MapboxMap;

    constructor(mapboxMap: MapboxMap) {
        this.map = mapboxMap;
    }

    getInSiteNavigation(
        siteId: string,
        start: SiteSpotPublished,
        end: SiteSpotPublished
    ): Observable<InSiteNavigation> {
        const inSiteNavigation = this.navigationList.find(
            (feature) =>
                feature.fromSpotId === start.id &&
                feature.toSpotId === end.id &&
                feature.mode === end.settings.navigationMode
        );
        if (inSiteNavigation) {
            return of(inSiteNavigation.feature);
        } else {
            const navigationMode =
                end.settings.navigationMode || NavigationModeTypes.DRIVE;
            return this.getInSiteNavigationFromServer(
                siteId,
                navigationMode,
                start.coordinate,
                end.coordinate
            ).pipe(
                map((featureRes) => {
                    const featureResFixed = this.addStartAndEndToNavigation(
                        start.coordinate,
                        end.coordinate,
                        featureRes
                    );
                    this.navigationList.push({
                        fromSpotId: start.id!,
                        toSpotId: end.id!,
                        mode: navigationMode,
                        feature: featureResFixed
                    });
                    return featureResFixed;
                })
            );
        }
    }

    private getInSiteNavigationFromServer(
        siteId: string,
        navigationMode: NavigationModeTypes,
        start: LatLon,
        end: LatLon
    ) {
        const mode = navigationMode.toLowerCase();
        return this.map.httpClient.get<InSiteNavigation>(
            `${environment.GATE_URL_2}/sites/v2/${siteId}/insitenavigation/v3`,
            {
                params: {
                    mode,
                    start: `${start.lat},${start.lon}`,
                    end: `${end.lat},${end.lon}`
                },
                headers: {
                    withCredentialsRemove: 'true'
                }
            }
        );
    }

    addNavigateLayer() {
        this.map.addSource(this.NAVIGATION_SOURCE_ID, {
            type: 'geojson',
            data: this.getFeatureCollection()
        });
        const layer: mapboxgl.Layer = getNavigationLayer(
            this.NAVIGATION_SOURCE_ID,
            this.NAVIGATION_LAYER_ID
        );

        this.map.addLayer(layer);
    }

    getFeatureCollection(data: Feature[] = []): FeatureCollection {
        return {
            type: 'FeatureCollection',
            features: data
        };
    }

    drawNavigation(siteId: string, challenges: SiteSpotPublished[]) {
        const featuresObs = challenges
            .map((spot, index) => {
                if (!index) {
                    return;
                }
                return this.getInSiteNavigation(
                    siteId,
                    challenges[index - 1],
                    spot
                );
            })
            .filter((feature) => !!feature) as Observable<InSiteNavigation>[];
        if (!featuresObs.length) {
            this.map.setSourceData(this.NAVIGATION_SOURCE_ID, {
                type: 'FeatureCollection',
                features: []
            });
            return;
        }
        forkJoin<InSiteNavigation[]>(featuresObs).subscribe((features) => {
            const featureCollection: FeatureCollection = {
                type: 'FeatureCollection',
                features: features
                    .map((feature) => feature.coordinates.features)
                    .flat(1)
            };
            this.map.setSourceData(
                this.NAVIGATION_SOURCE_ID,
                featureCollection
            );
        });
    }

    addStartAndEndToNavigation(
        start: LatLon,
        end: LatLon,
        collection: InSiteNavigation
    ) {
        const startCoordinators: number[] = [start.lon, start.lat];
        const endCoordinators: number[] = [end.lon, end.lat];
        (
            collection.coordinates as FeatureCollection<LineString>
        ).features[0].geometry.coordinates.unshift(startCoordinators);
        (collection.coordinates as FeatureCollection<LineString>).features[
            collection.coordinates.features.length - 1
        ].geometry.coordinates.push(endCoordinators);

        return collection;
    }
}
