import { createReducer, on } from '@ngrx/store';
import * as StepsActions from '../actions/site-wizard-stages.actions';
import { initialState } from '../site-wizard-store.constants';
import { siteWizardStageDetails } from '../../constants/site-wizard.constants';

export const siteStagesReducer = createReducer(
    initialState.activeStage,
    on(StepsActions.ChangeSiteStage, (state, { stage }) => {
        const newStage = siteWizardStageDetails.get(stage!);
        return newStage?.children?.length
            ? newStage!.children[0]
            : newStage?.stage;
    }),
    on(StepsActions.SiteNextStage, (state) => {
        const arrayStages = Array.from(siteWizardStageDetails.values())
            .filter((stage) => !stage.children?.length)
            .map((stage) => stage.stage);
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex + 1];
    }),
    on(StepsActions.SitePrevStage, (state) => {
        const arrayStages = Array.from(siteWizardStageDetails.values())
            .filter((stage) => !stage.children?.length)
            .map((stage) => stage.stage);
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex - 1];
    })
);
