import * as mapboxgl from 'mapbox-gl';
import { MapInjectedServices } from '../typings/mapbox-utils';
import { MapboxMap } from '../utils/MapboxMap';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-store.model';
import { MapboxEventsService } from './mapbox-events.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MapboxService {
    private Renderer: Renderer2;

    constructor(
        private Translate: TranslateService,
        private mapboxEventsService: MapboxEventsService,
        private storeService: Store<AppState>,
        private rendererFactory: RendererFactory2,
        private httpClient: HttpClient
    ) {
        mapboxgl.setRTLTextPlugin(
            'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.1/mapbox-gl-rtl-text.js',
            (error) => console.log('Failed to load RTL plugin', error)
        );

        this.Renderer = this.rendererFactory.createRenderer(null, null);
    }

    createMap(
        elementId: string,
        shouldLoadDraw: boolean = false,
        shouldLoadStyles: boolean = true,
        shouldLoadCompass: boolean = true
    ): MapboxMap {
        const injectedServices: MapInjectedServices = {
            Translate: this.Translate,
            MapboxEventsService: this.mapboxEventsService,
            StoreService: this.storeService,
            Renderer: this.Renderer,
            httpClient: this.httpClient
        };
        return new MapboxMap(
            injectedServices,
            elementId,
            shouldLoadDraw,
            shouldLoadStyles,
            shouldLoadCompass
        );
    }
}
