import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { AppState } from '../../../store/app-store.model';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ImplementationConfiguration, SiteCard } from '../../models/site.model';
import { combineLatestWith, Observable } from 'rxjs';

@Component({
    selector: 'app-implementation',
    templateUrl: './implementation.component.html',
    styleUrls: ['./implementation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImplementationComponent implements OnInit {
    site?: SiteCard;
    isAllowPremiumFeaturesAndImplementationConfiguration?: Observable<{
        isAllowPremiumFeatures: boolean | undefined;
        implementationConfiguration: ImplementationConfiguration;
    }>;

    constructor(
        private siteService: SiteService,
        private store: Store<AppState>,
        private activatedRouter: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.site = this.activatedRouter.snapshot.parent?.data.siteInfo;
        this.isAllowPremiumFeaturesAndImplementationConfiguration =
            this.siteService.getSiteSettings(this.site?.id!).pipe(
                map((siteSettings) => siteSettings?.allowPremiumFeatures),
                combineLatestWith(
                    this.siteService.getImplementationConfiguration()
                ),
                map(
                    ([
                        isAllowPremiumFeatures,
                        implementationConfiguration
                    ]) => ({
                        isAllowPremiumFeatures,
                        implementationConfiguration
                    })
                )
            );
    }
}
