import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { AuthRoutePaths } from '../../../../../auth/auth-routing.module';
import { AppRoutePaths } from '../../../../../app-routing.module';
import {
    WT_PRIVACY_POLICY_URL,
    WT_TOS_URL,
    WT_TRANSLATE_PRIVACY_POLICY_URL,
    WT_TRANSLATE_TOS_URL
} from '../../../../../shared/shared.constants';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppStoreService } from '../../../../../store/app-store.service';
import { LOCALES } from '../../../../../shared/locales.constants';
import { LocaleType } from '../../../../../shared/locales.models';
import { take, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ContactStandaloneComponent } from '../../../contact/contact-standalone.component';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TypographyDirective } from '../../../../directives/typography/typography.directive';
import { KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../../../../services/auth.service';

@Component({
    standalone: true,
    selector: 'app-mobile-sidenav',
    templateUrl: './mobile-sidenav.component.html',
    styleUrls: ['./mobile-sidenav.component.scss'],
    imports: [
        MatSidenavModule,
        RouterLinkActive,
        TypographyDirective,
        RouterLink,
        NgIf,
        TranslateModule,
        MatMenuModule,
        KeyValuePipe,
        NgForOf
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSidenavComponent implements OnInit, OnDestroy {
    protected readonly AuthRoutePaths = AuthRoutePaths;
    protected readonly AppRoutePaths = AppRoutePaths;
    private unsubscribe$ = new Subject<void>();
    user = this.appStoreService.state.auth?.user;
    websiteLocales: { [locale: string]: string } = {};
    siteLang!: string;
    websiteUrl = environment.WEBSITE_URL;
    businessUrl = environment.BUSINESS_URL_WEBSITE_NEW;
    privacyPolicyUrl = WT_PRIVACY_POLICY_URL;
    tosUrl = WT_TOS_URL;
    currentYear = JSON.stringify(new Date().getFullYear());

    constructor(
        private appStoreService: AppStoreService,
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.initLanguages();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initLanguages() {
        this.websiteLocales = this.translateService
            .getLangs()
            .reduce((localeMap: { [key: string]: string }, locale) => {
                localeMap[locale] = LOCALES[locale as LocaleType];
                return localeMap;
            }, {});
        this.siteLang = this.translateService.currentLang;
        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((langChangeEvent) => {
                this.initLanguageUrl(langChangeEvent.lang);
            });
        this.initLanguageUrl(this.siteLang);
    }

    initLanguageUrl(lang: string) {
        if (lang !== 'en') {
            this.tosUrl = `${WT_TRANSLATE_TOS_URL}?_x_tr_sl=en&_x_tr_tl=${lang}&_x_tr_hl=${lang}&_x_tr_pto=wapp`;
            this.privacyPolicyUrl = `${WT_TRANSLATE_PRIVACY_POLICY_URL}?_x_tr_sl=en&_x_tr_tl=${lang}&_x_tr_hl=${lang}&_x_tr_pto=wapp`;
        }
    }

    toggleLanguages($event: any): void {
        $event.preventDefault();
        $event.stopPropagation();
    }

    changeWebsiteLanguage(locale: string): void {
        localStorage.setItem('LANG_KEY', locale);
        this.siteLang = locale as LocaleType;
        this.translateService
            .use(locale)
            .pipe(take(1))
            .subscribe(() => window.location.reload());
    }

    openContact(): void {
        this.matDialog.open(ContactStandaloneComponent);
    }

    onLogout(): void {
        this.authService.logout().subscribe();
    }
}
