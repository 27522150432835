import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToggleSpinnerService } from './services/toggle-spinner.service';
import { filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, shareReplay, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { AppLoadService } from './services/app-load.service';
import { HubspotMeetingWidgetService } from './standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.service';
import { SCREEN_BREAK_POINTS } from './shared/shared.constants';
import { HeaderMode } from './standalone/components/header/header.model';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { AppRoutePaths } from './app-routing.module';
import { SitesRoutePaths } from './sites/sites-routing.module';
import { GamesRoutePaths } from './games/games-routing.module';
import { TreksRoutePaths } from './treks/treks-routing.module';
import { A11tPosition } from './components/accessibility-icon/accessibility-icon.component.consts';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    protected readonly HeaderMode = HeaderMode;
    favIcon!: HTMLLinkElement | null;
    showSpinner$!: Observable<boolean>;
    directionality!: Observable<'rtl' | 'ltr'>;
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
    headerMode$!: Observable<HeaderMode>;
    a11yIconPosition$ = new BehaviorSubject<A11tPosition>(
        A11tPosition.START_BOTTOM
    );

    constructor(
        private toggleSpinnerService: ToggleSpinnerService,
        private appLoadService: AppLoadService,
        public hubspotMeetingWidgetService: HubspotMeetingWidgetService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initHeaderMode();
        this.toggleSpinnerService.init();
        this.showSpinner$ = this.toggleSpinnerService.toggleEvent$!;

        this.favIcon = document.querySelector('#appIcon');
        this.favIcon!.href = `${environment.ASSETS_BASE_URL}/favicon.ico`;
        this.directionality = this.appLoadService.directionality.asObservable();
    }

    initHeaderMode() {
        this.headerMode$ = this.router.events.pipe(
            filter(
                (e) =>
                    e instanceof NavigationEnd || e instanceof NavigationSkipped
            ),
            map(() => {
                const routes = location.pathname.split('/');
                if (routes[1] === AppRoutePaths.AUTH) {
                    return HeaderMode.AUTH;
                }
                const inSite = routes[1] === AppRoutePaths.SITES;
                const siteWizard =
                    inSite && routes[3] === SitesRoutePaths.SITE_WIZARD;
                const trekWizard =
                    routes[1] === AppRoutePaths.TREKS &&
                    routes[2] &&
                    routes[2] !== TreksRoutePaths.CREATE_TREK;
                const gameWizard =
                    inSite &&
                    routes[3] === SitesRoutePaths.GAMES &&
                    routes[4] &&
                    routes[4] !== GamesRoutePaths.CREATE_GAME;
                const dcfWizard =
                    inSite &&
                    routes[3] === SitesRoutePaths.CAMPAIGNS &&
                    routes[4];
                if (siteWizard || trekWizard || gameWizard || dcfWizard) {
                    return HeaderMode.WIZARD;
                }
                return HeaderMode.NAVIGATION;
            }),
            tap(() => this.initA11yIconPosition(location.pathname)),
            shareReplay()
        );
    }

    ngOnDestroy(): void {
        this.toggleSpinnerService.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initA11yIconPosition(url: string) {
        let position: A11tPosition = A11tPosition.START_BOTTOM;
        if (url.includes(AppRoutePaths.SITES)) {
            position = A11tPosition.END_BOTTOM;
        }
        this.a11yIconPosition$.next(position);
    }
}
