import { createReducer, on } from '@ngrx/store';
import { initialState } from '../site-wizard-store.constants';
import * as RoutesActions from '../actions/routes.actions';

export const routesReducer = createReducer(
    initialState.siteRoutes,
    on(RoutesActions.AddRoute, (state, { route }) => ({
        ...state,
        data: {
            ...state.data,
            [route.trekId]: route
        },
        sectionStatus: {
            ...state.sectionStatus,
            disableDependants: false
        }
    })),
    on(RoutesActions.DeleteRoute, (state, { id }) => {
        const routes = { ...state.data };
        delete routes[id];
        return {
            ...state,
            data: routes
        };
    }),
    on(RoutesActions.UpdateNavigationMode, (state, { trekId, mode }) => {
        const data = { ...state?.data };
        data[trekId] = {
            ...data[trekId],
            navigationMode: mode
        };
        return {
            ...state,
            data
        };
    }),
    on(RoutesActions.UpdateShowPath, (state, { trekId, showPath }) => {
        const data = { ...state?.data };
        data[trekId] = {
            ...data[trekId],
            showPath
        };
        return {
            ...state,
            data
        };
    }),
    on(RoutesActions.UpdatePathProps, (state, { trekId, thickness, color }) => {
        const data = { ...state?.data };
        data[trekId] = {
            ...data[trekId],
            thickness,
            color
        };
        return {
            ...state,
            data
        };
    }),
    on(RoutesActions.RoutesComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus,
            complete: true
        }
    }))
);
