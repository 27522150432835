import { createReducer, on } from '@ngrx/store';
import {
    gameWizardStageDetails,
    GameWizardStages
} from '../../constants/game-wizard.const';
import {
    ChangeGameStage,
    NextGameStage,
    PrevGameStage
} from '../actions/game-wizard-stages.actions';

export const gameStagesReducer = createReducer(
    GameWizardStages.LANGUAGES,
    on(ChangeGameStage, (state, { stage }) => stage),
    on(NextGameStage, (state) => {
        const arrayStages = Array.from(gameWizardStageDetails.keys());
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex + 1];
    }),
    on(PrevGameStage, (state) => {
        const arrayStages = Array.from(gameWizardStageDetails.keys());
        const oldStageIndex = arrayStages.findIndex(
            (section) => section === state
        );
        return arrayStages[oldStageIndex - 1];
    })
);
