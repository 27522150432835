<div class="site-index-header">
    <div class="business-info">
        <a
            class="img-link"
            [routerLink]="['/', AppRoutePaths.BUSINESS]">
            <img
                [ngSrc]="user.businessLogoUrl!"
                height="70"
                width="70"
                class="business-logo"
                alt="business logo" />
        </a>
        <div
            class="business-name"
            appTypography="SECONDARY">
            {{ user.name }}
        </div>
    </div>
    <h2
        class="site-name"
        appTypography="SUBTITLE">
        {{ site.name || 'SITE_NAME' | translate }}
        {{ 'MANAGEMENT_CONSOLE' | translate }}
    </h2>
    <app-free-trial-indicator></app-free-trial-indicator>
    <app-standalone-custom-button
        buttonStyle="SECONDARY_OUTLINED"
        class="meeting-setup-btn"
        (clicked)="onSetupMeetingClick()">
        {{ 'NEED_HELP_SETTING_UP' | translate }}
    </app-standalone-custom-button>
</div>
<div class="menus-wrapper">
    <div
        *ngFor="let section of siteMenu"
        class="section-wrapper">
        <div class="section-title">
            <h3 appTypography="PRIMARY">
                {{ section.sectionTitle | translate }}
            </h3>
        </div>
        <div class="items-wrapper">
            <div
                *ngFor="let item of section.items"
                class="action-container"
                (click)="
                    item.clickAction(
                        item.url,
                        item.disabled,
                        item.hasPermission
                    )
                "
                [ngClass]="{ disabled: item.disabled }">
                <i [class]="item.icon">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                </i>
                <h3 appTypography="PRIMARY_BOLD">
                    {{ item.title | translate }}
                </h3>
                <p appTypography="TINY">
                    {{ item.description || '' | translate }}
                </p>
                <div
                    class="overlay"
                    *ngIf="item.disabled || !item.hasPermission">
                    <img
                        [ngSrc]="iconsBaseUrl + 'lock.svg'"
                        width="39"
                        height="37" />
                    <div *ngIf="!item.hasPermission && !item.disabled">
                        <h3 appTypography="PRIMARY_BOLD">
                            {{ 'ACCESS_RESTRICTED' | translate }}
                        </h3>
                        <p
                            appTypography="TINY"
                            translate="CONTACT_ADMIN_TO_UNLOCK">
                            {{ 'CONTACT_ADMIN_TO_UNLOCK' | translate }}
                        </p>
                    </div>
                    <div *ngIf="item.disabled">
                        <h3 appTypography="PRIMARY_BOLD">
                            {{
                                item.disabledText ||
                                    (site.isExpired
                                        ? 'SUBSCRIPTION_EXPIRED'
                                        : 'PUBLISH_TO_UNLOCK') | translate
                            }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
