import {
    SiteWizardStages,
    SiteWizardState,
    SiteWizardStepWithLang,
    WizardMode
} from './site-wizard-store.model';
import { WizardSectionStatus } from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import { MessageAppearance } from '../models/site-wizard-greeting.model';
import { SiteHoursSettings } from '../models/site-wizard-hours.model';
import {
    SiteMapStages,
    SiteMapStyleTypes
} from '../models/site-wizard-map-setup.model';
import { SiteWizardSpotsStep } from '../models/site-wizard-spots.model';
import { SiteLifeCycleStateType } from '../site-wizard.model';

export const daysInWeek = {
    1: 'SUNDAY',
    2: 'MONDAY',
    3: 'TUESDAY',
    4: 'WEDNESDAY',
    5: 'THURSDAY',
    6: 'FRIDAY',
    7: 'SATURDAY'
};

export enum MediaUploadTypes {
    SOI_AUDIO = 'SOI_AUDIO',
    SOI_MEDIA = 'SOI_MEDIA',
    SITE_MEDIA = 'SITE_MEDIA',
    SITE_AUDIO = 'SITE_AUDIO'
}

export const initialState: SiteWizardState = {
    mode: WizardMode.CREATE,
    lifecycle: SiteLifeCycleStateType.PENDING,
    activeStage: SiteWizardStages.LANGUAGES,
    languages: {
        sectionStatus: new WizardSectionStatus(true, false, true)
    },
    general: {
        sectionStatus: new SiteWizardStepWithLang(true, false)
    },
    media: {
        sectionStatus: new WizardSectionStatus(true, false)
    },
    contact: {
        sectionStatus: new WizardSectionStatus(false, false)
    },
    hours: {
        sectionStatus: new SiteWizardStepWithLang(false, false),
        data: {
            settings: new SiteHoursSettings()
        }
    },
    tags: {
        sectionStatus: new WizardSectionStatus(false, false)
    },
    map: {
        data: {
            mapStage: SiteMapStages.POLYGON,
            polygon: {
                showPolygon: true
            },
            anchorPoint: {
                navigatable: true
            },
            mapStyle: SiteMapStyleTypes.DEFAULT
        },
        sectionStatus: new WizardSectionStatus(true, false, true)
    },
    siteRoutes: {
        sectionStatus: new WizardSectionStatus(false, false)
    },
    spots: {
        sectionStatus: new SiteWizardSpotsStep(false, false, true)
    },
    categories: {
        sectionStatus: new WizardSectionStatus(false, false)
    },
    greeting: {
        data: {
            entryMessageEnabled: false,
            exitMessageEnabled: false,
            messageAppearance: MessageAppearance.RECENT_FOREGROUND
        },
        sectionStatus: new SiteWizardStepWithLang(false, false)
    },
    promotedTreks: {
        data: {
            starTreks: false
        },
        sectionStatus: new WizardSectionStatus(false, false)
    },
    showLogoInMovie: {
        data: true,
        sectionStatus: new WizardSectionStatus(false, false)
    },
    private: {
        data: false,
        sectionStatus: new WizardSectionStatus(false, false)
    }
};
