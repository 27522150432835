<h2 appTypography="SUBTITLE">
    {{ 'SITE_SMS_INVITE' | translate }}
</h2>
<p appTypography="SECONDARY">
    {{ 'SITE_SMS_INVITE_SUBTITLE' | translate }}
</p>
<div class="sms-wrap-form">
    <p appTypography="SECONDARY_BOLD">
        {{ 'PHONE_NUMBER_BOX_HEADER' | translate }}
    </p>
    <form [formGroup]="phoneForm">
        <div class="form-block tel-input-wrapper">
            <div>
                <ngx-intl-tel-input
                    [dir]="'ltr'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [phoneValidation]="true"
                    [searchCountryFlag]="true"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="countryCode"
                    cssClass="tel-input"
                    formControlName="phone"
                    inputId="tel-input-field"
                    [maxLength]="15"
                    name="phone"></ngx-intl-tel-input>
            </div>
            <span *ngIf="phoneForm.controls.phone.errors"> </span>
        </div>
        <div class="sms-wrapper-send-btn">
            <app-standalone-custom-button
                (clicked)="onSubmit()"
                [isDisabled]="
                    phoneForm.invalid || !phoneForm.controls.phone.value
                "
                id="send_contact"
                buttonType="submit">
                {{ 'SEND' | translate }}
            </app-standalone-custom-button>
        </div>
    </form>
</div>

<p
    *ngIf="displayForbiddenErrMsg$ | async"
    appTypography="SECONDARY">
    {{ 'SMS_INVITE_FORBIDDEN' | translate }}
</p>

<div
    *ngIf="sentNumbers.length || (sentNumbers$ | async)?.length"
    class="sent-sms-table-wrapper">
    <div class="sent-numbers-box-title">
        <p
            appTypography="PRIMARY_BOLD"
            color="WHITE"
            class="header-text">
            {{ 'SMS_SENT_INVITATIONS' | translate }}
        </p>
    </div>
    <div class="sent-numbers-box-body">
        <div *ngFor="let sentNumbers of sentNumbers$ | async">
            <p appTypography="SECONDARY">
                {{ sentNumbers.phoneNumber }}&nbsp;&nbsp;&nbsp;
                {{ sentNumbers.date | date : 'dd.MM.yyyy' }}
            </p>
        </div>
    </div>
</div>
