import { createReducer } from '@ngrx/store';
import {
    AddGameLanguage,
    RemoveGameLanguage
} from '../actions/game-wizard-languages.actions';
import { immerOn } from 'ngrx-immer/store';
import {
    GameIntroductionDetails,
    GameIntroductionSection,
    IntroductionDetails
} from '../../models/game-wizard-introduction.model';
import {
    AddGameIntroductionMedia,
    DeleteGameIntroductionMedia,
    OrderGameIntroductionMedia,
    setGameIntroductionSelectedLangTab,
    updateIntroductionDescription
} from '../actions/game-wizard-introduction.actions';
import { LocalesKeysObject, LocaleType } from '../../../shared/locales.models';
import { orderGameMediaItems } from '../../constants/game-wizard.const';

export const gameIntroductionReducer = createReducer(
    {} as GameIntroductionSection,
    immerOn(AddGameLanguage, (state, { langToAdd, defaultLang }) => {
        if (!state.data?.details) {
            state.data!.details = {} as LocalesKeysObject<IntroductionDetails>;
        }
        state.data!.details![langToAdd] = {
            introductionDescription: '',
            introductionMedia:
                Object.keys(state.data?.details || {}).length &&
                !state.sectionStatus.isMediaChanged
                    ? state.data?.details[
                          defaultLang ||
                              (
                                  Object.keys(
                                      state.data?.details
                                  ) as LocaleType[]
                              )[0]
                      ]?.introductionMedia || []
                    : []
        };
        state.sectionStatus.invalid = checkIfIntroductionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfIntroductionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        if (Object.values(state.data?.details || {}).length === 1) {
            state.sectionStatus.selectedLangTab = langToAdd;
        }
    }),
    immerOn(RemoveGameLanguage, (state, { langToRemove }) => {
        delete state.data?.details?.[langToRemove];
        state.sectionStatus.invalid = checkIfIntroductionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfIntroductionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        if (state.sectionStatus.selectedLangTab === langToRemove) {
            state.sectionStatus.selectedLangTab = Object.keys(
                state.data?.details || {}
            )[0] as LocaleType;
        }
    }),
    immerOn(
        updateIntroductionDescription,
        (state, { introductionDescription, local }) => {
            state.data!.details![local].introductionDescription =
                introductionDescription;
            state.sectionStatus.invalid = checkIfIntroductionStateInvalid(
                state.data?.details
            );
            state.sectionStatus.complete = checkIfIntroductionStateCompleted(
                state.data?.details
            );
            state.sectionStatus.disableDependants =
                !!state.sectionStatus.invalid;
        }
    ),
    immerOn(AddGameIntroductionMedia, (state, { path, local, defaultLang }) => {
        if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
            (Object.keys(state.data?.details || {}) as LocaleType[]).forEach(
                (lang) => {
                    state.data!.details![lang]!.introductionMedia = [
                        ...state.data!.details[lang].introductionMedia,
                        {
                            path,
                            index:
                                state.data!.details[lang]?.introductionMedia
                                    ?.length || 0
                        }
                    ];
                }
            );
        } else {
            state.data!.details![local]!.introductionMedia = [
                ...state.data!.details[local].introductionMedia,
                {
                    path,
                    index:
                        state.data!.details[local]?.introductionMedia?.length ||
                        0
                }
            ];
            state.sectionStatus!.isMediaChanged = true;
        }

        state.sectionStatus.invalid = checkIfIntroductionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfIntroductionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(
        OrderGameIntroductionMedia,
        (state, { introductionMedia, local, defaultLang }) => {
            if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
                (
                    Object.keys(state.data?.details || {}) as LocaleType[]
                ).forEach((lang) => {
                    state.data!.details![lang].introductionMedia =
                        orderGameMediaItems(introductionMedia);
                });
            } else {
                state.data!.details![local].introductionMedia =
                    orderGameMediaItems(introductionMedia);
                state.sectionStatus!.isMediaChanged = true;
            }
        }
    ),
    immerOn(
        DeleteGameIntroductionMedia,
        (state, { index, local, defaultLang }) => {
            if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
                (
                    Object.keys(state.data?.details || {}) as LocaleType[]
                ).forEach((lang) => {
                    const filteredMedia = state.data!.details?.[
                        lang
                    ].introductionMedia?.filter(
                        (introductionMedia, i) => i !== index
                    );
                    state.data!.details[lang].introductionMedia =
                        orderGameMediaItems(filteredMedia!);
                });
            } else {
                const filteredMedia = state.data!.details?.[
                    local
                ].introductionMedia?.filter(
                    (introductionMedia, i) => i !== index
                );
                state.data!.details[local].introductionMedia =
                    orderGameMediaItems(filteredMedia!);
                state.sectionStatus!.isMediaChanged = true;
            }
            state.sectionStatus.invalid = checkIfIntroductionStateInvalid(
                state.data?.details
            );
            state.sectionStatus.complete = checkIfIntroductionStateCompleted(
                state.data?.details
            );
            state.sectionStatus.disableDependants =
                !!state.sectionStatus.invalid;
        }
    ),
    immerOn(
        setGameIntroductionSelectedLangTab,
        (state, { selectedLangTab }) => {
            state.sectionStatus.selectedLangTab = selectedLangTab;
        }
    )
);

function checkIfIntroductionStateInvalid(
    state: GameIntroductionDetails['details'] | undefined
) {
    return (
        !state ||
        !Object.keys(state).length ||
        !(Object.keys(state) as LocaleType[]).every(
            (lang) =>
                (state || {})[lang]?.introductionMedia?.length &&
                (state || {})[lang]?.introductionDescription
        )
    );
}

function checkIfIntroductionStateCompleted(
    state: GameIntroductionDetails['details'] | undefined
) {
    return (
        !!(Object.keys(state || {}) as LocaleType[])?.length &&
        (Object.keys(state || {}) as LocaleType[]).every(
            (lang) =>
                state?.[lang]?.introductionDescription &&
                state?.[lang]?.introductionMedia?.length
        )
    );
}
