import { LocaleType } from '../../shared/locales.models';
import { SiteSpotPublished } from '../../site-wizard/models/site-wizard-spots.model';

export interface Announcement {
    timezone: string;
    alerts: AnnouncementAlert[];
}

export interface AnnouncementAlert {
    id?: string;
    soiId?: string;
    name: string;
    message: string;
    days?: AnnouncementTime[];
    everyDay?: {
        time?: string;
    };
}

export interface AnnouncementTime {
    day: AnnouncementDays;
    time: string;
}

export enum AnnouncementDays {
    SUN = 'SUN',
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT'
}

export const getAnnouncementDaysName = () =>
    new Map<AnnouncementDays, string>([
        [AnnouncementDays.SUN, 'SUNDAY_SHORT_VIEW'],
        [AnnouncementDays.MON, 'MONDAY_SHORT_VIEW'],
        [AnnouncementDays.TUE, 'TUESDAY_SHORT_VIEW'],
        [AnnouncementDays.WED, 'WEDNESDAY_SHORT_VIEW'],
        [AnnouncementDays.THU, 'THURSDAY_SHORT_VIEW'],
        [AnnouncementDays.FRI, 'FRIDAY_SHORT_VIEW'],
        [AnnouncementDays.SAT, 'SATURDAY_SHORT_VIEW']
    ]);

export interface AnnouncementDialogData {
    defaultLanguage: LocaleType;
    spots?: SiteSpotPublished[];
    timezone: string;
    alert?: AnnouncementAlert;
}

export type AnnouncementDialogResult =
    | {
          newAlert?: AnnouncementAlert | any;
          updatedAlert?: AnnouncementAlert | any;
          deletedAlert?: AnnouncementAlert;
      }
    | undefined;
