<div
    class="delete-button"
    (click)="deleteGame.emit(gameData.data); $event.stopPropagation()">
    <i class="WTicon-round-delete">
        <span class="path1"></span>
        <span class="path2"></span>
    </i>
</div>
<!--<div-->
<!--    class="duplicate-button"-->
<!--    (click)="duplicateCardClicked.emit(); $event.stopPropagation()">-->
<!--    <span class="WTicon-duplicate-game"></span>-->
<!--</div>-->
<app-base-card
    [imageUrl]="backgroundImage"
    [name]="gameData.data?.name || 'GAME_NAME' | translate"
    [subName]="gameData.data?.subtitle">
    <app-card-label
        [status]="gameData.active ? StatusLabel.ACTIVE : StatusLabel.INACTIVE"
        >{{ (gameData.active ? 'ACTIVE' : 'INACTIVE') | translate }}
    </app-card-label>
    <app-card-label
        *ngIf="gameData.isEdit"
        [status]="StatusLabel.IS_EDIT"
        >{{ 'EDITED' | translate }}
    </app-card-label>
</app-base-card>
