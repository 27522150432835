import { createReducer } from '@ngrx/store';
import { initialState } from '../site-wizard-store.constants';
import * as ShowLogoActions from '../actions/showLogo.actions';
import { immerOn } from 'ngrx-immer/store';

export const showLogoReducer = createReducer(
    initialState.showLogoInMovie,
    immerOn(ShowLogoActions.ToggleShowLogo, (draft) => {
        draft.data = !draft.data;
    }),
    immerOn(ShowLogoActions.ShowLogoComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
