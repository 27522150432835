import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import {
    Campaign,
    CampaignTypeDetails,
    CampaignTypes
} from '../dcf-wizard.model';
import { DcfWizardState } from '../store/dcf-wizard-store.model';
import {
    checkIfDetailsStateComplete,
    checkIfDetailsStateInvalid
} from '../store/reducers/dcf-wizard-details.reducer';
import { LocaleType } from '../../shared/locales.models';
import {
    checkIfDonationStateComplete,
    checkIfDonationStateInvalid,
    getEmptyLangDonations
} from '../store/reducers/dcf-wizard-donations.reducer';
import {
    checkIfTypeSectionCompleted,
    checkIfTypeSectionInvalid
} from '../store/reducers/dcf-wizard-type.reducer';

export enum DcfWizardStages {
    LANGUAGES = 'languages',
    CAMPAIGN_TYPE = 'campaignType',
    DETAILS = 'details',
    DONATIONS = 'donations',
    ACTIVATE = 'activate'
}

export const dcfWizardStageDetails: MapWizardStageDetails<DcfWizardStages> =
    new Map<DcfWizardStages, WizardSectionDetails<DcfWizardStages>>([
        [
            DcfWizardStages.LANGUAGES,
            {
                stage: DcfWizardStages.LANGUAGES,
                titleKey: 'CAMPAIGN_LANGUAGES_TITLE',
                descriptionKey: 'CAMPAIGN_LANGUAGES_DESCRIPTION',
                genieTextKey: 'DCF_GENIE_LANGUAGE',
                genieVideoUrl: '',
                navBarTextKey: 'LANGUAGES',
                icon: 'WTicon-Langueges',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                hidePreviousBtn: true
            }
        ],
        [
            DcfWizardStages.CAMPAIGN_TYPE,
            {
                stage: DcfWizardStages.CAMPAIGN_TYPE,
                titleKey: 'CAMPAIGN_TYPE_TITLE',
                descriptionKey: 'CAMPAIGN_TYPE_DESCRIPTION',
                genieTextKey: 'DCF_GENIE_CAMPAIGN_TYPE',
                genieVideoUrl: '',
                navBarTextKey: 'TYPE',
                icon: 'WTicon-Choose-the-challenge',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN
            }
        ],
        [
            DcfWizardStages.DETAILS,
            {
                stage: DcfWizardStages.DETAILS,
                titleKey: 'CAMPAIGN_DETAILS_TITLE',
                descriptionKey: 'CAMPAIGN_DETAILS_DESCRIPTION',
                genieTextKey: 'DCF_GENIE_DETAILS',
                genieVideoUrl: '',
                navBarTextKey: 'DETAILS',
                icon: 'WTicon-Basic-details',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                dependantOn: [DcfWizardStages.LANGUAGES]
            }
        ],
        [
            DcfWizardStages.DONATIONS,
            {
                stage: DcfWizardStages.DONATIONS,
                titleKey: 'CAMPAIGN_DONATIONS_TITLE',
                descriptionKey: 'CAMPAIGN_DONATIONS_DESCRIPTION',
                genieTextKey: 'DCF_GENIE_DONATIONS',
                genieVideoUrl: '',
                navBarTextKey: 'DONATIONS',
                icon: 'WTicon-certificate',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN,
                dependantOn: [DcfWizardStages.LANGUAGES]
            }
        ],
        [
            DcfWizardStages.ACTIVATE,
            {
                stage: DcfWizardStages.ACTIVATE,
                titleKey: 'CAMPAIGN_ACTIVATE_TITLE',
                descriptionKey: 'CAMPAIGN_ACTIVATE_DESCRIPTION',
                genieTextKey: 'DCF_GENIE_ACTIVATE',
                genieVideoUrl: '',
                navBarTextKey: 'ACTIVATE',
                icon: 'WTicon-toggle',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                hideNextBtn: true,
                dependantOn: [
                    DcfWizardStages.LANGUAGES,
                    DcfWizardStages.CAMPAIGN_TYPE,
                    DcfWizardStages.DETAILS,
                    DcfWizardStages.DONATIONS
                ]
            }
        ]
    ]);
export const mapCampaignObjectIntoDcfState = (
    campaign: Campaign
): DcfWizardState => {
    const languagesList =
        campaign.dcfWizardState?.languages.data?.languagesList ||
        campaign.data?.map((langData) => langData.language) ||
        [];
    return {
        id: campaign.id,
        ooiId: campaign.ooiId,
        activeStage:
            campaign.dcfWizardState?.activeStage || DcfWizardStages.LANGUAGES,
        languages: {
            data: {
                languagesList,
                defaultLanguage: campaign.defaultLanguage || ('' as LocaleType),
                recommendedLanguages: campaign.recommendedLanguages
            },
            sectionStatus: campaign.dcfWizardState?.languages.sectionStatus || {
                invalid: !languagesList.length,
                complete: !!languagesList.length,
                disableDependants: !languagesList.length
            }
        },
        campaignType: campaign.dcfWizardState?.campaignType || {
            data: campaign.typeDetails || {},
            sectionStatus: {
                invalid: checkIfTypeSectionInvalid(
                    campaign.typeDetails ||
                        ({} as CampaignTypeDetails<CampaignTypes>)
                ),
                complete: checkIfTypeSectionCompleted(
                    campaign.typeDetails ||
                        ({} as CampaignTypeDetails<CampaignTypes>)
                ),
                disableDependants: checkIfTypeSectionInvalid(
                    campaign.typeDetails ||
                        ({} as CampaignTypeDetails<CampaignTypes>)
                )
            }
        },
        details: campaign.dcfWizardState?.details?.data?.internalName
            ? campaign.dcfWizardState!.details
            : {
                  data: {
                      internalName: campaign.internalName,
                      generalDetails: campaign.data || []
                  },
                  sectionStatus: {
                      invalid:
                          !campaign.data?.length ||
                          checkIfDetailsStateInvalid({
                              internalName: campaign.internalName,
                              generalDetails: campaign.data
                          }),
                      complete:
                          campaign.data?.length &&
                          checkIfDetailsStateComplete({
                              internalName: campaign.internalName,
                              generalDetails: campaign.data
                          }),
                      disableDependants:
                          !campaign.data?.length ||
                          checkIfDetailsStateInvalid({
                              internalName: campaign.internalName,
                              generalDetails: campaign.data
                          })
                  }
              },
        donations: campaign.dcfWizardState?.donations || {
            data: campaign.paymentVariations || {
                defaultPayment: 0,
                languages: campaign.data
                    ? getEmptyLangDonations(
                          campaign.data.map((item) => item.language)
                      )
                    : []
            },
            sectionStatus: {
                invalid:
                    !campaign?.paymentVariations?.languages ||
                    checkIfDonationStateInvalid(
                        campaign.paymentVariations.languages
                    ),
                complete:
                    campaign?.paymentVariations?.languages &&
                    checkIfDonationStateComplete(
                        campaign.paymentVariations.languages
                    ),
                disableDependants:
                    !campaign?.paymentVariations?.languages ||
                    checkIfDonationStateInvalid(
                        campaign.paymentVariations.languages
                    )
            }
        },
        activate: campaign.dcfWizardState?.activate || {
            data: { active: campaign.isActive },
            sectionStatus: {
                invalid: false,
                complete: false
            }
        }
    } as DcfWizardState;
};

export const mapDcfStateIntoCampaignObject = (
    dcfWizardState: DcfWizardState
) => {
    const campaign: Campaign = {
        defaultLanguage: dcfWizardState?.languages?.data?.defaultLanguage,
        id: dcfWizardState.id,
        internalName:
            dcfWizardState.details.data?.internalName ||
            dcfWizardState.internalName,
        isActive: !!dcfWizardState.activate.data?.active,
        isEdit: dcfWizardState.isEdit,
        isOverlapping: dcfWizardState.isOverlapping,
        isPublished: dcfWizardState.isPublished,
        ooiId: dcfWizardState.ooiId,
        paymentVariations: dcfWizardState.donations.data,
        siteId: dcfWizardState.siteId,
        type: dcfWizardState.type,
        typeDetails: dcfWizardState.campaignType.data?.name
            ? dcfWizardState.campaignType.data
            : undefined,
        data: dcfWizardState.details.data?.generalDetails,
        recommendedLanguages:
            dcfWizardState.languages?.data?.recommendedLanguages || [],
        dcfWizardState
    };
    return campaign;
};
