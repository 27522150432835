import { createAction, props } from '@ngrx/store';
import {
    SiteAdditionalInformation,
    SiteGeneralDetails
} from '../../models/site-wizard-general-details.model';
import { LocaleType } from '../../../shared/locales.models';

export enum GeneralDetailsActionTypes {
    UPDATE_DETAILS = '[SiteWizard] General Update Details',
    UPDATE_INVALID = '[SiteWizard] General Update Invalid',
    ADD_AUDIO = '[SiteWizard] Add audio',
    DELETE_AUDIO = '[SiteWizard] Delete audio',
    ADD_TOPIC = '[SiteWizard] Add Topic',
    DELETE_TOPIC = '[SiteWizard] Delete Topic',
    UPDATE_TOPICS = '[SiteWizard] Update Topics',
    UPDATE_TOPIC = '[SiteWizard] Update Topic',
    GENERAL_COMPLETE = '[SiteWizard] General Complete',
    SET_SELECTED_LANG_TAB = '[SiteWizard] General Set Selected Lang Tab'
}

export const UpdateDetails = createAction(
    GeneralDetailsActionTypes.UPDATE_DETAILS,
    props<{
        language: LocaleType;
        data: SiteGeneralDetails;
        invalid: boolean;
    }>()
);

export const UpdateInvalid = createAction(
    GeneralDetailsActionTypes.UPDATE_INVALID,
    props<{ invalid: boolean }>()
);

export const AddAudio = createAction(
    GeneralDetailsActionTypes.ADD_AUDIO,
    props<{ language: LocaleType; audioUrl: string }>()
);

export const DeleteAudio = createAction(
    GeneralDetailsActionTypes.DELETE_AUDIO,
    props<{ language: LocaleType }>()
);

export const UpdateTopicsDetails = createAction(
    GeneralDetailsActionTypes.UPDATE_TOPICS,
    props<{
        language: LocaleType;
        details: SiteAdditionalInformation[];
        invalid: boolean;
    }>()
);

export const SetGeneralInfoSelectedLangTab = createAction(
    GeneralDetailsActionTypes.SET_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);

export const GeneralComplete = createAction(
    GeneralDetailsActionTypes.GENERAL_COMPLETE
);
