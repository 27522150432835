/**
 * Represents a meta element.
 */
import { Injectable } from '@angular/core';

export type MetaDefinition = {
    charset?: string;
    content?: string;
    httpEquiv?: string;
    id?: string;
    itemprop?: string;
    name?: string;
    property?: string;
    scheme?: string;
    url?: string;
} & {
    [prop: string]: string;
};

/**
 * A service that can be used to get and add meta tags.
 * Modified code from Angular 5 Meta Service -
 * https://github.com/angular/angular/blob/master/packages/platform-browser/src/browser/meta.ts
 */
@Injectable({ providedIn: 'root' })
export class MetaUtilsService {
    addTag(
        tag: MetaDefinition,
        forceCreation: boolean = false
    ): HTMLMetaElement | null {
        if (!tag) {
            return null;
        }
        return this._getOrCreateElement(tag, forceCreation);
    }

    addTags(
        tags: MetaDefinition[],
        forceCreation: boolean = false
    ): HTMLMetaElement[] {
        if (!tags) {
            return [];
        }
        return tags.reduce((result: HTMLMetaElement[], tag: MetaDefinition) => {
            if (tag) {
                result.push(this._getOrCreateElement(tag, forceCreation));
            }
            return result;
        }, []);
    }

    getTag(attrSelector: string): HTMLMetaElement | null {
        if (!attrSelector) {
            return null;
        }
        return document.querySelector(`meta[${attrSelector}]`) || null;
    }

    getTags(attrSelector: string): HTMLMetaElement[] {
        if (!attrSelector) {
            return [];
        }
        const list = document.querySelectorAll(`meta[${attrSelector}]`);
        return list ? [].slice.call(list) : [];
    }

    updateTag(tag: MetaDefinition, selector?: string): HTMLMetaElement | null {
        if (!tag) {
            return null;
        }
        selector = selector || this._parseSelector(tag);
        const meta: HTMLMetaElement = this.getTag(selector)!;
        if (meta) {
            return this._setMetaElementAttributes(tag, meta);
        }
        return this._getOrCreateElement(tag, true);
    }

    removeTag(attrSelector: string): void {
        this.removeTagElement(this.getTag(attrSelector)!);
    }

    removeTagElement(meta: HTMLMetaElement): void {
        if (meta) {
            meta.parentNode?.removeChild(meta);
        }
    }

    private _getOrCreateElement(
        meta: MetaDefinition,
        forceCreation: boolean = false
    ): HTMLMetaElement {
        if (!forceCreation) {
            const selector: string = this._parseSelector(meta);
            const elem: HTMLMetaElement = this.getTag(selector)!;
            // It's allowed to have multiple elements with the same name so
            // it's not enough to just check that element with the same name
            // already present on the page. We also need to check if element
            // has tag attributes
            if (elem && this._containsAttributes(meta, elem)) {
                return elem;
            }
        }
        const element: HTMLMetaElement = document.createElement(
            'meta'
        ) as HTMLMetaElement;
        this._setMetaElementAttributes(meta, element);
        const metaTags = document.getElementsByTagName('meta');
        metaTags[metaTags.length - 1].parentNode?.insertBefore(
            element,
            metaTags[metaTags.length - 1].nextSibling
        );
        return element;
    }

    private _setMetaElementAttributes(
        tag: MetaDefinition,
        el: HTMLMetaElement
    ): HTMLMetaElement {
        Object.keys(tag).forEach((prop: string) =>
            el.setAttribute(prop, tag[prop])
        );
        return el;
    }

    private _parseSelector(tag: MetaDefinition): string {
        const attr: string = tag.name ? 'name' : 'property';
        return `${attr}="${tag[attr]}"`;
    }

    private _containsAttributes(
        tag: MetaDefinition,
        elem: HTMLMetaElement
    ): boolean {
        return Object.keys(tag).every(
            (key: string) => elem.getAttribute(key) === tag[key]
        );
    }
}
