import * as debug from 'debug';
import * as mapboxgl from 'mapbox-gl';
import {
    CanvasSource,
    GeoJSONSource,
    ImageSource,
    LngLat,
    LngLatLike,
    MapboxGeoJSONFeature,
    Popup,
    RasterDemSource,
    RasterSource,
    ResourceType,
    VectorSource,
    VideoSource
} from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turfHelpers from '@turf/helpers';
import polygonKinks from '@turf/kinks';
import polygonArea from '@turf/area';
import {
    getMaskedPolygonLayer,
    getPolygonFillLayer,
    getPolygonOutlineLayer,
    getPolygonSourceData,
    getTrekPathLayer,
    getTrekPointsLayer
} from './layers';
import { EventedMarker } from './EventedMarker';
import { CustomLayersRef } from './CustomLayersRef';
import {
    LatLng,
    LatLon,
    MapInjectedServices,
    SetViewData,
    SourceInfo,
    StylesMap
} from '../typings/mapbox-utils';
import {
    latLngsToPositions,
    latLonsToPositions,
    PositionsToLngLats
} from './helpers';
import { LayerControl } from './LayerControl';
import { MapDrawer } from './MapDrawer';
import { Marker } from './Marker';
import { Path } from './Path';
import { LiveMap } from './LiveMap';
import { HeatMapLayer } from './HeatMapLayer';
import { TranslateService } from '@ngx-translate/core';
import { ElementRef, Renderer2 } from '@angular/core';
import { MapboxEventsService } from '../services/mapbox-events.service';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import { ClusteredSois } from './ClusteredSois';
import { AppState } from '../../store/app-store.model';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { DrawMode } from '@mapbox/mapbox-gl-draw';
import { HttpClient } from '@angular/common/http';
import { NavigationTools } from './NavigationTools';
import { SiteMapStyleTypes } from '../../site-wizard/models/site-wizard-map-setup.model';
import {
    SpotMarkerOptions,
    SpotStatus
} from '../../site-wizard/models/site-wizard-spots.model';

export class MapboxMap {
    private $log = debug('service:mapbox-map');
    private store!: Store<AppState>;
    public translate!: TranslateService;
    private map: mapboxgl.Map;
    private Renderer!: Renderer2;
    private mapboxEventsService!: MapboxEventsService;
    private pendingLayers!: Array<mapboxgl.Layer>;
    private pendingMarkers!: Array<EventedMarker>;
    private pendingSetView?: SetViewData;
    isMapLoaded: boolean;
    private layerControl!: LayerControl;
    private layersRef!: CustomLayersRef;
    private mapStyles: StylesMap;
    private mapEventPrefix = 'map-event:';
    private shouldLoadStyles: boolean;
    private shouldLoadCompass: boolean;
    private hasStationImage!: boolean;
    public live!: LiveMap;
    public marker!: Marker;
    public clusteredSois!: ClusteredSois;
    public mapDrawer!: MapDrawer;
    public heatMapLayer?: HeatMapLayer;
    httpClient!: HttpClient;
    navigationTools!: NavigationTools;
    public EVENTS = {
        SPOT_CLICK: this.mapEventPrefix + 'soi-click',
        SITE_CLICK: this.mapEventPrefix + 'site-click',
        MARKER_CLICK: this.mapEventPrefix + 'marker-click',
        ANCHOR_DRAG: this.mapEventPrefix + 'anchor-drag',
        MARKER_DRAG: this.mapEventPrefix + 'marker-drag',
        POLYLINE_COORD_ADDED: this.mapEventPrefix + 'polyline-coord-added',
        POLYGON_CLICK: this.mapEventPrefix + 'polygon-click',
        STYLE_CHANGE: this.mapEventPrefix + 'style-change',
        SEARCH_MAP_GEOCODER: this.mapEventPrefix + 'map-search-geocoder'
    };
    private transformUrlFunction: mapboxgl.TransformRequestFunction = (
        url: string,
        resourceType: ResourceType
    ) => {
        if (
            resourceType === 'Tile' &&
            url.startsWith(environment.BUSINESS_URL)
        ) {
            return {
                url,
                credentials: 'include'
            };
        } else {
            return { url };
        }
    };

    constructor(
        injectedServices: MapInjectedServices,
        elementId: string,
        shouldLoadDraw: boolean = false,
        shouldLoadStyles: boolean = true,
        shouldLoadCompass: boolean = true
    ) {
        this.unwrapInjectedServices(injectedServices);
        this.mapStyles = this.getMapStyles(this.translate);

        (mapboxgl as any).accessToken = environment.production
            ? 'pk.eyJ1IjoicmVjbnRyZWsiLCJhIjoiY2l5OXVteHl2MDA4czJxc3hwYXkxNTZlNCJ9.3qOpgF1ZyRXCoketBBIsOQ'
            : 'pk.eyJ1IjoicmVjbnRyZWsiLCJhIjoiY2txeXZrbGZmMTloYzMxcWgwdWZwMzRrciJ9.zD_Xl6L1DwPRso8LtqHywQ';

        this.shouldLoadStyles = shouldLoadStyles;
        this.shouldLoadCompass = shouldLoadCompass;
        this.isMapLoaded = false;
        this.map = new mapboxgl.Map({
            container: elementId,
            style: this.mapStyles.OUTDOORS.styleUrl,
            transformRequest: this.transformUrlFunction
        });

        this.resetPending();
        this.initMapHelpers(shouldLoadDraw);

        this.map.on('load', this.loadMap);
        this.map.on('style.load', this.onMapStyleChange);

        // Observable.fromEvent(window, 'resize')
        //   .pipe(debounceTime(250))
        //   .subscribe(() =>
        //     setTimeout(this.invalidateSize.bind(this), 500)
        //   );
    }

    initNavigationTools() {
        this.navigationTools = new NavigationTools(this);
        return this.navigationTools;
    }

    private getMapStyles(translate: TranslateService): StylesMap {
        return {
            LIGHT: {
                styleUrl: 'mapbox://styles/recntrek/cjtpom82q08io1fm3oukfawy2',
                label: translate.instant('CLEAR'),
                name: 'light',
                icon: 'clear'
            },
            OUTDOORS: {
                styleUrl: 'mapbox://styles/recntrek/cj9dy4ho77gp02rmt26h2xerl',
                label: translate.instant('TERRAIN'),
                name: 'outdoors',
                icon: 'terrain'
            },
            SATELLITE: {
                styleUrl: 'mapbox://styles/recntrek/clgeu1kpm000301pcgw7o8tpu',
                label: translate.instant('SATELLITE'),
                name: 'satellite',
                icon: 'satellite'
            }
        };
    }

    private unwrapInjectedServices(
        injectedServices: MapInjectedServices
    ): void {
        this.translate = injectedServices.Translate;
        this.mapboxEventsService = injectedServices.MapboxEventsService;
        this.store = injectedServices.StoreService;
        this.Renderer = injectedServices.Renderer;
        this.httpClient = injectedServices.httpClient;
    }

    private initMapHelpers(shouldLoadDraw: boolean): void {
        this.clusteredSois = new ClusteredSois(this, this.map, this.store);
        this.marker = new Marker(this);
        this.live = new LiveMap(
            this,
            this.map,
            this.translate.instant('WISHTRIP_SITES')
        );

        this.layersRef = new CustomLayersRef();
        this.layerControl = new LayerControl(
            this.translate.instant('MAP_DISPLAY')
        );

        this.layerControl.addStyles(
            [
                this.mapStyles.OUTDOORS,
                this.mapStyles.LIGHT,
                this.mapStyles.SATELLITE
            ],
            this.translate.instant('MAP_TYPE'),
            this.translate.instant('MAP_DISPLAY_NOTE')
        );

        if (shouldLoadDraw) {
            this.mapDrawer = new MapDrawer(this);
            this.mapDrawer.createMapboxDrawer(this.map);
        }
    }

    changeCursorStyle(cursorStyle: any): void {
        this.map.on('mousemove', () => {
            this.map.getCanvas().style.cursor = cursorStyle;
        });
    }

    addGeocoderToMap(element: ElementRef): void {
        const geo = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            placeholder: this.translate.instant('SEARCH'),
            trackProximity: true
        }).on('result', () => {
            this.notifyMapSearchGeocode();
        });
        this.Renderer.appendChild(element.nativeElement, geo.onAdd(this.map));
    }

    getLayerControl(): LayerControl {
        return this.layerControl;
    }

    getNewPath(layerId: string, start: LatLon): Path {
        return new Path(layerId, start);
    }

    private resetPending(): void {
        this.pendingLayers = [];
        this.pendingMarkers = [];
        this.pendingSetView = undefined;
    }

    addSource(id: string, source: mapboxgl.AnySourceData): void {
        this.layersRef.addSource({ id, source });
        if (!this.isMapLoaded) {
            return;
        }
        this.map.addSource(id, source);
    }

    addLayer(layer: mapboxgl.Layer): void {
        this.layersRef.addLayer(layer);
        if (!this.isMapLoaded) {
            return;
        }
        this.map.addLayer(layer);
    }

    removeSource(source: SourceInfo): void {
        this.layersRef.removeSource(source);
        this.map.removeSource(source.id);
    }

    removeLayer(layer: mapboxgl.Layer): void {
        this.layersRef.removeLayer(layer);
        this.map.removeLayer(layer.id);
    }

    private getBounds(coordinates: Position[]): mapboxgl.LngLatBounds {
        const positions = PositionsToLngLats(coordinates);
        return positions.reduce(
            (bounds: mapboxgl.LngLatBounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds()
        );
    }

    togglePolygonClickable(clickable: boolean): void {
        if (clickable) {
            this.map.on('click', (event) => this.notifyPolygonClick(event));
        } else {
            this.map.off('click', (event) => this.notifyPolygonClick(event));
        }
    }

    fitBounds(
        coordinates: Position[],
        options?: mapboxgl.FitBoundsOptions
    ): void {
        const bounds = this.getBounds(coordinates);
        options = {
            ...options,
            padding: options?.padding ? options.padding : 80,
            duration: options?.duration ? options.duration : 0
        };
        this.map.fitBounds(bounds, options);
    }

    invalidateSize(): void {
        this.map.resize();
    }

    setView({ lat, lng, zoom }: SetViewData): void {
        if (!this.isMapLoaded) {
            this.pendingSetView = { lat, lng, zoom };
            return;
        }

        this.map.easeTo({
            center: [lng, lat],
            zoom,
            pitch: 0,
            bearing: 0
        });
    }

    getSource(
        sourceId: string
    ):
        | VectorSource
        | RasterSource
        | RasterDemSource
        | GeoJSONSource
        | ImageSource
        | VideoSource
        | CanvasSource {
        return this.map.getSource(sourceId);
    }

    isSourceLoaded(sourceId: string): boolean {
        return this.map.isSourceLoaded(sourceId);
    }

    querySourceFeatures(
        sourceId: string,
        parameters?: { sourceLayer?: string; filter?: any[] }
    ): MapboxGeoJSONFeature[] {
        return this.map.querySourceFeatures(sourceId, parameters);
    }

    getPaintProperty(layer: string, name: string) {
        return this.map.getPaintProperty(layer, name);
    }

    setSourceData(id: string, data: FeatureCollection) {
        this.layersRef.setSourceData(id, data);
        const source = this.map.getSource(id);
        if (source) {
            (source as mapboxgl.GeoJSONSource).setData(data);
        }
    }

    getCenter(): LngLat {
        return this.map.getCenter();
    }

    getZoom(): number {
        return this.map.getZoom();
    }

    on(type: string, listener: (event?: any) => void, layerId?: string): void {
        if (layerId) {
            this.map.on(type as any, layerId, listener);
        } else {
            this.map.on(type, listener);
        }
    }

    removeSelectedPoints() {
        return this.mapDrawer.deleteSelectedPoints();
    }

    off(type: string, listener: (event?: any) => void, layerId?: string): void {
        if (layerId) {
            this.map.off(type as any, layerId, listener);
        } else {
            this.map.off(type, listener);
        }
    }

    drawPolygon(
        layerId: string,
        polygon: Polygon,
        color?: string,
        outlineOpacity?: number,
        fillOpacity?: number
    ): mapboxgl.Layer[] | void {
        if (
            this.map.getSource(`${layerId}-fill`) ||
            this.map.getSource(`${layerId}-outline`)
        ) {
            (this.map.getSource(`${layerId}-fill`) as GeoJSONSource).setData(
                getPolygonSourceData(polygon.coordinates)
            );
            (this.map.getSource(`${layerId}-outline`) as GeoJSONSource).setData(
                getPolygonSourceData(polygon.coordinates)
            );
            return;
        }

        const polygonFillLayer = getPolygonFillLayer(
            `${layerId}-fill`,
            polygon,
            color,
            fillOpacity
        );
        const polygonOutlineLayer = getPolygonOutlineLayer(
            `${layerId}-outline`,
            polygon,
            color,
            outlineOpacity
        );
        const layersArray = [polygonFillLayer, polygonOutlineLayer];

        if (!this.isMapLoaded) {
            this.pendingLayers.forEach((layer, index) => {
                if (
                    layer.id === polygonFillLayer.id ||
                    layer.id === polygonOutlineLayer.id
                ) {
                    delete this.pendingLayers[index];
                }
            });
            this.pendingLayers.push(polygonFillLayer, polygonOutlineLayer);
            return layersArray;
        }

        this.addLayer(polygonFillLayer);
        this.addLayer(polygonOutlineLayer);

        return layersArray;
    }

    drawMaskedPolygonLayer(coordinates: Array<LatLon>): any {
        const positions = latLonsToPositions(coordinates);
        const polygon = turfHelpers.polygon([positions]);
        const kinks = polygonKinks(polygon);
        if (kinks.features.length) {
            return;
        }

        const polygonMaskedLayer = getMaskedPolygonLayer(polygon);
        if (!this.isMapLoaded) {
            this.pendingLayers.push(polygonMaskedLayer);
            return polygonMaskedLayer;
        }

        this.addLayer(polygonMaskedLayer);
        return polygonMaskedLayer;
    }

    deletePolygon(layerId: any): void {
        if (!this.isMapLoaded) {
            return;
        }
        this.deleteSourceAndLayer(`${layerId}-outline`, `${layerId}-outline`);
        this.deleteSourceAndLayer(`${layerId}-fill`, `${layerId}-fill`);
    }

    drawTrekPath(
        layerId: string,
        coordinates: Array<LatLon>,
        geoJson?: any,
        color?: string,
        thickness?: number,
        opacity?: number
    ): void {
        this.deleteTrekPath(layerId);
        const trekPathLayer = getTrekPathLayer(
            layerId,
            coordinates,
            geoJson,
            color,
            thickness,
            opacity
        );

        if (!this.isMapLoaded) {
            this.pendingLayers.forEach((layer, index) => {
                if (layer.id === layerId) {
                    delete this.pendingLayers[index];
                }
            });

            this.pendingLayers.push(trekPathLayer);
            return;
        }
        this.addLayer(trekPathLayer);
    }

    deleteTrekPath(layerId: string): void {
        if (this.map.getSource(layerId)) {
            if (!this.isMapLoaded) {
                return;
            }
            this.removeLayer(this.map.getLayer(layerId));
            this.map.removeSource(layerId);
        }
    }

    setPaintProperty(
        layerId: string,
        name: string,
        value: string | number
    ): void {
        if (!this.isMapLoaded) {
            return;
        }
        this.map.setPaintProperty(layerId, name, value);
    }

    drawTrekCoords(layerId: string, coordinates: Array<any>): void {
        const coords = coordinates.map((coord, index) => ({
            coordinate: [coord.longitude, coord.latitude],
            properties: {
                timestamp: coord.timestamp,
                latitude: coord.latitude,
                longitude: coord.longitude,
                icon: 'marker',
                title:
                    index === 0
                        ? 'Start Trek'
                        : index === coordinates.length - 1
                        ? 'End Trek'
                        : ''
            }
        }));
        this.hasStationImage = true;
        setTimeout(() => {
            const trekPathCoordsLayer = getTrekPointsLayer(layerId, coords);
            if (!this.isMapLoaded) {
                this.pendingLayers.push(trekPathCoordsLayer);
                return;
            }
            this.addLayer(trekPathCoordsLayer);
        }, 700);
    }

    changeCursorToStyle(style?: string): void {
        this.map.getCanvas().style.cursor = style || '';
    }

    getPolygonArea(coords: LatLng[]): number {
        const positions = latLngsToPositions(coords);
        const polygon = turfHelpers.polygon([positions]);
        const area = polygonArea(polygon) / 1000;
        return Math.round(area);
    }

    drawSpotMarker(
        lat: number,
        lon: number,
        soiId: string | number,
        name?: string,
        layer?: any,
        options: SpotMarkerOptions = {}
    ): any {
        const defaultOptions: SpotMarkerOptions = {
            spotStatus: SpotStatus.COMPLETE,
            draggable: false,
            clickable: true
        };
        options = {
            ...defaultOptions,
            ...options
        };
        return this.marker.addSpotMarker(
            this.map,
            {
                loc: [lat, lon],
                name,
                id: soiId,
                urls: [{ url: '', type_id: 0, type: '' }]
            },
            options
        );
    }

    drawToiMarker(
        lat: number,
        lon: number,
        toiId: string,
        layer?: any
    ): EventedMarker {
        return this.marker.addToiMarker(this.map, toiId, [lat, lon]);
    }

    removeSoiMarker(soiId: number): any {
        return this.marker.removeSoiMarker(soiId);
    }

    removeToiMarker(toiId: string): any {
        return this.marker.removeToiMarker(toiId);
    }

    setStationsDraggable(draggable: boolean): void {
        this.marker.setDraggableStationMarker(draggable);
    }

    clearStationMarkers(): any {
        return this.marker.removeStationMarkers();
    }

    drawTrekStartMarker(coordinate: mapboxgl.LngLat): EventedMarker {
        const marker = this.marker.trekStart(coordinate);

        if (!this.isMapLoaded) {
            this.pendingMarkers.push(marker);
            return marker;
        }

        return marker.addTo(this.map);
    }

    drawTrekEndMarker(coordinate: mapboxgl.LngLat): EventedMarker {
        const marker = this.marker.trekEnd(coordinate);

        if (!this.isMapLoaded) {
            this.pendingMarkers.push(marker);
            return marker;
        }
        return marker.addTo(this.map);
    }

    drawLiveUserMarker(coordinate: mapboxgl.LngLat, userId: string): any {
        return this.marker.addLiveUserMarker(this.map, coordinate, userId);
    }

    removeLiveUserMarker(userId: string): any {
        return this.marker.removeLiveUserMarker(userId);
    }

    drawAnchorPoint(anchorPoint: LatLon, draggable: boolean = false): void {
        this.marker.addAnchorPointMarker(this.map, anchorPoint, draggable);
    }

    drawDeletePolygonMarker(latLon: LatLon, onClick: () => void) {
        this.marker.addDeletePolygonMarker(this.map, latLon, onClick);
    }

    drawDeleteAnchorPointMarker(latLon: LatLon, onClick: () => void) {
        this.marker.addDeleteAnchorPointMarker(this.map, latLon, onClick);
    }

    drawDeleteTrekMarker(latLon: LatLon, onClick: () => void) {
        this.marker.addDeleteTrekMarker(this.map, latLon, onClick);
    }

    clearAnchorPointMarker(): any {
        return this.marker.clearAnchorPointMarker();
    }

    toggleEntryPointMarkers(enable: boolean): any {
        return this.marker.toggleEntryPointMarkersOpacity(enable, this.map);
    }

    toggleSoiMarkersOpacity(enable: boolean): any {
        return this.marker.toggleSpotMarkersOpacity(enable, this.map);
    }

    toggleToiMarkerOpacity(toiId: string, enabled: boolean): void {
        this.marker.toggleToiMarkerOpacity(toiId, enabled);
    }

    clearSpotMarkers(): void {
        return this.marker.clearSpotMarkers();
    }

    clearAllMarkers(): void {
        return this.marker.clearAllMarkers();
    }

    setDrawerPolygon(polygon: Polygon): void {
        this.mapDrawer.setDrawerPolygon(polygon);
    }

    getDrawerPolygonArea(): number {
        return this.mapDrawer.getDrawerPolygonArea();
    }

    deleteDrawerElements(): any {
        return this.mapDrawer.deleteDrawerElements();
    }

    getDrawerMode(): any {
        return this.mapDrawer.getMode();
    }

    changeDrawerMode(mode: DrawMode): any {
        this.$log('changeDrawerMode', mode);
        this.mapDrawer.changeMode(mode);
    }

    setDrawerFeature(route: Feature): any {
        return this.mapDrawer.setDrawerFeature(route);
    }

    createHeatMapLayer(
        density: any, // DensityPoint[],
        min: number,
        max: number,
        type: string
    ): void {
        if (!this.heatMapLayer) {
            this.heatMapLayer = new HeatMapLayer(this);
        }
        this.heatMapLayer.createHeatmapLayer(this.map, density, min, max, type);
    }

    removeHeatmap(): any {
        if (!this.heatMapLayer) {
            return;
        }
        this.heatMapLayer = undefined;
        this.map.removeLayer('density');
        this.map.removeSource('density');
    }

    private loadMap = () => {
        this.isMapLoaded = true;
        this.pendingLayers.forEach((layer) => this.addLayer(layer));
        this.pendingMarkers.forEach((pendingMarker) =>
            pendingMarker.addTo(this.map)
        );

        if (this.pendingSetView) {
            this.setView(this.pendingSetView);
        }

        this.resetPending();

        if (this.shouldLoadStyles) {
            this.map.addControl(this.layerControl);
        }
        this.map.addControl(
            new mapboxgl.ScaleControl({ unit: 'imperial' }),
            'bottom-right'
        );
        this.map.addControl(
            new mapboxgl.ScaleControl({ unit: 'metric' }),
            'bottom-right'
        );
        this.map.addControl(
            new mapboxgl.NavigationControl({
                showCompass: this.shouldLoadCompass
            }),
            'top-right'
        );
    };

    private onMapStyleChange = () => {
        if (!this.isMapLoaded) {
            return;
        }

        if (this.pendingSetView) {
            this.setView(this.pendingSetView);
        }

        const loadedStyleName = this.layerControl.getSelectedStyle().name;
        this.notifyMapStyleChange(loadedStyleName);
        this.updateMapClassBasedOnStyle(loadedStyleName);
        this.restoreLayersOnStyleChange(loadedStyleName);
    };

    private restoreLayersOnStyleChange = (loadedStyleName: any) => {
        Object.entries(this.layersRef.getSources()).forEach((sourceInfo) => {
            this.map.addSource(sourceInfo[0], sourceInfo[1]);
        });
        this.layersRef.getLayers().forEach((layer) => {
            if (layer.id.indexOf('trek-path-layer') >= 0) {
                (layer.paint as any)['line-color'] =
                    loadedStyleName === this.mapStyles.SATELLITE.name
                        ? '#f9c70a'
                        : '#403C3E';
            }

            this.map.addLayer(layer);
        });
    };

    private updateMapClassBasedOnStyle = (styleName: string) => {
        const mapContainer = this.map.getContainer();
        const styleClass = this.mapStyles.SATELLITE.name;
        if (styleName === styleClass) {
            this.Renderer.addClass(mapContainer, styleClass);
        } else {
            this.Renderer.removeClass(mapContainer, styleClass);
        }
    };

    notify(event: string, ...eventData: any[]): any {
        eventData.unshift(event);
        this.mapboxEventsService.changeEvent(eventData);
    }

    notifyMapStyleChange = (styleLabel: string) => {
        this.$log('map notifyMapStyleChange', styleLabel);
        this.notify(this.EVENTS.STYLE_CHANGE, styleLabel);
    };

    notifySpotMarkerClick = (soiId: number) => {
        this.$log('map notifySpotMarkerClick', soiId);
        this.notify(this.EVENTS.SPOT_CLICK, soiId);
    };

    notifySiteMarkerClick = (siteId: number) => {
        this.$log('map notifySiteMarkerClick', siteId);
        this.notify(this.EVENTS.SITE_CLICK, siteId);
    };

    notifyAnchorDrag = (event: any) => {
        this.$log('map notifyMarkerDrag', event);
        this.notify(
            this.EVENTS.ANCHOR_DRAG,
            event.target._lngLat,
            event.target.properties
        );
    };

    notifyMarkerDrag = (event: any) => {
        this.$log('map notifyMarkerDrag', event);
        this.notify(
            this.EVENTS.MARKER_DRAG,
            event.target._lngLat,
            event.target.properties
        );
    };

    notifyPolygonClick = (event: any) => {
        this.$log('map notifyPolygonClick', event);
        this.notify(this.EVENTS.POLYGON_CLICK, event.lngLat);
    };

    notifyMapSearchGeocode = () => {
        this.$log('map notifyMapSearchGeocoder');
        this.notify(this.EVENTS.SEARCH_MAP_GEOCODER);
    };

    changeToSelectedMapStyle(mapStyle?: SiteMapStyleTypes) {
        this.layerControl.changeSelectedStyle(mapStyle!, this.map);
    }

    addPopup(lngLat: LngLatLike, htmlContent: string): Popup {
        const popup = new Popup({ closeButton: false });
        popup.addTo(this.map);
        popup.setLngLat(lngLat);
        popup.setHTML(htmlContent);
        return popup;
    }

    deleteSourceAndLayer(sourceId: string, layerId: string) {
        const source = this.map.getSource(sourceId);
        if (source) {
            if (!this.isMapLoaded) {
                return;
            }
            this.removeLayer(this.map.getLayer(layerId));
            this.removeSource({ id: sourceId, source });
        }
    }

    getLatLonFromCoordinates(coordinates: Position): LatLon {
        return { lat: coordinates[1], lon: coordinates[0] };
    }
}
