import { createAction, props } from '@ngrx/store';
import { TrekDetails } from '../../models/trek-wizard-details.model';

export enum MapActionTypes {
    DETAILS_UPDATE = '[trekWizard] Details Update',
    DETAILS_COMPLETE = '[trekWizard] Details Complete'
}

export const UpdateDetails = createAction(
    MapActionTypes.DETAILS_UPDATE,
    props<{ details: TrekDetails; invalid: boolean }>()
);

export const DetailsComplete = createAction(MapActionTypes.DETAILS_COMPLETE);
