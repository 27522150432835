import { createAction, props } from '@ngrx/store';
import { GameChallengesSectionData } from '../../models/game-wizard-challenges.model';
import {
    ChallengeAnswer,
    ChallengeDisplayTypes
} from '../../../games/models/challenge.interface';
import { LocaleType } from '../../../shared/locales.models';
import { GameMedia } from '../../../games/models/game.interface';
import { ChallengesDialogStages } from '../../pages/game-challenge-dialog/game-challenges-dialog.const';
import { NavigationModeTypes } from '../../../standalone/components/navigation-settings/navigation-settings.model';

export enum GameWizardChallengesActionTypes {
    SET_CHALLENGES = '[GameWizard]: Set Game Challenges',
    SELECT_CHALLENGE = '[GameWizard]: Select Challenge',
    UNSELECT_CHALLENGE = '[GameWizard]: Unselect Challenge',
    DELETE_CHALLENGE = '[GameWizard]: Delete Challenge',
    ORDER_CHALLENGES = '[GameWizard]: OrderChallenges',
    UPDATE_CHALLENGE_TITLE = '[GameWizard]: Update Challenge Title',
    UPDATE_CHALLENGE_DESCRIPTION = '[GameWizard]: Update Challenge Description',
    UPDATE_CHALLENGE_ANSWERS = '[GameWizard]: Update Challenge Answers',
    UPDATE_CHALLENGE_QUESTION = '[GameWizard]: Update Challenge Question',
    UPDATE_POST_CHALLENGE_DESCRIPTION = '[GameWizard]: Update   post Challenge Description',
    ADD_CHALLENGE_MEDIA = '[GameWizard] Add Challenge Media',
    ORDER_CHALLENGE_MEDIA = '[GameWizard] Order Challenge Media',
    DELETE_CHALLENGE_MEDIA = '[GameWizard] Delete Challenge Media',
    ADD_POST_CHALLENGE_MEDIA = '[GameWizard] Add Post Challenge Media',
    ORDER_POST_CHALLENGE_MEDIA = '[GameWizard] Order Post Challenge Media',
    DELETE_POST_CHALLENGE_MEDIA = '[GameWizard] Delete Post Challenge Media',
    SET_CHALLENGE_DIALOG_STAGE = '[GameWizard]: Set Challenge Dialog Stage',
    SET_CHALLENGE_TYPE = '[GameWizard]: Set Challenge Type',
    UPDATE_CHALLENGE_RADIUS = '[GameWizard]: Update Challenge Radius',
    UPDATE_CHALLENGE_NAVIGATION_MODE = '[GameWizard]: Update Challenge Navigation Mode',
    SET_CHALLENGE_DETAILS_SELECTED_LANG_TAB = '[GameWizard]: Set Challenge Details Selected Lang Tab',
    SET_POST_CHALLENGE_SELECTED_LANG_TAB = '[GameWizard]: Set Post Challenge Details Selected Lang Tab'
}

export const SetGameChallenges = createAction(
    GameWizardChallengesActionTypes.SET_CHALLENGES,
    props<{ challenges: GameChallengesSectionData }>()
);

export const SelectChallenge = createAction(
    GameWizardChallengesActionTypes.SELECT_CHALLENGE,
    props<{ spotId: string }>()
);

export const UnSelectChallenge = createAction(
    GameWizardChallengesActionTypes.UNSELECT_CHALLENGE
);

export const DeleteChallenge = createAction(
    GameWizardChallengesActionTypes.DELETE_CHALLENGE,
    props<{ spotId: string }>()
);

export const OrderChallenges = createAction(
    GameWizardChallengesActionTypes.ORDER_CHALLENGES,
    props<{
        previousIndex: number;
        currentIndex: number;
    }>()
);

export const SetChallengeDialogStage = createAction(
    GameWizardChallengesActionTypes.SET_CHALLENGE_DIALOG_STAGE,
    props<{ challengeDialogStage: ChallengesDialogStages }>()
);

export const SetChallengeType = createAction(
    GameWizardChallengesActionTypes.SET_CHALLENGE_TYPE,
    props<{ challengeDisplayType: ChallengeDisplayTypes }>()
);

export const UpdateChallengeTitle = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_TITLE,
    props<{ local: LocaleType; title: string }>()
);
export const UpdateChallengeDescription = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_DESCRIPTION,
    props<{ description: string; local: LocaleType }>()
);
export const UpdateChallengeAnswers = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_ANSWERS,
    props<{ local: LocaleType; answers: ChallengeAnswer[] }>()
);
export const UpdateChallengeQuestion = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_QUESTION,
    props<{ local: LocaleType; question: string }>()
);
export const UpdatePostChallengeDescription = createAction(
    GameWizardChallengesActionTypes.UPDATE_POST_CHALLENGE_DESCRIPTION,
    props<{ local: LocaleType; postChallengeDescription: string }>()
);

export const AddGameChallengeMedia = createAction(
    GameWizardChallengesActionTypes.ADD_CHALLENGE_MEDIA,
    props<{ path: string; local: LocaleType; defaultLang: LocaleType }>()
);

export const OrderGameChallengeMedia = createAction(
    GameWizardChallengesActionTypes.ORDER_CHALLENGE_MEDIA,
    props<{ media: GameMedia[]; local: LocaleType; defaultLang: LocaleType }>()
);

export const DeleteGameChallengeMedia = createAction(
    GameWizardChallengesActionTypes.DELETE_CHALLENGE_MEDIA,
    props<{ index: number; local: LocaleType; defaultLang: LocaleType }>()
);

export const AddGamePostChallengeMedia = createAction(
    GameWizardChallengesActionTypes.ADD_POST_CHALLENGE_MEDIA,
    props<{ path: string; local: LocaleType; defaultLang: LocaleType }>()
);

export const OrderGamePostChallengeMedia = createAction(
    GameWizardChallengesActionTypes.ORDER_POST_CHALLENGE_MEDIA,
    props<{ media: GameMedia[]; local: LocaleType; defaultLang: LocaleType }>()
);

export const DeleteGamePostChallengeMedia = createAction(
    GameWizardChallengesActionTypes.DELETE_POST_CHALLENGE_MEDIA,
    props<{ index: number; local: LocaleType; defaultLang: LocaleType }>()
);

export const UpdateChallengeRadius = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_RADIUS,
    props<{ radius: number }>()
);

export const UpdateChallengeNavigationMode = createAction(
    GameWizardChallengesActionTypes.UPDATE_CHALLENGE_NAVIGATION_MODE,
    props<{ navigationMode: NavigationModeTypes }>()
);

export const setChallengeDetailsSelectedLangTab = createAction(
    GameWizardChallengesActionTypes.SET_CHALLENGE_DETAILS_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
export const setPostChallengeSelectedLangTab = createAction(
    GameWizardChallengesActionTypes.SET_POST_CHALLENGE_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
