import { SiteCard } from '../sites/models/site.model';

export interface UserResponseOld {
    email: string;
    name: string;
    profile_pic: string;
    user_id: string;
}

export interface UserResponse {
    name: string;
    email: string;
    id: string;
    profileImageUrl: string;
}

export interface CreateUserResponse extends UserResponseOld {
    password: string;
}

export interface UserTableModel extends UserResponse {
    type?: string[];
    password?: string;
}

export interface ResetPasswordResponse {
    user_id: string;
    password: string;
}

export interface UserPermissionsResponse {
    userId: string;
    resourceId: string;
    roles: string[];
}

export enum USER_PERMISSIONS {
    B_ADMIN = 'B_ADMIN',
    TREK_CREATOR = 'TREK_CREATOR',
    S_ADMIN = 'S_ADMIN',
    SITE_EDITOR = 'SITE_EDITOR',
    SEND_SMS = 'SEND_SMS',
    LIVE_MAP = 'LIVE_MAP',
    ALERTS = 'ALERTS',
    STATISTICS = 'STATISTICS',
    SETTINGS_EDITOR = 'SETTINGS_EDITOR',
    GAME = 'GAME',
    SHARE = 'SHARE',
    RATING = 'RATING',
    CAMPAIGN_CREATOR = 'CAMPAIGN_CREATOR'
}

export enum USER_ACTIONS {
    VIEW_SITE = 'VIEW_SITE',
    EDIT_SITE = 'EDIT_SITE',
    SEND_SMS = 'SEND_SMS',
    ALERTS = 'ALERTS',
    LIVE_MAP = 'LIVE_MAP',
    STATISTICS = 'STATISTICS',
    EDIT_GAME = 'EDIT_GAME',
    VIEW_RATES = 'VIEW_RATES',
    CREATE_TREK = 'CREATE_TREK',
    CREATE_SHARE_LINK = 'CREATE_SHARE_LINK',
    CREATE_USER = 'CREATE_USER',
    EDIT_BUSINESS = 'EDIT_BUSINESS',
    CREATE_SITE = 'CREATE_SITE',
    CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
    EDIT_SETTINGS = 'EDIT_SETTINGS'
}

export type BusinessPermissionType =
    | USER_PERMISSIONS.B_ADMIN
    | USER_PERMISSIONS.TREK_CREATOR;

export type BusinessPermissionDescriptionsMap = {
    [type in BusinessPermissionType]: { title: string; description?: string };
};

export class BusinessPermission {
    constructor(
        public type: BusinessPermissionType,
        public title: string,
        public model: boolean
    ) {}
}

export type BusinessPermissionMap = {
    [type in BusinessPermissionType]: boolean;
};

export type SitePermissionType =
    | USER_PERMISSIONS.S_ADMIN
    | USER_PERMISSIONS.SITE_EDITOR
    | USER_PERMISSIONS.SEND_SMS
    | USER_PERMISSIONS.LIVE_MAP
    | USER_PERMISSIONS.ALERTS
    | USER_PERMISSIONS.STATISTICS
    | USER_PERMISSIONS.SETTINGS_EDITOR
    | USER_PERMISSIONS.GAME
    | USER_PERMISSIONS.SHARE
    | USER_PERMISSIONS.RATING
    | USER_PERMISSIONS.CAMPAIGN_CREATOR;

export type SitePermissionDescriptionMap = {
    [type in SitePermissionType]: { title: string; description?: string };
};

export type SitePermissionMap = { [type in SitePermissionType]: boolean };

export class SitePermission {
    constructor(public siteData: SiteCard, public model: SitePermissionMap) {}
}

export type UpdatedPermissions = {
    [resourceType: string]: {
        [id: string]: {
            [type: string]: boolean;
        };
    };
};

export interface UpdatedSitePermissions {
    resourceId: string;
    type: string;
    permissions: {
        role: string;
        value: boolean;
    }[];
}

export class UpdatedUserPermissions {
    constructor(
        public userId: string,
        public resource: UpdatedUserResource,
        public updatedPermissions: UpdatedUserResourcePermission[]
    ) {}
}

export class UpdatedUserResource {
    constructor(public type: string, public id: string) {}
}

export class UpdatedUserResourcePermission {
    constructor(public role: string, public value: boolean) {}
}

export class PermissionsModel {
    business!: { [type in BusinessPermissionType]: boolean };
    site: {
        [siteId: string]: { [type in SitePermissionType]: boolean };
    };

    constructor(
        businessPermissions: BusinessPermission[],
        sitePermissions: SitePermission[]
    ) {
        (this.business as any) = {};
        this.site = {};

        businessPermissions.forEach(
            (permission) => (this.business[permission.type] = permission.model)
        );
    }
}

export const BusinessPermissionDescriptions: BusinessPermissionDescriptionsMap =
    {
        [USER_PERMISSIONS.B_ADMIN]: {
            title: 'USER_ADMIN',
            description: 'USER_ADMIN_DESCRIPTION'
        },
        [USER_PERMISSIONS.TREK_CREATOR]: {
            title: 'USER_TREK_CREATOR',
            description: 'USER_TREK_CREATOR_DESCRIPTION'
        }
    };

export const sitePermissionsDescriptions: SitePermissionDescriptionMap = {
    [USER_PERMISSIONS.S_ADMIN]: {
        title: 'USER_SITE_ADMIN'
    },
    [USER_PERMISSIONS.SITE_EDITOR]: {
        title: 'USER_EDIT_SITE',
        description: 'USER_EDIT_SITE_DESCRIPTION'
    },
    [USER_PERMISSIONS.SEND_SMS]: {
        title: 'USER_SEND_SMS',
        description: 'USER_SEND_SMS_DESCRIPTION'
    },
    [USER_PERMISSIONS.LIVE_MAP]: {
        title: 'USER_LIVE_MAP',
        description: 'USER_LIVE_MAP_DESCRIPTION'
    },
    [USER_PERMISSIONS.ALERTS]: {
        title: 'USER_ALERTS',
        description: 'USER_ALERTS_DESCRIPTION'
    },
    [USER_PERMISSIONS.STATISTICS]: {
        title: 'USER_STATISTIC',
        description: 'USER_STATISTICS_DESCRIPTION'
    },
    [USER_PERMISSIONS.SETTINGS_EDITOR]: {
        title: 'USER_CHAT_EDITOR',
        description: 'USER_CHAT_EDITOR_DESCRIPTION'
    },
    [USER_PERMISSIONS.GAME]: {
        title: 'USER_GAME',
        description: 'USER_GAME_DESCRIPTION'
    },
    [USER_PERMISSIONS.SHARE]: {
        title: 'USER_SHARE',
        description: 'USER_SHARE_DESCRIPTION'
    },
    [USER_PERMISSIONS.RATING]: {
        title: 'USER_RATING',
        description: 'USER_RATING_DESCRIPTION'
    },
    [USER_PERMISSIONS.CAMPAIGN_CREATOR]: {
        title: 'USER_CAMPAIGN',
        description: 'USER_CAMPAIGN_DESCRIPTION'
    }
};
