import { environment } from '@env/environment';

import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails,
    WizardSectionStatus
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import { NavigationModeTypes } from '../../standalone/components/navigation-settings/navigation-settings.model';
import {
    TrekWizardMode,
    TrekWizardStages,
    TrekWizardState
} from '../store/trek-wizard.model';

export const trekWizardStageDetails: MapWizardStageDetails<TrekWizardStages> =
    new Map<TrekWizardStages, WizardSectionDetails<TrekWizardStages>>([
        [
            TrekWizardStages.CREATE_ROUTE,
            {
                stage: TrekWizardStages.CREATE_ROUTE,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'TREK_WIZARD_GENIE_MAP',
                genieVideoUrl: 'PaQWJU1Ruzw',
                navBarTextKey: 'CREATE_ROUTE',
                icon: ' WTicon-trek-menu-draw',
                pageLayoutStatus: WizardPageLayout.FULL_SCREEN,
                hidePreviousBtn: true
            }
        ],
        [
            TrekWizardStages.DETAILS_OPTIONS,
            {
                stage: TrekWizardStages.DETAILS_OPTIONS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                navBarTextKey: 'DETAILS',
                icon: 'WTicon-trek-menu-details',
                children: [TrekWizardStages.DETAILS, TrekWizardStages.TAGS]
            }
        ],
        [
            TrekWizardStages.DETAILS,
            {
                stage: TrekWizardStages.DETAILS,
                titleKey: 'TREK_WIZARD_TITLE_DETAILS',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_DETAILS',
                genieTextKey: 'TREK_WIZARD_GENIE_DETAILS',
                genieVideoUrl: 'Q5qjv1saUtQ',
                navBarTextKey: 'GENERAL',
                icon: ' WTicon-trek-menu-data',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                dependantOn: [TrekWizardStages.CREATE_ROUTE]
            }
        ],
        [
            TrekWizardStages.TAGS,
            {
                stage: TrekWizardStages.TAGS,
                titleKey: 'TREK_WIZARD_TITLE_TAGS',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_TAGS',
                genieTextKey: 'TREK_WIZARD_GENIE_TAGS',
                genieVideoUrl: '',
                navBarTextKey: 'TAGS',
                icon: 'WTicon-trek-menu-tags',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS,
                dependantOn: [TrekWizardStages.CREATE_ROUTE]
            }
        ],
        [
            TrekWizardStages.STATIONS,
            {
                stage: TrekWizardStages.STATIONS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: 'TREK_WIZARD_GENIE_STATIONS',
                genieVideoUrl: '48AkmoN02w8',
                navBarTextKey: 'STATIONS',
                icon: 'WTicon-trek-menu-stations',
                pageLayoutStatus: WizardPageLayout.FULL_SCREEN,
                dependantOn: [TrekWizardStages.CREATE_ROUTE]
            }
        ],
        [
            TrekWizardStages.COVER_IMAGE,
            {
                stage: TrekWizardStages.COVER_IMAGE,
                titleKey: 'TREK_WIZARD_TITLE_COVER_MEDIA',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_COVER_MEDIA',
                genieTextKey: 'TREK_WIZARD_GENIE_COVER_MEDIA',
                genieVideoUrl: '',
                navBarTextKey: 'COVER_MEDIA',
                icon: 'WTicon-trek-menu-media',
                pageLayoutStatus: WizardPageLayout.THREE_QUARTERS_SCREEN
            }
        ],
        [
            TrekWizardStages.PUBLISH,
            {
                stage: TrekWizardStages.PUBLISH,
                titleKey: 'TREK_WIZARD_TITLE_PUBLISH',
                descriptionKey: '',
                genieTextKey: 'TREK_WIZARD_GENIE_PUBLISH',
                genieVideoUrl: 'B1E3VidwMH4',
                navBarTextKey: 'PUBLISH',
                icon: 'WTicon-trek-menu-publish',
                pageLayoutStatus: WizardPageLayout.HALF_SCREEN,
                hideNextBtn: true
            }
        ]
    ]);

export const initialTrekState: TrekWizardState = {
    mode: TrekWizardMode.CREATED,
    activeStage: TrekWizardStages.CREATE_ROUTE,
    map: {
        data: {
            settings: {
                segmentType: NavigationModeTypes.FOOT
            }
        },
        sectionStatus: new WizardSectionStatus(true, false, true)
    },
    details: {
        sectionStatus: new WizardSectionStatus(true, false, true)
    },
    tags: {
        sectionStatus: new WizardSectionStatus(false, false, true)
    },
    stations: {
        sectionStatus: new WizardSectionStatus(false, false, true)
    },
    coverMedia: {
        data: {
            useTrekMovie: true,
            coverMediaUrl: undefined
        },
        sectionStatus: new WizardSectionStatus(false, false)
    }
};

export enum TrekDifficuly {
    EASY = 'easy',
    MEDIUM = 'medium',
    EXTREME = 'extreme'
}

export const TrekDifficultyLevels = {
    1: TrekDifficuly.EASY,
    4: TrekDifficuly.MEDIUM,
    7: TrekDifficuly.EXTREME
};

export const TREK_MINIMIZED_KEY = environment.production
    ? 'TerkMinimizedProd'
    : 'TrekMinimizedStg';
export const TREK_STATION_MINIMIZED_KEY = environment.production
    ? 'TrekStationMinimizedProd'
    : 'TrekStationMinimizedStg';
