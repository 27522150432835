import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../services/site.service';
import { SiteCard } from '../../models/site.model';
import { concatMap, map } from 'rxjs/operators';
import { AppStoreService } from '../../../store/app-store.service';
import { SCREEN_BREAK_POINTS } from '../../../shared/shared.constants';

@Component({
    selector: 'app-preview-site',
    templateUrl: './preview-site.component.html',
    styleUrls: ['./preview-site.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewSiteComponent implements OnInit {
    siteUrl$!: Observable<string>;
    shareLink$!: Observable<string>;
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.laptop;

    constructor(
        private activatedRoute: ActivatedRoute,
        private siteService: SiteService,
        private appStoreService: AppStoreService
    ) {}

    ngOnInit(): void {
        this.initUrls();
    }

    initUrls() {
        const siteInfo = (
            this.activatedRoute.snapshot.parent?.data as {
                siteInfo: SiteCard;
            }
        ).siteInfo;
        this.initSiteUrl(siteInfo);
        this.initShareLink(siteInfo.id!);
    }

    initSiteUrl(siteInfo: SiteCard) {
        const user = this.appStoreService.state.auth.user!;
        this.siteUrl$ =
            !siteInfo.isPrivate && !siteInfo.freeTrial
                ? of(
                      `${environment.WEBSITE_URL}/site/${siteInfo.id}?ownerEdit=true`
                  )
                : this.siteService
                      .getPrivateLinks(user.businessId, siteInfo.id!, user.id)
                      .pipe(
                          concatMap((privateLinks) => {
                              if (privateLinks?.length || siteInfo.freeTrial) {
                                  return of(
                                      `${privateLinks[0].url}&ownerEdit=true`
                                  );
                              } else {
                                  return this.siteService
                                      .generatePrivateLink(
                                          user.businessId!,
                                          siteInfo.id!,
                                          user.id!
                                      )
                                      .pipe(
                                          map(
                                              (privateLink) =>
                                                  `${privateLink.url}&ownerEdit=true`
                                          )
                                      );
                              }
                          })
                      );
    }

    initShareLink(siteId: string) {
        this.shareLink$ = this.siteService
            .getShareLinksForSite(siteId)
            .pipe(
                map((links) => links.find((link) => link.isDefault)?.url || '')
            );
    }
}
