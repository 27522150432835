import { createAction, props } from '@ngrx/store';
import { TrekWizardStages } from '../trek-wizard.model';

export enum TrekStagesActionTypes {
    CHANGE_STAGE = '[TrekWizard]: Change Stage',
    NEXT_STAGE = '[TrekWizard] Next Stage',
    PREVIOUS_STAGE = '[TrekWizard]: Previous Stage',
    COMPLETED_STAGE = '[TrekWizard]: Complete Stage'
}

export const ChangeTrekStage = createAction(
    TrekStagesActionTypes.CHANGE_STAGE,
    props<{ stage?: TrekWizardStages }>()
);

export const TrekNextStage = createAction(TrekStagesActionTypes.NEXT_STAGE);

export const TrekPrevStage = createAction(TrekStagesActionTypes.PREVIOUS_STAGE);

export const completeState = createAction(
    TrekStagesActionTypes.COMPLETED_STAGE,
    props<{ stage: TrekWizardStages }>()
);
