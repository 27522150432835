import { LocaleType } from '../../shared/locales.models';
import { Polygon } from 'geojson';
import { TAGS as TagsConst } from '../../shared/wishtrip-common/tag';
import { SiteCategory } from '../models/site-wizard-categories.model';
import { SiteGreeting } from '../models/site-wizard-greeting.model';
import { ContactInformation } from '../models/site-wizard-contact.model';
import { SiteHours } from '../models/site-wizard-hours.model';
import { SiteMap } from '../models/site-wizard-map-setup.model';
import { SiteLanguages } from '../models/site-wizard-languages.model';
import {
    SiteAdditionalInformation,
    SiteGeneralDetails
} from '../models/site-wizard-general-details.model';
import { SiteMedia } from '../models/site-wizard-media.model';
import { SiteTags } from '../models/site-wizard-tags.model';
import {
    SiteWizardSpotsSection,
    SpotsMappedByLocation
} from '../models/site-wizard-spots.model';
import { SiteRouteMap } from '../models/site-wizard-routes.model';
import { PromotedTreks } from '../models/site-wizard-promoted-treks.model';
import {
    WizardSection,
    WizardSectionStatus
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';
import { SiteLifeCycleStateType } from '../site-wizard.model';
import { CardInput } from '../../sites/models/site.model';

export interface SiteWizardStateResponse {
    state: SiteWizardState;
}

export interface SiteWizardState {
    activeStage?: SiteWizardStages;
    id?: string;
    businessId?: string;
    mode?: WizardMode;
    languages: WizardSection<SiteLanguages>;
    general: SiteWizardSectionWithLang<Record<LocaleType, SiteGeneralDetails>>;
    media: WizardSection<SiteMedia[]>;
    contact?: WizardSection<ContactInformation>;
    hours: WizardSection<SiteHours>;
    tags: WizardSection<SiteTags>;
    map: WizardSection<SiteMap>;
    siteRoutes: WizardSection<SiteRouteMap>;
    spots: SiteWizardSpotsSection<SpotsMappedByLocation>;
    categories: WizardSection<SiteCategory[]>;
    greeting: SiteWizardSectionWithLang<SiteGreeting>;
    promotedTreks: WizardSection<PromotedTreks>;
    showLogoInMovie: WizardSection<boolean>;
    private: WizardSection<boolean>;
    countryCode?: LocaleType;
    isLiteWizard?: boolean;
    lifecycle?: SiteLifeCycleStateType;
}

export class SiteWizardStepWithLang extends WizardSectionStatus {
    selectedLangTab?: LocaleType;
}

export interface SiteWizardSectionWithLang<T> extends WizardSection<T> {
    sectionStatus: SiteWizardStepWithLang;
}

export interface SiteStatePreview {
    id?: string;
    language?: string;
    name?: string;
    description?: string;
    byline?: string;
    accessibilityDescription?: string;
    sois?: SpotsMappedByLocation;
    tois?: SiteRouteMap;
    relatedTreks?: CardInput[];
    polygon?: Polygon;
    availableLanguages?: string[];
    additionalInformation?: SiteAdditionalInformation[];
    media?: SiteMedia[];
    businessDetails?: {
        id?: string;
        language?: string;
    };
    accessibilityTags?: (keyof typeof TagsConst)[];
    contactInformation?: ContactInformation;
    categories?: SiteCategory[];
    recommendedTimeAllocation?: string;
    showPolygon?: boolean;
    countryCode?: string;
    hasEntryMessage?: boolean;
    hasExitMessage?: boolean;
    entryMessage?: string;
    exitMessage?: string;
}

export enum WizardMode {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    EDIT_REVIEW = 'EDIT_REVIEW',
    REVIEW = 'REVIEW',
    APPROVED = 'APPROVED'
}

export enum SiteWizardStages {
    LANGUAGES = 'languages',
    GENERAL = 'general',
    MEDIA = 'media',
    CONTACT = 'contact',
    HOURS = 'hours',
    TAGS = 'tags',
    MAP = 'map',
    SITE_ROUTES = 'siteRoutes',
    SPOTS = 'spots',
    CATEGORIES = 'categories',
    GREETINGS = 'greeting',
    PROMOTED_TREKS = 'promotedTreks',
    BRANDING = 'showLogoInMovie',
    PUBLISH = 'private',
    SETTINGS = 'settings',
    DETAILS = 'details',
    MAP_DETAILS = 'mapDetails',
    ENGAGEMENT = 'engagement'
}
