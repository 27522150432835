import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { document } from 'ngx-bootstrap/utils';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-qr-view',
    templateUrl: './qr-view.component.html',
    styleUrls: ['./qr-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrViewComponent implements OnInit {
    @Input() shareLink = '';
    @Input() qrWidth = 110;
    @Input() itemId = '';
    isMobile = window.innerWidth <= 600;
    @Input() nameForDownload = 'WishTrip';
    @Input() isDeletable = false;
    @Input() qrName?: string;
    @Input() qrId?: string;
    @Output() deleteHandler: EventEmitter<string | undefined> =
        new EventEmitter<string | undefined>();
    @ViewChild('qrcode') qrcode!: HTMLElement;

    constructor(
        private snackbarService: SnackbarService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {}

    onOpenLinkClicked(): void {
        window.open(this.shareLink, '_blank');
    }

    downloadQrImage(): void {
        const link: HTMLLinkElement = document.createElement('a');
        (link as any).download = `My-WishTrip-${this.nameForDownload}.png`;
        document
            .getElementById(`id-${this.itemId}`)
            .getElementsByTagName('canvas')[0]
            .toBlob((blob: Blob) => {
                link.href = URL.createObjectURL(blob);
                link.click();
            }, 'image/png');
    }

    onLinkCopied(): void {
        this.snackbarService.open(this.translate.instant('LINK_COPIED'));
    }

    onShareQrImage(): void {
        document
            .getElementById(`id-${this.itemId}`)
            .getElementsByTagName('canvas')[0]
            .toBlob((blob: Blob) => {
                const image = new File(
                    [blob],
                    `My-WishTrip-${this.nameForDownload}.png`,
                    blob
                );
                navigator.share({ files: [image] });
            }, 'image/png');
    }

    onShareLink(): void {
        navigator.share({ url: this.shareLink });
    }
}
