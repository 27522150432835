import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TreksComponent } from './pages/treks/treks.component';
import { CreateTrekComponent } from './pages/create-trek/create-trek.component';
import { MainComponent } from '../standalone/components/main/main.component';

export enum TreksRoutePaths {
    CREATE_TREK = 'create-trek'
}

const TREK_ROUTES: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                component: TreksComponent
            },
            {
                path: TreksRoutePaths.CREATE_TREK,
                component: CreateTrekComponent
            }
        ]
    },
    {
        path: ':trekId',
        loadChildren: () =>
            import('../trek-wizard/trek-wizard.module').then(
                (m) => m.TrekWizardModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forChild(TREK_ROUTES)],
    exports: [RouterModule]
})
export class TreksRoutingModule {}
