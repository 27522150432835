export enum OperativeTypographyTypes {
    FOOTER = 'FOOTER',
    TITLE_BOLD = 'TITLE_BOLD',
    SUBTITLE = 'SUBTITLE',
    PRIMARY = 'PRIMARY',
    PRIMARY_BOLD = 'PRIMARY_BOLD',
    PLACEHOLDER = 'PLACEHOLDER',
    SECONDARY = 'SECONDARY',
    SECONDARY_BOLD = 'SECONDARY_BOLD',
    SECONDARY_UNDERLINE = 'SECONDARY_UNDERLINE',
    SECONDARY_BOLD_UNDERLINE = 'SECONDARY_BOLD_UNDERLINE',
    SMALL = 'SMALL',
    TINY_BOLD = 'TINY_BOLD',
    TINY = 'TINY'
}

export interface OperativeTypographyStyle {
    fontSize: string;
    fontWeight: string;
    letterSpacing: string;
    lineHeight: string;
    textDecoration?: string;
}

export enum OperativeTypographyColorName {
    DEFAULT_COLOR = 'DEFAULT_COLOR',
    DEFAULT_COLOR_OPACITY = 'DEFAULT_COLOR_OPACITY',
    DEFAULT_COLOR_DISABLED = 'DEFAULT_COLOR_DISABLED', // disabled
    WARN = 'WARN',
    TURQUOISE = 'TURQUOISE',
    WHITE = 'WHITE',
    LIGHT_GRAY = 'LIGHT_GRAY',
    ACCENT = 'ACCENT',
    GREEN = 'GREEN'
}

export interface OperativeTypographyColorNumber {
    color: string;
}
