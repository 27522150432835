<h2 appTypography="SUBTITLE">{{ 'MY_QR' | translate }}</h2>
<div
    class="my-qr-subtitle"
    appTypography="SECONDARY"
    [innerHTML]="
        (isMobile ? 'MY_QR_SUBTITLE_MOBILE' : 'MY_QR_PAGE_SUBTITLE') | translate
    "></div>
<div
    class="share-links"
    *ngIf="shareLinksList$ | async as links">
    <div class="links-wrapper">
        <app-qr-view
            *ngFor="let link of links"
            [shareLink]="link.url!"
            [qrWidth]="150"
            [itemId]="siteId!"
            [qrId]="link.id"
            [qrName]="link.name || 'DEFAULT' | translate"
            [isDeletable]="!link.isDefault"
            (deleteHandler)="deleteQrLink($event)"
            nameForDownload="site"></app-qr-view>
    </div>
    <div class="create-new-link">
        <app-custom-input-standalone
            [label]="'ADD_QR_LABEL' | translate"
            [formControl]="newLinkName"></app-custom-input-standalone>

        <app-standalone-custom-button
            buttonSize="MEDIUM"
            (clicked)="createNewLink()"
            [isDisabled]="newLinkName.invalid">
            {{ 'ADD_QR_BTN' | translate }}
        </app-standalone-custom-button>
    </div>
</div>

<div class="treks-and-spots-qr-wrapper">
    <div
        appTypography="PRIMARY_BOLD"
        color="WHITE"
        class="title-box">
        <p class="header-text">{{ 'GENERATE_NEW_QR_CODE' | translate }}</p>
    </div>
    <div
        class="view-wrapper"
        *ngIf="siteSpotsAndTreksSubject$ | async as siteSpotsAndTreks">
        <app-search-input #trekAndSpotSearchTerm></app-search-input>

        <ng-container *ngIf="(siteSpotsAndTreks?.siteTreks || []).length">
            <p appTypography="SECONDARY_BOLD">
                {{ 'TREKS' | translate }}
            </p>
            <div
                *ngFor="
                    let trek of siteSpotsAndTreks?.siteTreks || []
                        | searchTreksOrSpotsByName
                            : (trekAndSpotSearchTerm.searchTerm | async) || ''
                "
                class="entity-qr-wrapper">
                <div appTypography="SECONDARY">
                    {{ trek?.name }}
                    <a
                        *ngIf="!trek?.shareLink"
                        appTypography="SECONDARY_UNDERLINE"
                        (click)="generateQRForTrek(trek?.id!)"
                        class="generate-link">
                        {{ 'GENERATE_LINK' | translate }}
                    </a>
                </div>
                <div
                    class="qr-wrapper"
                    *ngIf="trek?.shareLink">
                    <app-qr-view
                        [shareLink]="trek?.shareLink!"
                        [itemId]="trek?.id!"
                        nameForDownload="trek-{{ trek?.name }}"
                        class="item-qr"></app-qr-view>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="(siteSpotsAndTreks.siteSpots || []).length">
            <p appTypography="SECONDARY_BOLD">
                {{ 'SPOTS' | translate }}
            </p>
            <div
                *ngFor="
                    let spot of siteSpotsAndTreks.siteSpots || []
                        | searchTreksOrSpotsByName
                            : (trekAndSpotSearchTerm.searchTerm | async) || ''
                "
                class="entity-qr-wrapper">
                <div appTypography="SECONDARY">
                    {{ spot?.name }}
                    <a
                        *ngIf="!spot?.shareLink"
                        appTypography="SECONDARY_UNDERLINE"
                        (click)="generateQRForSpot(spot?.id!)"
                        class="generate-link">
                        {{ 'GENERATE_LINK' | translate }}
                    </a>
                </div>
                <div
                    class="qr-wrapper"
                    *ngIf="spot?.shareLink">
                    <app-qr-view
                        [shareLink]="spot?.shareLink!"
                        [itemId]="spot?.id!"
                        nameForDownload="spot-{{ spot?.name }}"
                        class="item-qr"></app-qr-view>
                </div>
            </div>
        </ng-container>
    </div>
</div>
