import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app-store.model';

@Injectable({ providedIn: 'root' })
export class AppStoreService {
    private _state!: AppState;
    get store() {
        return this._store$;
    }

    get state() {
        return this._state;
    }

    constructor(private _store$: Store<AppState>) {}

    setSyncState(appState: AppState) {
        this._state = appState;
    }
}
