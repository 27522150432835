import * as mapboxgl from 'mapbox-gl';
import { getTrekPathLayer } from './layers';
import { getLineStringSource } from './geojson';
import { latLonToPosition } from './helpers';
import { MapboxMap } from './MapboxMap';
import { LatLon } from '../typings/mapbox-utils';

export class Path {
    private pathSourceData: GeoJSON.Feature<GeoJSON.LineString>;
    private pathLayer: mapboxgl.Layer;
    private layerId!: string;
    private mapboxMap!: MapboxMap;

    constructor(layerId: string, start: LatLon) {
        this.pathSourceData = getLineStringSource([start]);
        this.pathLayer = getTrekPathLayer(this.layerId, [start], '');
        this.layerId = this.pathLayer.id;
    }

    init(mapboxMap: MapboxMap): any {
        this.mapboxMap = mapboxMap;
        this.mapboxMap.addLayer(this.pathLayer);

        return this;
    }

    terminate(): void {
        this.mapboxMap.removeLayer(this.pathLayer);
    }

    add(location: LatLon): void {
        this.pathSourceData.geometry.coordinates.push(
            latLonToPosition(location)
        );

        (this.mapboxMap.getSource(this.layerId) as any).setData(
            this.pathSourceData
        );
    }
}
