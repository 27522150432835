<div class="site-rating">
    <div class="site-manager-page-header">
        <h2 appTypography="SUBTITLE">
            {{ 'AVERAGE_SITE_RATING' | translate }}
        </h2>
        <p appTypography="SECONDARY">
            {{ 'RATING_DESCRIPTION' | translate }}
        </p>
    </div>
    <div class="select-range">
        <div class="select-range-options">
            <h3
                (click)="selectRange(range.key)"
                *ngFor="let range of SITE_RATE_RANGE | keyvalue : originalOrder"
                [ngClass]="{ active: selectedRange === range.key }"
                [appTypography]="
                    selectedRange === range.key ? 'PRIMARY_BOLD' : 'PRIMARY'
                "
                class="range">
                {{ range.value | translate }}
            </h3>
        </div>
        <app-date-range-picker
            [ngClass]="{
                hidden: selectedRange !== SITE_RATE_RANGE.SPECIFIC_DATE
            }"
            [max]="today"
            (dateRangeChanged)="changeDate($event)"
            [dateFormatDisplay]="(dateFormat$ | async)!">
        </app-date-range-picker>
    </div>
    <div
        *ngIf="showSpinner$ | async"
        class="inline-spinner">
        <mat-spinner></mat-spinner>
    </div>
    <div
        *ngIf="rating$ | async as rating"
        class="rating-result">
        <div class="stars">
            <i
                *ngFor="let star of [1, 2, 3, 4, 5]"
                [ngClass]="{ 'not-selected': star > rating.average }"
                class="WTicon-star-rating">
                <span class="path1"></span>
                <span class="path2"></span>
                <span
                    [style.background]="
                        rating.average > star - 1 && rating.average < star
                            ? 'linear-gradient(90deg, #f9ca08 ' +
                              (rating.average % 1) * 100 +
                              '%, #eaeaea 0%)'
                            : ''
                    "
                    class="path3"></span>
            </i>
        </div>
        <h2
            class="average"
            appTypography="SUBTITLE">
            {{ rating.average }}
        </h2>
        <div
            class="stats"
            appTypography="SECONDARY">
            <h3>
                {{ 'NUMBER_OF_RATINGS' | translate }}:
                <span appTypography="SECONDARY_BOLD">{{
                    rating.total_votes | number
                }}</span>
            </h3>
            <h3>
                {{ 'TOTAL_VISITORS' | translate }}:
                <span appTypography="SECONDARY_BOLD">{{
                    rating.total_travelers | number
                }}</span>
            </h3>
        </div>
    </div>
</div>
