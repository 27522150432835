<div class="locale-settings">
    <p appTypography="SECONDARY">
        {{ 'LOCALE_SETTINGS_DESCRIPTION' | translate }}
    </p>
    <form
        [formGroup]="localeSettingsForm"
        (ngSubmit)="saveLocaleSettings(localeSettingsForm.value)"
        class="settings-form">
        <div class="radio-group">
            <h4
                appTypography="SECONDARY_BOLD"
                id="timeFormatLabel">
                {{ 'TIME' | translate }}
            </h4>
            <mat-radio-group formControlName="timeFormat">
                <mat-radio-button
                    *ngFor="let timeFormat of timeFormatOptions | keyvalue"
                    [value]="timeFormat.key">
                    <span appTypography="SECONDARY">{{
                        timeFormat.value | translate
                    }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="radio-group">
            <h4
                appTypography="SECONDARY_BOLD"
                id="dateFormatLabel">
                {{ 'DATE' | translate }}
            </h4>
            <mat-radio-group formControlName="dateFormat">
                <mat-radio-button
                    *ngFor="let dateFormat of dateFormatOptions | keyvalue"
                    [value]="dateFormat.key">
                    <span appTypography="SECONDARY">{{
                        dateFormat.value | translate
                    }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="radio-group">
            <h4
                appTypography="SECONDARY_BOLD"
                id="lengthTypeLabel">
                {{ 'DISTANCE' | translate }}
            </h4>
            <mat-radio-group formControlName="lengthType">
                <mat-radio-button
                    *ngFor="let lengthType of lengthTypeOptions | keyvalue"
                    [value]="lengthType.key">
                    <span appTypography="SECONDARY">{{
                        lengthType.value | translate
                    }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="radio-group">
            <h4
                appTypography="SECONDARY_BOLD"
                id="weekStart">
                {{ 'WEEK_START' | translate }}
            </h4>
            <mat-radio-group formControlName="weekStart">
                <mat-radio-button
                    *ngFor="let weekStart of weekStartOptionsMap | keyvalue"
                    [value]="weekStart.key">
                    <span appTypography="SECONDARY">{{
                        weekStart.value | translate
                    }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <app-standalone-custom-button
            [isDisabled]="localeSettingsForm.pristine"
            buttonType="submit"
            class="save-button">
            {{ 'SAVE' | translate }}
        </app-standalone-custom-button>
    </form>
</div>
