import { Injectable } from '@angular/core';
import * as AuthSelectors from '../auth/store/auth-store.selectors';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-store.model';
import { User } from '../auth/auth.model';
import { ActivatedRoute } from '@angular/router';

const CRISP_EVENT_PAGES = ['wizard', 'trek-wizard'];

enum CrispCustomEvents {
    UserEnteredSiteWizard = 'user_entered_site_wizard',
    UserEnteredTrekWizard = 'user_entered_trek_wizard'
}

const CrispPagesEventsMap = new Map([
    [CRISP_EVENT_PAGES[0], CrispCustomEvents.UserEnteredSiteWizard],
    [CRISP_EVENT_PAGES[1], CrispCustomEvents.UserEnteredTrekWizard]
]);

@Injectable({
    providedIn: 'root'
})
export class CrispLiveChatService {
    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute
    ) {}

    initCrispLiveChat(): void {
        (window as any).CRISP_READY_TRIGGER = () => {
            (window as any).$crisp.push(['safe', true]);
            this.store
                .select(AuthSelectors.getUser)
                .pipe(take(1))
                .subscribe((user) => {
                    if (user) {
                        this.setUserDetails(user);
                    }
                });
        };
        (window as any).CRISP_WEBSITE_ID = environment.CRISP_WEBSITE_ID;
        const js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = 'https://client.crisp.chat/l.js';
        js.async = true;
        document.getElementsByTagName('head')[0].appendChild(js);
    }

    showChat(): void {
        (window as any).$crisp?.push(['do', 'chat:show']);
    }

    hideChat(): void {
        (window as any).$crisp?.push(['do', 'chat:hide']);
    }

    fireWizardEvent(): void {
        const currentRoute =
            this.activatedRoute.firstChild?.snapshot.routeConfig?.path;
        const customEvent = CrispPagesEventsMap.get(currentRoute!);
        (window as any).$crisp?.push([
            'set',
            'session:event',
            [[[customEvent]]]
        ]);
    }

    setUserDetails(user: User): void {
        if (user?.email) {
            (window as any).$crisp?.push(['set', 'user:email', user.email]);
        }
        if (user?.name) {
            (window as any).$crisp?.push(['set', 'user:nickname', user.name]);
        }
        if (user?.userProfileUrl) {
            (window as any).$crisp?.push([
                'set',
                'user:avatar',
                user.userProfileUrl
            ]);
        }
    }
}
