import * as mapboxgl from 'mapbox-gl';
import { EventedMarker } from './EventedMarker';
import { AnimatedMarker } from './AnimatedMarker';
import { MapboxMap } from './MapboxMap';
import { LatLon } from '../typings/mapbox-utils';
import {
    SpotMarkerOptions,
    SpotStatus
} from '../../site-wizard/models/site-wizard-spots.model';

export class Marker {
    private mapboxMap: MapboxMap;
    private userMarkers: { [id: string]: EventedMarker };
    private spotMarkers: { [id: string]: EventedMarker };
    private toiMarkers: { [id: string]: EventedMarker };
    private stationMarkers: { [id: string]: EventedMarker };
    private anchorPointMarker!: EventedMarker | null;
    private deletePolygonMarker: EventedMarker | undefined;
    private deleteAnchorPointMarker: EventedMarker | undefined;
    private deleteTrekMarker: EventedMarker | undefined;

    constructor(mapboxMap: MapboxMap) {
        this.mapboxMap = mapboxMap;
        this.userMarkers = {};
        this.spotMarkers = {};
        this.toiMarkers = {};
        this.stationMarkers = {};
    }

    addSpotMarker(
        map: mapboxgl.Map,
        spot: any,
        options?: SpotMarkerOptions
    ): EventedMarker {
        if (this.spotMarkers[spot.id]) {
            return this.spotMarkers[spot.id];
        }

        this.spotMarkers[spot.id] = this.spotMarker(spot, options).addTo(map);
        return this.spotMarkers[spot.id];
    }

    spotMarker(spot: any, options?: SpotMarkerOptions): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.className = 'mapbox__spot-marker';
        markerEl.innerHTML = this.GetSpotIcon.get(options?.spotStatus!)!;
        if (options?.index) {
            const indexEl = document.createElement('div');
            indexEl.innerText = `${options.index}`;
            indexEl.className = 'mapbox__spot-index';
            markerEl.appendChild(indexEl);
        }
        const marker = new EventedMarker(markerEl)
            .setLngLat(new mapboxgl.LngLat(spot.loc[1], spot.loc[0]))
            .setDraggable(!!options?.draggable)
            .addProperty('spotId', spot.id)
            .setOffset([0, -10])
            .onAdd('click', (event: any) => {
                this.mapboxMap.notifySpotMarkerClick(
                    marker.getProperty('spotId')
                );
                event.stopPropagation();
            })
            .onAdd('touchstart', (event: any) => {
                this.mapboxMap.notifySpotMarkerClick(
                    marker.getProperty('spotId')
                );
                event.stopPropagation();
            })
            .on('dragend', (event: any) => {
                this.mapboxMap.notifyMarkerDrag(event);
            });

        if (spot.name) {
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                anchor: 'bottom',
                offset: [0, -15]
            }).setHTML(
                `<div class="mapbox__spot-marker__popup">${spot.name}</div>`
            );

            marker
                .setPopup(popup)
                .onAdd('mouseenter', () => {
                    if (popup.isOpen()) {
                        return;
                    }
                    marker.togglePopup();
                })
                .onAdd('mouseleave', () => {
                    if (!popup.isOpen()) {
                        return;
                    }
                    marker.togglePopup();
                });
        }

        return marker;
    }

    removeSoiMarker(soiId: number): { [id: string]: EventedMarker } {
        this.spotMarkers[soiId].remove();
        return this.spotMarkers;
    }

    addDeletePolygonMarker(
        map: mapboxgl.Map,
        latLon: LatLon,
        onClick: () => void
    ) {
        if (this.deletePolygonMarker) {
            this.deletePolygonMarker.setLngLat(latLon);
        } else {
            this.deletePolygonMarker = this.createDeleteButtonMarker(
                latLon,
                this.mapboxMap.translate.instant('DELETE_POLYGON')
            )
                .addTo(map)
                .onAdd('click', () => {
                    onClick();
                })
                .onAdd('touchstart', () => {
                    onClick();
                });
        }
    }

    addDeleteAnchorPointMarker(
        map: mapboxgl.Map,
        latLon: LatLon,
        onClick: () => void
    ) {
        if (this.deleteAnchorPointMarker) {
            this.deleteAnchorPointMarker.setLngLat(latLon);
        } else {
            this.deleteAnchorPointMarker = this.createDeleteButtonMarker(
                latLon,
                this.mapboxMap.translate.instant('DELETE_ANCHOR_POINT')
            ).addTo(map);
            this.deleteAnchorPointMarker
                .getElement()
                .addEventListener('click', (e) => {
                    e.stopImmediatePropagation();
                    return onClick();
                });
            this.deleteAnchorPointMarker
                .getElement()
                .addEventListener('touchstart', (e) => {
                    e.stopImmediatePropagation();
                    return onClick();
                });
        }
    }

    addDeleteTrekMarker(
        map: mapboxgl.Map,
        latLon: LatLon,
        onClick: () => void
    ) {
        if (this.deleteTrekMarker) {
            this.deleteTrekMarker.setLngLat(latLon);
        } else {
            this.deleteTrekMarker = this.createDeleteButtonMarker(
                latLon,
                this.mapboxMap.translate.instant('DELETE_ROUTE')
            ).addTo(map);
            this.deleteTrekMarker
                .getElement()
                .addEventListener('click', () => onClick());
            this.deleteTrekMarker
                .getElement()
                .addEventListener('touchstart', () => onClick());
        }
    }

    removeDeleteAnchorPointMarker() {
        this.deleteAnchorPointMarker?.remove();
        this.deleteAnchorPointMarker = undefined;
    }

    removeDeletePolygonMarker() {
        this.deletePolygonMarker?.remove();
        this.deletePolygonMarker = undefined;
    }

    removeDeleteTrekButton() {
        this.deleteTrekMarker?.remove();
        this.deleteTrekMarker = undefined;
    }

    addToiMarker(
        map: mapboxgl.Map,
        toiId: string,
        location: number[]
    ): EventedMarker {
        this.toiMarkers[toiId] = this.toiMarker(location).addTo(map);
        return this.toiMarkers[toiId];
    }

    toiMarker(location: number[]): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.className = 'mapbox__toi-marker';
        return new EventedMarker(markerEl).setLngLat(
            new mapboxgl.LngLat(location[0], location[1])
        );
    }

    removeToiMarker(toiId: string): { [id: string]: EventedMarker } {
        this.toiMarkers[toiId].remove();
        return this.toiMarkers;
    }

    clearAllMarkers(): void {
        this.clearUserMarkers();
        this.clearSpotMarkers();
        this.clearToiMarkers();
        this.clearAnchorPointMarker();
    }

    clearUserMarkers(): void {
        this.clearMarkersType(this.userMarkers);
    }

    clearSpotMarkers(): void {
        this.clearMarkersType(this.spotMarkers);
    }

    clearToiMarkers(): void {
        this.clearMarkersType(this.toiMarkers);
    }

    clearAnchorPointMarker(): void {
        this.anchorPointMarker?.remove();
        this.anchorPointMarker = null;
    }

    clearMarkersType(markers: { [id: string]: EventedMarker }): void {
        for (const marker of Object.keys(markers)) {
            markers[marker].remove();
            delete markers[marker];
        }
    }

    siteMarker(site: any): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.className = 'mapbox__site-marker';
        // markerEl.innerHTML = `<img
        // src="${require('../../../assets/images/map/site-marker.svg')}"/>`;
        markerEl.setAttribute('style', 'height: "36px", width: "25px"');

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            anchor: 'bottom',
            offset: [1, -10]
        }).setHTML(
            `<div class="mapbox__site-marker__popup"><div class="mapbox__site-marker__popup__title">${site.name}</div></div>`
        );

        return new EventedMarker((markerEl as any)[0])
            .setLngLat(
                new mapboxgl.LngLat(site.location.lon, site.location.lat)
            )
            .setPopup(popup)
            .addProperty('site_id', site.id);
    }

    setDraggableStationMarker(draggable: boolean): void {
        if (this.spotMarkers) {
            Object.keys(this.spotMarkers).forEach((station) => {
                this.spotMarkers[station].setDraggable(draggable);
            });
            return;
        }
    }

    removeStationMarkers(): void {
        Object.keys(this.stationMarkers).forEach((station) => {
            this.stationMarkers[station].remove();
        });
    }

    trekStart(coordinate: mapboxgl.LngLat, ts?: number): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.innerHTML =
            '<i class="mapbox__trek-marker__flag WTicon-start-timeline"><span class="path1"></span><span class="path2"></span></i>';

        return new EventedMarker(markerEl)
            .addProperty('ts', ts)
            .setLngLat(coordinate);
    }

    trekEnd(coordinate: mapboxgl.LngLat, ts?: number): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.innerHTML =
            '<i class="mapbox__trek-marker__flag WTicon-end-timeline"><span class="path1"></span><span class="path2"></span></i>';

        return new EventedMarker(markerEl)
            .addProperty('ts', ts)
            .setLngLat(coordinate);
    }

    addLiveUserMarker(
        map: mapboxgl.Map,
        coordinate: mapboxgl.LngLat,
        userId: string
    ): EventedMarker {
        if (this.userMarkers[userId]) {
            return this.userMarkers[userId].setLngLat(coordinate);
        }

        this.userMarkers[userId] = this.LiveUserMarker(
            coordinate,
            userId
        ).addTo(map);

        return this.userMarkers[userId];
    }

    removeLiveUserMarker(
        userId: string
    ): { [id: string]: EventedMarker } | void {
        if (!this.userMarkers[userId]) {
            return;
        }
        this.userMarkers[userId].remove();
        delete this.userMarkers[userId];
        return this.userMarkers;
    }

    addAnchorPointMarker(
        map: mapboxgl.Map,
        anchorPoint: LatLon,
        draggable: boolean
    ): void {
        if (this.anchorPointMarker) {
            this.anchorPointMarker.setLngLat([
                anchorPoint.lon,
                anchorPoint.lat
            ]);
            this.anchorPointMarker.setDraggable(draggable);
            return;
        }
        this.anchorPointMarker = this.createAnchorPointMarker(
            anchorPoint,
            draggable
        ).addTo(map);
    }

    private createDeleteButtonMarker(
        latLon: LatLon,
        text: string
    ): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.className = 'mapbox__delete_button-marker';
        markerEl.innerText = text;
        return new EventedMarker(markerEl).setLngLat(latLon);
    }

    createAnchorPointMarker(
        anchorPoint: LatLon,
        draggable: boolean
    ): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.className = 'mapbox__entry_point-marker';
        markerEl.innerHTML = `<i class="WTicon-start-timeline"><span class="path1"></span><span class="path2"></span></i>`;

        return new EventedMarker(markerEl)
            .setDraggable(draggable)
            .setLngLat(new mapboxgl.LngLat(anchorPoint.lon, anchorPoint.lat))
            .on('dragend', (event: any) => {
                this.mapboxMap.notifyAnchorDrag(event);
            });
    }

    toggleEntryPointMarkersOpacity(enable: boolean, map: mapboxgl.Map): void {
        this.toggleMarkersOpacity([this.anchorPointMarker], enable);
    }

    toggleSpotMarkersOpacity(enable: boolean, map: mapboxgl.Map): void {
        this.toggleMarkersOpacity(this.spotMarkers, enable);
    }

    toggleToiMarkerOpacity(toiId: string, enable: boolean): void {
        this.toggleMarkersOpacity([this.toiMarkers[toiId]], enable);
    }

    toggleMarkersOpacity(markerLayer: any, enable: boolean): void {
        if (!enable) {
            for (const marker of Object.keys(markerLayer)) {
                this.addClassToMarker(markerLayer[marker]._element, 'opacity');
            }
        } else {
            for (const marker of Object.keys(markerLayer)) {
                this.removeClassFromMarker(
                    markerLayer[marker]._element,
                    'opacity'
                );
            }
        }
    }

    addClassToMarker(el: any, className: string): void {
        if (el.classList) {
            el.classList.add(className);
        } else if (!this.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    }

    removeClassFromMarker(el: any, className: string): void {
        if (this.hasClass(el, className)) {
            el.classList.remove(className);
        }
    }

    private LiveUserMarker(
        coordinate: mapboxgl.LngLat,
        userId: string,
        userName?: string
    ): EventedMarker {
        const markerEl = document.createElement('div');
        markerEl.innerHTML = `<div class="mapbox__user-marker"></div>`;
        markerEl.setAttribute('style', 'height: "20px", width: "20px"');

        return (
            new AnimatedMarker(markerEl)
                // .setOptions({ distance: 300, interval: 750 })
                .setLngLat(coordinate)
        );
    }

    private hasClass(el: any, className: string): any {
        return el.classList
            ? el.classList.contains(className)
            : new RegExp('\\b' + className + '\\b').test(el.className);
    }

    GetSpotIcon = new Map<SpotStatus, string>([
        [
            SpotStatus.EMPTY,
            `<i class="WTicon-spot-${'empty'}"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>`
        ],
        [
            SpotStatus.UNFINISHED,
            `<i class="WTicon-spot-${'unfinished'}"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>`
        ],
        [
            SpotStatus.COMPLETE,
            `<i class="WTicon-spot-${'complete'}"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>`
        ]
    ]);
}
