import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { StatusLabel, statusLabelColorMap } from './card-label.consts';

@Component({
    selector: 'app-card-label',
    standalone: true,
    imports: [CommonModule, TypographyDirective],
    templateUrl: './card-label.component.html',
    styleUrls: ['./card-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardLabelComponent implements OnInit {
    getLabelStatusColor = statusLabelColorMap;
    backgroundColor?: string;
    @Input() status?: StatusLabel;

    ngOnInit() {
        this.backgroundColor = this.getLabelStatusColor.get(this.status!);
    }
}
