import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppRoutePaths } from '../../../../../app-routing.module';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TypographyDirective } from '../../../../directives/typography/typography.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'app-header-links',
    templateUrl: './header-links.component.html',
    styleUrls: ['./header-links.component.scss'],
    imports: [
        RouterLinkActive,
        RouterLink,
        TypographyDirective,
        TranslateModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderLinksComponent {
    protected readonly AppRoutePaths = AppRoutePaths;
}
