<div class="navbar-section mobile-side-menu">
    <mat-sidenav-container>
        <i
            class="WTicon-menu menu-icon"
            (click)="sidenav.toggle()"></i>
        <mat-sidenav
            class="menu-list"
            #sidenav
            [fixedInViewport]="true"
            [position]="'end'"
            (click)="sidenav.toggle()"
            [fixedTopGap]="40">
            <a
                #loginMenu="routerLinkActive"
                [appTypography]="
                    loginMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                [routerLink]="['/', AppRoutePaths.AUTH, AuthRoutePaths.LOGIN]"
                routerLinkActive
                class="menu-link width-line"
                *ngIf="!user"
                color="WHITE"
                >{{ 'LOGIN' | translate }}
            </a>
            <a
                class="user-link-area menu-link"
                routerLinkActive="active"
                [routerLink]="['/', AppRoutePaths.USERS, user!.id]"
                *ngIf="user">
                <div class="user-image">
                    <img
                        alt="user-profile"
                        *ngIf="user.userProfileUrl"
                        [src]="user.userProfileUrl" />
                </div>
                <div
                    class="user-title"
                    appTypography="SECONDARY"
                    color="WHITE">
                    {{ user.name }}
                </div>
            </a>
            <a
                appTypography="SECONDARY"
                color="WHITE"
                (click)="onLogout()"
                *ngIf="user"
                class="menu-link width-line"
                >{{ 'LOGOUT' | translate }}
            </a>
            <a
                #profileMenu="routerLinkActive"
                [appTypography]="
                    profileMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                routerLinkActive
                color="WHITE"
                [routerLink]="[AppRoutePaths.USERS, user!.id]"
                *ngIf="user"
                class="menu-link"
                >{{ 'MY_PROFILE' | translate }}
            </a>
            <a
                #sitesMenu="routerLinkActive"
                [appTypography]="
                    sitesMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                routerLinkActive
                color="WHITE"
                [routerLink]="['/', AppRoutePaths.SITES]"
                class="menu-link"
                >{{ 'SITES' | translate }}
            </a>
            <a
                #treksMenu="routerLinkActive"
                [appTypography]="
                    treksMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                routerLinkActive
                color="WHITE"
                [routerLink]="['/', AppRoutePaths.TREKS]"
                class="menu-link"
                >{{ 'TREKS' | translate }}
            </a>
            <a
                #usersMenu="routerLinkActive"
                [appTypography]="
                    usersMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                routerLinkActive
                color="WHITE"
                [routerLink]="['/', AppRoutePaths.USERS]"
                class="menu-link"
                >{{ 'USERS' | translate }}
            </a>
            <a
                #businessMenu="routerLinkActive"
                [appTypography]="
                    businessMenu.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
                "
                routerLinkActive
                color="WHITE"
                [routerLink]="['/', AppRoutePaths.BUSINESS]"
                class="menu-link width-line"
                >{{ 'MY_ORGANIZATION' | translate }}
            </a>

            <a
                [matMenuTriggerFor]="language"
                (click)="toggleLanguages($event)"
                class="menu-link"
                appTypography="SECONDARY"
                color="WHITE">
                {{ websiteLocales[siteLang] }}
            </a>
            <mat-menu
                #language
                class="wt-menu">
                <a
                    appTypography="SECONDARY"
                    mat-menu-item
                    *ngFor="let item of websiteLocales | keyvalue"
                    (click)="changeWebsiteLanguage(item.key)">
                    {{ item.value }}
                </a>
            </mat-menu>

            <a
                appTypography="SECONDARY"
                color="WHITE"
                class="menu-link"
                (click)="openContact()"
                >{{ 'CONTACT_US' | translate }}
            </a>
            <a
                appTypography="SECONDARY"
                color="WHITE"
                target="_blank"
                rel="noopener"
                class="menu-link"
                [href]="websiteUrl"
                >WishTrip
            </a>
            <a
                appTypography="SECONDARY"
                color="WHITE"
                target="_blank"
                rel="noopener"
                class="menu-link"
                [href]="businessUrl"
                >WishTrip Enterprise
            </a>
            <a
                appTypography="SECONDARY"
                color="WHITE"
                target="_blank"
                rel="noopener"
                class="menu-link"
                [href]="privacyPolicyUrl"
                >{{ 'PRIVACY_POLICY' | translate }}</a
            >
            <a
                appTypography="SECONDARY"
                color="WHITE"
                target="_blank"
                rel="noopener"
                class="menu-link"
                [href]="tosUrl"
                >{{ 'TERMS_AND_CONDITIONS' | translate }}</a
            >
            <a
                class="menu-link"
                appTypography="SECONDARY"
                color="WHITE"
                >© Rec ‘n’ Trek {{ currentYear }}</a
            >
        </mat-sidenav>
    </mat-sidenav-container>
</div>
