import { createAction, props } from '@ngrx/store';

export enum CoverMediaActionTypes {
    UPDATE_COVER_MEDIA_URL = '[trekWizard] Update Cover Media Url',
    UPDATE_USE_TREK_MOVIE = '[trekWizard] Update Use Trek Movie',
    COVER_MEDIA_COMPLETE = '[trekWizard] Cover Media Complete'
}

export const UpdateCoverMedia = createAction(
    CoverMediaActionTypes.UPDATE_COVER_MEDIA_URL,
    props<{ coverMediaUrl: string }>()
);

export const UpdateUseTrekMovie = createAction(
    CoverMediaActionTypes.UPDATE_USE_TREK_MOVIE,
    props<{ useTrekMovie: boolean }>()
);

export const CoverMediaComplete = createAction(
    CoverMediaActionTypes.COVER_MEDIA_COMPLETE
);
