import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    ResolveFn,
    RouterStateSnapshot
} from '@angular/router';
import { SmsSettings } from '../models/site.model';
import { SiteService } from '../services/site.service';
import { Observable } from 'rxjs';

export const settingsPageResolver: ResolveFn<SmsSettings> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<SmsSettings> => {
    const siteId = route.parent?.data.siteInfo.id;
    return inject(SiteService).getSiteSettingsSms(siteId!);
};
