import { createAction, props } from '@ngrx/store';
import { SiteRoute } from '../../models/site-wizard-routes.model';
import { NavigationModeTypes } from '../../../standalone/components/navigation-settings/navigation-settings.model';

export enum RoutesActionTypes {
    ADD_ROUTE = '[SiteWizard] Add Route',
    DELETE_ROUTE = '[SiteWizard] Delete Route',
    UPDATE_NAVIGATION_MODE = '[SiteWizard] Update Navigation Mode',
    UPDATE_SHOW_PATH = '[SiteWizard] Update Show Path',
    UPDATE_PATH_PROPS = '[SiteWizard] Update Path Props',
    ROUTE_COMPLETE = '[SiteWizard] Update Routes Complete'
}

export const AddRoute = createAction(
    RoutesActionTypes.ADD_ROUTE,
    props<{ route: SiteRoute }>()
);

export const DeleteRoute = createAction(
    RoutesActionTypes.DELETE_ROUTE,
    props<{ id: string }>()
);

export const UpdateNavigationMode = createAction(
    RoutesActionTypes.UPDATE_NAVIGATION_MODE,
    props<{ trekId: string; mode: NavigationModeTypes }>()
);

export const UpdateShowPath = createAction(
    RoutesActionTypes.UPDATE_SHOW_PATH,
    props<{ trekId: string; showPath: boolean }>()
);

export const UpdatePathProps = createAction(
    RoutesActionTypes.UPDATE_PATH_PROPS,
    props<{ trekId: string; thickness: number; color: string }>()
);

export const RoutesComplete = createAction(RoutesActionTypes.ROUTE_COMPLETE);
