<app-register-form>
    <div class="content">
        <h1
            appTypography="TITLE_BOLD"
            [innerHTML]="'FREE_TRIAL_TITLE' | translate"></h1>
        <div
            class="subtitle"
            appTypography="SECONDARY">
            {{ 'REGISTRATION_VARIANT_1_SUBTITLE' | translate }}
        </div>
    </div>
</app-register-form>
