import { createReducer, on } from '@ngrx/store';
import * as StepsActions from '../actions/trek-wizard-stages.actions';
import {
    initialTrekState,
    trekWizardStageDetails
} from '../../constants/trek-wizard.const';

export const trekStagesReducer = createReducer(
    initialTrekState.activeStage,
    on(StepsActions.ChangeTrekStage, (state, { stage }) => {
        const newStage = trekWizardStageDetails.get(stage!);
        return newStage?.children?.length
            ? newStage.children[0]
            : newStage?.stage;
    }),
    on(StepsActions.TrekNextStage, (state) => {
        const arrayStages = Array.from(trekWizardStageDetails.values())
            .filter((stage) => !stage.children?.length)
            .map((stage) => stage.stage);
        const stateNumber = arrayStages.findIndex((stage) => stage === state);
        return arrayStages[stateNumber + 1];
    }),
    on(StepsActions.TrekPrevStage, (state) => {
        const arrayStages = Array.from(trekWizardStageDetails.values())
            .filter((stage) => !stage.children?.length)
            .map((stage) => stage.stage);
        const stateNumber = arrayStages.findIndex((stage) => stage === state);
        return arrayStages[stateNumber - 1];
    })
);
