import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WT_LOGO } from '../shared/shared.constants';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent {
    WT_LOGO = WT_LOGO;
}
