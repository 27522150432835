import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { environment } from '@env/environment';

@Component({
    standalone: true,
    imports: [
        TranslateModule,
        CommonModule,
        TypographyDirective,
        NgOptimizedImage
    ],
    selector: 'app-create-entity-card',
    templateUrl: './create-entity-card.component.html',
    styleUrls: ['./create-entity-card.component.scss']
})
export class CreateEntityCardComponent implements OnInit {
    @Input() hasPermission!: boolean;
    @Output() cardClicked = new EventEmitter();
    iconsBaseUrl = `${environment.ASSETS_BASE_URL}/assets/icons/`;

    @HostListener('click')
    onClick() {
        this.cardClicked.emit();
    }

    constructor() {}

    ngOnInit(): void {}
}
