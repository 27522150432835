import { SiteWizardStages } from '../../store/site-wizard-store.model';
import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../../standalone/components/wizard/wizars-template/wizard-template.model';

export enum SpotDialogStages {
    SPOTS = SiteWizardStages.SPOTS,
    GENERAL = SiteWizardStages.GENERAL,
    MEDIA = SiteWizardStages.MEDIA,
    SETTINGS = SiteWizardStages.SETTINGS,
    CONTACT = SiteWizardStages.CONTACT,
    HOURS = SiteWizardStages.HOURS,
    TAGS = SiteWizardStages.TAGS
}

export const spotWizardStageDetails: MapWizardStageDetails<SpotDialogStages> =
    new Map<SpotDialogStages, WizardSectionDetails<SpotDialogStages>>([
        [
            SpotDialogStages.SPOTS,
            {
                stage: SpotDialogStages.SPOTS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                navBarTextKey: 'SPOTS',
                icon: ' WTicon-Spot_big',
                children: [
                    SpotDialogStages.GENERAL,
                    SpotDialogStages.MEDIA,
                    SpotDialogStages.SETTINGS,
                    SpotDialogStages.CONTACT,
                    SpotDialogStages.HOURS,
                    SpotDialogStages.TAGS
                ]
            }
        ],
        [
            SpotDialogStages.GENERAL,
            {
                stage: SpotDialogStages.GENERAL,
                titleKey: 'WIZARD_TITLE_GENERAL',
                descriptionKey: 'WIZARD_DESCRIPTION_SPOT_GENERAL',
                genieTextKey: 'WIZARD_GENIE_SPOT_GENERAL',
                genieVideoUrl: 'NgYZppLpnuM',
                navBarTextKey: 'GENERAL',
                icon: ' WTicon-Spot_big',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG
            }
        ],
        [
            SpotDialogStages.MEDIA,
            {
                stage: SpotDialogStages.MEDIA,
                titleKey: 'WIZARD_TITLE_MEDIA',
                descriptionKey: 'WIZARD_DESCRIPTION_MEDIA',
                genieTextKey: 'WIZARD_GENIE_SPOT_GENERAL',
                genieVideoUrl: '12xsxd7afVc',
                navBarTextKey: 'MEDIA',
                icon: 'WTicon-menu-media1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.FULL_ON_DIALOG
            }
        ],
        [
            SpotDialogStages.SETTINGS,
            {
                stage: SpotDialogStages.SETTINGS,
                titleKey: 'WIZARD_TITLE_SPOT_SETTINGS',
                descriptionKey: '',
                genieTextKey: 'WIZARD_GENIE_SPOT_SETTINGS',
                genieVideoUrl: 'PrwRJvFNJyY',
                navBarTextKey: 'SETTINGS',
                icon: 'WTicon-Settings_small',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG,
                isLastOnViewLessMode: true
            }
        ],
        [
            SpotDialogStages.CONTACT,
            {
                stage: SpotDialogStages.CONTACT,
                titleKey: 'WIZARD_TITLE_CONTACT',
                descriptionKey: 'WIZARD_DESCRIPTION_CONTACT',
                genieTextKey: 'WIZARD_GENIE_SPOT_CONTACT',
                genieVideoUrl: '',
                navBarTextKey: 'CONTACT',
                icon: 'WTicon-menu-contact1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG
            }
        ],
        [
            SpotDialogStages.HOURS,
            {
                stage: SpotDialogStages.HOURS,
                titleKey: 'WIZARD_TITLE_SPOT_OPENING_HOURS',
                descriptionKey: 'WIZARD_DESCRIPTION_SPOT_OPENING_HOURS',
                genieTextKey: 'WIZARD_GENIE_SPOT_OPENING_HOURS',
                genieVideoUrl: 'YR6AQjwwhrM',
                navBarTextKey: 'OPENING_HOURS',
                icon: 'WTicon-menu-hours',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS_ON_DIALOG
            }
        ],
        [
            SpotDialogStages.TAGS,
            {
                stage: SpotDialogStages.TAGS,
                titleKey: 'WIZARD_TITLE_TAGS',
                descriptionKey: 'WIZARD_DESCRIPTION_SPOT_TAGS',
                genieTextKey: 'WIZARD_GENIE_SPOT_TAGS',
                genieVideoUrl: 'LUX93_1XN2I',
                navBarTextKey: 'TAGS',
                icon: 'WTicon-menu-tags1',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.TWO_COLUMNS_ON_DIALOG
            }
        ]
    ]);
