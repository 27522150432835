import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { ContactInformation } from '../../../site-wizard/models/site-wizard-contact.model';
import { ContactForm } from './contacts-form.model';

export const contactFormMaxValidators = {
    phone: 15,
    email: 320,
    url: 2048
};

export const fieldPatternValidators = {
    phone: '^[0-9\\-\\+]{9,15}$',
    url: 'https?://.+',
    facebook:
        '^(?:(?:http|https):\\/\\/)?(?:www.)?facebook.com\\/[A-Za-z0-9-.?=/\\s]*$',
    instagram: '^(?:(?:http|https):\\/\\/)?(?:www.)?instagram.com\\/.+',
    whatsappUrl: '^https:\\/\\/wa\\.me\\/\\d{7,15}$'
};

export function noWhitespaceValidator(control: AbstractControl): any {
    if (!control.value) {
        return;
    }
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : { whitespace: true };
}

export const getContactFormGroup = (
    contactInformation: ContactInformation
): FormGroup<ContactForm> =>
    new FormGroup<ContactForm>({
        phone: new FormControl(contactInformation?.phone, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.phone),
                Validators.pattern(fieldPatternValidators.phone)
            ],
            nonNullable: true
        }),
        email: new FormControl(contactInformation?.email, {
            validators: [
                noWhitespaceValidator,
                Validators.email,
                Validators.maxLength(contactFormMaxValidators.email)
            ],
            nonNullable: true
        }),
        website: new FormControl(contactInformation?.website, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.url)
            ],
            nonNullable: true
        }),
        facebook: new FormControl(contactInformation?.facebook, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.facebook)
            ],
            nonNullable: true
        }),
        instagram: new FormControl(contactInformation?.instagram, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.instagram)
            ],
            nonNullable: true
        }),
        whatsapp: new FormControl(contactInformation?.whatsapp, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.whatsappUrl)
            ],
            nonNullable: true
        }),
        tripadvisor: new FormControl(contactInformation?.tripadvisor, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.url)
            ],
            nonNullable: true
        }),
        expedia: new FormControl(contactInformation?.expedia, {
            validators: [
                noWhitespaceValidator,
                Validators.maxLength(contactFormMaxValidators.url),
                Validators.pattern(fieldPatternValidators.url)
            ],
            nonNullable: true
        })
    });
