import { createReducer, on } from '@ngrx/store';
import { initialState } from '../site-wizard-store.constants';
import * as PrivacyActions from '../actions/private.actions';

export const privacyReducer = createReducer(
    initialState.private,
    on(PrivacyActions.UpdatePrivacy, (state, { privacy }) => ({
        ...state,
        data: privacy
    })),
    on(PrivacyActions.PrivacyComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state.sectionStatus,
            complete: true
        }
    }))
);
