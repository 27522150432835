import { SocketEvents } from './models/site.model';
import { environment } from '@env/environment';

export const SITE_RATE_RANGE = {
    ALL_TIMES: 'ALL_TIMES',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_YEAR: 'LAST_YEAR',
    SPECIFIC_DATE: 'SPECIFIC_DATE'
};

export const SOCKET_EVENTS: SocketEvents = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    RECONNECT: 'reconnect',
    STATS_SPLIT: 'split',
    USER_LOCATION: 'user_location_update',
    ALERT_NEW: 'alert',
    ALERT_CONFIRM: 'alert_confirm',
    ALERT_ERROR: 'alert_error',
    ALERT_HISTORY_ADD: 'message',
    ALERT_HISTORY_REQUEST: 'get_message',
    SITE_ID: 'site_id'
};

export const SITE_CONSOLE_MINIMIZED_KEY = environment.production
    ? 'SiteConsoleMinimizedProd'
    : 'SiteConsoleMinimizedStg';

export const liveStats = {
    today: 'TOTAL_VISITORS_TODAY',
    week: 'TOTAL_VISITORS_THIS_WEEK',
    month: 'TOTAL_VISITORS_THIS_MONTH',
    year: 'TOTAL_VISITORS_THIS_YEAR',
    in_site: 'CURRENT_VISITORS_IN_SITE'
};
