import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, forkJoin, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import {
    SiteWizardState,
    SiteWizardStateResponse,
    WizardMode
} from '../../site-wizard/store/site-wizard-store.model';
import { map, tap } from 'rxjs/operators';
import { SiteCard, SiteListData } from '../models/site.model';
import { MEDIA_VIDEO_TYPES } from '../../shared/shared.constants';
import { getFileExtension } from '../../shared/shared-utils';
import { SiteMedia } from '../../site-wizard/models/site-wizard-media.model';

export type SiteListV2 = {
    nextPageOffset: number;
    sitesList: SiteListData[];
};

@Injectable()
export class SiteSharedService {
    // Use V2 of the API in sites component only. This API implements
    // pagination on the site list.
    sitesListNextOffset = 0;
    /*
     * @version 1
     */
    private baseSiteUrlV2 = `${environment.BUSINESS_URL}/api/v2/site`;
    /*
     * @version 2
     */
    private baseSiteUrlV1 = `${environment.BUSINESS_URL}/api/v1/sites`;

    constructor(private http: HttpClient) {}

    /*
     * @param {number} useApiVersion - Determine which version of API to use.
     */
    getSitesList(useApiVersion: number): Observable<SiteCard[] | null> {
        // in case the user reached the end of the list then don't ask for more
        // sites. (version 2 only).
        if (this.sitesListNextOffset < 0 && useApiVersion === 2) {
            return of(null);
        }
        return this.http
            .get<SiteListV2 | SiteListData[]>(
                `${
                    useApiVersion === 2
                        ? this.baseSiteUrlV2
                        : this.baseSiteUrlV1
                }/list?limit=11&offset=${this.sitesListNextOffset}`
            )
            .pipe(
                tap((response) => {
                    if (useApiVersion === 2) {
                        this.sitesListNextOffset = (
                            response as SiteListV2
                        ).nextPageOffset;
                    }
                }),
                concatMap((result: SiteListV2 | SiteListData[]) => {
                    const siteList =
                        useApiVersion === 2
                            ? (result as SiteListV2).sitesList
                            : (result as SiteListData[]);
                    return this.forkStreamBasedOnSiteType(siteList);
                }),
                map(
                    ([siteStates, siteHeaders, wizardModeMap]: [
                        [{ [state: string]: SiteWizardState }],
                        any,
                        any
                    ]) => {
                        const today = new Date();
                        const siteStatesWithMode = siteStates.map(
                            (siteState) => {
                                const state = siteState.state;
                                const defaultLanguage =
                                    siteState.state?.languages?.data
                                        ?.defaultLanguage;
                                const exp = new Date(
                                    wizardModeMap[state.id!]?.trialExpiration
                                );
                                return {
                                    ...state,
                                    media: state?.media?.data
                                        ? this.getStateMedia(state?.media.data)
                                        : undefined,
                                    name: state?.general?.data
                                        ? state?.general?.data[defaultLanguage!]
                                              ?.name
                                        : undefined,
                                    location: state?.general?.data
                                        ? state?.general?.data[defaultLanguage!]
                                              ?.locationDescription
                                        : undefined,
                                    country: state?.countryCode,
                                    ...wizardModeMap[state.id!],
                                    isExpired:
                                        wizardModeMap[state.id!]?.freeTrial &&
                                        wizardModeMap[state.id!]
                                            ?.trialExpiration &&
                                        today > exp
                                };
                            }
                        );

                        const siteHeadersWithMode = siteHeaders.map(
                            (siteHeader: any) => {
                                const exp = new Date(
                                    wizardModeMap[
                                        siteHeader.siteId
                                    ].trialExpiration
                                );
                                return {
                                    ...siteHeader,
                                    media: siteHeader?.url?.length
                                        ? this.getInfoMedia(siteHeader?.url)
                                        : undefined,
                                    ...wizardModeMap[siteHeader.siteId],
                                    isExpired:
                                        wizardModeMap[siteHeader.siteId]
                                            ?.freeTrial &&
                                        wizardModeMap[siteHeader.siteId]
                                            ?.trialExpiration &&
                                        today > exp
                                };
                            }
                        );
                        return [...siteStatesWithMode, ...siteHeadersWithMode];
                    }
                )
            );
    }

    getStateMedia(media: SiteMedia[]): string | undefined {
        let featuredMedia;
        for (const m of media) {
            if (
                m &&
                !MEDIA_VIDEO_TYPES.includes(getFileExtension(m.path) as string)
            ) {
                return (featuredMedia = m.path);
            }
        }
        return featuredMedia;
    }

    getInfoMedia(media: string[]): string | undefined {
        let featuredMedia;
        for (const m of media) {
            if (
                m &&
                !MEDIA_VIDEO_TYPES.includes(getFileExtension(m) as string)
            ) {
                return (featuredMedia = m);
            }
        }
        return featuredMedia;
    }

    getSiteHeaders(siteIds: string[]): Observable<any> {
        const config = {
            params: {
                siteIds: siteIds.join()
            }
        };
        return this.http.get(`${this.baseSiteUrlV1}/headers/`, config);
    }

    getState(siteId: string | string[]): Observable<SiteWizardStateResponse[]> {
        const config = {
            params: {
                siteId
            }
        };
        return this.http.get<SiteWizardStateResponse[]>(
            `${this.baseSiteUrlV1}/state`,
            config
        );
    }

    private forkStreamBasedOnSiteType(
        sitesWizardMode: SiteListData[]
    ): Observable<any> {
        const approvedSites: string[] = [];
        const wizardSites: string[] = [];
        const wizardModeMap = {}; //SiteWizardModeMap = {};

        sitesWizardMode.forEach((siteMode) => {
            if (
                siteMode.mode === WizardMode.APPROVED ||
                siteMode.mode === WizardMode.EDIT ||
                siteMode.mode === WizardMode.EDIT_REVIEW
            ) {
                approvedSites.push(siteMode.id);
            } else {
                wizardSites.push(siteMode.id);
            }

            (wizardModeMap as any)[siteMode.id] = siteMode;
        });

        return forkJoin([
            wizardSites.length ? this.getState(wizardSites) : of([]),
            approvedSites.length ? this.getSiteHeaders(approvedSites) : of([]),
            of(wizardModeMap)
        ]);
    }
}
