import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { User } from '../auth.model';

@Injectable()
export class PasswordService {
    private baseUrl = `${environment.BUSINESS_URL}/api/v1/password`;

    constructor(private http: HttpClient) {}

    validateToken(email: string, token: string): Observable<any> {
        const config = {
            params: {
                token,
                email
            }
        };
        return this.http.get(`${this.baseUrl}/validate`, config);
    }

    resetPassword(
        token: string,
        email: string,
        password: string
    ): Observable<User> {
        const data = {
            token,
            email,
            password
        };
        return this.http.put<User>(this.baseUrl, data);
    }

    sendEmail(email: string): Observable<any> {
        const config = {
            params: {
                email
            }
        };
        return this.http.get(this.baseUrl, config);
    }
}
