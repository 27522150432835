import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { TrekHeadersResultNew } from '../site-wizard.model';

@Injectable()
export class SearchTrekService {
    constructor(private http: HttpClient) {}

    getSearchedTreks(
        term: string | string[],
        key: string,
        limit: number,
        offset: number
    ): Observable<any> {
        const filterQuery = {
            query_filter: {
                filter: [
                    {
                        m_key: key,
                        m_value: term
                    }
                ],
                limit: {
                    trek: limit,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                offset: {
                    trek: offset,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                order_by: { trek_id: -1 }
            }
        };
        return this.http.post(
            `${environment.GATE_URL_2}/search/old/v2`,
            filterQuery
        );
    }

    getUserTreks(
        userId: string,
        limit: number,
        offset: number
    ): Observable<any> {
        const filterQuery = {
            query_filter: {
                filter: [
                    {
                        m_key: 'user_id',
                        m_value: userId
                    }
                ],
                limit: {
                    trek: limit,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                offset: {
                    trek: offset,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                order_by: { trek_id: -1 }
            }
        };

        return this.http.post(
            `${environment.GATE_URL_2}/search/old/v2`,
            filterQuery
        );
    }

    getTrekCoordinates(trekIds: string[]): Observable<any> {
        return this.http.get(
            `${environment.GATE_URL}/v2/trek/coordinates?trek_ids=${trekIds}`
        );
    }

    getTreksHeader(trekIds: string[]): Observable<TrekHeadersResultNew> {
        const params = {
            trek_ids: trekIds.join(','),
            fields: 'trek_id,trek_name,master_media'
        };
        return this.http.get<TrekHeadersResultNew>(
            `${environment.GATE_URL}/v2/trek/`,
            { params }
        );
    }
}
