import { createAction, props } from '@ngrx/store';
import { CardInput } from '../../../sites/models/site.model';

export enum PromotedActionTypes {
    PROMOTED_ADD_TREK = '[SiteWizard] Promoted Add Trek',
    PROMOTED_REMOVE_TREK = '[SiteWizard] Promoted Delete Trek',
    PROMOTED_TOGGLE_STAR_TREK = '[SiteWizard] Promoted Toggle Star Trek',
    PROMOTED_COMPLETE = '[SiteWizard] Promoted Complete'
}

export const AddTrek = createAction(
    PromotedActionTypes.PROMOTED_ADD_TREK,
    props<{ trekData: CardInput }>()
);

export const RemoveTrek = createAction(
    PromotedActionTypes.PROMOTED_REMOVE_TREK,
    props<{ id: string }>()
);

export const ToggleStarTreks = createAction(
    PromotedActionTypes.PROMOTED_TOGGLE_STAR_TREK
);

export const PromotedComplete = createAction(
    PromotedActionTypes.PROMOTED_COMPLETE
);
