<button
    *ngIf="!hrefLink && !link; else linkTemplate"
    [disabled]="isDisabled"
    [ngClass]="[
        buttonSize | lowercase,
        buttonStyle | lowercase,
        isContainsIcon ? 'is-contains-icon' : '',
        overrideColor ? 'override-color' : ''
    ]"
    [style.background-color]="overrideColor"
    [type]="buttonType"
    (click)="clicked.emit()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<ng-template #linkTemplate>
    <a
        *ngIf="hrefLink; else routerLinkTemplate"
        [href]="hrefLink"
        [target]="routerTarget"
        [ngClass]="[
            buttonSize | lowercase,
            buttonStyle | lowercase,
            overrideColor ? 'override-color' : ''
        ]"
        [style.background-color]="overrideColor"
        (click)="clicked.emit()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>
<ng-template #routerLinkTemplate>
    <a
        [routerLink]="link"
        [state]="routerState"
        [target]="routerTarget"
        [fragment]="fragment"
        [replaceUrl]="routerReplaceUrl"
        [ngClass]="[
            buttonSize | lowercase,
            buttonStyle | lowercase,
            overrideColor ? 'override-color' : ''
        ]"
        [style.background-color]="overrideColor"
        (click)="clicked.emit()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
