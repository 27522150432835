import { createAction, props } from '@ngrx/store';
import { GameWizardStages } from '../../constants/game-wizard.const';

export enum GameWizardStagesActionTypes {
    CHANGE_STAGE = '[GameWizard]: Change Stage',
    NEXT_STAGE = '[GameWizard] Next Stage',
    PREVIOUS_STAGE = '[GameWizard]: Previous Stage'
}

export const ChangeGameStage = createAction(
    GameWizardStagesActionTypes.CHANGE_STAGE,
    props<{ stage: GameWizardStages }>()
);
export const NextGameStage = createAction(
    GameWizardStagesActionTypes.NEXT_STAGE
);

export const PrevGameStage = createAction(
    GameWizardStagesActionTypes.PREVIOUS_STAGE
);
