import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SitesComponent } from './pages/sites/sites.component';
import { SiteMainMenuComponent } from './pages/site-main-menu/site-main-menu.component';
import { CreateSiteComponent } from './pages/create-site/create-site.component';
import { RatingComponent } from './pages/rating/rating.component';
import { SiteIndexComponent } from './pages/index/site-index.component';
import { MainComponent } from '../standalone/components/main/main.component';
import { LiveComponent } from './pages/live/live.component';
import { PrivatelyComponent } from './pages/privately/privately.component';
import { SitesGuard } from './guards/sites.guard';
import { PromoVideoComponent } from './pages/promo-video/promo-video.component';
import { PreviewSiteComponent } from './pages/preview-site/preview-site.component';
import { SmsInviteComponent } from './pages/sms-invite/sms-invite.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ScheduledAnnouncementComponent } from './pages/scheduled-announcement/scheduled-announcement.component';
import { settingsPageResolver } from './resolvers/settings-page.resolver';
import { MyQrPageComponent } from './pages/my-qr-page/my-qr-page.component';
import { ImplementationComponent } from './pages/implementation/implementation.component';
import { QRPageResolver } from './resolvers/qrpage.resolver';
import { SiteResolver } from './resolvers/site.resolver';

export enum SitesRoutePaths {
    SITE_WIZARD = 'wizard',
    CREATE_SITE = 'create-site',
    RATING = 'rating',
    LIVE = 'live',
    PRIVATELY = 'privately',
    VIDEO = 'video',
    SETTINGS = 'settings',
    PREVIEW_SITE = 'preview-site',
    SMS_INVITE = 'sms-invite',
    SCHEDULED_ANNOUNCEMENTS = 'scheduled-announcement',
    MY_QR = 'my-qr',
    IMPLEMENTATION = 'implementation',
    DASHBOARD = 'dashboard',
    GAMES = 'games',
    CAMPAIGNS = 'campaigns'
}

const SITES_ROUTES: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                component: SitesComponent,
                canActivate: [SitesGuard]
            },
            {
                path: `${SitesRoutePaths.CREATE_SITE}`,
                component: CreateSiteComponent
            }
        ]
    },
    {
        path: ':siteId',
        component: SiteMainMenuComponent,
        resolve: {
            siteInfo: SiteResolver
        },
        children: [
            {
                path: '',
                component: SiteIndexComponent
            },
            {
                path: SitesRoutePaths.SITE_WIZARD,
                loadChildren: () =>
                    import('../site-wizard/site-wizard.module').then(
                        (m) => m.SiteWizardModule
                    )
            },
            {
                path: SitesRoutePaths.RATING,
                component: RatingComponent,
                data: {
                    breadcrumb: 'SITE_RATING'
                }
            },
            {
                path: SitesRoutePaths.LIVE,
                component: LiveComponent,
                data: {
                    breadcrumb: 'SITE_LIVE_MAP'
                }
            },
            {
                path: SitesRoutePaths.PRIVATELY,
                component: PrivatelyComponent,
                data: {
                    breadcrumb: 'SITE_SHARE_PRIVATELY'
                }
            },
            {
                path: SitesRoutePaths.VIDEO,
                component: PromoVideoComponent,
                data: {
                    breadcrumb: 'SITE_PROMO_VIDEO'
                }
            },
            {
                path: SitesRoutePaths.SETTINGS,
                component: SettingsComponent,
                data: {
                    breadcrumb: 'SETTINGS'
                },
                resolve: { settingsPageData: settingsPageResolver }
            },

            {
                path: SitesRoutePaths.PREVIEW_SITE,
                component: PreviewSiteComponent,
                data: {
                    breadcrumb: 'SITE_VIEW_SITE'
                }
            },
            {
                path: SitesRoutePaths.SMS_INVITE,
                component: SmsInviteComponent,
                data: {
                    breadcrumb: 'SITE_SMS_INVITE'
                }
            },
            {
                path: SitesRoutePaths.SCHEDULED_ANNOUNCEMENTS,
                component: ScheduledAnnouncementComponent,
                data: {
                    breadcrumb: 'SITE_TIMED_ANNOUNCEMENTS'
                }
            },
            {
                path: SitesRoutePaths.MY_QR,
                component: MyQrPageComponent,
                data: {
                    breadcrumb: 'MY_QR'
                },
                resolve: {
                    spotsAndTreks: QRPageResolver
                }
            },
            {
                path: SitesRoutePaths.IMPLEMENTATION,
                component: ImplementationComponent,
                data: {
                    breadcrumb: 'IMPLEMENTATION_BOX_TITLE'
                }
            },
            {
                path: SitesRoutePaths.DASHBOARD,
                loadChildren: () =>
                    import('../dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                data: {
                    breadcrumb: 'SITE_DASHBOARD'
                }
            },

            {
                path: SitesRoutePaths.GAMES,
                data: {
                    breadcrumb: 'GAMES'
                },
                loadChildren: () =>
                    import('./../games/games.module').then((m) => m.GamesModule)
            },
            {
                path: SitesRoutePaths.CAMPAIGNS,
                data: {
                    breadcrumb: 'SITE_DYNAMIC_CROWDFUNDING'
                },
                loadChildren: () =>
                    import('../dcf/dcf.module').then((m) => m.DcfModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(SITES_ROUTES)],
    exports: [RouterModule]
})
export class SitesRoutingModule {}
