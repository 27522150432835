import { createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { updateDcfActivate } from '../actions/dcf-wizard-activate.action';
import { DcfWizardActivateSection } from '../../models/dcf-wizard-activate.model';

export const dcfWizardActivateReducer = createReducer(
    {} as DcfWizardActivateSection,
    immerOn(updateDcfActivate, (state) => {
        state.data!.active = !state.data?.active;
    })
);
