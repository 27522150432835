import { Injectable } from '@angular/core';
import { UserActions } from '../auth/auth.model';
import { AppStoreService } from '../store/app-store.service';

@Injectable()
export class PermissionsService {
    constructor(private appStoreService: AppStoreService) {}

    checkIfUserHasPermission(permission: string, resourceId?: string): boolean {
        if (!resourceId) {
            resourceId = this.appStoreService.state.auth.user?.businessId;
        }
        const actions: UserActions =
            this.appStoreService.state.auth.user?.actions!;
        // @ts-ignore
        return actions[resourceId]?.includes(permission);
    }
}
