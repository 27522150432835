import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CreateGameResponse,
    Game,
    SiteGamesList
} from '../models/game.interface';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { gameRequestFields } from '../constants/games.const';

@Injectable()
export class GamesService {
    siteBaseUrl = `${environment.BUSINESS_URL}/api/v2/site`;
    gameBaseUrl = `${environment.BUSINESS_URL}/api/v2/game`;

    constructor(private http: HttpClient) {}

    getSiteGamesList(siteId: string): Observable<SiteGamesList> {
        return this.http.get<SiteGamesList>(
            `${this.siteBaseUrl}/${siteId}/game`
        );
    }

    getGamesDetails(gameIds: string[]): Observable<Game[]> {
        const options = {
            params: {
                gameIds: gameIds.join(','),
                isEdit: true,
                fields: gameRequestFields
            }
        };
        return this.http
            .get<{ games: Game[] }>(`${this.gameBaseUrl}`, options)
            .pipe(map((res) => res.games));
    }

    createGame(siteId: string): Observable<CreateGameResponse> {
        return this.http.post<CreateGameResponse>(
            `${this.siteBaseUrl}/${siteId}/game`,
            {}
        );
    }

    duplicateGame(gameId: string) {
        return this.http
            .post<Game>(`${this.gameBaseUrl}/${gameId}/duplicate`, {})
            .pipe(map((res) => res.data));
    }

    deleteGame(gameId: string): Observable<{ data: unknown }> {
        return this.http.delete<{ data: unknown }>(
            `${this.gameBaseUrl}/${gameId}`
        );
    }
}
