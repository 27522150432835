import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { LOCALES } from '../../shared/locales.constants';
import { ContactStandaloneComponent } from '../../standalone/components/contact/contact-standalone.component';
import { FacebookPixelService } from '../../services/facebook-pixel.service';
import {
    WT_PRIVACY_POLICY_URL,
    WT_TOS_URL,
    WT_TRANSLATE_PRIVACY_POLICY_URL,
    WT_TRANSLATE_TOS_URL
} from '../../shared/shared.constants';
import { LocaleType } from '../../shared/locales.models';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SocialLinks, socialLinksMap } from './app-footer.consts';

@Component({
    selector: 'app-footer',
    templateUrl: 'app-footer.component.html',
    styleUrls: ['./app-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent implements OnInit {
    websiteLocales = {} as Record<LocaleType, string>;
    siteLang!: LocaleType;
    websiteUrl = environment.WEBSITE_URL;
    businessUrl = environment.BUSINESS_URL_WEBSITE_NEW;
    tosUrl = WT_TOS_URL;
    privacyPolicyUrl = WT_PRIVACY_POLICY_URL;
    currentYear = JSON.stringify(new Date().getFullYear());
    socialLinksMap = socialLinksMap;
    SocialLinks = SocialLinks;
    @Input() isMinimizedFooter!: boolean;

    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private facebookPixelService: FacebookPixelService
    ) {}

    ngOnInit(): void {
        this.siteLang = this.translateService.currentLang as LocaleType;
        this.websiteLocales = (
            this.translateService.getLangs() as LocaleType[]
        ).reduce((localeMap, locale) => {
            localeMap[locale] = LOCALES[locale];
            return localeMap;
        }, {} as Record<LocaleType, string>);
        const translateLang = this.siteLang.split('_')[0];
        this.tosUrl =
            translateLang === 'en'
                ? WT_TOS_URL
                : `${WT_TRANSLATE_TOS_URL}?_x_tr_sl=en&_x_tr_tl=${translateLang}&_x_tr_hl=${translateLang}&_x_tr_pto=wapp`;
        this.privacyPolicyUrl =
            translateLang === 'en'
                ? WT_PRIVACY_POLICY_URL
                : `${WT_TRANSLATE_PRIVACY_POLICY_URL}?_x_tr_sl=en&_x_tr_tl=${translateLang}&_x_tr_hl=${translateLang}&_x_tr_pto=wapp`;
    }

    changeWebsiteLanguage(locale: string): void {
        localStorage.setItem('LANG_KEY', locale);
        this.siteLang = locale as LocaleType;
        this.translateService
            .use(locale)
            .pipe(take(1))
            .subscribe(() => window.location.reload());
    }

    openContact(): void {
        this.matDialog.closeAll();
        this.matDialog.open(ContactStandaloneComponent);
    }

    emitFbqEvent(linkId: string): void {
        this.facebookPixelService.fireFbqEvent(
            'trackCustom',
            'wtInteraction',
            linkId,
            'click'
        );
    }
}
