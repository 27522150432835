import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HubspotMeetingWidgetService {
    private _displayMeetingWidgetUrl$ = new BehaviorSubject('');
    get displayMeetingWidgetUrl$() {
        return this._displayMeetingWidgetUrl$.asObservable();
    }

    get displayMeetingWidgetUrl() {
        return this._displayMeetingWidgetUrl$.value;
    }

    set displayMeetingWidgetUrl(value: string) {
        const userAgent = window.navigator.userAgent;
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        const isiOS =
            /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
        if (value) {
            document.body.style.overflow = 'hidden';
            if (isSafari && isiOS) {
                document.body.style.position = 'fixed';
            }
        } else {
            document.body.style.overflow = 'unset';
            if (isSafari && isiOS) {
                document.body.style.position = 'unset';
            }
        }
        this._displayMeetingWidgetUrl$.next(value);
    }
}
