<div class="site-main-menu-page">
    <app-site-menu
        *ngIf="!(hideSitesMenuView$ | async)"
        (toggleAlerts)="toggleAlerts()"
        [hasAccessAlerts]="hasAccessAlerts!"
        [site]="site"></app-site-menu>

    <div class="site-menu-container">
        <router-outlet></router-outlet>
    </div>
</div>
<div
    *ngIf="!(hideSitesMenuView$ | async)"
    [ngClass]="{ opened: alertsOpened$ | async }"
    class="site-alerts">
    <app-alerts-dialog
        (loadMoreAlerts)="loadMoreAlerts()"
        (openNewAlert)="toggleNewAlert()"
        (toggleAlerts)="toggleAlerts()"
        *ngIf="!(newAlertOpened$ | async); else newAlert"
        [alerts]="(notificationsList$ | async)!"
        [spots]="site.spots!">
    </app-alerts-dialog>
    <ng-template #newAlert>
        <app-new-alert (closeNewAlert)="toggleNewAlert()"></app-new-alert>
    </ng-template>
</div>
