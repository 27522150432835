import { SiteWizardState, WizardMode } from './site-wizard-store.model';
import { initialState } from './site-wizard-store.constants';
import { StatusLabel } from '../../standalone/components/card-label/card-label.consts';

export function CreateNewSiteState(
    siteId: string,
    businessId: string,
    isLiteWizard: boolean
): SiteWizardState {
    return {
        ...initialState,
        id: siteId,
        businessId,
        mode: WizardMode.CREATE,
        isLiteWizard
    };
}

export function orderItems(items: any[]): any[] {
    return items.map((item, i) => ({
        ...item,
        order: i
    }));
}

export const siteStatusLabelMap = new Map<WizardMode, StatusLabel>([
    [WizardMode.CREATE, StatusLabel.INACTIVE],
    [WizardMode.REVIEW, StatusLabel.IS_EDIT],
    [WizardMode.EDIT_REVIEW, StatusLabel.ACTIVE],
    [WizardMode.EDIT, StatusLabel.PENDING]
]);
