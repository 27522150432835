import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { GameCardData, GameData } from '../../models/game.interface';
import { environment } from '@env/environment';
import { StatusLabel } from '../../../standalone/components/card-label/card-label.consts';

@Component({
    selector: 'app-game-card',
    templateUrl: './game-card.component.html',
    styleUrls: ['./game-card.component.scss']
})
export class GameCardComponent implements OnInit {
    protected readonly StatusLabel = StatusLabel;
    @Input() gameData!: GameCardData;
    @Output() deleteGame = new EventEmitter<GameData>();
    @Output() cardClicked = new EventEmitter<void>();
    @Output() duplicateCardClicked = new EventEmitter<void>();

    backgroundImage!: string;

    ngOnInit() {
        this.backgroundImage =
            this.gameData.icon ||
            environment.ASSETS_BASE_URL + '/assets/images/card-placeholder.jpg';
    }

    @HostListener('click')
    onCardClick() {
        this.cardClicked.emit();
    }
}
