import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';

export enum GameWizardGeneralDetailsActionTypes {
    UPDATE_GAME_NAME = '[GameWizard]:General Details Update Name',
    UPDATE_GAME_SUBTITLE = '[GameWizard]:General Details Update Subtitle',
    UPDATE_GAME_ICON = '[GameWizard]:General Details Update Icon',
    SET_SELECTED_LANG_TAB = '[GameWizard]:General Details Set Selected Lang Tab'
}

export const updateGameName = createAction(
    GameWizardGeneralDetailsActionTypes.UPDATE_GAME_NAME,
    props<{ name: string; local: LocaleType }>()
);

export const updateGameSubtitle = createAction(
    GameWizardGeneralDetailsActionTypes.UPDATE_GAME_SUBTITLE,
    props<{ subtitle: string; local: LocaleType }>()
);
export const updateGameIcon = createAction(
    GameWizardGeneralDetailsActionTypes.UPDATE_GAME_ICON,
    props<{ icon: string }>()
);
export const setGeneralDetailsSelectedLangTab = createAction(
    GameWizardGeneralDetailsActionTypes.SET_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
