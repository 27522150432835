import { createAction, props } from '@ngrx/store';
import { SiteLifeCycleStateType } from '../../site-wizard.model';

export enum SiteLifeCycleActionTypes {
    SET_SITE_LIFECYCLE = '[SiteWizard] Set Lifecycle'
}

export const setLifecycle = createAction(
    SiteLifeCycleActionTypes.SET_SITE_LIFECYCLE,
    props<{ lifeCycleState: SiteLifeCycleStateType }>()
);
