<form [formGroup]="announcementForm">
    <div class="announcement-details">
        <app-custom-input-standalone
            [label]="'ANNOUNCEMENT_TITLE_PLACEHOLDER' | translate"
            formControlName="name"
            type="text"
            cdkAutosizeMinRows="15"></app-custom-input-standalone>

        <p appTypography="SECONDARY">
            {{ 'SCHEDULED_ANNOUNCEMENTS_POP_UP_DESCRIPTION' | translate }}
        </p>
        <p appTypography="SECONDARY_BOLD">
            {{ 'ADD_YOUR_ANNOUNCEMENT_BELOW' | translate }}
        </p>
        <app-custom-textarea-standalone
            class="announcement-message"
            [placeholder]="'ANNOUNCEMENT_TEXT_PLACEHOLDER'"
            formControlName="message"
            [rows]="3"
            [maxLength]="250"></app-custom-textarea-standalone>
        <app-custom-input-standalone
            [label]="'RELATED_SPOT' | translate"
            [autoCompleteOptions]="spotOptionList"
            [autoCompleteDisplayWithFn]="displaySpotOptionFunc"
            formControlName="soiId"></app-custom-input-standalone>
    </div>
    <div class="announcement-alerts">
        <p appTypography="SECONDARY_BOLD">
            {{ 'WHEN_DO_YOU_WANT_IT_SENT' | translate }}
        </p>
        <p appTypography="SECONDARY">
            {{ data.timezone }} {{ 'TIME_TIMEZONE' | translate }}
        </p>

        <mat-tab-group [(selectedIndex)]="selectedTabIndex">
            <mat-radio-group aria-label="Select an option">
                <mat-tab
                    *ngFor="
                        let option of SendAlertTime
                            | keyvalue : originalOrderOption;
                        let i = index
                    ">
                    <ng-template mat-tab-label>
                        <mat-radio-button
                            [checked]="selectedTabIndex === i"
                            [id]="'option-' + option.value"
                            [value]="option.value">
                            {{ option.value | translate }}
                        </mat-radio-button>
                    </ng-template>
                    <ng-template matTabContent>
                        <div
                            *ngIf="option.value === SendAlertTime.EVERYDAY"
                            class="hours-tab-content">
                            <div class="section">
                                <app-timepicker
                                    [timeFormat]="siteTimeFormat"
                                    [max]="'23:59'"
                                    [placeholder]="'HH_MM' | translate"
                                    formControlName="everyDay"></app-timepicker>
                            </div>
                        </div>
                        <div
                            class="specific-day"
                            *ngIf="option.value === SendAlertTime.DAYS">
                            <div
                                class="wrapper-hours"
                                *ngFor="let day of days; let i = index"
                                formArrayName="days">
                                <mat-checkbox
                                    (change)="
                                        !$event.checked &&
                                            deleteDayAlert($event.checked, i)
                                    "
                                    [checked]="
                                        !!$any(announcementForm.controls.days)
                                            ?.controls[i].value
                                    "
                                    #dayCheckbox>
                                    {{ daysNames.get(day)! | translate }}
                                </mat-checkbox>
                                <div>
                                    <app-timepicker
                                        [disabled]="!dayCheckbox.checked"
                                        [timeFormat]="siteTimeFormat"
                                        [max]="'23:59'"
                                        [placeholder]="'HH_MM' | translate"
                                        [formControl]="
                                            $any(announcementForm.controls.days)
                                                ?.controls[i]
                                        "></app-timepicker>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-radio-group>
        </mat-tab-group>
    </div>
</form>

<!--************-->

<app-standalone-buttons-container
    class="mat-dialog-actions"
    mat-dialog-actions>
    <app-standalone-custom-button
        [isDisabled]="announcementForm.invalid"
        buttonType="submit"
        (clicked)="onSaveAnnouncement()">
        {{ 'SAVE' | translate }}
    </app-standalone-custom-button>
    <app-standalone-custom-button
        *ngIf="data.alert"
        (clicked)="onDeleteAnnouncement()"
        buttonStyle="WARN"
        buttonType="submit">
        {{ 'DELETE_ANNOUNCEMENT' | translate }}
    </app-standalone-custom-button>
    <app-standalone-custom-button
        *ngIf="!data.alert"
        mat-dialog-close
        buttonStyle="SECONDARY">
        {{ 'CANCEL' | translate }}
    </app-standalone-custom-button>
</app-standalone-buttons-container>
<div
    class="close-dialog"
    mat-dialog-close>
    <i class="WTicon-round-close">
        <span class="path1"></span>
        <span class="path2"></span>
    </i>
</div>
