<div
    class="page-content-wrapper"
    *ngIf="displayTreks$ | async as displayTreks">
    <div
        *ngIf="showSpinner && !displayTreks.length"
        class="inline-spinner">
        <mat-spinner></mat-spinner>
    </div>
    <ng-container *ngIf="!showSpinner || displayTreks.length">
        <div class="filters-section">
            <app-search-input
                class="search-input"
                (searchTerm)="filerByTerm($event)"></app-search-input>

            <div
                class="filter-mode"
                *ngIf="treks?.length! > 3"
                appTypography="SECONDARY">
                {{ 'FILTER' | translate }}:
                <div
                    *ngFor="let mode of TrekWizardMode | keyvalue"
                    class="filter"
                    (click)="filterByMode(mode.value)"
                    [style]="{
                        'background-color': statusLabelColorMap.get(
                            trekStatusLabelMap.get($any(mode.key))!
                        )
                    }"
                    appTypography="SECONDARY"
                    color="WHITE"
                    [ngClass]="{ active: filteredMode === mode.key }">
                    {{ mode.key | translate }}
                </div>
            </div>
        </div>
        <div
            class="not-found"
            *ngIf="treks.length && !displayTreks?.length"
            appTypography="SECONDARY">
            {{ 'NO_ITEMS_PLACEHOLDER' | translate }}
        </div>
        <div
            class="trek-cards"
            infiniteScroll
            (scrolled)="onScrollDown()"
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="0">
            <app-create-entity-card
                (cardClicked)="createTrek()"
                [hasPermission]="hasAccessCreateTrek!">
                <h3 appTypography="PRIMARY_BOLD">
                    {{ 'CREATE_NEW_TREK' | translate }}
                </h3>
            </app-create-entity-card>
            <app-trek-card
                *ngFor="let trek of displayTreks"
                [trek]="trek"
                (deleteTrekEmit)="deleteTrek($event)">
            </app-trek-card>
            <div
                *ngIf="showSpinner && displayTreks?.length"
                class="inline-spinner">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </ng-container>
</div>
