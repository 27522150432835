import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppLoadService } from './services/app-load.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { rootReducer } from './store/app-store.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MapboxModule } from './mapbox/mapbox.module';
import { environment } from '@env/environment';
import { TypographyDirective } from './standalone/directives/typography/typography.directive';
import { HubspotMeetingWidgetComponent } from './standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.component';
import { AppStoreEffect } from './store/app-store.effect';
import { BreadcrumbsComponent } from './standalone/components/breadcrumbs/breadcrumbs.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { NgOptimizedImage } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RequestIntercepter } from './services/http-interceptor.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import {
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatDialogModule
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderComponent } from './standalone/components/header/header.component';
import { HeaderLinksComponent } from './standalone/components/header/components/header-links/header-links.component';
import { AccessibilityIconComponent } from './components/accessibility-icon/accessibility-icon.component';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(rootReducer),
        EffectsModule.forRoot([AppStoreEffect]),
        StoreDevtoolsModule.instrument(),
        MapboxModule,
        TypographyDirective,
        HubspotMeetingWidgetComponent,
        BreadcrumbsComponent,
        NgOptimizedImage,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatSidenavModule,
        MatDialogModule,
        MatSnackBarModule,
        HeaderComponent,
        HeaderLinksComponent,
        AccessibilityIconComponent
    ],
    declarations: [AppComponent, AppFooterComponent, SpinnerComponent],
    providers: [
        TranslateService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AppLoadService],
            useFactory: (appLoadService: AppLoadService) => () =>
                appLoadService.initApp()
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestIntercepter,
            multi: true
        },
        {
            provide: ErrorHandler
            //  useValue: Sentry.createErrorHandler()
        },
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useValue: MAT_AUTOCOMPLETE_SCROLL_STRATEGY
        },

        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                position: {
                    top: '10vh'
                },
                hasBackdrop: true,
                panelClass: 'wt-dialog-cdk-overlay-pane',
                closeOnNavigation: true
            }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        }
    ],

    bootstrap: [AppComponent]
})
export class AppModule {}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    const prefix = environment.ASSETS_BASE_URL + '/';
    return new TranslateHttpLoader(http, `${prefix}strings/strings.`, '.json');
}
