<div class="warning-dialog">
    <div
        *ngIf="data.showExitButton"
        class="close-dialog"
        [mat-dialog-close]="WarningActions.EXIT">
        <i class="WTicon-round-close">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
    </div>
    <div class="dialog-title">
        <div
            class="text"
            appTypography="PRIMARY"
            color="WHITE">
            {{ data.title | translate }}
        </div>
    </div>
    <div class="content">
        <div
            [innerHTML]="data.image"
            class="image"></div>
        <div
            appTypography="SECONDARY"
            class="text">
            {{ data.content | translate }}
        </div>
    </div>
    <app-standalone-buttons-container mat-dialog-actions>
        <app-standalone-custom-button
            *ngIf="data.actionText"
            id="warning-action-button"
            [mat-dialog-close]="WarningActions.ACTION">
            {{ data.actionText | translate }}
        </app-standalone-custom-button>
        <app-standalone-custom-button
            *ngIf="data.deleteText"
            id="warning-delete-button"
            buttonStyle="WARN"
            [mat-dialog-close]="WarningActions.DELETE">
            {{ data.deleteText | translate }}
        </app-standalone-custom-button>
        <app-standalone-custom-button
            *ngIf="data.cancelText"
            id="warning-cancel-button"
            buttonStyle="SECONDARY"
            [mat-dialog-close]="WarningActions.CANCEL">
            {{ data.cancelText | translate }}
        </app-standalone-custom-button>
    </app-standalone-buttons-container>
</div>
