<div class="login-form-wrapper">
    <h1
        appTypography="TITLE_BOLD"
        translate="WELCOME_BACK"></h1>
    <p
        appTypography="SECONDARY"
        class="subtitle"
        translate="LOG_IN_TO_WT"></p>
    <form
        class="login-form"
        [formGroup]="loginForm"
        id="login-form">
        <app-custom-input-standalone
            [appErrorDot]="!!loginForm.controls.email.errors"
            [label]="'EMAIL' | translate"
            formControlName="email"
            id="login-email"
            type="email"></app-custom-input-standalone>

        <app-custom-input-standalone
            [appErrorDot]="!!loginForm.controls.password.errors"
            [label]="'PASSWORD' | translate"
            formControlName="password"
            id="login-password"
            type="password"></app-custom-input-standalone>
        <div class="forgot-password">
            <a
                appTypography="SECONDARY_UNDERLINE"
                [routerLink]="[
                    '/',
                    AppRoutePaths.AUTH,
                    AuthRoutePaths.FORGOT_PASSWORD
                ]"
                >{{ 'FORGOT_PASSWORD' | translate }}</a
            >
        </div>
        <div
            *ngIf="loginError"
            id="login-error"
            class="form-error-message"
            translate="LOGIN_ERROR"></div>
        <app-standalone-custom-button
            buttonType="submit"
            class="login-btn"
            id="login-submit"
            (clicked)="logIn()"
            [isDisabled]="loginForm.invalid">
            {{ 'LOGIN' | translate }}
        </app-standalone-custom-button>
    </form>
    <p appTypography="SECONDARY">
        {{ 'NEED_HELP_CONTACT' | translate }}
        <a
            appTypography="SECONDARY_UNDERLINE"
            (click)="openContact()"
            translate="TECH_SUPPORT"></a>
    </p>
</div>
<p
    appTypography="SECONDARY"
    class="register-or-login">
    <span [innerHTML]="'DONT_HAVE_ACCOUNT' | translate"></span>
    <a
        appTypography="SECONDARY_UNDERLINE"
        [routerLink]="['/', AppRoutePaths.AUTH, AuthRoutePaths.REGISTER]"
        translate="REGISTER_HERE"></a>
</p>
