import * as mapboxgl from 'mapbox-gl';
import { LatLon, LatLng } from '../typings/mapbox-utils';
import { Position } from 'geojson';

export function lngLatsToPositions(
    lngLats: Array<mapboxgl.LngLat>
): Array<GeoJSON.Position> {
    return lngLats.map((lngLat) => lngLatToPosition(lngLat));
}

export function lngLatToPosition(lngLat: mapboxgl.LngLat): GeoJSON.Position {
    return [lngLat.lng, lngLat.lat];
}

export function latLonToPosition(latLon: LatLon): GeoJSON.Position {
    return [latLon.lon, latLon.lat];
}

export function latLngToPosition(latLng: LatLng): GeoJSON.Position {
    return [latLng.lng, latLng.lat];
}

export function latLonToLngLat(latLon: LatLon): mapboxgl.LngLat {
    return new mapboxgl.LngLat(latLon.lon, latLon.lat);
}

export function lngLatLikeToPosition(lngLat: any): number[] {
    // mapboxgl.LngLatLike
    return Array.isArray(lngLat) ? lngLat : [lngLat.lng, lngLat.lat];
}

export function lngLatLikeToLngLat(lngLat: mapboxgl.LngLatLike): any {
    return Array.isArray(lngLat)
        ? new mapboxgl.LngLat(lngLat[0], lngLat[1])
        : lngLat;
}

export function latLonsToPositions(
    latLons: Array<LatLon>
): Array<GeoJSON.Position> {
    return latLons.map((latLon) => latLonToPosition(latLon));
}

export function latLngsToPositions(
    latLngs: Array<LatLng>
): Array<GeoJSON.Position> {
    return latLngs.map((latLng) => latLngToPosition(latLng));
}

export function latLonsToLngLats(
    latLons: Array<LatLon>
): Array<mapboxgl.LngLat> {
    return latLons.map((latLon) => latLonToLngLat(latLon));
}

export function positionToLngLat(position: Position): mapboxgl.LngLat {
    return new mapboxgl.LngLat(position[0], position[1]);
}

export function locToLngLat(loc: [number, number]): mapboxgl.LngLat {
    return new mapboxgl.LngLat(loc[1], loc[0]);
}

export function LatLngsToLatLons(latLngs: LatLng[]): Array<LatLon> {
    return latLngs.map((latLng) => LatLngToLatLon(latLng));
}

export function LatLngToLatLon(latLng: LatLng): LatLon {
    return {
        lat: latLng.lat,
        lon: latLng.lng
    };
}

export function PositionsToLngLats(
    positions: Position[]
): Array<mapboxgl.LngLat> {
    return positions.map((position) => positionToLngLat(position));
}
