import { LatLon } from '../mapbox/typings/mapbox-utils';
import { SiteLanguages } from './models/site-wizard-languages.model';
import { SiteGeneralDetails } from './models/site-wizard-general-details.model';
import { SiteMedia } from './models/site-wizard-media.model';
import { ContactInformation } from './models/site-wizard-contact.model';
import { SiteHours } from './models/site-wizard-hours.model';
import { SiteTags } from './models/site-wizard-tags.model';
import { SiteMap } from './models/site-wizard-map-setup.model';
import { SiteRoute } from './models/site-wizard-routes.model';
import { SiteSpotPublished } from './models/site-wizard-spots.model';
import { SiteCategory } from './models/site-wizard-categories.model';
import { SiteGreeting } from './models/site-wizard-greeting.model';
import { PromotedTreks } from './models/site-wizard-promoted-treks.model';
import { LocaleType } from '../shared/locales.models';
import { WizardMode } from './store/site-wizard-store.model';

export interface CountryBoundaries {
    [countryCode: string]: {
        sw: LatLon;
        ne: LatLon;
    };
}

export interface SiteInfo {
    id?: string;
    businessId?: string;
    wizardMode?: WizardMode;
    languages?: SiteLanguages;
    general?: Record<LocaleType, SiteGeneralDetails>;
    media?: SiteMedia[];
    contact?: ContactInformation;
    hours?: SiteHours;
    tags?: SiteTags;
    map?: SiteMap;
    siteRoutes?: SiteRoute[];
    spots?: SiteSpotPublished[];
    categories?: SiteCategory[];
    greeting?: SiteGreeting;
    promotedTreks?: PromotedTreks;
    showLogoInMovie?: boolean;
    private?: boolean;
    countryCode?: LocaleType;
}

export enum OoiType {
    TOI = 'TOI',
    SOI = 'SOI'
}

export interface DensityPoint extends LatLon {
    total: number;
}

export interface CreateSiteResponse {
    siteId: string;
}

export interface TrekHeadersResultNew {
    data: TrekHeaderResultNew[];
    error: any;
}

export interface TrekHeaderResultNew {
    data: TrekHeader;
}

export interface TrekHeader {
    trek_id: string;
    trek_name: string;
    country: string;
    location: string;
    user_id: string;
    user_name: string;
    start_point: [number, number];
    labels: number[];
    trek_privacy: number;
    media_privacy: number;
    urls: TrekInfoUrl[];
    master_media: TrekInfoUrl;
    is_in_wish_list: boolean;
    start_date: number;
    time: number;
    difficulty: number;
    comment_count: string;
    totalLength: number;
    difficultyLevel: string;
}

export interface TrekInfoUrl {
    path: string;
    id: string;
    ts: string;
    type: string;
    locLat: number;
    locLon: number;
}

export interface SiteLifeCycleState {
    siteId: string;
    state: SiteLifeCycleStateType;
}

export enum SiteLifeCycleStateType {
    NEVER_EDITED = 'neverEditedSiteWizard',
    EDITED = 'editedSiteWizard',
    PENDING = 'pending'
}

export enum SiteWizardRoutePaths {
    MANAGE_TREKS = 'manage-treks'
}
