import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';

export enum CountryActionTypes {
    UPDATE_COUNTRY = '[SiteWizard] Update Country'
}

export const UpdateCountry = createAction(
    CountryActionTypes.UPDATE_COUNTRY,
    props<{ countryCode: LocaleType }>()
);
