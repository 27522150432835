import { Locales, LocaleType } from './locales.models';
import { AbstractControl } from '@angular/forms';

export function parseLocales(
    locales: Locales
): [string[], Record<string, LocaleType>] {
    const languages: any = [];
    const mapLangToLocale: any = {};

    Object.entries(locales).map(([locale, language]) => {
        languages.push(language);
        mapLangToLocale[language] = locale;
    });
    languages.sort();
    return [languages, mapLangToLocale];
}

export function getSupportedContentTypes(fileTypes: string[]): string {
    return fileTypes
        .map((fileType) => getContentTypeByFile(`.${fileType}`))
        .join(',');
}

export function getContentTypeByFile(fileName: string): string {
    let rc = 'application/octet-stream';
    const fn = fileName.toLowerCase();

    if (fn.indexOf('.html') >= 0) {
        rc = 'text/html';
    } else if (fn.indexOf('.css') >= 0) {
        rc = 'text/css';
    } else if (fn.indexOf('.json') >= 0) {
        rc = 'application/json';
    } else if (fn.indexOf('.js') >= 0) {
        rc = 'application/x-javascript';
    } else if (fn.indexOf('.png') >= 0) {
        rc = 'image/png';
    } else if (fn.indexOf('.jpg') >= 0) {
        rc = 'image/jpeg';
    } else if (fn.indexOf('.jpeg') >= 0) {
        rc = 'image/jpeg';
    } else if (fn.indexOf('.svg') >= 0) {
        rc = 'image/svg+xml';
    } else if (fn.indexOf('.mp4') >= 0) {
        rc = 'video/mp4';
    } else if (fn.indexOf('.avi') >= 0) {
        rc = 'video/*,.avi';
    } else if (fn.indexOf('.mkv') >= 0) {
        rc = 'video/*,.mkv';
    } else if (fn.indexOf('.mov') >= 0) {
        rc = 'video/*,.mov';
    } else if (fn.indexOf('.mpg') >= 0) {
        rc = 'video/mpeg';
    } else if (fn.indexOf('.mpeg') >= 0) {
        rc = 'video/mpeg';
    } else if (fn.indexOf('.wav') >= 0) {
        rc = 'audio/x-wav';
    } else if (fn.indexOf('.wma') >= 0) {
        rc = 'audio/x-ms-wma';
    } else if (fn.indexOf('.aac') >= 0) {
        rc = 'audio/aac';
    } else if (fn.indexOf('.mp3') >= 0) {
        rc = 'audio/mpeg';
    } else if (fn.indexOf('.flac') >= 0) {
        rc = 'audio/flac';
    } else if (fn.indexOf('.geojson') >= 0) {
        rc = 'application/geo+json';
    } else if (fn.indexOf('.gpx') >= 0) {
        rc = '.gpx';
    } else if (fn.indexOf('.kml') >= 0) {
        rc = '.kml';
    } else if (fn.indexOf('.kmz') >= 0) {
        rc = '.kmz';
    } else if (fn.indexOf('.crs') >= 0) {
        rc = '.crs';
    } else if (fn.indexOf('.tcx') >= 0) {
        rc = '.tcx';
    }

    return rc;
}

export function getFileExtension(fileName: string | undefined): string | void {
    if (!fileName) {
        return;
    }
    let stringWithoutParams = fileName;
    if (fileName.lastIndexOf('?') !== -1) {
        stringWithoutParams = fileName.substring(0, fileName.lastIndexOf('?'));
    }
    return stringWithoutParams.substring(
        stringWithoutParams.lastIndexOf('.') + 1
    );
}

export function noWhitespaceValidator(control: AbstractControl): any {
    if (!control.value) {
        return;
    }
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : { whitespace: true };
}
