import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { noWhitespaceValidator } from '../../../../shared/shared-utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-new-alert',
    templateUrl: './new-alert.component.html',
    styleUrls: ['./new-alert.component.scss']
})
export class NewAlertComponent implements OnInit {
    messageMaxLength = 500;
    newAlertForm!: UntypedFormGroup;
    @Output() closeNewAlert = new EventEmitter();
    @Output() addAlert = new EventEmitter();

    displaySpotOptionFunc = (spot: any) => spot?.name;
    spotOptionList: [] = this.data.spots.map((spot: any) => ({
        id: spot.id,
        name: spot?.general[this.data?.defaultLanguage]?.name
    }));

    constructor(
        private fB: UntypedFormBuilder,
        @Optional() @Inject(MAT_DIALOG_DATA) public data?: any
    ) {}

    ngOnInit(): void {
        this.newAlertForm = this.fB.group({
            text: new UntypedFormControl('', [
                Validators.required,
                noWhitespaceValidator,
                Validators.maxLength(this.messageMaxLength)
            ]),
            spot: new UntypedFormControl()
        });
    }

    submit(): void {
        const { text, spot } = this.newAlertForm.value;
        this.addAlert.emit({ text, spot: spot.id });
    }
}
