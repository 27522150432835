<h2 appTypography="SUBTITLE">
    {{ 'SITE_VIEW_SITE' | translate }}
</h2>
<ng-container *ngIf="!isMobile">
    <p
        appTypography="SECONDARY"
        class="preview-site-subtitle">
        {{ 'SITE_PREVIEW_SUBTITLE' | translate }}
    </p>
    <div class="preview-site-content">
        <div class="content-box">
            <i class="WTicon-preview-mobile"></i>
            <div
                appTypography="SECONDARY_BOLD"
                class="box-title">
                {{ 'ON_MOBILE' | translate }}
            </div>
            <div
                appTypography="SECONDARY"
                class="box-description">
                {{ 'SITE_PREVIEW_ON_MOBILE' | translate }}
            </div>
            <qrcode
                [qrdata]="(shareLink$ | async) || ''"
                [allowEmptyString]="true"
                [width]="100"
                [errorCorrectionLevel]="'M'">
            </qrcode>
        </div>
        <div class="content-box">
            <i class="WTicon-preview-website"></i>
            <div class="box-title">{{ 'ON_WEBSITE' | translate }}</div>
            <div
                class="box-description"
                appTypography="SECONDARY">
                {{ 'SITE_PREVIEW_ON_WEBSITE' | translate }}
                <a
                    appTypography="SECONDARY_BOLD_UNDERLINE"
                    [href]="siteUrl$ | async"
                    target="_blank"
                    >www.wishtrip.com
                </a>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isMobile">
    <div appTypography="SECONDARY">
        {{ 'SITE_PREVIEW_MOBILE_1' | translate }}
        <a
            appTypography="SECONDARY_BOLD_UNDERLINE"
            [href]="shareLink$ | async">
            {{ 'HERE' | translate }}
        </a>
    </div>
    <div appTypography="SECONDARY">
        {{ 'SITE_PREVIEW_MOBILE_2' | translate }}
    </div>
    <div appTypography="SECONDARY">
        {{ 'SITE_PREVIEW_MOBILE_3' | translate }}
        <a
            appTypography="SECONDARY_BOLD_UNDERLINE"
            [href]="siteUrl$ | async">
            {{ 'HERE' | translate }}
        </a>
    </div>
</ng-container>
