import { createReducer } from '@ngrx/store';
import * as TagsAction from '../actions/tags.actions';
import { immerOn } from 'ngrx-immer/store';
import { initialTrekState } from '../../constants/trek-wizard.const';

export const trekTagsReducer = createReducer(
    initialTrekState.tags,
    immerOn(TagsAction.AddTag, (draft, { tagId }) => {
        if (!draft.data) {
            draft.data = {};
        }
        draft.data.tagIds = draft.data.tagIds
            ? [...draft.data.tagIds, tagId]
            : [tagId];
    }),
    immerOn(TagsAction.DeleteTag, (draft, { tagId }) => {
        draft.data!.tagIds = draft.data?.tagIds?.filter(
            (tag: any) => tag !== tagId
        );
    }),
    immerOn(TagsAction.AddLabel, (draft, { labelId }) => {
        if (!draft.data) {
            draft.data = {};
        }
        draft.data.labelIds = draft.data.labelIds
            ? [...draft.data.labelIds, labelId]
            : [labelId];
    }),
    immerOn(TagsAction.DeleteLabel, (draft, { labelId }) => {
        draft.data!.labelIds = draft.data?.labelIds?.filter(
            (label: any) => label !== labelId
        );
    }),
    immerOn(TagsAction.TagsComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
