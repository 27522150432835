import { createAction, props } from '@ngrx/store';
import { DcfWizardStages } from '../../constants/dcf-wizard.const';

export enum GameWizardStagesActionTypes {
    CHANGE_STAGE = '[DcfWizard]: Change Stage',
    NEXT_STAGE = '[DcfWizard] Next Stage',
    PREVIOUS_STAGE = '[DcfWizard]: Previous Stage'
}

export const ChangeDcfStage = createAction(
    GameWizardStagesActionTypes.CHANGE_STAGE,
    props<{ stage: DcfWizardStages }>()
);
export const NextDcfStage = createAction(
    GameWizardStagesActionTypes.NEXT_STAGE
);

export const PrevDcfStage = createAction(
    GameWizardStagesActionTypes.PREVIOUS_STAGE
);
