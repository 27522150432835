import { createReducer } from '@ngrx/store';
import * as DetailsActions from '../actions/details.actions';
import { immerOn } from 'ngrx-immer/store';
import { initialTrekState } from '../../constants/trek-wizard.const';

export const trekDetailsReducer = createReducer(
    initialTrekState.details,
    immerOn(DetailsActions.UpdateDetails, (draft, { details, invalid }) => {
        draft.data = {
            ...draft.data,
            ...details
        };
        draft.sectionStatus.invalid = invalid;
        draft.sectionStatus.disableDependants = invalid;
    }),
    immerOn(DetailsActions.DetailsComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
