import { createAction, props } from '@ngrx/store';

export enum TagsActionTypes {
    ADD_TAG = '[SiteWizard] Add Tag',
    DELETE_TAG = '[SiteWizard] Delete Tag',
    UPDATE_KEYWORD = '[SiteWizard] Update Keyword',
    TAGS_COMPLETE = '[SiteWizard] Tags Complete'
}

export const AddTag = createAction(
    TagsActionTypes.ADD_TAG,
    props<{ tagId: string }>()
);

export const DeleteTag = createAction(
    TagsActionTypes.DELETE_TAG,
    props<{ tagId: string }>()
);

export const UpdateKeywords = createAction(
    TagsActionTypes.UPDATE_KEYWORD,
    props<{ keywordString: string }>()
);

export const TagsComplete = createAction(TagsActionTypes.TAGS_COMPLETE);
