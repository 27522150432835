import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ActivatedRoute, Router, UrlSegmentGroup } from '@angular/router';
import { TrekWizardMode } from '../../../trek-wizard/store/trek-wizard.model';
import { environment } from '@env/environment';
import { AppRoutePaths } from '../../../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { SitesRoutePaths } from '../../../sites/sites-routing.module';
import { TrekCard } from './trek-card.model';
import { BaseCardComponent } from '../base-card/base-card.component';
import { CardLabelComponent } from '../card-label/card-label.component';
import { StatusLabel } from '../card-label/card-label.consts';
import { trekStatusLabelMap } from '../../../trek-wizard/store/trek-wizard-store.utils';

@Component({
    selector: 'app-trek-card',
    standalone: true,
    imports: [
        TranslateModule,
        CommonModule,
        TypographyDirective,
        BaseCardComponent,
        CardLabelComponent
    ],
    templateUrl: './trek-card.component.html',
    styleUrls: ['./trek-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrekCardComponent implements OnInit {
    TrekWizardMode = TrekWizardMode;
    statusLabelColor!: StatusLabel;
    trekUrl!: string;
    backgroundImage!: string;
    statusLabelMap = trekStatusLabelMap;
    @Input() trek!: TrekCard;
    @Output() deleteTrekEmit: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.backgroundImage =
            this.trek.media ||
            `${environment.ASSETS_BASE_URL}/assets/images/card-placeholder.jpg`;
        this.statusLabelColor = this.statusLabelMap.get(this.trek.mode!)!;
        this.trekUrl = `${environment.WEBSITE_URL}/trek/${this.trek.id}`;
    }

    cardClick(): void {
        const isInsideDialog =
            (
                (this.activatedRoute.snapshot as any)
                    ._urlSegment as UrlSegmentGroup
            ).segments[2]?.path === SitesRoutePaths.SITE_WIZARD;

        this.router.navigate(
            isInsideDialog
                ? [this.trek.id]
                : ['/', AppRoutePaths.TREKS, this.trek.id],

            { relativeTo: isInsideDialog ? this.activatedRoute : undefined }
        );
    }

    deleteTrek($event: any): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.deleteTrekEmit.emit(this.trek.id);
    }
}
