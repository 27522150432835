import {
    AfterContentInit,
    Component,
    forwardRef,
    Injector,
    Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NgxMaterialTimepickerModule,
    NgxMaterialTimepickerTheme
} from 'ngx-material-timepicker';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule
} from '@angular/forms';
import { DateTime } from 'luxon/index';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ACCENT_TIMEPICKER_THEME } from './timepicker.const';

@Component({
    selector: 'app-timepicker',
    standalone: true,
    imports: [
        CommonModule,
        NgxMaterialTimepickerModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true
        }
    ],
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent
    implements AfterContentInit, ControlValueAccessor
{
    @Input() timeFormat: 12 | 24 = 24;
    @Input() max: string | DateTime = '23:59';
    @Input() min: string | DateTime = '00:00';
    @Input() placeholder = '';
    @Input() label?: string;
    @Input() disabled?: boolean;
    ACCENT_TIMEPICKER_THEME: NgxMaterialTimepickerTheme =
        ACCENT_TIMEPICKER_THEME;
    onChange = (_: any) => {};
    onTouched = (_: any) => {};
    control!: FormControl<string>;
    isMobile = window.innerWidth <= 992;
    private _value = '';
    get value() {
        return this._value || this.control?.value;
    }

    set value(time: string) {
        this._value = this.convertTimeTo24Hours(time);
        this.control?.setValue(this._value);
    }

    constructor(private injector: Injector) {}

    ngAfterContentInit(): void {
        this.control = this.injector.get(NgControl, undefined)
            ?.control as FormControl<string>;
    }

    writeValue(time: string): void {
        this._value = time;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    convertTimeTo24Hours = (inputTime: string) => {
        const [time, modifier] = inputTime.split(' ');
        if (!modifier) {
            return inputTime;
        }
        const timeSplit = time.split(':');
        const minutes = timeSplit[1];
        let hours = timeSplit[0].padStart(2, '0');

        if (modifier === 'AM') {
            if (hours === '12') {
                hours = '00';
            }
        } else {
            if (hours !== '12') {
                hours = '' + (parseInt(hours, 10) + 12);
            }
        }
        return `${hours}:${minutes}`;
    };
}
