<header
    [ngClass]="{ 'only-wizard': isWithinSiteWizard }"
    class="base-header">
    <div
        *ngIf="!isWithinSiteWizard"
        class="logo-wrapper">
        <a [routerLink]="['/', AppRoutePaths.SITES]">
            <span
                *ngIf="isTablet; else bigLogo"
                class="WTicon-wishtrip-logo small-logo">
                <span class="path1"></span><span class="path2"></span
                ><span class="path3"></span>
            </span>
            <ng-template #bigLogo>
                <img
                    [ngSrc]="WT_LOGO"
                    height="57"
                    width="146"
                    alt="logo"
                    class="logo" />
            </ng-template>
        </a>
        <app-breadcrumbs
            class="breadcrumb-element"
            [breadcrumbs]="(breadcrumbs$ | async)!"></app-breadcrumbs>
    </div>
    <ng-container *ngIf="!isMobile">
        <ng-content></ng-content>
    </ng-container>

    <ng-container *ngIf="!isWithinSiteWizard">
        <div
            class="user"
            *ngIf="!isMobile; else mobileMenu">
            <div
                *ngIf="user$ | async as user; else login"
                [matMenuTriggerFor]="menu"
                class="user-clickable">
                <div class="user-image">
                    <img
                        alt="user-profile"
                        height="30"
                        width="30"
                        *ngIf="user.userProfileUrl"
                        [ngSrc]="user.userProfileUrl" />
                </div>
                <div
                    class="user-title"
                    appTypography="SECONDARY"
                    color="WHITE"
                    [title]="user.name">
                    {{ user.name }}
                </div>
                <i
                    class="WTicon-arrow-bottom arrow-bottom"
                    id="logout-arrow"></i>
                <mat-menu
                    #menu="matMenu"
                    class="wt-menu user-profile-menu"
                    xPosition="before">
                    <a
                        appTypography="SECONDARY"
                        mat-menu-item
                        (click)="onLogout()"
                        id="logout-button">
                        {{ 'LOGOUT' | translate }}
                    </a>
                    <a
                        mat-menu-item
                        [routerLink]="['/', AppRoutePaths.USERS, user.id]"
                        appTypography="SECONDARY">
                        {{ 'MY_PROFILE' | translate }}
                    </a>
                </mat-menu>
            </div>
            <ng-template #login>
                <a
                    [routerLink]="[
                        '/',
                        AppRoutePaths.AUTH,
                        AuthRoutePaths.LOGIN
                    ]"
                    appTypography="SECONDARY"
                    color="WHITE"
                    >{{ 'LOGIN' | translate }}</a
                >
            </ng-template>
        </div>
    </ng-container>
    <ng-template #mobileMenu>
        <app-mobile-sidenav></app-mobile-sidenav>
    </ng-template>
</header>
