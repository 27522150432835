import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SITE_CONSOLE_MINIMIZED_KEY } from '../sites.constants';

@Injectable()
export class SiteLocalStorageService {
    private _isMinimizedNavigation$ = new BehaviorSubject<boolean>(
        localStorage.getItem(SITE_CONSOLE_MINIMIZED_KEY) === 'true' ||
            window.innerWidth < 600
    );
    get isMinimizedNavigation$() {
        return this._isMinimizedNavigation$.asObservable();
    }

    get isMinimizedNavigation() {
        return this._isMinimizedNavigation$.value;
    }

    set isMinimizedNavigation(status: boolean) {
        this._isMinimizedNavigation$.next(status);
        localStorage.setItem(SITE_CONSOLE_MINIMIZED_KEY, status.toString());
    }

    toggleMinimizedNavigation() {
        const currentStatus = this._isMinimizedNavigation$.value;
        this.isMinimizedNavigation = !currentStatus;
    }
}
