import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    take,
    tap
} from 'rxjs/operators';
import {
    SiteCard,
    SiteVideoState,
    SiteVideoUrl
} from '../../models/site.model';
import { SiteService } from '../../services/site.service';
import { interval, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { saveAs } from 'file-saver';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactStandaloneComponent } from '../../../standalone/components/contact/contact-standalone.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-promo-video',
    templateUrl: './promo-video.component.html',
    styleUrls: ['./promo-video.component.scss']
})
export class PromoVideoComponent implements OnInit, OnDestroy {
    @Input() id?: string;
    showGameFrame!: boolean;
    siteId?: string;
    businessId?: string;
    movieURL!: string;
    movieState!: string;
    initVideoSub!: Subscription;
    displayForbiddenErrMsg = false;

    constructor(
        private siteService: SiteService,
        private route: ActivatedRoute,
        private spinnerService: ToggleSpinnerService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private matDialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.initSiteInfo();
        this.initVideoSub = this.initVideo().subscribe();
    }

    ngOnDestroy(): void {
        this.initVideoSub?.unsubscribe();
    }

    initSiteInfo(): void {
        (this.route.parent?.data as Observable<{ siteInfo: SiteCard }>)
            .pipe(take(1))
            .subscribe(({ siteInfo }) => {
                this.siteId = siteInfo.id;
                this.businessId = siteInfo.businessId;
            });
    }

    initVideo(): Observable<any> {
        this.spinnerService.toggle(true);
        return this.siteService.getMovieUrl(this.siteId!, this.movieURL).pipe(
            tap((movie: SiteVideoUrl) => {
                this.movieURL = movie.movieURL;
                this.movieState = movie.movieState;
                this.spinnerService.toggle(false);
            }),
            filter(() => this.movieState === SiteVideoState.IN_PROGRESS),
            concatMap(() => interval(60 * 1000)),
            filter(
                () =>
                    this.movieState === SiteVideoState.IN_PROGRESS ||
                    this.movieState === SiteVideoState.FAILED
            ),
            concatMap(() =>
                this.siteService.getMovieUrl(this.siteId!, this.movieURL)
            ),
            tap((movie: SiteVideoUrl) => {
                this.movieState = movie.movieState;
                if (
                    this.movieState === SiteVideoState.IN_PROGRESS ||
                    this.movieState === SiteVideoState.DONE
                ) {
                    this.movieURL = movie.movieURL;
                }
            })
        );
    }

    generateVideo(): void {
        this.spinnerService.toggle(true);
        this.siteService
            .startMovieGenerating(
                this.siteId!,
                this.businessId!,
                this.showGameFrame
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        this.displayForbiddenErrMsg = true;
                    }
                    return of(null);
                }),
                finalize(() => {
                    this.spinnerService.toggle(false);
                }),
                concatMap((res) => {
                    if (res) {
                        return this.siteService.getMovieUrl(
                            this.siteId!,
                            this.movieURL
                        );
                    }
                    return of(undefined);
                }),
                concatMap((movie: SiteVideoUrl) => {
                    if (movie) {
                        this.movieURL = movie.movieURL;
                        this.movieState = movie.movieState;
                        return this.initVideo();
                    }
                    return of(null);
                })
            )
            .subscribe();
    }

    downloadPromoVideo(): void {
        const fileName = this.siteId;
        this.siteService.download(this.movieURL).subscribe(
            (blob) => saveAs(blob, fileName),
            () => console.log('error downloading movie')
        );
    }

    openContact(): void {
        this.matDialog.open(ContactStandaloneComponent);
    }

    // enlargeMedia(index: string): void {
    //   this.matDialog.open(LargeMediaBlockComponent, {
    //     height: '80vh',
    //     width: '80VW',
    //     data: {
    //       media: this.movieURL,
    //       selectedMedia: index,
    //       id: `media-block-${index}`
    //     }
    //   });
    // }
    // getEmbedUrl(type: string): string {
    //   const baseUrl =
    // `${window.location.protocol}//${window.location.host}`;
    //   switch (type) {
    //     case 'EmbedVideo':
    //       return `<iframe src=
    // ${baseUrl}/embedded?trek_id=${this.movieURL}&video=1 width="600"
    // height="445" frameborder="0"></iframe>`; } } onCopySuccess(type:
    // string): void {
    // this.snackbarService.open(this.translate.instant('LINK_COPIED_TO_CLIPBOARD'));
    // }
}
