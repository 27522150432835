import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    createUrlTreeFromSnapshot
} from '@angular/router';
import { AuthRoutePaths } from '../auth/auth-routing.module';
import { AppRoutePaths } from '../app-routing.module';
import { AppStoreService } from '../store/app-store.service';

export const hasPermissionGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot
) => {
    const user = inject(AppStoreService).state.auth.user;
    return (
        !!user ||
        createUrlTreeFromSnapshot(route.root, [
            AppRoutePaths.AUTH,
            AuthRoutePaths.LOGIN
        ])
    );
};
