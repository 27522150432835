<div>
    <h2 appTypography="SUBTITLE">
        {{ 'SITE_TIMED_ANNOUNCEMENTS' | translate }}
    </h2>
    <p
        appTypography="SECONDARY"
        class="announcement-description">
        {{ 'SITE_TIMED_ANNOUNCEMENTS_DESCRIPTION' | translate }}
    </p>
</div>
<app-standalone-custom-button (clicked)="openAnnouncementDialog()">
    {{ 'NEW_ANNOUNCEMENTS_BTN' | translate }}
</app-standalone-custom-button>
<ng-container *ngIf="announcement$ | async as announcement">
    <ng-container *ngIf="announcement.alerts.length">
        <div class="announcement-manager-box-title">
            <p
                appTypography="PRIMARY_BOLD"
                color="WHITE"
                class="header-text">
                {{ 'ANNOUNCEMENT_MANAGER' | translate }}
            </p>
        </div>
        <div class="announcement-manager-box-body">
            <div
                *ngFor="let alert of announcement.alerts"
                class="announcement-manager-content">
                <p
                    appTypography="SECONDARY"
                    class="alert-name">
                    {{ alert.name }}
                </p>
                <app-standalone-custom-button
                    class="edit-btn"
                    buttonStyle="SECONDARY_OUTLINED"
                    buttonSize="MEDIUM"
                    (clicked)="openAnnouncementDialog(alert)">
                    {{ 'EDIT_ANNOUNCEMENT' | translate }}
                </app-standalone-custom-button>
                <i
                    class="WTicon-round-delete delete"
                    (click)="deleteAnnouncement(alert)">
                    <span class="path2"></span>
                </i>
            </div>
        </div>
    </ng-container>
</ng-container>
