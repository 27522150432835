import { createAction, props } from '@ngrx/store';
import { GameWizardState } from './game-wizard-store.model';

export enum GameWizardActionTypes {
    SET_WIZARD_STATE = '[GameWizard] Set Wizard State',
    RESET_WIZARD_STATE = '[GameWizard] Reset Wizard State',
    UNDO = '[GameWizard] Undo',
    REDO = '[GameWizard] REDO'
}

export const setGameWizardState = createAction(
    GameWizardActionTypes.SET_WIZARD_STATE,
    props<{ state: GameWizardState }>()
);

export const resetGameWizardState = createAction(
    GameWizardActionTypes.RESET_WIZARD_STATE
);

export const gameWizardUndo = createAction(GameWizardActionTypes.UNDO);
export const gameWizardRedo = createAction(GameWizardActionTypes.REDO);
