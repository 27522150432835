import * as mapboxgl from 'mapbox-gl';
import { MapboxMap } from './MapboxMap';

export class MarkerLayer {
    private mapboxMap;
    private map: mapboxgl.Map;
    private markers: Array<mapboxgl.Marker>;
    private layerShown: boolean;
    private pendingMarkers!: boolean;

    constructor(mapboxMap: MapboxMap, map: mapboxgl.Map) {
        this.mapboxMap = mapboxMap;
        this.map = map;
        this.markers = [];
        this.layerShown = false;

        this.map.on('load', () => {
            if (this.pendingMarkers) {
                this.markers.forEach((marker) => marker.addTo(this.map));
            }

            this.pendingMarkers = false;
        });
    }

    addMarkers(markers: Array<mapboxgl.Marker>): any {
        this.markers = this.markers.concat(markers);

        if (this.layerShown) {
            markers.forEach((marker) => marker.addTo(this.map));
        }

        return this;
    }

    addMarker(marker: mapboxgl.Marker): any {
        this.markers.push(marker);

        if (this.layerShown) {
            marker.addTo(this.map);
        }

        return this;
    }

    removeMarker(marker: mapboxgl.Marker): any {
        const deletedMarker = this.markers.splice(
            this.markers.indexOf(marker),
            1
        );
        deletedMarker[0].remove();

        return this;
    }

    setMarkers(markers: Array<mapboxgl.Marker>): any {
        this.clearMarkers();
        this.markers = markers;

        return this;
    }

    clearMarkers(): void {
        this.markers.forEach((marker) => marker.remove());
        this.markers = [];
    }

    showLayer(): void {
        if (this.layerShown) {
            return;
        }

        this.layerShown = true;

        if (!this.mapboxMap.isMapLoaded) {
            this.pendingMarkers = true;
            return;
        }

        this.markers.forEach((marker) => marker.addTo(this.map));
    }

    hideLayer(): void {
        if (!this.layerShown) {
            return;
        }

        this.layerShown = false;
        this.markers.forEach((marker) => marker.remove());
    }

    isShown(): boolean {
        return this.layerShown;
    }
}
