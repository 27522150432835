import { ActionReducerMap } from '@ngrx/store';
import { SiteWizardReducer } from '../site-wizard/store/site-wizard.reducer';
import { AuthReducer } from '../auth/store/auth.reducer';
import { SiteCategoriesReducer } from '../site-wizard/store/site-categories-store';
import { TrekWizardReducer } from '../trek-wizard/store/trek-wizard.reducer';
import { GameWizardReducers } from '../game-wizard/store/game-wizard.reducers';
import { BusinessDataReducer } from '../trek-wizard/store/business-data.store';
import { AppState } from './app-store.model';
import { SiteSettingsReducer } from '../sites/store/selections/site-settings.store';
import { cdfWizardReducer } from '../dcf-wizard/store/dcf-wizard.reducer';

export const rootReducer: ActionReducerMap<AppState> = {
    auth: AuthReducer,
    siteWizard: SiteWizardReducer,
    siteCategories: SiteCategoriesReducer,
    trekWizard: TrekWizardReducer,
    gameWizard: GameWizardReducers,
    businessData: BusinessDataReducer,
    siteSettings: SiteSettingsReducer,
    dcfWizard: cdfWizardReducer
};
