import { WizardMode } from '../../site-wizard/store/site-wizard-store.model';
import { environment } from '@env/environment';
import { SiteSpotPublished } from '../../site-wizard/models/site-wizard-spots.model';
import { SiteMap } from '../../site-wizard/models/site-wizard-map-setup.model';
import { LocaleType } from '../../shared/locales.models';

export interface SiteListData {
    businessId: string;
    freeTrial: boolean;
    id: string;
    isPrivate: false;
    mode: WizardMode;
    packageType: string;
    trialExpiration: Date;
}

export interface RatingResponse {
    average: number;
    total_travelers: number;
    total_votes: number;
}

export interface SmsSettings {
    message?: string;
    shareLinkId: string | null;
    siteId: string;
}

export interface SocketEvents {
    CONNECT: string;
    DISCONNECT: string;
    RECONNECT: string;
    STATS_SPLIT: string;
    USER_LOCATION: string;
    ALERT_NEW: string;
    ALERT_CONFIRM: string;
    ALERT_ERROR: string;
    ALERT_HISTORY_ADD: string;
    ALERT_HISTORY_REQUEST: string;
    SITE_ID: string;
}

export interface Alert {
    id: string;
    title: string;
    text: string;
    date: string | Date;
    alertType: string;
    extendedMsg?: {
        certificate: AlertCertificate;
    };
}

export interface LiveAlert {
    id: string;
    image_url: string;
    msg: string;
    name: string;
    site_id: string;
    site_name: string;
    soi_id: number;
    soi_name: string;
    ts: number;
    type: string;
    url: string;
    alert_type: string;
    extended_msg?: {
        certificate: AlertCertificate;
    };
}

export interface AlertCertificate {
    id: string;
    entityType: string;
    date: number;
    siteId: string;
    siteName: string;
    businessLogoUrl: string;
    country: string;
    entityId: string;
    entityName: string;
    entityMediaUrl: string;
    campaignId: string;
    paymentVariationId: string;
    paymentVariationDescription: string;
    sponsorUserId: string;
    sponsorPaymentName: string;
    sponsorsDedication: string;
    campaignName: string;
    isAnonymous: boolean;
    amount: number;
    currency: string;
    transactionId: string;
}

export class AlertMap {
    [alertId: string]: Alert;
}

export interface LiveAlertData {
    msg: string;
    soi_id: number;
}

export interface ChatInfo {
    credentials: ChatCredentials;
    display_name: string;
    enable: boolean;
    image_url: string;
}

export interface ChatCredentials {
    token: string;
    user_id: string;
}

export interface PrivateToken {
    [x: string]: any;

    token_id: string;
    token_name: string;
    token_value: string;
    site_id: string;
}

export class SitePrivateLink {
    name: string;
    url: string;
    token: PrivateToken;

    constructor(token: PrivateToken) {
        this.token = token;
        this.name = token.token_name;
        this.url = `${environment.WEBSITE_URL}/site/${token.site_id}?private_token=${token.token_value}`;
    }
}

export interface SiteVideo {
    siteId: string;
    businessId: string;
    showGameFrame: boolean;
}

export interface SiteVideoUrl {
    movieURL: string;
    movieState: SiteVideoState;
}

export enum SiteVideoState {
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILED = 'FAILED'
}

export interface NumberSms {
    phoneNumber: string;
}

export interface SentSms extends NumberSms {
    date: Date;
}

export enum SendAlertTime {
    EVERYDAY = 'EVERYDAY',
    DAYS = 'DAYS'
}

export enum SendAlertDay {
    SUN = 'SUNDAY_SHORT_VIEW',
    MON = 'MONDAY_SHORT_VIEW',
    TUES = 'TUESDAY_SHORT_VIEW',
    WED = 'WEDNESDAY_SHORT_VIEW',
    THURS = 'THURSDAY_SHORT_VIEW',
    FRI = 'FRIDAY_SHORT_VIEW',
    SAT = 'SATURDAY_SHORT_VIEW'
}

export interface SiteMenuItem {
    sectionTitle: string;
    items: {
        title: string;
        description?: string;
        icon: string;
        hasPermission: boolean;
        disabled: boolean;
        disabledText?: string;
        url?: string;
        clickAction: (...args: any[]) => void;
    }[];
}

export type SiteMenu = SiteMenuItem[];

export interface ImplementationConfiguration {
    packs: ImplementationPack[];
}

export interface ImplementationPack {
    title: string;
    data: ImplementationItem[];
}

export interface ImplementationItem {
    name: string;
    size: string;
    type: string;
    description: string;
    callToAction: string;
    url: string;
}

export interface SiteCard {
    id?: string;
    businessId?: string;
    name?: string;
    media?: string;
    freeTrial?: boolean;
    trialExpiration?: Date | null;
    isExpired?: boolean;
    location?: string;
    country?: string;
    mode?: WizardMode;
    isPrivate?: boolean;
    tier?: string;
    spots?: SiteSpotPublished[];
    map?: SiteMap;
    defaultLanguage?: LocaleType;
}

export interface CardInput {
    name?: string;
    url?: string;
    id?: string;
}
