import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';

export enum LanguageActionTypes {
    ADD_SITE_LANGUAGE = '[SiteWizard] Add Language',
    DELETE_SITE_LANGUAGE = '[SiteWizard] Delete Language',
    SET_DEFAULT_LANGUAGE = '[SiteWizard] Set Default Language',
    LANGUAGES_COMPLETE = '[SiteWizard] Languages Complete'
}

export const addSiteLanguage = createAction(
    LanguageActionTypes.ADD_SITE_LANGUAGE,
    props<{ language: LocaleType }>()
);

export const deleteSiteLanguage = createAction(
    LanguageActionTypes.DELETE_SITE_LANGUAGE,
    props<{ language: LocaleType }>()
);

export const setDefaultLanguage = createAction(
    LanguageActionTypes.SET_DEFAULT_LANGUAGE,
    props<{ language: LocaleType }>()
);

export const languagesComplete = createAction(
    LanguageActionTypes.LANGUAGES_COMPLETE
);
