// @ts-nocheck
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, OPERATIONAL_SITE_SUPPORTED_LANGUAGES, RTL_LOCALES } from '../shared/locales.constants';
import { environment } from '@env/environment';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { ToggleSpinnerService } from './toggle-spinner.service';
import { MetaService } from './meta.service';
import { CrispLiveChatService } from './crisp-live-chat.service';
import { FacebookPixelService } from './facebook-pixel.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-store.reducer';
import { getUser } from '../auth/store/auth-store.selectors';
import * as Sentry from '@sentry/angular-ivy';
import { Event } from '@sentry/angular-ivy';
import { BehaviorSubject, Observable } from 'rxjs';
import packageJson from '../../../package.json';
import { LocaleType } from '../shared/locales.models';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AppLoadService {
  private renderer: Renderer2;
  directionality: BehaviorSubject<'rtl' | 'ltr'> = new BehaviorSubject<'rtl' | 'ltr'>(
    'ltr');

  constructor(
    private translateService: TranslateService,
    private rendererFactory: RendererFactory2,
    private authService: AuthService,
    private router: Router,
    private toggleSpinnerService: ToggleSpinnerService,
    private metaService: MetaService,
    private crispLiveChatService: CrispLiveChatService,
    private store: Store<AppState>,
    private facebookPixelService: FacebookPixelService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  async initApp() {
    await this.authService.getSession();
    this.addAssetsPreConnectTag();
    this.initCrispSnippet();
    this.initPixelSnippet();
    this.configTranslation();
    this.initHubSpot();
    this.initLinkedInSnippet();
    this.initArabianTwitterSnippet();
    this.initTwitterSnippet();
    this.subscribeToRouterEvent();
    this.setSpinner();
    this.initSentry();
    this.initBuildIdAndVersion();
    this.initGoogleTagManager();
  }

  initCrispSnippet(): void {
    this.store.select(getUser).subscribe((user) => {
      if (user) {
        this.crispLiveChatService.initCrispLiveChat();
      }
    });
  }

  initPixelSnippet(): void {
    if (environment.production) {
      this.facebookPixelService.initPixelEvents();
    }
  }

  configTranslation(): void {
    OPERATIONAL_SITE_SUPPORTED_LANGUAGES.forEach(lang => {
      this.translateService.setTranslation(lang,
        require(`../../strings/strings.${lang}.json`));
    });
    this.translateService.setDefaultLang(DEFAULT_LOCALE);
    this.translateService.langs = OPERATIONAL_SITE_SUPPORTED_LANGUAGES;
    const userLang = localStorage.getItem('LANG_KEY') ||
      this.translateService.getBrowserLang();
    const lang = OPERATIONAL_SITE_SUPPORTED_LANGUAGES.includes(userLang) ?
      userLang : DEFAULT_LOCALE;
    this.translateService.use(lang);
    this.setAppDirection(lang);
    this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.setAppDirection(event.lang);
      });
  }

  setAppDirection(lang: LocaleType) {
    if (RTL_LOCALES.indexOf(lang) >= 0) {
      this.renderer.addClass(document.body, 'rtl');
      this.renderer.setAttribute(document.body, 'dir', 'rtl');
      this.renderer.setStyle(document.body, 'direction', 'rtl');
      this.directionality.next('rtl');
    } else {
      this.renderer.removeClass(document.body, 'rtl');
      this.renderer.setAttribute(document.body, 'dir', 'ltr');
      this.renderer.setStyle(document.body, 'direction', 'ltr');
      this.directionality.next('ltr');
    }
  }

  initHubSpot(): void {
    if (environment.production) {
      // initialize HubSpot
      ((id, src) => {
        if (document.getElementById(id)) {
          return;
        }
        const scriptElement = document.createElement('script');
        scriptElement.src = src;
        scriptElement.type = 'text/javascript';
        scriptElement.id = id;
        scriptElement.async = true;
        const e = document.getElementsByTagName('script')[0];
        e.parentNode!.insertBefore(scriptElement, e);
      })('hs-analytics',
        '//js.hs-analytics.net/analytics/1547038500000/4561053.js');
    }
  }

  initLinkedInSnippet(): void {
    if (environment.production) {
      // Linkedin Insight Tag
      // eslint-disable-next-line
      // @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
      const _linkedin_partner_id = '1740393';
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids ||
        [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (() => {
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode?.insertBefore(b, s);
      })();
    }
  }

  initTwitterSnippet(): void {
    if (environment.production) {
      // twitter

      !function(e, t, n, s, u, a) {
        e.twq || (s = e.twq = function() {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }, s.version = '1.1',
          s.queue = [],
          u = t.createElement(n),
          u.async = !0,
          u.src = '//static.ads-twitter.com/uwt.js',
          a = t.getElementsByTagName(n)[0],
          a.parentNode.insertBefore(u, a));
      }(window, document, 'script');
      // Insert Twitter Pixel ID and Standard Event data below
      window.twq('config', 'o0yn3');
      window.twq('track', 'PageView');
    }
  }

  initArabianTwitterSnippet(): void {
    if (environment.production) {

      // @ts-ignore
      !function(e, t, n, s, u, a) {
        e.twq || (s = e.twq = function() {
          // eslint-disable-next-line prefer-rest-params,prefer-spread
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }, s.version = '1.1',
          s.queue = [],
          u = t.createElement(n),
          u.async = !0,
          u.src = '//static.ads-twitter.com/uwt.js',
          a = t.getElementsByTagName(n)[0],
          a.parentNode.insertBefore(
            u, a));
      }(window, document, 'script');
      // Insert Twitter Pixel ID and Standard Event data below
      window.twq('init', 'o8gph');
      window.twq('track', 'PageView');
    }
  }

  subscribeToRouterEvent(): void {
    (this.router.events as Observable<RouterEvent>)
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.fireGtmEvent(event);
        this.fireHubSpotEvent();
        this.metaService.resetTags();
      });
  }

  setSpinner(): void {
    (this.router.events as Observable<RouterEvent>)
      .subscribe((event: RouterEvent) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.toggleSpinnerService.toggle(true);
            break;

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
            this.toggleSpinnerService.toggle(false);
            break;

          default:
            break;
        }
      });
  }

  fireGtmEvent(routerEvent: NavigationEnd): void {
    (window as any).dataLayer?.push({
      event: 'virtualPageview',
      pageUrl: routerEvent.url,
      pageTitle: document.title
    });
  }

  fireHubSpotEvent(): void {
    // hubspot
    if (environment.production) {
      // eslint-disable-next-line @typescript-eslint/naming-convention,
      // no-underscore-dangle, id-blacklist, id-match
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', location.pathname]);
      _hsq.push(['trackPageView']);
    }
  }

  initSentry(): void {
    if (!environment.dev) {
      Sentry.init({
        dsn: 'https://65b6bd5a913d475b9799216770e3cbd7@o1150503.ingest.sentry.io/6223426',
        tracesSampleRate: environment.production ? 0.5 : 0.1,
        environment: environment.production ? 'prd' : 'stg',
        release: `WT-B2B@${packageJson.version}`,
        sampleRate: 1,
        integrations: [
          new Sentry.BrowserTracing()
        ],
        beforeSend: (event: Event) => {
          return this.store.pipe(take(1),
            map((appState: AppState) => {
              event.breadcrumbs.push({
                type: 'Debug',
                level: 'info',
                event_id: event.event_id,
                category: 'console',
                message: 'The app state on beforeSend',
                data: { appState },
                timestamp: new Date().getTime()
              });
              if (appState.auth.user?.businessId) {
                event.tags.businessId = appState.auth.user.businessId;
              }
              if (appState.auth.user?.id) {
                event.tags.userId = appState.auth.user.id;
              }
              if (appState.siteWizard.present.id) {
                event.tags.currentSiteId = appState.siteWizard.present.id;
              }
              if (appState.trekWizard.present.trekId) {
                event.tags.currentTrekId = appState.trekWizard.present.trekId;
              }
              if (appState.auth.user.email) {
                event.tags.email = appState.auth.user.email;
              }
              return event;
            })).toPromise();
        },
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // See:
          // http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          'Can\'t find variable: ZiteReader',
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform`
          // seems to reduce this. (thanks @acdha) See
          // http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See
          // http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          // Generic error code from errors outside the security
          // sandbox You can delete this if using raven.js > 1.0,
          // which ignores these automatically.
          'Script error.',
          // Avast extension error
          '_avast_submit'
        ],
        denyUrls: [
          // Google Adsense
          /pagead\/js/i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ]
      });
    }
  }

  initBuildIdAndVersion() {
    localStorage.setItem('BUILD_ID', packageJson.buildId);
    localStorage.setItem('APP_VERSION', packageJson.version);
  }

  initGoogleTagManager(): void {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.GTM_CONTAINER_ID}');`;
    script.async = true;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM_CONTAINER_ID}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }

  addAssetsPreConnectTag() {
    const head = document.getElementsByTagName('head')[0];
    const assetsLinkElement = document.createElement('link');
    assetsLinkElement.rel = 'preconnect';
    assetsLinkElement.href = environment.ASSETS_BASE_URL;
    head.appendChild(assetsLinkElement);

  }
}
