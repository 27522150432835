import { LocaleType } from '../../../shared/locales.models';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../../shared/shared-utils';
import { FIELD_MAX_LENGTH } from '../../constants/site-wizard-field-limits.const';
import { GeneralFormGroup } from './general-form.model';
import { SiteGeneralDetails } from '../../models/site-wizard-general-details.model';

export const getGeneralDetailsFormArray = (
    generalInfo: Record<LocaleType, SiteGeneralDetails>
): FormArray<GeneralFormGroup> =>
    new FormArray<GeneralFormGroup>(
        (Object.keys(generalInfo) as LocaleType[]).map(
            (lang) =>
                new FormGroup({
                    name: new FormControl(generalInfo[lang].name || '', {
                        validators: [
                            Validators.required,
                            noWhitespaceValidator,
                            Validators.maxLength(FIELD_MAX_LENGTH.name)
                        ],
                        nonNullable: true
                    }),
                    slogan: new FormControl(generalInfo[lang].slogan || '', {
                        validators: [
                            noWhitespaceValidator,
                            Validators.maxLength(FIELD_MAX_LENGTH.slogan)
                        ],
                        nonNullable: true
                    }),
                    locationDescription: new FormControl(
                        generalInfo[lang].locationDescription || '',
                        {
                            validators: [
                                noWhitespaceValidator,
                                Validators.maxLength(
                                    FIELD_MAX_LENGTH.locationDescription
                                )
                            ],
                            nonNullable: true
                        }
                    ),
                    recommendedTimeNeeded: new FormControl(
                        generalInfo[lang].recommendedTimeNeeded || '',
                        {
                            validators: [
                                noWhitespaceValidator,
                                Validators.maxLength(
                                    FIELD_MAX_LENGTH.recommendedTimeNeeded
                                )
                            ],
                            nonNullable: true
                        }
                    ),
                    description: new FormControl(
                        generalInfo[lang].description || '',
                        {
                            validators: [
                                Validators.required,
                                noWhitespaceValidator
                            ],
                            nonNullable: true
                        }
                    ),
                    accessibilityDescription: new FormControl(
                        generalInfo[lang].accessibilityDescription || '',
                        {
                            validators: [noWhitespaceValidator],
                            nonNullable: true
                        }
                    )
                })
        )
    );
