import { createReducer } from '@ngrx/store';
import { initialState } from '../site-wizard-store.constants';
import * as PromotedActions from '../actions/promoted.actions';
import { immerOn } from 'ngrx-immer/store';

export const promotedReducer = createReducer(
    initialState.promotedTreks,
    immerOn(PromotedActions.AddTrek, (draft, { trekData }) => {
        draft.data!.ids = draft.data?.ids || [];
        draft.data?.ids.push(trekData.id!);
        if (!draft.data?.treksData) {
            draft.data!.treksData = {};
        }
        draft.data!.treksData[trekData.id!] = trekData;
    }),
    immerOn(PromotedActions.RemoveTrek, (draft, { id }) => {
        if (!draft.data?.ids) {
            return;
        }
        draft.data.ids = draft.data.ids.filter((trek) => trek !== id);
        delete draft.data.treksData![id];
    }),
    immerOn(PromotedActions.ToggleStarTreks, (draft) => {
        draft.data!.starTreks = !draft.data!.starTreks;
    }),
    immerOn(PromotedActions.PromotedComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
