import { GeoJsonProperties } from 'geojson';
import { latLonsToPositions } from './helpers';
import { LatLon, PointData } from '../typings/mapbox-utils';
import bboxPolygon from '@turf/bbox-polygon';
import differencePolygon from '@turf/difference';
import { Layer } from 'mapbox-gl';

interface PolygonDrawerSource {
    drawing: boolean;
    displayControlsDefault: boolean;
    mode: string;
    modes?: {};
    styles: Layer[];
}

export function getLineStringSource(
    coordinates: Array<LatLon>,
    properties: GeoJsonProperties = {}
): GeoJSON.Feature<GeoJSON.LineString> {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'LineString',
            coordinates: latLonsToPositions(coordinates)
        }
    };
}

export function getPolygonSource(
    coordinates: Array<LatLon>,
    properties: GeoJsonProperties = {}
): GeoJSON.Feature<GeoJSON.Polygon> {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'Polygon',
            coordinates: [latLonsToPositions(coordinates)]
        }
    };
}

export function getPointsSource(
    points: Array<PointData>
): GeoJSON.FeatureCollection<GeoJSON.Point> {
    return {
        type: 'FeatureCollection',
        features: points.map(
            (pointData: PointData): GeoJSON.Feature<GeoJSON.Point> => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: pointData.coordinate
                },
                properties: pointData.properties
            })
        )
    };
}

export function getMaskedPolygonData(mask: any, bounds: any): any {
    const bboxPoly = bboxPolygon(bounds);
    return differencePolygon(bboxPoly, mask);
}

export function getPolygonDrawerSource(): PolygonDrawerSource {
    return {
        drawing: true,
        displayControlsDefault: false,
        mode: 'draw_polygon',
        styles: [
            {
                id: 'gl-draw-polygon-fill-inactive',
                type: 'fill',
                filter: [
                    'all',
                    ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                paint: {
                    'fill-color': '#2997a7',
                    'fill-outline-color': '#2997a7',
                    'fill-opacity': 0.2
                }
            },
            {
                id: 'gl-draw-polygon-fill-active',
                type: 'fill',
                filter: [
                    'all',
                    ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                paint: {
                    'fill-color': '#2997a7',
                    'fill-outline-color': '#2997a7',
                    'fill-opacity': 0.2
                }
            },
            {
                id: 'gl-draw-polygon-midpoint',
                type: 'circle',
                filter: [
                    'all',
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']
                ],
                paint: {
                    'circle-radius': 7,
                    'circle-color': '#2997a7'
                }
            },
            {
                id: 'gl-draw-polygon-stroke-inactive',
                type: 'line',
                filter: [
                    'all',
                    ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 5
                }
            },
            {
                id: 'gl-draw-polygon-stroke-active',
                type: 'line',
                filter: [
                    'all',
                    ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 5
                }
            },
            {
                id: 'gl-draw-line-inactive',
                type: 'line',
                filter: [
                    'all',
                    ['==', 'active', 'false'],
                    ['==', '$type', 'LineString'],
                    ['!=', 'mode', 'static']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 4
                }
            },
            {
                id: 'gl-draw-line-active',
                type: 'line',
                filter: [
                    'all',
                    ['==', '$type', 'LineString'],
                    ['==', 'active', 'true']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 4
                }
            },
            {
                id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                type: 'circle',
                filter: [
                    'all',
                    ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#2997a7'
                }
            },
            {
                id: 'gl-draw-polygon-and-line-vertex-inactive',
                type: 'circle',
                filter: [
                    'all',
                    ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#2997a7'
                }
            },
            {
                id: 'gl-draw-point-point-stroke-inactive',
                type: 'circle',
                filter: [
                    'all',
                    ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-opacity': 1,
                    'circle-color': '#fff'
                }
            },
            {
                id: 'gl-draw-point-inactive',
                type: 'circle',
                filter: [
                    'all',
                    ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#2997a7'
                }
            },
            {
                id: 'gl-draw-point-stroke-active',
                type: 'circle',
                filter: [
                    'all',
                    ['==', '$type', 'Point'],
                    ['==', 'active', 'true'],
                    ['!=', 'meta', 'midpoint']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#2997a7'
                }
            },
            {
                id: 'gl-draw-point-active',
                type: 'circle',
                filter: [
                    'all',
                    ['==', '$type', 'Point'],
                    ['!=', 'meta', 'midpoint'],
                    ['==', 'active', 'true']
                ],
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#fff'
                }
            },
            {
                id: 'gl-draw-polygon-fill-static',
                type: 'fill',
                filter: [
                    'all',
                    ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                paint: {
                    'fill-color': '#2997a7',
                    'fill-outline-color': '#2997a7',
                    'fill-opacity': 0.1
                }
            },
            {
                id: 'gl-draw-polygon-stroke-static',
                type: 'line',
                filter: [
                    'all',
                    ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 5
                }
            },
            {
                id: 'gl-draw-line-static',
                type: 'line',
                filter: [
                    'all',
                    ['==', 'mode', 'static'],
                    ['==', '$type', 'LineString']
                ],
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#2997a7',
                    'line-width': 5
                }
            },
            {
                id: 'gl-draw-point-static',
                type: 'circle',
                filter: [
                    'all',
                    ['==', 'mode', 'static'],
                    ['==', '$type', 'Point']
                ],
                paint: {
                    'circle-radius': 5,
                    'circle-color': '#2997a7'
                }
            }
        ]
    };
}

export function getHeatmapPaintOptions(color: string): any {
    return {
        // Increase the heatmap weight based on frequency and property total
        'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'total'],
            0,
            0,
            2,
            1
        ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 10, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(255, 0, 0, 0)',
            1,
            color
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 3, 10]
    };
}
