import { createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import * as CoverImageActions from '../actions/cover-image.action';
import { initialTrekState } from '../../constants/trek-wizard.const';

export const trekCoverMediaReducer = createReducer(
    initialTrekState.coverMedia,
    immerOn(CoverImageActions.UpdateCoverMedia, (draft, { coverMediaUrl }) => {
        draft.data = {
            ...draft?.data,
            coverMediaUrl,
            useTrekMovie: false
        };
    }),
    immerOn(CoverImageActions.UpdateUseTrekMovie, (draft, { useTrekMovie }) => {
        draft.data = {
            ...draft?.data,
            useTrekMovie,
            coverMediaUrl: undefined
        };
    }),
    immerOn(CoverImageActions.CoverMediaComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
