import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import format from 'date-fns/format';
import {
    DateFormat,
    WeekStart
} from '../../../sites/models/site-settings.model';
import { inject, Injectable } from '@angular/core';
import { AppStoreService } from '../../../store/app-store.service';

export const DATE_RANGE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'yyyy-MM-dd'
    },
    display: {
        dateInput: 'yy-MM-dd',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy'
    }
};
@Injectable()
export class DateRangeAdapter extends NativeDateAdapter {
    siteSettings = inject(AppStoreService).state.siteSettings;

    format(date: Date, displayFormat: string): string {
        return format(date, dateFormatMap.get(displayFormat) || displayFormat);
    }

    override getFirstDayOfWeek(): number {
        return this.siteSettings.weekStart === WeekStart.MONDAY ? 1 : 0;
    }
}

const dateFormatMap: Map<string, string> = new Map([
    [DateFormat.DD_MM_YYYY, 'dd/MM/yyyy'],
    [DateFormat.MM_DD_YYYY, 'MM/dd/yyyy'],
    [DateFormat.YYYY_MM_DD, 'yyyy/MM/dd']
]);
