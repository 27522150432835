<h2
    appTypography="SUBTITLE"
    class="title">
    {{ 'IMPLEMENTATION_BOX_TITLE' | translate }}
</h2>

<p
    appTypography="SECONDARY"
    class="description">
    {{ 'IMPLEMENTATION_DESCRIPTION' | translate }}
</p>

<div
    *ngIf="
        (isAllowPremiumFeaturesAndImplementationConfiguration | async)
            ?.isAllowPremiumFeatures;
        else isNotAllowPremiumFeatures
    ">
    <div
        *ngFor="
            let section of (
                isAllowPremiumFeaturesAndImplementationConfiguration | async
            )?.implementationConfiguration?.packs
        "
        class="section-wrapper">
        <div>
            <div class="section-title">
                <h3 appTypography="PRIMARY">
                    {{ section.title }}
                </h3>
            </div>
        </div>
        <div class="action-container-wrapper">
            <div
                *ngFor="let item of section.data"
                class="action-container">
                <p appTypography="PRIMARY_BOLD">
                    {{ item.name }}
                </p>
                <p
                    appTypography="SECONDARY"
                    class="type-info">
                    {{ 'TYPE' | translate }}: {{ item.type }} /
                    {{ 'SIZE' | translate }}: {{ item.size }}
                </p>

                <p
                    appTypography="SECONDARY"
                    class="implementation-description">
                    {{ item.description }}
                </p>
                <app-standalone-custom-button
                    buttonSize="MEDIUM"
                    [hrefLink]="item.url"
                    routerTarget="_blank"
                    class="wt-button-round accent-color call-to-action-button">
                    {{ item.callToAction }}
                </app-standalone-custom-button>
            </div>
        </div>
    </div>
</div>

<ng-template #isNotAllowPremiumFeatures>
    <p appTypography="SECONDARY">
        {{ 'IMPLEMENTATION_KIT_FORBIDDEN' | translate }}
    </p>
</ng-template>
