import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './standalone/components/main/main.component';
import { IsLoggedInGuard } from './services/is-logged-in.guard';
import { AddNoindexTagGuard } from './guards/add-noindex-tag.guard';
import {
    hideCrispLiveChatGuard,
    showCrispLiveChatGuard
} from './guards/crisp-live-chat.guard';
import { hasPermissionGuard } from './services/has-permission.guard';
import { AuthRoutePaths } from './auth/auth-routing.module';

export enum AppRoutePaths {
    AUTH = 'auth',
    SITES = 'sites',
    TREKS = 'treks',
    USERS = 'users',
    BUSINESS = 'business',
    LIZARD = 'lizard',
    ERROR = 'error'
}

const APP_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AppRoutePaths.AUTH
    },
    {
        path: AppRoutePaths.AUTH,
        canActivate: [IsLoggedInGuard, hideCrispLiveChatGuard],
        canDeactivate: [showCrispLiveChatGuard],
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: AppRoutePaths.SITES,
        canActivate: [hasPermissionGuard, AddNoindexTagGuard],
        loadChildren: () =>
            import('./sites/sites.module').then((m) => m.SitesModule)
    },
    {
        path: AppRoutePaths.TREKS,
        loadChildren: () =>
            import('./treks/treks.module').then((m) => m.TreksModule),
        canActivate: [hasPermissionGuard, AddNoindexTagGuard]
    },
    {
        path: AppRoutePaths.USERS,
        loadChildren: () =>
            import('./users/users.module').then((m) => m.UsersModule),
        component: MainComponent,
        canActivate: [hasPermissionGuard, AddNoindexTagGuard]
    },
    {
        path: AppRoutePaths.BUSINESS,
        loadChildren: () =>
            import('./business/business.module').then((m) => m.BusinessModule),
        component: MainComponent,
        canActivate: [hasPermissionGuard, AddNoindexTagGuard]
    },
    {
        path: AppRoutePaths.LIZARD,
        loadChildren: () =>
            import('./lizard/lizard.module').then((m) => m.LizardModule),
        canActivate: [hasPermissionGuard, AddNoindexTagGuard]
    },
    {
        path: AuthRoutePaths.LOGIN,
        redirectTo: AppRoutePaths.AUTH
    },
    {
        path: AuthRoutePaths.REGISTER,
        redirectTo: AppRoutePaths.AUTH + '/' + AuthRoutePaths.REGISTER
    },
    {
        path: AuthRoutePaths.CAMPAIGN_REGISTER,
        redirectTo: AppRoutePaths.AUTH + '/' + AuthRoutePaths.REGISTER
    },
    {
        path: AuthRoutePaths.RESET_PASSWORD,
        redirectTo: AppRoutePaths.AUTH + '/' + AuthRoutePaths.RESET_PASSWORD
    },
    {
        path: AuthRoutePaths.FORGOT_PASSWORD,
        redirectTo: AppRoutePaths.AUTH + '/' + AuthRoutePaths.FORGOT_PASSWORD
    },
    {
        path: 'wizard',
        redirectTo: AppRoutePaths.SITES
    },
    {
        path: 'trek-wizard',
        redirectTo: AppRoutePaths.TREKS
    },
    {
        path: AppRoutePaths.ERROR,
        component: MainComponent,
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './standalone/components/error-page/error-page.component'
                    ).then((c) => c.ErrorPageComponent)
            }
        ]
    },
    {
        path: '**',
        redirectTo: `/${AppRoutePaths.ERROR}`
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
            scrollPositionRestoration: 'top'
        })
    ],
    exports: [RouterModule],
    providers: [AddNoindexTagGuard]
})
export class AppRoutingModule {}
