<div class="site-video">
    <div class="text-width">
        <h2 appTypography="SUBTITLE">
            {{ 'SITE_PROMO_VIDEO' | translate }}
        </h2>
        <p appTypography="SECONDARY">
            {{ 'SITE_PROMO_VIDEO_DESTINATION1' | translate }}
        </p>
        <p appTypography="SECONDARY">
            {{ 'SITE_PROMO_VIDEO_DESTINATION2' | translate }}
        </p>
        <p appTypography="SECONDARY">
            {{ 'SITE_PROMO_VIDEO_DESTINATION3' | translate }}
        </p>
        <p appTypography="SECONDARY">
            {{ 'SITE_PROMO_VIDEO_DESTINATION4' | translate }}
            {{ 'NO' | translate }}
            <app-toggle
                (checkedChange)="this.showGameFrame = $event"
                [disabled]="movieState === 'IN_PROGRESS'">
            </app-toggle>
            <span appTypography="SECONDARY">
                {{ 'YES' | translate }}
            </span>
        </p>
    </div>
    <div class="generate-button">
        <app-standalone-custom-button
            (clicked)="generateVideo()"
            [isDisabled]="movieState === 'IN_PROGRESS'"
            class="go-to-account">
            {{ 'GENERATE' | translate }}
        </app-standalone-custom-button>
    </div>
    <p
        *ngIf="displayForbiddenErrMsg"
        appTypography="SECONDARY">
        {{ 'PROMO_VIDEO_FORBIDDEN' | translate }}
    </p>
    <div *ngIf="movieState === 'IN_PROGRESS'">
        <div class="in-progress-area">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <p appTypography="SECONDARY">
                {{ 'LOADING_BAR_TEXT' | translate }}
            </p>
        </div>
    </div>

    <div
        *ngIf="movieState === 'DONE' || movieURL"
        class="video-position">
        <div *ngIf="movieState === 'FAILED'">
            <p
                class="failed"
                appTypography="SECONDARY">
                {{ 'CREAT_MOVIE_FAILED_MESSAGE' | translate }}
                <a
                    appTypography="SECONDARY_UNDERLINE"
                    (click)="openContact()"
                    translate="EMAIL_WISHTRIP"></a>
                {{ 'CREAT_MOVIE_FAILED_MESSAGE2' | translate }}
            </p>
        </div>

        <app-video
            [hidePlay]="false"
            [source]="movieURL"
            [thumbnailFrame]="6"
            class="image"></app-video>
        <div class="download-button-flex">
            <app-standalone-custom-button (clicked)="downloadPromoVideo()">
                {{ 'DOWNLOAD_VIDEO' | translate }}
            </app-standalone-custom-button>
        </div>
    </div>
</div>
