<div class="container">
    <div class="qr-view">
        <div class="qr">
            <qrcode
                [qrdata]="shareLink"
                [allowEmptyString]="true"
                [width]="!isMobile ? qrWidth : qrWidth * 0.75"
                [errorCorrectionLevel]="'M'"
                [title]="qrName"
                id="id-{{ itemId }}"
                #qrcode></qrcode>
            <div
                appTypography="SECONDARY"
                class="qr-name">
                {{ qrName }}
            </div>
        </div>
    </div>
    <div class="link-actions">
        <app-standalone-custom-button
            class="link-btn"
            buttonStyle="SECONDARY_OUTLINED"
            buttonSize="MEDIUM"
            (clicked)="onOpenLinkClicked()">
            {{ 'OPEN_LINK' | translate }}
        </app-standalone-custom-button>
        <app-standalone-custom-button
            class="link-btn"
            buttonStyle="SECONDARY_OUTLINED"
            buttonSize="MEDIUM"
            (clicked)="onLinkCopied()"
            [cdkCopyToClipboard]="shareLink">
            {{ 'COPY_LINK' | translate }}
        </app-standalone-custom-button>
        <app-standalone-custom-button
            class="link-btn"
            buttonStyle="SECONDARY_OUTLINED"
            buttonSize="MEDIUM"
            (clicked)="downloadQrImage()">
            {{ 'DOWNLOAD_QR' | translate }}
        </app-standalone-custom-button>
        <div class="bottom-buttons">
            <i
                *ngIf="isMobile"
                (click)="onShareQrImage()"
                class="WTicon-share"
                ><span class="path1"></span><span class="path2"></span
            ></i>
            <i
                *ngIf="isDeletable"
                class="WTicon-round-delete"
                (click)="deleteHandler.emit(qrId)"
                ><span class="path1"></span><span class="path2"></span
            ></i>
        </div>
    </div>
</div>
