<a
    #sites_link="routerLinkActive"
    [routerLink]="['/', AppRoutePaths.SITES]"
    routerLinkActive
    [appTypography]="sites_link.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'"
    color="WHITE"
    >{{ 'SITES' | translate }}
</a>
<span
    color="WHITE"
    appTypography="SECONDARY"
    >|</span
>
<a
    #treks_link="routerLinkActive"
    [routerLink]="['/', AppRoutePaths.TREKS]"
    routerLinkActive
    [appTypography]="treks_link.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'"
    color="WHITE"
    >{{ 'TREKS' | translate }}
</a>
<span
    color="WHITE"
    appTypography="SECONDARY"
    >|</span
>
<a
    #users_link="routerLinkActive"
    [routerLink]="['/', AppRoutePaths.USERS]"
    routerLinkActive
    [appTypography]="users_link.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'"
    color="WHITE"
    >{{ 'USERS' | translate }}
</a>
<span
    color="WHITE"
    appTypography="SECONDARY"
    >|</span
>
<a
    #my_organisation_link="routerLinkActive"
    [appTypography]="
        my_organisation_link.isActive ? 'SECONDARY_BOLD' : 'SECONDARY'
    "
    [routerLink]="['/', AppRoutePaths.BUSINESS]"
    routerLinkActive
    color="WHITE"
    >{{ 'MY_ORGANIZATION' | translate }}
</a>
