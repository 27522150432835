export interface WarningModalData {
    title: string;
    content: string;
    image: string;
    actionText?: string;
    cancelText?: string;
    deleteText?: string;
    showExitButton?: boolean;
}

export enum WarningActions {
    'DELETE',
    'CANCEL',
    'ACTION',
    'EXIT'
}
