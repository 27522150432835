<div class="content">
    <div
        class="meetings-iframe-container"
        [attr.data-src]="
            hubspotMeetingWidgetService.displayMeetingWidgetUrl + '?embed=true'
        ">
        <i
            *ngIf="isMobile"
            class="WTicon-round-close"
            (click)="closeWidget()">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
    </div>
</div>
<div
    class="backdrop"
    (click)="closeWidget()"></div>
