import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HUBSPOT_PORTAL_ID } from '../shared/hubspot.constants';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
import { retry } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HubspotService {
    constructor(private http: HttpClient) {}

    sendHubspotForm(
        formId: string,
        params: { [key: string]: string | number | boolean }
    ): any {
        if (!environment.production) {
            return;
        }
        const data = {
            fields: Object.entries(params).map(([key, value]) => ({
                name: key,
                value
            })),
            context: {
                hutk: ('; ' + document.cookie)
                    .split(`; hubspotutk=`)
                    .pop()
                    ?.split(';')[0],
                pageUri: location.href,
                pageName: location.pathname.substring(
                    location.pathname.lastIndexOf('/') + 1
                )
            }
        };
        if (!data.context.hutk) {
            delete data.context.hutk;
            Sentry.withScope(function (scope) {
                scope.setTag('scope', 'hubspot');
                scope.setLevel('error');
                Sentry.captureException(
                    { Error: 'No hutk cookies found' },
                    {
                        contexts: {
                            COOKIES: { cookies: document.cookie }
                        }
                    }
                );
            });
        }
        const options = {
            headers: {
                withCredentialsRemove: 'true'
            }
        };
        return this.http
            .post(
                `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${formId}`,
                data,
                options
            )
            .pipe(retry(1))
            .subscribe({
                error: (error) => {
                    Sentry.withScope(function (scope) {
                        scope.setTag('scope', 'hubspot');
                        scope.setLevel('error');
                        Sentry.captureException(error, {
                            contexts: {
                                'HTTP BODY': {
                                    ...data.fields,
                                    context: data.context
                                }
                            }
                        });
                    });
                }
            });
    }
}
