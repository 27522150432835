<ng-container *ngIf="!(isSuccess$ | async) as isSuccess; else reset_msg">
    <h1
        appTypography="TITLE_BOLD"
        translate="RESET_PASSWORD"></h1>
    <p
        appTypography="SECONDARY"
        class="subtitle"
        translate="PASSWORD_RESET__INSTRUCTIONS"></p>
    <form
        [formGroup]="resetForm"
        class="reset-form"
        id="login-form">
        <app-custom-input-standalone
            class="email-input"
            [appErrorDot]="!!resetForm.controls.email.errors"
            [label]="'EMAIL' | translate"
            formControlName="email"
            type="email"
            id="login-email"></app-custom-input-standalone>
        <div
            *ngIf="(isSuccess$ | async) === false"
            id="login-error"
            class="send-email-error"
            [appTypography]="'SMALL'"
            color="WARN">
            {{ 'LOGIN_ERROR' | translate }}
        </div>
        <app-standalone-custom-button
            buttonType="submit"
            class="reset-btn"
            id="login-submit"
            (clicked)="resetPassword()"
            [isDisabled]="resetForm.invalid">
            {{ 'RESET' | translate }}
        </app-standalone-custom-button>
    </form>
    <p appTypography="SECONDARY_UNDERLINE">
        <a
            [routerLink]="['/', AppRoutePaths.AUTH, AuthRoutePaths.LOGIN]"
            translate="BACK_TO_LOGIN"></a>
    </p>
</ng-container>

<ng-template #reset_msg>
    <div class="success-reset">
        <img
            alt="success"
            [src]="imgSuccessReset" />
        <h3
            appTypography="PRIMARY_BOLD"
            translate="PASSWORD_RESET__SUCCESS__TITLE"></h3>
        <p
            appTypography="SECONDARY"
            translate="PASSWORD_RESET__SUCCESS__DESCRIPTION"></p>
    </div>
</ng-template>
