import { createAction, props } from '@ngrx/store';

export enum PrivacyActionTypes {
    UPDATE_PRIVACY = '[SiteWizard] Update Privacy',
    PRIVACY_COMPLETE = '[SiteWizard] Privacy Complete'
}

export const UpdatePrivacy = createAction(
    PrivacyActionTypes.UPDATE_PRIVACY,
    props<{ privacy: boolean }>()
);

export const PrivacyComplete = createAction(
    PrivacyActionTypes.PRIVACY_COMPLETE
);
