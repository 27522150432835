<mat-form-field
    appearance="outline"
    class="select-date-range"
    color="accent">
    <mat-label>{{ label || 'SELECT_DATES' | translate }}</mat-label>
    <mat-date-range-input
        [dir]="'ltr'"
        [formGroup]="dateRangeForm"
        (click)="picker.open()"
        [max]="max"
        [rangePicker]="$any(picker)">
        <input
            formControlName="start"
            [placeholder]="'FROM' | translate"
            matStartDate
            readonly />
        <input
            formControlName="end"
            [placeholder]="'UNTIL' | translate"
            matEndDate
            readonly />
    </mat-date-range-input>
    <mat-datepicker-toggle
        [disableRipple]="true"
        [for]="picker"
        class="toggle-datepicker"
        matSuffix></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error
        *ngIf="dateRangeForm.controls.start.hasError('matStartDateInvalid')"
        appTypography="TINY"
        >Invalid start date
    </mat-error>
    <mat-error
        *ngIf="dateRangeForm.controls.end.hasError('matEndDateInvalid')"
        appTypography="TINY"
        >Invalid end date
    </mat-error>
</mat-form-field>
