import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';

export enum GameWizardLanguagesActionTypes {
    ADD_LANGUAGES = '[GameWizard]: Add Languages',
    REMOVE_LANGUAGE = '[GameWizard]: Remove Language',
    SET_DEFAULT_LANGUAGE = '[GameWizard]: Set Default Language'
}

export const AddGameLanguage = createAction(
    GameWizardLanguagesActionTypes.ADD_LANGUAGES,
    props<{ langToAdd: LocaleType; defaultLang: LocaleType }>()
);
export const RemoveGameLanguage = createAction(
    GameWizardLanguagesActionTypes.REMOVE_LANGUAGE,
    props<{ langToRemove: LocaleType }>()
);
export const SetDefaultGameLanguage = createAction(
    GameWizardLanguagesActionTypes.SET_DEFAULT_LANGUAGE,
    props<{ language: LocaleType }>()
);
