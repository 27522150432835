import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';

export enum DcfWizardLanguagesActionTypes {
    ADD_LANGUAGES = '[DcfWizard]: Add Languages',
    REMOVE_LANGUAGE = '[DcfWizard]: Remove Language',
    SET_DEFAULT_LANGUAGE = '[DcfWizard]: Set Default Language'
}

export const AddDcfLanguage = createAction(
    DcfWizardLanguagesActionTypes.ADD_LANGUAGES,
    props<{ langToAdd: LocaleType; defaultLang: LocaleType }>()
);
export const RemoveDcfLanguage = createAction(
    DcfWizardLanguagesActionTypes.REMOVE_LANGUAGE,
    props<{ langToRemove: LocaleType }>()
);
export const SetDefaultDcfLanguage = createAction(
    DcfWizardLanguagesActionTypes.SET_DEFAULT_LANGUAGE,
    props<{ language: LocaleType }>()
);
