interface SocialLinkInfo {
    link: string;
    id: string;
}

export enum SocialLinks {
    INSTAGRAM = 'INSTAGRAM',
    LINKEDIN = 'LINKEDIN',
    FACEBOOK = 'FACEBOOK',
    TWITTER = 'TWITTER'
}

export const socialLinksMap = new Map<SocialLinks, SocialLinkInfo>([
    [
        SocialLinks.INSTAGRAM,
        {
            link: 'https://www.instagram.com/wishtrip/',
            id: 'social-instagram'
        }
    ],
    [
        SocialLinks.LINKEDIN,
        {
            link: 'https://il.linkedin.com/company/wishtrip',
            id: 'social-linkedin'
        }
    ],
    [
        SocialLinks.FACEBOOK,
        {
            link: 'https://www.facebook.com/WishTrip-146988566066509/',
            id: 'social-facebook'
        }
    ],
    [
        SocialLinks.TWITTER,
        {
            link: 'https://twitter.com/Wishtrip',
            id: 'social-twitter'
        }
    ]
]);
