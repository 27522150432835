import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class RequestIntercepter implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.headers.get('withCredentialsRemove') ||
            request.url.includes('strings/strings')
        ) {
            const headers = request.headers.delete('withCredentialsRemove');
            request = request.clone({ headers });
        } else {
            request = request.clone({
                withCredentials: true
            });
        }
        return next.handle(request).pipe(
            catchError((error) => {
                Sentry.withScope(function (scope) {
                    scope.setTag('scope', 'Http-failure');
                    scope.setLevel('error');
                    Sentry.captureException(error, {
                        contexts: {
                            'HTTP BODY': request.body || {}
                        }
                    });
                });
                return throwError(error);
            })
        );
    }
}
