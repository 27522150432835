import { createReducer } from '@ngrx/store';
import { initialState } from '../site-wizard-store.constants';
import * as GreetingActions from '../actions/greeting.actions';
import { SetGreetingSelectedLangTab } from '../actions/greeting.actions';
import { immerOn } from 'ngrx-immer/store';
import * as LanguageActions from '../actions/language.actions';
import {
    GreetingMessageByLang,
    SiteGreeting
} from '../../models/site-wizard-greeting.model';
import { LocaleType } from '../../../shared/locales.models';

export const greetingReducer = createReducer(
    initialState.greeting,
    immerOn(GreetingActions.ToggleWelcomeMessage, (draft) => {
        draft.data!.entryMessageEnabled = !draft.data!.entryMessageEnabled;
        draft.sectionStatus.invalid = checkIfGreetingsValid(draft.data!);
    }),
    immerOn(GreetingActions.ToggleExitMessage, (draft) => {
        draft.data!.exitMessageEnabled = !draft.data!.exitMessageEnabled;
        draft.sectionStatus.invalid = checkIfGreetingsValid(draft.data!);
    }),
    immerOn(
        GreetingActions.UpdateMessages,
        (draft, { language, fieldName, value }) => {
            if (!draft.data?.messages) {
                draft.data!.messages = {} as GreetingMessageByLang;
            }
            (draft.data!.messages as any)[language][fieldName] = value;
            draft.sectionStatus.invalid = checkIfGreetingsValid(draft.data!);
        }
    ),
    immerOn(
        GreetingActions.ChangeMessageAppearance,
        (draft, { messageAppearance }) => {
            draft.data!.messageAppearance = messageAppearance;
        }
    ),
    immerOn(GreetingActions.GreetingComplete, (draft) => {
        draft.sectionStatus.complete = true;
    }),
    immerOn(LanguageActions.addSiteLanguage, (draft, { language }) => {
        if (!draft.data?.messages) {
            draft.data!.messages = {} as GreetingMessageByLang;
        }
        draft.data!.messages[language] = {};
        draft.sectionStatus.complete = false;
        if (Object.values(draft.data!.messages).length === 1) {
            draft.sectionStatus.selectedLangTab = language;
        }
    }),
    immerOn(LanguageActions.deleteSiteLanguage, (draft, { language }) => {
        if (!draft.data?.messages) {
            return;
        }
        const { [language]: value, ...withoutLang } = draft.data.messages;
        draft.data!.messages = { ...withoutLang } as GreetingMessageByLang;
        if (draft.sectionStatus.selectedLangTab === language) {
            draft.sectionStatus.selectedLangTab = Object.keys(
                draft.data.messages
            )[0] as LocaleType;
        }
    }),
    immerOn(SetGreetingSelectedLangTab, (draft, { selectedLangTab }) => {
        draft.sectionStatus.selectedLangTab = selectedLangTab;
    })
);

function checkIfGreetingsValid(greetings: SiteGreeting): boolean {
    let invalid = false;
    if (!greetings.entryMessageEnabled && !greetings.exitMessageEnabled) {
        return invalid;
    }
    if (greetings.entryMessageEnabled || greetings.exitMessageEnabled) {
        for (const message of Object.values(greetings.messages!)) {
            if (
                greetings.entryMessageEnabled &&
                (!message?.entryMessage || message?.entryMessage === '')
            ) {
                return (invalid = true);
            }
            if (
                greetings.exitMessageEnabled &&
                (!message?.exitMessage || message?.exitMessage === '')
            ) {
                return (invalid = true);
            }
        }
    }
    return invalid;
}
