import { createAction, props } from '@ngrx/store';
import { Polygon, Position } from 'geojson';
import { LatLon } from '../../../mapbox/typings/mapbox-utils';
import {
    SiteMapStages,
    SiteMapStyleTypes
} from '../../models/site-wizard-map-setup.model';

export enum MapActionTypes {
    UPDATE_ANCHOR_POINT = '[SiteWizard] Update Anchor Point',
    DELETE_ANCHOR_POINT = '[SiteWizard] Delete Anchor Point',
    UPDATE_NAVIGATABLE = '[SiteWizard] Navigatable',
    CREATE_POLYGON = '[SiteWizard] Create Polygon',
    UPDATE_POLYGON = '[SiteWizard] Update Polygon',
    FIX_POLYGON = '[SiteWizard] Fix Polygon',
    DELETE_POLYGON = '[SiteWizard] Delete Polygon',
    UPDATE_SHOW_POLYGON = '[SiteWizard] Update Show Polygon',
    UPDATE_MAP_STYLE = '[SiteWizard] Update Map Style',
    UPDATE_MAP_STAGE = '[SiteWizard] Update Map Stage',
    MAP_COMPLETE = '[SiteWizard] Map Complete'
}

export const updateAnchorPoint = createAction(
    MapActionTypes.UPDATE_ANCHOR_POINT,
    props<{ coordinate: LatLon }>()
);

export const deleteAnchorPoint = createAction(
    MapActionTypes.DELETE_ANCHOR_POINT
);

export const updateNavigatable = createAction(
    MapActionTypes.UPDATE_NAVIGATABLE,
    props<{ navigatable: boolean }>()
);

export const createPolygon = createAction(
    MapActionTypes.CREATE_POLYGON,
    props<{ geoJson: Polygon }>()
);

export const updatePolygon = createAction(
    MapActionTypes.UPDATE_POLYGON,
    props<{ coords: Position[] }>()
);

export const fixPolygon = createAction(
    MapActionTypes.FIX_POLYGON,
    props<{ coords: Position[] }>()
);

export const deletePolygon = createAction(MapActionTypes.DELETE_POLYGON);

export const updateShowPolygon = createAction(
    MapActionTypes.UPDATE_SHOW_POLYGON,
    props<{ showPolygon: boolean }>()
);

export const updateMapStyle = createAction(
    MapActionTypes.UPDATE_MAP_STYLE,
    props<{ mapStyle: SiteMapStyleTypes }>()
);

export const updateMapStage = createAction(
    MapActionTypes.UPDATE_MAP_STAGE,
    props<{ stage: SiteMapStages }>()
);

export const MapComplete = createAction(MapActionTypes.MAP_COMPLETE);
