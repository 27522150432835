import { createReducer } from '@ngrx/store';
import {
    AddGameLanguage,
    RemoveGameLanguage
} from '../actions/game-wizard-languages.actions';
import {
    GameGeneralDetailsSection,
    GameWizardGeneralDetails
} from '../../models/game-wizard-general-details.model';
import { immerOn } from 'ngrx-immer/store';
import {
    setGeneralDetailsSelectedLangTab,
    updateGameIcon,
    updateGameName,
    updateGameSubtitle
} from '../actions/game-wizard-general-details.actions';
import { LocaleType } from '../../../shared/locales.models';

export const gameGeneralDetailsReducer = createReducer(
    {} as GameGeneralDetailsSection,
    immerOn(AddGameLanguage, (state, { langToAdd }) => {
        state.data!.details![langToAdd] = { name: '', subtitle: '' };
        state.sectionStatus.invalid = checkIfGeneralDetailsStateInvalid(
            state.data
        );
        state.sectionStatus.complete = checkIfGeneralDetailsStateCompleted(
            state.data
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        if (Object.values(state.data?.details || {}).length === 1) {
            state.sectionStatus.selectedLangTab = langToAdd;
        }
    }),
    immerOn(RemoveGameLanguage, (state, { langToRemove }) => {
        delete state.data?.details?.[langToRemove];
        state.sectionStatus.invalid = checkIfGeneralDetailsStateInvalid(
            state.data
        );
        state.sectionStatus.complete = checkIfGeneralDetailsStateCompleted(
            state.data
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        if (state.sectionStatus.selectedLangTab === langToRemove) {
            state.sectionStatus.selectedLangTab = Object.keys(
                state.data?.details || {}
            )[0] as LocaleType;
        }
    }),
    immerOn(updateGameName, (state, { name, local }) => {
        state.data!.details![local].name = name;
        state.sectionStatus.invalid = checkIfGeneralDetailsStateInvalid(
            state.data
        );
        state.sectionStatus.complete = checkIfGeneralDetailsStateCompleted(
            state.data
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(updateGameSubtitle, (state, { subtitle, local }) => {
        state.data!.details![local].subtitle = subtitle;
        state.sectionStatus.invalid = checkIfGeneralDetailsStateInvalid(
            state.data
        );
        state.sectionStatus.complete = checkIfGeneralDetailsStateCompleted(
            state.data
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(updateGameIcon, (state, { icon }) => {
        state.data!.icon = icon;
        state.sectionStatus.invalid = checkIfGeneralDetailsStateInvalid(
            state.data
        );
        state.sectionStatus.complete = checkIfGeneralDetailsStateCompleted(
            state.data
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(setGeneralDetailsSelectedLangTab, (state, { selectedLangTab }) => {
        state.sectionStatus.selectedLangTab = selectedLangTab;
    })
);

function checkIfGeneralDetailsStateInvalid(
    state:
        | {
              details?: GameWizardGeneralDetails;
              icon?: string;
          }
        | undefined
) {
    return (
        !state ||
        !Object.keys(state?.details || {}).length ||
        !(
            (Object.keys(state?.details || {}) as LocaleType[]).every(
                (lang) =>
                    state.details![lang]?.name && state.details![lang]?.subtitle
            ) && !!state?.icon
        )
    );
}

function checkIfGeneralDetailsStateCompleted(
    state:
        | {
              details?: GameWizardGeneralDetails;
              icon?: string;
          }
        | undefined
) {
    return (
        !!(Object.keys(state?.details || {}) as LocaleType[])?.length &&
        !!(Object.keys(state?.details || {}) as LocaleType[]).every(
            (lang) =>
                state?.details![lang].name &&
                state.details![lang].subtitle &&
                state.icon
        )
    );
}
