import { Directive, DoCheck, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appErrorDot]'
})
export class ErrorDotDirective implements OnInit, DoCheck {
    @Input('appErrorDot') displayDot!: boolean;
    @Input() dotTop = '35%';
    dotElement = document.createElement('span');

    constructor(private hostElement: ElementRef) {}

    ngOnInit(): void {
        Object.assign(this.dotElement.style, {
            height: '7px',
            width: '7px',
            borderRadius: '50%',
            backgroundColor: '#c1272d',
            position: 'absolute',
            'inset-inline-start': '-10px',
            top: this.dotTop
        });
        this.hostElement.nativeElement.insertBefore(
            this.dotElement,
            this.hostElement.nativeElement.firstChild
        );
    }

    ngDoCheck(): void {
        if (this.displayDot) {
            this.dotElement.style.display = 'inline';
        } else {
            this.dotElement.style.display = 'none';
        }
    }
}
