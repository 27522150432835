import { EventedMarker } from './EventedMarker';
import * as mapboxgl from 'mapbox-gl';
import distance from '@turf/distance';
import { lngLatLikeToLngLat, lngLatLikeToPosition } from './helpers';

interface AnimatedMarkerOptions {
    interval?: number;
    distance?: number;
}

export class AnimatedMarker extends EventedMarker {
    private options: AnimatedMarkerOptions = {
        interval: 1000,
        distance: 200
    };
    private previousLocation?: mapboxgl.LngLat;
    private transitionProp = this.testProp([
        'webkitTransition',
        'transition',
        'OTransition',
        'MozTransition',
        'msTransition'
    ]);

    setOptions(options?: AnimatedMarkerOptions): this {
        // this.options = {
        //     ...this.options,
        //     ...options
        // };

        return this;
    }

    setLngLat(lngLat: mapboxgl.LngLatLike): this {
        if (!this.previousLocation) {
            this.previousLocation = lngLatLikeToLngLat(lngLat);
            return super.setLngLat(lngLat);
        }

        const prevPosition: number[] = [
            this.previousLocation.lng,
            this.previousLocation.lat
        ];
        const currPosition: number[] = lngLatLikeToPosition(lngLat);

        const distanceMeters = distance(prevPosition, currPosition) * 1000;

        this.previousLocation = lngLatLikeToLngLat(lngLat);
        return super.setLngLat(lngLat);
    }

    // @function testProp(props: String[]): String|false
    // Goes through the array of style names and returns the first name
    // that is a valid style name for an element. If no such name is found,
    // it returns false. Useful for vendor-prefixed styles like `transform`.
    private testProp(props: string[]) {
        const style = document.documentElement.style;

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < props.length; i++) {
            if (props[i] in style) {
                return props[i];
            }
        }
        return false;
    }
}
