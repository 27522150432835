import { createAction, props } from '@ngrx/store';
import { ContactInformation } from '../../models/site-wizard-contact.model';

export enum ContactActionTypes {
    UPDATE_CONTACT = '[SiteWizard] Contact Update Details',
    CONTACT_COMPLETE = '[SiteWizard] Contact Complete'
}

export const UpdateContact = createAction(
    ContactActionTypes.UPDATE_CONTACT,
    props<{ contactInfo: ContactInformation; invalid: boolean }>()
);

export const ContactComplete = createAction(
    ContactActionTypes.CONTACT_COMPLETE
);
