import { Pipe, PipeTransform } from '@angular/core';
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl,
    SafeScript,
    SafeStyle,
    SafeUrl
} from '@angular/platform-browser';
import { SafeTypes } from './safe-pipe.const';

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(
        value: string,
        type?: string
    ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case SafeTypes.HTML:
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case SafeTypes.STYLE:
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case SafeTypes.SCRIPT:
                return this.sanitizer.bypassSecurityTrustScript(value);
            case SafeTypes.URL:
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            case SafeTypes.RESOURCE_URL:
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                return this.sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
