import { CanActivateFn, CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { CrispLiveChatService } from '../services/crisp-live-chat.service';

export const showCrispLiveChatGuard: CanDeactivateFn<any> = () => {
    const crispLiveChatService = inject(CrispLiveChatService);
    crispLiveChatService.showChat();
    return true;
};

export const hideCrispLiveChatGuard: CanActivateFn = () => {
    const crispLiveChatService = inject(CrispLiveChatService);
    crispLiveChatService.hideChat();
    return true;
};
