import {
    OperativeTypographyColorName,
    OperativeTypographyColorNumber,
    OperativeTypographyStyle,
    OperativeTypographyTypes
} from './typography.model';

export const getOperativeTypographyDesktopStyleMap = () =>
    new Map<keyof typeof OperativeTypographyTypes, OperativeTypographyStyle>([
        [
            OperativeTypographyTypes.TITLE_BOLD,
            {
                fontSize: '48px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.1'
            }
        ],
        [
            OperativeTypographyTypes.SUBTITLE,
            {
                fontSize: '32px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.1'
            }
        ],
        [
            OperativeTypographyTypes.PRIMARY,
            {
                fontSize: '20px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.PRIMARY_BOLD,
            {
                fontSize: '20px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY,
            {
                fontSize: '16px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_BOLD,
            {
                fontSize: '16px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_UNDERLINE,
            {
                fontSize: '16px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2',
                textDecoration: 'underline'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_BOLD_UNDERLINE,
            {
                fontSize: '16px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2',
                textDecoration: 'underline'
            }
        ],
        [
            OperativeTypographyTypes.SMALL,
            {
                fontSize: '14.4px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.FOOTER,
            {
                fontSize: '13px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1'
            }
        ],
        [
            OperativeTypographyTypes.TINY,
            {
                fontSize: '12px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.TINY_BOLD,
            {
                fontSize: '12px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ]
    ]);

export const getOperativeTypographyMobileStyleMap = () =>
    new Map<keyof typeof OperativeTypographyTypes, OperativeTypographyStyle>([
        [
            OperativeTypographyTypes.TITLE_BOLD,
            {
                fontSize: '42px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.1'
            }
        ],
        [
            OperativeTypographyTypes.SUBTITLE,
            {
                fontSize: '28px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.1'
            }
        ],
        [
            OperativeTypographyTypes.PRIMARY,
            {
                fontSize: '17.5px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.PRIMARY_BOLD,
            {
                fontSize: '17.5px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY,
            {
                fontSize: '14px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_BOLD,
            {
                fontSize: '14px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_UNDERLINE,
            {
                fontSize: '14px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2',
                textDecoration: 'underline'
            }
        ],
        [
            OperativeTypographyTypes.SECONDARY_BOLD_UNDERLINE,
            {
                fontSize: '14px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2',
                textDecoration: 'underline'
            }
        ],
        [
            OperativeTypographyTypes.SMALL,
            {
                fontSize: '12.6px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.TINY,
            {
                fontSize: '10.5px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1.2'
            }
        ],
        [
            OperativeTypographyTypes.FOOTER,
            {
                fontSize: '13px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '1'
            }
        ],
        [
            OperativeTypographyTypes.TINY_BOLD,
            {
                fontSize: '10.5px',
                letterSpacing: '0',
                fontWeight: 'bold',
                lineHeight: '1.2'
            }
        ]
    ]);

export const getOperativeTypographyColorMap = () =>
    new Map<
        keyof typeof OperativeTypographyColorName,
        OperativeTypographyColorNumber
    >([
        [OperativeTypographyColorName.DEFAULT_COLOR, { color: '#403C3E' }], //$primary-Black
        [
            OperativeTypographyColorName.DEFAULT_COLOR_OPACITY,
            { color: '#B6B6B6' } //$gray-400
        ],
        [OperativeTypographyColorName.WARN, { color: '#BF360C' }], //$primary-Red
        [OperativeTypographyColorName.TURQUOISE, { color: '#57645C' }], //$console-header
        [OperativeTypographyColorName.WHITE, { color: '#fff' }], //$primary-White
        [OperativeTypographyColorName.LIGHT_GRAY, { color: '#9b9b9b' }], //$gray-500
        [OperativeTypographyColorName.ACCENT, { color: '#ffc905' }], //$primary-Yellow
        [
            OperativeTypographyColorName.DEFAULT_COLOR_DISABLED,
            { color: '#D7D7D7' } //$gray-300
        ],
        [OperativeTypographyColorName.GREEN, { color: '#70a682' }] //$Main-wizard-green
    ]);
