import { Pipe, PipeTransform } from '@angular/core';
import { SpotAndTrekBaseQrData } from '../models/my-qr.model';

@Pipe({
    name: 'searchTreksOrSpotsByName'
})
export class SearchTreksOrSpotsByNamePipe implements PipeTransform {
    transform(
        treksOrSpots: SpotAndTrekBaseQrData[],
        searchTerm: string
    ): SpotAndTrekBaseQrData[] {
        return treksOrSpots?.filter(
            (trekOrSpot) =>
                !searchTerm ||
                trekOrSpot?.name
                    ?.toLowerCase()
                    ?.includes(searchTerm?.toLowerCase())
        );
    }
}
