import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    OnDestroy,
    OnInit
} from '@angular/core';
import { finalize, map, take } from 'rxjs/operators';
import { TrekService } from '../../services/trek.service';
import { WarningDialogComponent } from '../../../standalone/components/warning-dialog/warning-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { TrekWizardMode } from 'src/app/trek-wizard/store/trek-wizard.model';
import { PermissionsService } from '../../../services/permissions.service';
import { USER_ACTIONS } from '../../../users/users.model';
import { ActivatedRoute, Router, UrlSegmentGroup } from '@angular/router';
import { SCREEN_BREAK_POINTS } from '../../../shared/shared.constants';
import {
    WarningActions,
    WarningModalData
} from '../../../standalone/components/warning-dialog/warning-dialog.const';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutePaths } from '../../../app-routing.module';
import { TreksRoutePaths } from '../../treks-routing.module';
import { BehaviorSubject } from 'rxjs';
import { AppStoreService } from '../../../store/app-store.service';
import { SitesRoutePaths } from '../../../sites/sites-routing.module';
import { HeaderService } from '../../../services/header.service';
import { TrekCard } from '../../../standalone/components/trek-card/trek-card.model';
import { statusLabelColorMap } from '../../../standalone/components/card-label/card-label.consts';
import { trekStatusLabelMap } from '../../../trek-wizard/store/trek-wizard-store.utils';

@Component({
    selector: 'app-treks',
    templateUrl: './treks.component.html',
    styleUrls: ['./treks.component.scss'],
    providers: [TrekService], // TrekService is provided here in order to
    // trigger its ngOnDestroy() once TreksComponent is destroyed
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreksComponent implements OnInit, OnDestroy {
    TrekWizardMode = TrekWizardMode;
    filteredMode?: TrekWizardMode;
    searchTerm?: string;
    treks: TrekCard[] = [];
    displayTreks$ = new BehaviorSubject<TrekCard[]>([]);
    showSpinner?: boolean;
    hasAccessCreateTrek?: boolean;
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
    treksFirstLoad = true;
    statusLabelColorMap = statusLabelColorMap;
    trekStatusLabelMap = trekStatusLabelMap;
    @HostBinding('class.is-in-dialog') isInsideDialog =
        ((this.activatedRoute.snapshot as any)._urlSegment as UrlSegmentGroup)
            .segments[2]?.path === SitesRoutePaths.SITE_WIZARD;

    constructor(
        private appStoreService: AppStoreService,
        private trekService: TrekService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private snackbarService: SnackbarService,
        private permissionsService: PermissionsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private headerService: HeaderService
    ) {}

    ngOnInit(): void {
        if (!this.isInsideDialog) {
            this.headerService.breadcrumbs = [];
        }
        this.getTreksList();
        this.hasAccessCreateTrek =
            this.permissionsService.checkIfUserHasPermission(
                USER_ACTIONS.CREATE_TREK
            );
    }

    ngOnDestroy(): void {
        this.trekService.treksPaginationIndex = 0;
    }

    getTreksList(): void {
        const businessId = this.appStoreService.state.auth.user?.businessId!;
        this.showSpinner = true;
        this.trekService
            .getTreksList(businessId)
            .pipe(
                map((result) => {
                    this.treks = [...this.treks, ...result];
                    this.displayTreks$.next([
                        ...this.displayTreks$.value,
                        ...result
                    ]);
                }),
                finalize(() => {
                    this.showSpinner = false;
                }),
                take(1)
            )
            .subscribe(() => {
                if (this.treksFirstLoad) {
                    this.treksFirstLoad = false;
                    this.getTreksList();
                }
            });
    }

    filerByTerm(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filterTreks();
    }

    filterByMode(mode: TrekWizardMode): void {
        this.filteredMode = this.filteredMode !== mode ? mode : undefined;
        this.filterTreks();
    }

    filterTreks(): void {
        let displayTreks =
            !this.searchTerm || this.searchTerm === ''
                ? this.treks
                : this.treks?.filter((trek) =>
                      trek?.name
                          ?.toLowerCase()
                          ?.includes(this.searchTerm?.toLowerCase()!)
                  );
        if (this.filteredMode) {
            displayTreks = displayTreks?.filter(
                (trek) => trek?.mode === this.filteredMode
            );
        }
        this.displayTreks$.next(displayTreks);
    }

    deleteTrek(trekId: string): void {
        const data: WarningModalData = {
            title: 'DELETE_TREK',
            content: 'DELETE_TREK_ARE_YOU_SURE',
            image: '<i class="WTicon-are_you_sure"></i>',
            deleteText: 'YES_DELETE_IT',
            cancelText: 'ACTUALLY_LETS_KEEP_IT'
        };
        const dialogRef = this.matDialog.open(WarningDialogComponent, {
            maxWidth: '550px',
            data
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === WarningActions.DELETE) {
                this.showSpinner = true;
                this.trekService
                    .deleteTrek(trekId)
                    .pipe(finalize(() => (this.showSpinner = false)))
                    .subscribe({
                        next: () => {
                            this.treks = this.treks.filter(
                                (trek) => trek.id !== trekId
                            );
                            this.displayTreks$.next(
                                this.displayTreks$.value.filter(
                                    (trek) => trek.id !== trekId
                                )
                            );
                            this.snackbarService.open(
                                this.translate.instant(
                                    'SUCCESSFULLY_DELETED_TREK'
                                )
                            );
                        },
                        error: () =>
                            this.snackbarService.open(
                                this.translate.instant('ERROR_DELETING_TREK')
                            )
                    });
            }
        });
    }

    createTrek(): void {
        if (this.isMobile) {
            return this.snackbarService.open(
                this.translate.instant('MOBILE_TREK_TOAST')
            );
        }
        if (!this.hasAccessCreateTrek) {
            return;
        }
        const isInsideDialog =
            (
                (this.activatedRoute.snapshot as any)
                    ._urlSegment as UrlSegmentGroup
            ).segments[2]?.path === SitesRoutePaths.SITE_WIZARD;
        this.router.navigate(
            [
                isInsideDialog ? './' : `./${AppRoutePaths.TREKS}`,
                TreksRoutePaths.CREATE_TREK
            ],
            { relativeTo: isInsideDialog ? this.activatedRoute : undefined }
        );
    }

    onScrollDown(): void {
        if (this.showSpinner) {
            return;
        }
        this.getTreksList();
    }
}
