import { Component, Inject } from '@angular/core';

import { ButtonsContainerStandaloneComponent } from '../buttons/buttons-container/buttons-container-standalone.component';
import { CustomButtonStandaloneComponent } from '../buttons/custom-button/custom-button-standalone.component';
import { NgIf } from '@angular/common';
import { WarningActions, WarningModalData } from './warning-dialog.const';
import { TranslateModule } from '@ngx-translate/core';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
    standalone: true,
    selector: 'app-warning-dialog',
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['./warning-dialog.component.scss'],
    imports: [
        ButtonsContainerStandaloneComponent,
        CustomButtonStandaloneComponent,
        MatDialogModule,
        NgIf,
        TranslateModule,
        TypographyDirective
    ]
})
export class WarningDialogComponent {
    WarningActions = WarningActions;

    constructor(@Inject(MAT_DIALOG_DATA) public data: WarningModalData) {}
}
