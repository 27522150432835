import { createReducer } from '@ngrx/store';
import * as CategoriesActions from '../actions/categories.actions';
import * as RoutesActions from '../actions/routes.actions';
import { initialState } from '../site-wizard-store.constants';
import { DEFAULT_MAP_CATEGORY } from '../../constants/site-wizard.constants';
import { orderItems } from '../site-wizard-store.utils';
import * as SpotsActions from '../actions/spots.actions';
import { getSpotTempId } from '../../services/state-parser.service';
import { immerOn } from 'ngrx-immer/store';
import {
    CategoryDisplayMode,
    CategoryDisplaySize,
    SiteCategory,
    SiteCategoryItem
} from '../../models/site-wizard-categories.model';
import { OoiType } from '../../site-wizard.model';
import { transferArrayItem } from '@angular/cdk/drag-drop';

export const categoriesReducer = createReducer(
    initialState.categories,
    immerOn(CategoriesActions.AddCategory, (draft, { id }) => {
        if (!draft.data?.length) {
            draft.data = [];
        }
        draft.data = orderItems([...draft.data, new SiteCategory(id)]);
        draft.sectionStatus.invalid = false;
    }),
    immerOn(CategoriesActions.OrderCategory, (draft, { categories }) => {
        draft.data = orderItems(categories);
    }),
    immerOn(CategoriesActions.DeleteCategory, (draft, { id }) => {
        if (!draft?.data?.length) {
            return;
        }
        const deletedCategoryItems = draft.data.find(
            (category) => category.id === id
        )?.items;
        if (deletedCategoryItems?.length) {
            const items = draft.data[0].items?.length
                ? draft.data[0].items
                : [];
            draft.data[0].items = [...items, ...deletedCategoryItems];
        }
        draft.data = orderItems(
            draft.data.filter((category) => category.id !== id)
        );
        draft.sectionStatus.invalid = draft.data?.length < 1;
    }),
    immerOn(
        CategoriesActions.UpdateCategoryDisplayMode,
        (draft, { id, displayMode }) => {
            const selectedIndex = draft.data!.findIndex(
                (category) => category.id === id
            );
            draft!.data![selectedIndex].displayMode = displayMode;
        }
    ),
    immerOn(
        CategoriesActions.UpdateCategoryDisplaySize,
        (draft, { id, displaySize }) => {
            const selectedIndex = draft.data!.findIndex(
                (category) => category.id === id
            );
            draft!.data![selectedIndex].displaySize = displaySize;
        }
    ),
    immerOn(CategoriesActions.UpdateCategoryRandom, (draft, { id, random }) => {
        const selectedIndex = draft!.data!.findIndex(
            (category) => category.id === id
        );
        draft!.data![selectedIndex].itemsRandomOrder = random;
    }),
    immerOn(CategoriesActions.UpdateCategoriesData, (draft, { categories }) => {
        if (!draft?.data?.length || !categories) {
            return;
        }
        draft!.data = categories.map((category, index) => ({
            ...category,
            order: index,
            items: category.items?.length
                ? orderItems([...category.items])
                : undefined
        }));
    }),
    immerOn(CategoriesActions.CategoriesComplete, (draft) => {
        draft!.sectionStatus.complete = true;
    }),
    immerOn(RoutesActions.AddRoute, (draft, { route }) => {
        const categoryItem: SiteCategoryItem = {
            id: route.trekId,
            order: 0,
            type: OoiType.TOI
        };
        draft!.data = addItemToCategories(draft!.data!, categoryItem);
    }),
    immerOn(RoutesActions.DeleteRoute, (draft, { id }) => {
        if (!draft?.data?.length) {
            return;
        }
        draft!.data = draft!.data.map((category) => ({
            ...category,
            items: category.items?.length
                ? orderItems([
                      ...category.items.filter((item) => item.id !== id)
                  ])
                : undefined
        }));
    }),
    immerOn(SpotsActions.AddSpot, (draft, { coordinates, languages }) => {
        const categoryItem: SiteCategoryItem = {
            id: getSpotTempId(coordinates),
            tempId: getSpotTempId(coordinates),
            order: 0,
            type: OoiType.SOI
        };
        draft!.data = addItemToCategories(draft!.data!, categoryItem);
    }),
    immerOn(SpotsActions.UpdateSpotLocation, (draft, { id, coordinate }) => {
        if (!draft?.data?.length) {
            return;
        }
        draft!.data = draft!.data.map((cat) => ({
            ...cat,
            items: !cat.items
                ? []
                : cat.items.map((item) => ({
                      ...item,
                      id: item.id === id ? getSpotTempId(coordinate) : item.id,
                      tempId:
                          item.tempId === id
                              ? getSpotTempId(coordinate)
                              : item.tempId
                  }))
        }));
    }),
    immerOn(SpotsActions.DeleteSpot, (draft, { id }) => {
        draft.data = deleteItemFromCategories(draft!.data!, id);
    }),
    immerOn(
        SpotsActions.HandleSpotsFromSheet,
        (draft, { sheetSpotsList, languagesList }) => {
            if (!draft.data?.length) {
                draft.data = [];
            }
            sheetSpotsList.forEach((sheetSpot) => {
                const sheetCategoryId =
                    sheetSpot.category?.toUpperCase() || DEFAULT_MAP_CATEGORY;
                const spotId = getSpotTempId({
                    lat: +(+sheetSpot.lat).toFixed(6),
                    lon: +(+sheetSpot.lon).toFixed(6)
                });
                const categoryItem: SiteCategoryItem = {
                    id: spotId,
                    tempId: spotId,
                    order: 0,
                    type: OoiType.SOI
                };
                const existedSpotCategoryIndex = draft.data!.findIndex(
                    (category) =>
                        category.items?.find((item) => item.tempId === spotId)
                );
                const targetCategoryIndex = draft.data!.findIndex(
                    (category) => category.id === sheetCategoryId
                );
                const isPreviousCategoryExist = existedSpotCategoryIndex > -1;
                const isTargetCategoryExist = targetCategoryIndex > -1;
                const isCategoryEqual =
                    isPreviousCategoryExist &&
                    targetCategoryIndex === existedSpotCategoryIndex;
                if (isCategoryEqual) {
                    return;
                } else if (isPreviousCategoryExist && isTargetCategoryExist) {
                    if (!draft.data![targetCategoryIndex].items) {
                        draft.data![targetCategoryIndex].items = [];
                    }
                    const existedSpotItemIndex = draft.data![
                        existedSpotCategoryIndex
                    ].items!.findIndex((item) => item.tempId === spotId);
                    transferArrayItem<SiteCategoryItem>(
                        draft.data![existedSpotCategoryIndex].items!,
                        draft.data![targetCategoryIndex].items!,
                        existedSpotItemIndex,
                        draft.data![targetCategoryIndex].items!.length
                    );
                    draft.data![targetCategoryIndex].items = orderItems(
                        draft.data![targetCategoryIndex].items!
                    );
                } else if (isPreviousCategoryExist && !isTargetCategoryExist) {
                    const existedSpotItemIndex = draft.data![
                        existedSpotCategoryIndex
                    ].items!.findIndex((item) => item.tempId === spotId);
                    draft.data = orderItems([
                        ...draft.data!,
                        new SiteCategory(sheetCategoryId)
                    ]);
                    transferArrayItem<SiteCategoryItem>(
                        draft.data![existedSpotCategoryIndex].items!,
                        draft.data![draft.data!.length - 1].items!,
                        existedSpotItemIndex,
                        0
                    );
                } else if (!isPreviousCategoryExist && isTargetCategoryExist) {
                    draft.data![targetCategoryIndex].items = orderItems([
                        ...(draft.data![targetCategoryIndex].items || []),
                        categoryItem
                    ]);
                } else {
                    draft!.data = addItemToCategories(
                        [...draft!.data!],
                        categoryItem,
                        sheetCategoryId
                    );
                }
            });
        }
    )
);

function addItemToCategories(
    categories: SiteCategory[],
    item: SiteCategoryItem,
    category?: string
): SiteCategory[] {
    if (!categories?.length) {
        categories = [
            {
                id: DEFAULT_MAP_CATEGORY,
                displayMode: CategoryDisplayMode.VERTICAL,
                displaySize: CategoryDisplaySize.LARGE,
                order: 0,
                itemsRandomOrder: false,
                items: []
            }
        ];
    }
    const categoryIndex = categories.findIndex(
        (c) => c.id === (category || DEFAULT_MAP_CATEGORY)
    );
    if (categoryIndex > -1) {
        categories[categoryIndex] = {
            ...categories[categoryIndex],
            items: orderItems(
                !categories[categoryIndex]?.items
                    ? [item]
                    : [...categories[categoryIndex].items!, item]
            )
        };
    } else {
        categories = orderItems([
            ...categories,
            {
                id: category!,
                displayMode: CategoryDisplayMode.VERTICAL,
                displaySize: CategoryDisplaySize.LARGE,
                order: 0,
                itemsRandomOrder: false,
                items: [item]
            }
        ]);
    }
    return categories;
}

function deleteItemFromCategories(
    categories: SiteCategory[],
    id: string
): SiteCategory[] {
    categories.forEach((category, index) => {
        categories[index] = {
            ...categories[index],
            items: categories[index].items?.length
                ? orderItems([
                      ...categories[index].items!.filter(
                          (item) => item.tempId !== id
                      )
                  ])
                : undefined
        };
    });
    return categories;
}
