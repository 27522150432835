import { createReducer } from '@ngrx/store';
import {
    AddGameLanguage,
    RemoveGameLanguage
} from '../actions/game-wizard-languages.actions';
import { immerOn } from 'ngrx-immer/store';

import {
    CompletionDetails,
    GameCompletionDetails,
    GameCompletionSection
} from '../../models/game-wizard-completion.model';
import {
    AddGameCompletionMedia,
    DeleteGameCompletionMedia,
    OrderGameCompletionMedia,
    setGameCompletionSelectedLangTab,
    updateGameCompletionEndDescription
} from '../actions/game-wizard-completion.actions';
import { LocalesKeysObject, LocaleType } from '../../../shared/locales.models';
import { orderGameMediaItems } from '../../constants/game-wizard.const';

export const gameCompletionReducer = createReducer(
    {} as GameCompletionSection,
    immerOn(AddGameLanguage, (state, { langToAdd, defaultLang }) => {
        if (!state.data?.details) {
            state.data!.details = {} as LocalesKeysObject<CompletionDetails>;
        }
        state.data!.details[langToAdd] = {
            endDescription: '',
            endMedia:
                Object.keys(state.data?.details || {}).length &&
                !state.sectionStatus.isMediaChanged
                    ? state.data?.details[
                          defaultLang ||
                              (
                                  Object.keys(
                                      state.data?.details
                                  ) as LocaleType[]
                              )[0]
                      ]?.endMedia || []
                    : []
        };
        if (Object.values(state.data?.details || {}).length === 1) {
            state.sectionStatus.selectedLangTab = langToAdd;
        }
        state.sectionStatus.invalid = checkIfCompletionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfCompletionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(RemoveGameLanguage, (state, { langToRemove }) => {
        delete state.data?.details?.[langToRemove];
        state.sectionStatus.invalid = checkIfCompletionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfCompletionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
        if (state.sectionStatus.selectedLangTab === langToRemove) {
            state.sectionStatus.selectedLangTab = Object.keys(
                state.data?.details || {}
            )[0] as LocaleType;
        }
    }),
    immerOn(
        updateGameCompletionEndDescription,
        (state, { endDescription, local }) => {
            state.data!.details[local].endDescription = endDescription;
            state.sectionStatus.invalid = checkIfCompletionStateInvalid(
                state.data?.details
            );
            state.sectionStatus.complete = checkIfCompletionStateCompleted(
                state.data?.details
            );
            state.sectionStatus.disableDependants =
                !!state.sectionStatus.invalid;
        }
    ),
    immerOn(AddGameCompletionMedia, (state, { path, local, defaultLang }) => {
        if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
            (Object.keys(state.data?.details || {}) as LocaleType[]).forEach(
                (lang) => {
                    state.data!.details[lang].endMedia = [
                        ...state.data!.details[lang]?.endMedia,
                        {
                            path,
                            index:
                                state.data!.details[lang]?.endMedia?.length || 0
                        }
                    ];
                }
            );
        } else {
            state.data!.details[local].endMedia = [
                ...state.data!.details[local]?.endMedia,
                {
                    path,
                    index: state.data!.details[local]?.endMedia?.length || 0
                }
            ];
            state.sectionStatus!.isMediaChanged = true;
        }
        state.sectionStatus.invalid = checkIfCompletionStateInvalid(
            state.data?.details
        );
        state.sectionStatus.complete = checkIfCompletionStateCompleted(
            state.data?.details
        );
        state.sectionStatus.disableDependants = !!state.sectionStatus.invalid;
    }),
    immerOn(
        OrderGameCompletionMedia,
        (state, { endMedia, local, defaultLang }) => {
            if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
                (
                    Object.keys(state.data?.details || {}) as LocaleType[]
                ).forEach((lang) => {
                    state.data!.details[lang].endMedia =
                        orderGameMediaItems(endMedia);
                });
            } else {
                state.data!.details[local].endMedia =
                    orderGameMediaItems(endMedia);
                state.sectionStatus!.isMediaChanged = true;
            }
        }
    ),

    immerOn(
        DeleteGameCompletionMedia,
        (state, { index, local, defaultLang }) => {
            if (local === defaultLang && !state.sectionStatus?.isMediaChanged) {
                (
                    Object.keys(state.data?.details || {}) as LocaleType[]
                ).forEach((lang) => {
                    const filteredMedia = state.data?.details?.[
                        lang
                    ]?.endMedia.filter((endMedia, i) => i !== index);
                    state.data!.details[lang].endMedia = orderGameMediaItems(
                        filteredMedia!
                    );
                });
            } else {
                const filteredMedia = state.data?.details?.[
                    local
                ]?.endMedia.filter((endMedia, i) => i !== index);
                state.data!.details[local].endMedia = orderGameMediaItems(
                    filteredMedia!
                );
                state.sectionStatus!.isMediaChanged = true;
            }
            state.sectionStatus.invalid = checkIfCompletionStateInvalid(
                state.data?.details
            );
            state.sectionStatus.complete = checkIfCompletionStateCompleted(
                state.data?.details
            );
            state.sectionStatus.disableDependants =
                !!state.sectionStatus.invalid;
        }
    ),
    immerOn(setGameCompletionSelectedLangTab, (state, { selectedLangTab }) => {
        state.sectionStatus.selectedLangTab = selectedLangTab;
    })
);

function checkIfCompletionStateInvalid(
    state: GameCompletionDetails['details'] | undefined
) {
    return (
        !state ||
        !Object.keys(state).length ||
        !(Object.keys(state) as LocaleType[]).every(
            (lang) =>
                state?.[lang]?.endMedia?.length && state?.[lang]?.endDescription
        )
    );
}

function checkIfCompletionStateCompleted(
    state: GameCompletionDetails['details'] | undefined
) {
    return (
        !!(Object.keys(state || {}) as LocaleType[])?.length &&
        (Object.keys(state || {}) as LocaleType[]).every(
            (lang) =>
                state?.[lang]?.endDescription && state?.[lang]?.endMedia?.length
        )
    );
}
