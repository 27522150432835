export interface SiteHours {
    settings: SiteHoursSettings;
    exceptions?: SiteHoursException[];
    basics?: SiteHoursDay[];
    advanced?: SiteHoursAdvance[];
}

export class SiteHoursSettings {
    hasOpeningHours: boolean;
    alwaysOpen: boolean;
    advanced: boolean;
    hasExceptions: boolean;

    constructor() {
        this.hasOpeningHours = false;
        this.alwaysOpen = false;
        this.advanced = false;
        this.hasExceptions = false;
    }
}

export class SiteHoursException {
    startDate: string; // DD/MM/YYYY
    endDate: string; // DD/MM/YYYY
    isClose: boolean;
    openingHours?: SiteHoursTimeRange[];

    constructor() {
        this.startDate = '';
        this.endDate = '';
        this.isClose = true;
    }
}

export class SiteHoursAdvance {
    startDate: string; // DD/MM,
    endDate: string; // DD/MM,
    days: SiteHoursDay[];

    constructor() {
        this.startDate = '';
        this.endDate = '';
        this.days = [];
    }
}

export type SiteDayHoursMapped = Record<string, SiteHoursDay>;
export type SiteAdvancedHoursMapped = {
    days: SiteDayHoursMapped;
    startDate: string;
    endDate: string;
};

export class SiteHoursDay {
    dayInWeek: number; // 1-7,
    openingHours?: SiteHoursTimeRange[];

    constructor(day: number) {
        this.dayInWeek = Number(day);
        this.openingHours = [];
    }
}

export interface BasicRange {
    dayInWeek?: number; // 1-7,
    openingHours?: SiteHoursTimeRange[];
}

export interface SiteHoursTimeRange {
    openTime: string | undefined; // HH:mm,
    closeTime: string | undefined; // HH:mm,
}

export enum HoursOptions {
    N_A = 'N/A',
    ALWAYS_OPEN = 'ALWAYS_OPEN',
    SELECT_HOURS = 'SELECT_HOURS'
}
