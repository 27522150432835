import { LatLon } from '../../mapbox/typings/mapbox-utils';
import { Polygon } from 'geojson';

export interface SiteMap {
    anchorPoint?: SiteAnchorPoint;
    polygon?: SitePolygon;
    mapStyle?: SiteMapStyleTypes;
    mapStage?: SiteMapStages;
}

export enum SiteMapStyleTypes {
    TERRAIN = 'TERRAIN',
    CLEAR = 'CLEAR',
    SATELLITE = 'SATELLITE',
    DEFAULT = 'DEFAULT'
}

export enum SiteMapStages {
    'POLYGON',
    'ANCHOR_POINT',
    'MAP_OPTIONS',
    'PREVIEW'
}

export interface SiteAnchorPoint {
    coordinate?: LatLon;
    navigatable?: boolean;
}

export interface SitePolygon {
    data?: Polygon;
    showPolygon?: boolean;
}
