import { Component, OnInit } from '@angular/core';
import { User } from '../../../auth/auth.model';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { ActivatedRoute, Router, UrlSegmentGroup } from '@angular/router';
import { map } from 'rxjs/operators';
import { TrekService } from '../../services/trek.service';
import { AppRoutePaths } from '../../../app-routing.module';
import { WizardMobile } from '../../../standalone/components/wizard/wizars-template/wizard-template.model';
import { AppStoreService } from '../../../store/app-store.service';
import { SitesRoutePaths } from '../../../sites/sites-routing.module';

@Component({
    selector: 'app-create-trek',
    templateUrl: './create-trek.component.html'
})
export class CreateTrekComponent implements OnInit {
    genieVideo = 'https://www.youtube.com/embed/ebd1ayl2ueI';
    user!: User | null;
    wizardMobileData!: WizardMobile;

    constructor(
        private toggleSpinnerService: ToggleSpinnerService,
        private router: Router,
        private appStoreService: AppStoreService,
        private trekService: TrekService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.wizardMobileData = {
            link: ['/', AppRoutePaths.TREKS],
            messageKey: 'MOBILE_TEXT_TREK',
            buttonTextKey: 'BACK_TO_WISHTRIP_TREK'
        };
    }

    createTrek(): void {
        this.toggleSpinnerService.toggle(true);
        const isInsideDialog =
            (
                (this.activatedRoute.snapshot as any)
                    ._urlSegment as UrlSegmentGroup
            ).segments[2]?.path === SitesRoutePaths.SITE_WIZARD;
        const businessId = this.appStoreService.state.auth.user?.businessId!;
        this.trekService
            .createTrek(businessId)
            .pipe(
                map((result) =>
                    this.router
                        .navigate(
                            isInsideDialog
                                ? ['..', result.id]
                                : [AppRoutePaths.TREKS, result.id],
                            {
                                relativeTo: isInsideDialog
                                    ? this.activatedRoute
                                    : undefined
                            }
                        )
                        .finally(() => this.toggleSpinnerService.toggle(false))
                )
            )
            .subscribe({
                error: () => this.toggleSpinnerService.toggle(false)
            });
    }
}
