import { LatLon } from '../../mapbox/typings/mapbox-utils';
import { StationDialogStages } from '../pages/trek-wizard-station-dialog/station-dialog.const';
import {
    WizardSection,
    WizardSectionStatus
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';

export class TrekStation {
    id?: string;
    coordinate?: LatLon;
    media?: WizardSection<TrekMedia[]>;
    audios?: WizardSection<TrekMedia[]>;
    text?: WizardSection<string>;
    pois?: WizardSection<string[]>;
    complete: boolean;
    selectedStage?: StationDialogStages;

    constructor(stationId: string, coordinate: LatLon) {
        this.id = stationId;
        this.coordinate = coordinate;
        this.media = { sectionStatus: new WizardSectionStatus(false, false) };
        this.text = { sectionStatus: new WizardSectionStatus(false, false) };
        this.audios = { sectionStatus: new WizardSectionStatus(false, false) };
        this.pois = { sectionStatus: new WizardSectionStatus(false, false) };
        this.complete = false;
    }
}

export interface TrekStationMap {
    [id: string]: TrekStation;
}

export interface TrekMedia {
    path: string;
    order?: number;
    id?: number;
}

export interface TrekInfoStation {
    id?: string;
    coordinate?: LatLon;
    media?: TrekMedia[];
    audios?: TrekMedia[];
    text?: string;
    pois?: string[];
}

export interface TrekWizardStationsSection<T> extends WizardSection<T> {
    sectionStatus: TrekWizardStationsStep;
}

export class TrekWizardStationsStep extends WizardSectionStatus {
    selectedStationId?: string;
}
