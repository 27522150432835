import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app-store.model';

export const selectAuthStateFeature = (state: AppState) => state.auth;

export const getUser = createSelector(
    selectAuthStateFeature,
    (state) => state?.user
);

export const getUserId = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.id
);

export const getUserEmail = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.email
);

export const getUserBusinessLogoUrl = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.businessLogoUrl
);

export const getUserBusinessName = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.businessName.en
);

export const getUserBusinessId = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.businessId
);

export const getUserActions = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.actions
);

export const canApproveSite = createSelector(
    selectAuthStateFeature,
    (state) => state?.user?.canApproveSite
);
