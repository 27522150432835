import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../store/app-store.model';
import { Store } from '@ngrx/store';
import { getUser } from '../../auth/store/auth-store.selectors';
import { map, tap } from 'rxjs/operators';
import { AppRoutePaths } from '../../app-routing.module';

@Injectable()
export class SitesGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.store.select(getUser).pipe(
            map((user) => !user?.siteIdForLiteWizard),
            tap((isAllowed) => {
                if (!isAllowed) {
                    this.router.navigate([`/${AppRoutePaths.LIZARD}`]);
                }
            })
        );
    }
}
