import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';
import { GameMedia } from '../../../games/models/game.interface';

export enum GameCompletionActionTypes {
    ADD_MEDIA = '[GameWizard] Add Game Completion Media',
    ORDER_MEDIA = '[GameWizard] Order Game Completion Media',
    DELETE_MEDIA = '[GameWizard] Delete Game Completion Media',
    UPDATE_COMPLETION_END_DESCRIPTION = '[GameWizard] Update Completion End Description',
    SET_SELECTED_LANG_TAB = '[GameWizard] Completion Set Selected Lang Tab'
}

export const AddGameCompletionMedia = createAction(
    GameCompletionActionTypes.ADD_MEDIA,
    props<{
        path: string;
        local: LocaleType;
        defaultLang: LocaleType;
    }>()
);

export const OrderGameCompletionMedia = createAction(
    GameCompletionActionTypes.ORDER_MEDIA,
    props<{
        endMedia: GameMedia[];
        local: LocaleType;
        defaultLang: LocaleType;
    }>()
);

export const DeleteGameCompletionMedia = createAction(
    GameCompletionActionTypes.DELETE_MEDIA,
    props<{ index: number; local: LocaleType; defaultLang: LocaleType }>()
);

export const updateGameCompletionEndDescription = createAction(
    GameCompletionActionTypes.UPDATE_COMPLETION_END_DESCRIPTION,
    props<{ endDescription: string; local: LocaleType }>()
);

export const setGameCompletionSelectedLangTab = createAction(
    GameCompletionActionTypes.SET_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
