import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SiteWizardStateResponse } from '../../site-wizard/store/site-wizard-store.model';
import { map } from 'rxjs/operators';
import {
    ImplementationConfiguration,
    PrivateToken,
    SitePrivateLink,
    SiteVideo,
    SiteVideoUrl,
    SmsSettings
} from '../models/site.model';
import {
    Announcement,
    AnnouncementAlert,
    AnnouncementTime
} from '../models/announcement.model';
import { TrekInfo } from '../../trek-wizard/store/trek-wizard.model';
import { SetSiteSetting, SiteSettings } from '../models/site-settings.model';
import {
    CreateSiteResponse,
    SiteInfo
} from '../../site-wizard/site-wizard.model';
import { ShardLink } from '../models/my-qr.model';

@Injectable()
export class SiteService {
    private baseSiteUrlV1 = `${environment.BUSINESS_URL}/api/v1/sites`;
    private baseSiteUrlV2 = `${environment.BUSINESS_URL}/api/v2/site`;
    private baseBusinessUrlV1 = `${environment.BUSINESS_URL}/api/v1/business`;
    private baseBusinessUrlOld = `${environment.BUSINESS_URL}/api/site`;
    private baseTrekUrlV1 = `${environment.BUSINESS_URL}/api/v1/treks`;

    constructor(private http: HttpClient) {}

    getState(siteId: string | string[]): Observable<SiteWizardStateResponse[]> {
        const config = {
            params: {
                siteId
            }
        };
        return this.http.get<SiteWizardStateResponse[]>(
            `${this.baseSiteUrlV1}/state`,
            config
        );
    }

    getSiteInfo(siteId: string | string[]): Observable<SiteInfo> {
        return this.http.get<SiteInfo>(`${this.baseSiteUrlV1}/${siteId}/info`);
    }

    getSiteWizardMode(siteId: string): Observable<any> {
        return this.http.get(`${this.baseSiteUrlV1}/${siteId}/mode`);
    }

    getSiteSettings(siteId: string): Observable<SiteSettings> {
        return this.http.get<SiteSettings>(
            `${this.baseSiteUrlV2}/${siteId}/settings`
        );
    }

    createSite(businessId: string): Observable<CreateSiteResponse> {
        return this.http.post<CreateSiteResponse>(
            `${this.baseBusinessUrlV1}/${businessId}/site`,
            {}
        );
    }

    getPrivateLinks(
        businessId: string,
        siteId: string,
        userId: string
    ): Observable<SitePrivateLink[]> {
        const config = {
            params: {
                business_id: businessId,
                site_id: siteId,
                user_id: userId
            }
        };

        return this.http
            .get(`${this.baseBusinessUrlOld}/private-tokens`, config)
            .pipe(
                map((result: PrivateToken[]) => result) as any,
                map((tokens: PrivateToken[]) =>
                    tokens.map((token) => new SitePrivateLink(token))
                )
            );
    }

    generatePrivateLink(
        businessId: string,
        siteId: string,
        userId: string,
        tokenName: string = `token-${new Date()}`
    ): Observable<SitePrivateLink> {
        const data = {
            business_id: businessId,
            site_id: siteId,
            user_id: userId,
            token_name: tokenName
        };
        console.log('generatePrivateLink2', data);
        return this.http
            .post(`${this.baseBusinessUrlOld}/private-token`, data)
            .pipe(
                map((result: PrivateToken) => result) as any,
                map((token: PrivateToken) => new SitePrivateLink(token))
            );
    }

    getRatingsForSite(
        siteId: string,
        from?: string,
        to?: string
    ): Observable<any> {
        const config: any = {
            params: {
                site_id: siteId
            }
        };
        if (from && to) {
            config.params.from = from;
            config.params.to = to;
        }
        return this.http.get(`${this.baseBusinessUrlOld}/rate`, config);
    }

    createPlanSite(siteId: string, plan: string): Observable<any> {
        const params = {
            tier: plan
        };
        return this.http.put(`${this.baseSiteUrlV1}/${siteId}/plan`, params);
    }

    deletePrivateLink(
        businessId: string,
        siteId: string,
        userId: string,
        token: PrivateToken
    ): Observable<PrivateToken> {
        const config = {
            params: {
                site_id: siteId,
                business_id: businessId,
                user_id: userId,
                token_id: token.token_id
            }
        };
        return this.http
            .delete<PrivateToken>(
                `${this.baseBusinessUrlOld}/private-token`,
                config
            )
            .pipe(map((result: PrivateToken) => result));
    }

    startMovieGenerating(
        siteId: string,
        businessId: string,
        showGameFrame: boolean
    ): Observable<SiteVideo> {
        const data = {
            siteId,
            showGameFrame,
            businessId
        };
        return this.http.post<SiteVideo>(
            `${this.baseSiteUrlV1}/${siteId}/createMovie`,
            data
        );
    }

    getMovieUrl(siteId: string, movieURL: string): Observable<any> {
        const config: any = {
            params: {
                siteId,
                movieURL
            }
        };
        return this.http.get<SiteVideoUrl>(
            `${this.baseSiteUrlV1}/${siteId}/getMovie`,
            config
        );
    }

    download(url: string): Observable<Blob> {
        return this.http.get(url, {
            headers: {
                withCredentialsRemove: 'true'
            },
            responseType: 'blob'
        });
    }

    updateSiteSettings(
        siteId: string,
        data: SetSiteSetting
    ): Observable<SiteSettings> {
        return this.http.put(`${this.baseSiteUrlV1}/${siteId}/settings`, data);
    }

    getSiteSettingsSms(siteId: string): Observable<SmsSettings> {
        return this.http.get<SmsSettings>(
            `${environment.BUSINESS_URL}/api/sms/getSettings?siteId=${siteId}`
        );
    }

    updateSiteSettingsSms(
        siteId: string,
        message: string,
        shareLinkId: string
    ): Observable<any> {
        const data = {
            message,
            siteId,
            shareLinkId
        };

        return this.http.put(
            `${environment.BUSINESS_URL}/api/sms/updateSettings`,
            data
        );
    }

    sendSms(
        business_id: string,
        site_id: string,
        numbers: string[]
    ): Observable<any> {
        const data = {
            business_id,
            site_id,
            to: numbers,
            unix_time: new Date().getTime() / 1000 //send current time as
            // seconds
        };

        return this.http.post(`${environment.BUSINESS_URL}/api/sms/send`, data);
    }

    getSiteScheduledAlerts(siteId: string): Observable<Announcement> {
        return this.http
            .get(
                `${environment.BUSINESS_URL}/api/sites/${siteId}/scheduled-alerts`
            )
            .pipe(map((result: any) => result));
    }

    postSiteScheduledAlerts(
        siteId: string,
        soiId: string,
        name: string,
        message: string,
        days: AnnouncementTime[] | undefined,
        everyDay: AnnouncementAlert['everyDay'] | undefined
    ): Observable<Announcement> {
        const data: AnnouncementAlert = {
            soiId,
            name,
            message,
            days,
            everyDay
        };
        if (!data.days) {
            delete data.days;
        }
        if (!data.everyDay?.time) {
            delete data.everyDay;
        }

        return this.http.post<Announcement>(
            `${environment.BUSINESS_URL}/api/sites/${siteId}/scheduled-alerts`,
            data
        );
    }

    updateSiteScheduledAlerts(
        siteId: string,
        alertId: string,
        soiId: string,
        name: string,
        message: string,
        days: AnnouncementTime[] | undefined,
        everyDay: AnnouncementAlert['everyDay'] | undefined
    ): Observable<Announcement> {
        const data: AnnouncementAlert = {
            soiId,
            name,
            message,
            days,
            everyDay
        };
        if (!data.days) {
            delete data.days;
        }
        if (!data.everyDay?.time) {
            delete data.everyDay;
        }

        return this.http.put<Announcement>(
            `${environment.BUSINESS_URL}/api/sites/${siteId}/scheduled-alerts/${alertId}`,
            data
        );
    }

    deleteAlerts(
        siteId: string,
        alertId: AnnouncementAlert['id']
    ): Observable<AnnouncementAlert> {
        return this.http
            .delete<AnnouncementAlert>(
                `${environment.BUSINESS_URL}/api/sites/${siteId}/scheduled-alerts/${alertId}`
            )
            .pipe(map((result: AnnouncementAlert) => result));
    }

    getShareLinksForSite(siteId: string): Observable<ShardLink[]> {
        return this.http.get<ShardLink[]>(
            `${environment.BUSINESS_URL}/api/v2/sites/${siteId}/share-link`
        );
    }

    createShareLinkForSite(
        siteId: string,
        name: string
    ): Observable<ShardLink> {
        return this.http.post<ShardLink>(
            `${environment.BUSINESS_URL}/api/sites/${siteId}/share-link`,
            { name }
        );
    }

    deleteShareLinkForSite(siteId: string, shareLinkId: string) {
        return this.http.delete(
            `${environment.BUSINESS_URL}/api/sites/${siteId}/share-link/${shareLinkId}`
        );
    }

    getShareLinkForSpot(
        siteId: string,
        spotId: string
    ): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${environment.BUSINESS_URL}/api/sites/${siteId}/sois/${spotId}/share-link`
        );
    }

    getShareLinkForTrek(trekId: string): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${environment.BUSINESS_URL}/api/treks/${trekId}/share-link`
        );
    }

    getImplementationConfiguration(): Observable<ImplementationConfiguration> {
        const config = {
            headers: {
                withCredentialsRemove: 'true'
            },
            params: {}
        };
        return this.http.get<ImplementationConfiguration>(
            `${environment.IMPLEMENTATION_S3_BASE_URL}configuration/implementation_packs.json`,
            config
        );
    }

    getTrekName(trekId: string | string[]): Observable<TrekInfo[]> {
        const config = {
            params: {
                fields: 'id,details'
            }
        };
        return this.http.get<TrekInfo[]>(
            `${this.baseTrekUrlV1}/info?trekIds=${trekId}`,
            config
        );
    }
}
