import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RatingResponse } from '../../models/site.model';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { SITE_RATE_RANGE } from '../../sites.constants';
import { KeyValue } from '@angular/common';
import { AppState } from '../../../store/app-store.model';
import { Store } from '@ngrx/store';
import { getSiteDateFormat } from '../../store/selections/site-settings.store';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit {
    SITE_RATE_RANGE = SITE_RATE_RANGE;
    selectedRange = SITE_RATE_RANGE.ALL_TIMES;
    showSpinner$ = new BehaviorSubject(true);
    siteId!: string;
    rating$!: Observable<RatingResponse | null>;
    today = new Date();
    dateFormat$ = this.store.select(getSiteDateFormat);

    constructor(
        private siteService: SiteService,
        private route: ActivatedRoute,
        private store: Store<AppState>
    ) {}

    ngOnInit(): void {
        this.siteId = this.route.parent?.snapshot.data.siteInfo.id;
        this.initRating();
    }

    initRating(startDate?: string, endDate?: string): void {
        this.showSpinner$.next(true);
        this.rating$ = this.siteService
            .getRatingsForSite(this.siteId, startDate, endDate)
            .pipe(
                catchError((error) => {
                    console.log('error', error);
                    return of(null);
                }),
                finalize(() => {
                    this.showSpinner$.next(false);
                })
            );
    }

    selectRange(range: string): void {
        this.selectedRange = range;
        let startDate: Date;
        const today = new Date();
        switch (range) {
            case SITE_RATE_RANGE.SPECIFIC_DATE:
                return;
            case SITE_RATE_RANGE.ALL_TIMES:
                return this.initRating();
            case SITE_RATE_RANGE.LAST_7_DAYS:
                startDate = new Date(new Date().setDate(today.getDate() - 7));
                break;
            case SITE_RATE_RANGE.LAST_30_DAYS:
                startDate = new Date(new Date().setDate(today.getDate() - 30));
                break;
            case SITE_RATE_RANGE.LAST_YEAR:
                startDate = new Date(
                    this.today.getFullYear() - 1,
                    this.today.getMonth(),
                    this.today.getDate()
                );
                break;
        }
        this.initRating(
            startDate!.toISOString().split('T')[0],
            this.today.toISOString().split('T')[0]
        );
    }

    changeDate(event: { startDate: Date; endDate: Date }): void {
        const startDate = event.startDate.toISOString().split('T')[0];
        const endDate = event.endDate.toISOString().split('T')[0];

        if (startDate && endDate) {
            this.initRating(startDate, endDate);
        }
    }

    originalOrder = (
        a: KeyValue<string, string>,
        b: KeyValue<string, string>
    ): number => 0;
}
