import { LocaleType } from '../shared/locales.models';
import {
    WizardSection,
    WizardSectionStatus
} from '../standalone/components/wizard/wizars-template/wizard-template.model';

export interface GameWizardSection<SectionDataType>
    extends WizardSection<SectionDataType> {
    sectionStatus: GameWizardSectionStatus;
}

export class GameWizardSectionStatus extends WizardSectionStatus {
    isMediaChanged?: boolean;
    selectedLangTab?: LocaleType;
}
