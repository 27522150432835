import {
    Challenge,
    ChallengeDisplayTypes,
    ChallengeTextTypes,
    ChallengeTypes
} from '../../games/models/challenge.interface';
import { GameWizardSectionStatus } from '../game-wizard.model';
import { LocalesKeysObject, LocaleType } from '../../shared/locales.models';
import { ChallengesDialogStages } from '../pages/game-challenge-dialog/game-challenges-dialog.const';
import { WizardSectionStatus } from '../../standalone/components/wizard/wizars-template/wizard-template.model';

export type GameChallengesSection = {
    data?: GameChallengesSectionData;
    sectionStatus: GameWizardChallengesStep;
};

export type GameChallengesSectionData = LocalesKeysObject<{
    [spotId: string]: Challenge;
}>;

export type ChallengesDialogSteps = Record<string, ChallengeDialogSteps>;

export type ChallengeDialogSteps = Record<
    ChallengesDialogStages,
    { sectionStatus: GameWizardSectionStatus }
> & { selectedChallengeStage?: ChallengesDialogStages };

export class GameWizardChallengesStep extends GameWizardSectionStatus {
    selectedChallengeSpotId?: string;
    isChallengeTextWithCorrectAnswer?: boolean;
    dialogSteps: ChallengesDialogSteps = {};
}

export const getChallengeDialogDefaultState = (
    langsChallenge: Challenge[]
): ChallengeDialogSteps => ({
    [ChallengesDialogStages.SELECT_TYPE]: {
        sectionStatus: new GameWizardSectionStatus(
            !langsChallenge.every((challenge) => challenge.type),
            langsChallenge.every((challenge) => challenge.type),
            !langsChallenge.every((challenge) => challenge.type)
        )
    },
    [ChallengesDialogStages.CHALLENGE_DETAILS]: {
        sectionStatus: new GameWizardSectionStatus(
            !langsChallenge.every(
                (challenge) =>
                    challenge.title &&
                    challenge.media?.length &&
                    (!challenge.answers?.length ||
                        (challenge?.answers?.some(
                            (answer) => answer.isCorrect
                        ) &&
                            challenge.answers?.every((answer) => answer.value)))
            ),
            langsChallenge.every(
                (challenge) =>
                    challenge.title &&
                    challenge.description &&
                    challenge.media?.length &&
                    (!challenge.answers?.length ||
                        (challenge?.answers?.some(
                            (answer) => answer.isCorrect
                        ) &&
                            challenge.answers?.every((answer) => answer.value)))
            )
        )
    },
    [ChallengesDialogStages.POST_CHALLENGE]: {
        sectionStatus: new WizardSectionStatus(
            !langsChallenge.every(
                (challenge) =>
                    challenge.postChallengeDescription &&
                    challenge.postChallengeMedia?.length
            ),
            langsChallenge.every(
                (challenge) =>
                    challenge.postChallengeDescription &&
                    challenge.postChallengeMedia?.length
            )
        )
    },
    [ChallengesDialogStages.SETTINGS]: {
        sectionStatus: new GameWizardSectionStatus(
            false,
            langsChallenge.every(
                (challenge) => challenge.radius && challenge.navigationMode
            )
        )
    }
});

export type CurrentChallengeData = {
    challenge: Challenge | undefined;
    local: LocaleType;
}[];

export const GetChallengeTypeTitle = () =>
    new Map<ChallengeDisplayTypes, string>([
        [ChallengeTypes.MULTIPLECHOICE, 'MULTIPLE_CHOICE_CHALLENGE_TITLE'],
        [ChallengeTypes.VIDEO, 'VIDEO_CHALLENGE_TITLE'],
        [ChallengeTypes.PHOTO, 'PHOTO_CHALLENGE_TITLE'],
        [ChallengeTypes.AUDIO, 'AUDIO_CHALLENGE_TITLE'],
        [ChallengeTypes.LOCATION, 'GO_TO_LOCATION_CHALLENGE_TITLE'],
        [ChallengeTextTypes.TEXT_CORRECT, 'FREE_TEXT_CHALLENGE_TITLE'],
        [ChallengeTextTypes.TEXT_NO_CORRECT, 'FREE_TEXT_CHALLENGE_TITLE']
    ]);
