import { ActionReducer, combineReducers } from '@ngrx/store';
import { TrekWizardState } from './trek-wizard.model';
import { TrekWizardActionTypes } from './trek-wizard.actions';
import { trekStagesReducer } from './reducers/trek-wizard-stages.reducers';
import { trekMapReducer } from './reducers/map.reducers';
import { trekDetailsReducer } from './reducers/details.reducers';
import { trekTagsReducer } from './reducers/tags.reducers';
import { trekStationsReducer } from './reducers/stations.reducers';
import { StationsActionTypes } from './actions/stations.actions';
import { trekCoverMediaReducer } from './reducers/cover-image.reducers';
import { AppActionTypes } from '../../store/app-store.actions';
import { getUndoableWizardStore } from '../../store/undoable/undoable-store.reducer';

export const trekWizardFeatureKey = 'trekWizard';

export const trekWizardMainReducer = combineReducers<TrekWizardState>({
    businessId: (state) => state,
    id: (state) => state,
    mode: (state) => state,
    activeStage: trekStagesReducer,
    map: trekMapReducer,
    details: trekDetailsReducer,
    tags: trekTagsReducer,
    stations: trekStationsReducer,
    coverMedia: trekCoverMediaReducer
});
const _trekWizardRootReducer: ActionReducer<TrekWizardState> = (
    state: TrekWizardState | undefined,
    action: any // WizardActions | AppActions
) => {
    switch (action.type) {
        case AppActionTypes.RESET_APP_STATE:
        case TrekWizardActionTypes.RESET_WIZARD_STATE:
            return undefined as unknown as TrekWizardState;

        case TrekWizardActionTypes.SET_WIZARD_STATE:
            return trekWizardMainReducer(action.state, action);
        default:
            return trekWizardMainReducer(state, action);
    }
};

const undoableIgnoredActions = [
    AppActionTypes.RESET_APP_STATE,
    TrekWizardActionTypes.SET_WIZARD_STATE,
    TrekWizardActionTypes.RESET_WIZARD_STATE,
    StationsActionTypes.STATIONS_UPDATE_LOCATION
];

const resetHistoryActions = [
    AppActionTypes.RESET_APP_STATE,
    TrekWizardActionTypes.RESET_WIZARD_STATE
];

export const TrekWizardReducer = getUndoableWizardStore<TrekWizardState>(
    _trekWizardRootReducer,
    TrekWizardActionTypes,
    undoableIgnoredActions,
    resetHistoryActions
)();
