import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import {
    MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { BehaviorSubject, Observable } from 'rxjs';
import { OperativeTypographyTypes } from '../../directives/typography/typography.model';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-custom-input-standalone',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        TypographyDirective,
        MatAutocompleteModule,
        FormsModule
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomInputStandaloneComponent),
            multi: true
        },
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: {
                hideSingleSelectionIndicator: true,
                overlayPanelClass: 'wt-select-options'
            }
        }
    ],
    templateUrl: './custom-input-standalone.component.html',
    styleUrls: ['./custom-input-standalone.component.scss']
})
export class CustomInputStandaloneComponent<T = string>
    implements ControlValueAccessor, OnInit
{
    @HostBinding('class')
    @Input()
    size: 'default' | 'large' | 'small' = 'default';
    @Input() label!: string;
    @Input() id!: string;
    @Input() type!: 'url' | 'text' | 'email' | 'tel' | 'password' | 'number';
    @Input() min = 1;
    @Input() hint = '';
    @Input() hintError = false;
    @Input() name = '';
    @Input() maxlength!: string | number | null;
    @Input() autoCompleteOptions: any[] = [];
    @Input() pattern: string | RegExp = '';
    @Input() placeholder = '';
    @Input() value: T = '' as T;
    @Input() autoCompleteDisplayWithFn: ((value: T) => string) | null = null;
    @Output() valueChange = new EventEmitter<T>();
    @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> =
        new EventEmitter<MatAutocompleteSelectedEvent>();
    @Output() keydownEnter = new EventEmitter<T>();
    @ViewChild('inputElement') inputElement!: ElementRef;
    nativeAutocomplete = 'auto';
    isInputFocus$ = new BehaviorSubject(false);

    get _value() {
        return this.value;
    }

    set _value(val: T) {
        this.value = val;
        this.onChange(this.value);
        this.valueChange.emit(this.value);
    }

    ngOnInit() {
        if (this.autoCompleteOptions.length) {
            this.nativeAutocomplete = 'off';
        }
    }

    labelSize$: Observable<keyof typeof OperativeTypographyTypes> =
        this.isInputFocus$.pipe(
            map((isFocus) => {
                if (
                    !this._value &&
                    this._value !== 0 &&
                    !isFocus &&
                    this.size === 'large'
                ) {
                    return 'SUBTITLE';
                } else if (
                    (this._value || this._value === 0) &&
                    isFocus &&
                    this.size === 'large'
                ) {
                    return 'SMALL';
                } else if (this.size === 'default') {
                    return 'SMALL';
                } else if (this.size === 'small') {
                    return 'TINY';
                } else {
                    return 'SMALL';
                }
            })
        );

    onChange = (_: T) => {};
    onTouched = () => {};

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    writeValue(value: T): void {
        if (value || value === 0) {
            this.isInputFocus$.next(true);
        } else if (this.inputElement) {
            this.inputElement.nativeElement.value = '';
        }
        this.value = value;
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        this.optionSelected.emit(event);
        setTimeout(() => this.inputElement.nativeElement.blur());
    }
}
