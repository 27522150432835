<div class="base-card-text">
    <div
        class="base-card-name"
        appTypography="PRIMARY"
        color="WHITE">
        {{ name }}
    </div>
    <div
        *ngIf="subName"
        class="base-card-sub-name"
        appTypography="SMALL"
        color="WHITE">
        {{ subName }}
    </div>
</div>
<div class="card-labels">
    <ng-content></ng-content>
</div>
