import {
    SiteWizardStages,
    SiteWizardState,
    SiteWizardStepWithLang
} from '../store/site-wizard-store.model';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { SearchTrekService } from './search-trek.service';
import { map } from 'rxjs/operators';
import { LocaleType } from '../../shared/locales.models';
import { LatLon } from '../../mapbox/typings/mapbox-utils';
import { NavigationModeTypes } from '../../standalone/components/navigation-settings/navigation-settings.model';
import {
    GreetingMessageByLang,
    MessageAppearance,
    SiteGreeting
} from '../models/site-wizard-greeting.model';
import { SiteMapStages } from '../models/site-wizard-map-setup.model';
import {
    SiteSpot,
    SiteSpotPublished,
    SiteWizardSpotsStep,
    SpotsMappedByLocation
} from '../models/site-wizard-spots.model';
import { SiteRoute, SiteRouteMap } from '../models/site-wizard-routes.model';
import {
    OoiType,
    SiteInfo,
    TrekHeaderResultNew,
    TrekHeadersResultNew
} from '../site-wizard.model';
import {
    WizardSection,
    WizardSectionStatus
} from '../../standalone/components/wizard/wizars-template/wizard-template.model';

const cleanDeep = require('clean-deep');

@Injectable()
export class StateParserService {
    constructor(private trekService: SearchTrekService) {}

    restoreState(parsedState: SiteInfo): Observable<SiteWizardState> {
        const restoredStateObject = this.restoreStateObject(parsedState);
        return forkJoin([
            restoredStateObject.siteRoutes?.data
                ? this.trekService.getTreksHeader(
                      Object.keys(restoredStateObject.siteRoutes.data)
                  )
                : of([]),
            restoredStateObject.siteRoutes?.data
                ? this.trekService.getTrekCoordinates(
                      Object.keys(restoredStateObject.siteRoutes.data)
                  )
                : of([]),
            restoredStateObject.promotedTreks?.data?.ids
                ? this.trekService.getTreksHeader(
                      restoredStateObject.promotedTreks.data.ids
                  )
                : of([]),
            of(restoredStateObject)
        ]).pipe(
            map(([trekHeaders, trekCoords, promotedHeaders, restoredState]) =>
                this.mapHeadersToState(
                    trekHeaders,
                    trekCoords,
                    promotedHeaders,
                    restoredState
                )
            )
        );
    }

    restoreStateObject(siteInfo: SiteInfo): SiteWizardState {
        return {
            activeStage: SiteWizardStages.LANGUAGES,
            id: siteInfo.id,
            businessId: siteInfo.businessId,
            mode: siteInfo.wizardMode,
            languages: {
                data: siteInfo.languages,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            media: {
                data: siteInfo.media,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            general: {
                data: siteInfo.general,
                sectionStatus: new SiteWizardStepWithLang(false, true)
            },
            contact: {
                data: siteInfo.contact,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            hours: {
                data: siteInfo.hours,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            tags: {
                data: siteInfo.tags,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            map: {
                data: {
                    ...siteInfo.map,
                    mapStage: SiteMapStages.POLYGON
                },
                sectionStatus: new WizardSectionStatus(false, true)
            },
            siteRoutes: {
                data: siteInfo.siteRoutes
                    ? siteInfo.siteRoutes.reduce(this.restoreRoutes, {})
                    : undefined,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            spots: {
                data: siteInfo.spots?.length
                    ? siteInfo.spots.reduce(this.restoreSpots, {})
                    : undefined,
                sectionStatus: new SiteWizardSpotsStep(false, true)
            },
            categories: {
                data: siteInfo.categories?.map((cat) => ({
                    ...cat,
                    items: cat.items?.map((item) => {
                        const itemId =
                            item.type === OoiType.SOI
                                ? getSpotTempId(
                                      siteInfo.spots!.find(
                                          (soi) => soi.id === item.id.toString()
                                      )!.coordinate
                                  )
                                : item.id;
                        return {
                            ...item,
                            tempId: itemId
                        };
                    })
                })),
                sectionStatus: new WizardSectionStatus(false, true)
            },
            greeting: {
                data: this.restoreGreetings(
                    siteInfo.greeting!,
                    siteInfo.languages!.data
                ),
                sectionStatus: new SiteWizardStepWithLang(false, true)
            },
            promotedTreks: {
                data: siteInfo.promotedTreks,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            showLogoInMovie: {
                data: siteInfo.showLogoInMovie,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            private: {
                data: siteInfo.private,
                sectionStatus: new WizardSectionStatus(false, true)
            },
            countryCode: siteInfo.countryCode
        };
    }

    restoreRoutes(
        result: SiteRouteMap,
        publishedRoute: SiteRoute
    ): SiteRouteMap {
        result[publishedRoute.trekId] = {
            ...publishedRoute,
            navigationMode:
                publishedRoute.navigationMode || NavigationModeTypes.DRIVE
        };
        return result;
    }

    restoreSpots(
        result: SpotsMappedByLocation,
        publishedSpot: SiteSpotPublished
    ): SpotsMappedByLocation {
        result[getSpotTempId(publishedSpot.coordinate)] = {
            id: publishedSpot.id,
            coordinate: publishedSpot.coordinate,
            media: {
                sectionStatus: new WizardSectionStatus(false, true),
                data: publishedSpot.media
            },
            general: {
                sectionStatus: new SiteWizardStepWithLang(false, true),
                data: publishedSpot.general
            },
            contact: {
                sectionStatus: new WizardSectionStatus(false, true),
                data: publishedSpot.contact
            },
            hours: {
                sectionStatus: new WizardSectionStatus(false, true),
                data: publishedSpot.hours
            },
            tags: {
                sectionStatus: new WizardSectionStatus(false, true),
                data: publishedSpot.tags
            },
            settings: {
                sectionStatus: new WizardSectionStatus(false, true),
                data: {
                    ...publishedSpot.settings,
                    navigationMode:
                        publishedSpot.settings?.navigationMode ||
                        NavigationModeTypes.DRIVE,
                    radius: publishedSpot.settings?.radius
                }
            },
            complete: true
        };
        return result;
    }

    restoreGreetings(
        siteInfoGreeting: SiteGreeting,
        siteInfoLanguages: LocaleType[]
    ): SiteGreeting {
        return {
            messageAppearance:
                siteInfoGreeting?.messageAppearance ||
                MessageAppearance.RECENT_FOREGROUND,
            entryMessageEnabled: siteInfoGreeting?.entryMessageEnabled || false,
            exitMessageEnabled: siteInfoGreeting?.exitMessageEnabled || false,
            messages: siteInfoLanguages?.reduce(
                (result: GreetingMessageByLang, lang) => {
                    result[lang] =
                        siteInfoGreeting?.messages?.[lang as LocaleType] || {};
                    return result;
                },
                {} as GreetingMessageByLang
            )
        };
    }

    parseStateObject(siteState: SiteWizardState): SiteInfo {
        const stateWithoutSectionStatus: {
            siteRoutes?: SiteRoute[];
            spots?: SiteSpot[];
            [key: string]: any;
        } = {};
        let siteInfo: SiteInfo;

        Object.keys(siteState).forEach((key) => {
            if (
                (siteState[key as keyof SiteWizardState] as WizardSection<any>)
                    ?.sectionStatus &&
                (siteState[key as keyof SiteWizardState] as WizardSection<any>)
                    ?.data !== undefined
            ) {
                stateWithoutSectionStatus[key] = (
                    siteState[
                        key as keyof SiteWizardState
                    ] as WizardSection<any>
                ).data;
            } else if (
                !(siteState[key as keyof SiteWizardState] as WizardSection<any>)
                    ?.sectionStatus
            ) {
                stateWithoutSectionStatus[key] =
                    siteState[key as keyof SiteWizardState];
            }
        });

        siteInfo = {
            ...stateWithoutSectionStatus,
            siteRoutes: stateWithoutSectionStatus.siteRoutes
                ? Object.values(stateWithoutSectionStatus.siteRoutes).map(
                      (route: SiteRoute) => ({
                          trekId: route.trekId,
                          navigationMode: route.navigationMode,
                          thickness: route.thickness,
                          color: route.color,
                          showPath: route.showPath
                      })
                  )
                : undefined,
            spots: stateWithoutSectionStatus.spots
                ? Object.values(stateWithoutSectionStatus.spots).map((spot) => {
                      const newSpot = {} as SiteSpotPublished;
                      Object.keys(spot).forEach((key) => {
                          if (
                              (
                                  spot[
                                      key as keyof SiteSpot
                                  ] as WizardSection<any>
                              )?.sectionStatus &&
                              (
                                  spot[
                                      key as keyof SiteSpot
                                  ] as WizardSection<any>
                              )?.data !== undefined
                          ) {
                              newSpot[key as keyof SiteSpotPublished] = (
                                  spot[
                                      key as keyof SiteSpot
                                  ] as WizardSection<any>
                              ).data;
                          } else if (
                              !(
                                  siteState[
                                      key as keyof SiteWizardState
                                  ] as WizardSection<any>
                              )?.sectionStatus
                          ) {
                              (newSpot as any)[key] =
                                  spot[key as keyof SiteSpot];
                          }
                      });
                      return newSpot;
                  })
                : undefined
        };

        console.log('parsed state before clean', siteInfo);
        siteInfo = cleanDeep(siteInfo);
        console.log('parsed state after clean', siteInfo);
        return siteInfo as SiteInfo;
    }

    private mapHeadersToState(
        trekHeaders: TrekHeadersResultNew | any,
        trekCoords: any,
        promotedHeaders: TrekHeadersResultNew | any,
        wizardState: SiteWizardState
    ): SiteWizardState {
        return {
            ...wizardState,
            siteRoutes: {
                ...wizardState.siteRoutes,
                data: !wizardState.siteRoutes!.data
                    ? undefined
                    : Object.entries(wizardState.siteRoutes!.data)?.reduce(
                          (acc: { [key: string]: any }, [id, route]) => {
                              const trekInfo = trekHeaders.data.find(
                                  (trek: TrekHeaderResultNew) =>
                                      trek.data.trek_id.toString() ===
                                      route.trekId.toString()
                              );
                              if (!trekInfo) {
                                  return acc;
                              }
                              const trekCoordsData = trekCoords.data.find(
                                  (trek: any) =>
                                      trek.trekId.toString() ===
                                      route.trekId.toString()
                              );
                              if (!trekCoords) {
                                  return acc;
                              }
                              acc[id] = {
                                  ...route,
                                  cardInfo: {
                                      id: route.trekId.toString(),
                                      name: trekInfo.data.trek_name,
                                      url: trekInfo.data.master_media.path
                                  },
                                  geoJson: trekCoordsData.data
                              };
                              return acc;
                          },
                          {}
                      )
            },
            promotedTreks: {
                ...wizardState.promotedTreks,
                data: {
                    ...wizardState.promotedTreks?.data,
                    treksData: !wizardState.promotedTreks?.data?.ids
                        ? undefined
                        : wizardState.promotedTreks?.data?.ids?.reduce(
                              (acc: { [key: string]: any }, trekId) => {
                                  const trekInfo = promotedHeaders.data.find(
                                      (t: TrekHeaderResultNew) =>
                                          t.data.trek_id.toString() === trekId
                                  );
                                  if (!trekInfo) {
                                      return acc;
                                  }
                                  acc[trekId] = {
                                      id: trekId,
                                      name: trekInfo.data.trek_name,
                                      url: trekInfo.data.master_media.path
                                  };
                                  return acc;
                              },
                              {}
                          )
                }
            }
        };
    }
}

export function getSpotTempId(soiCoordinate: LatLon): string {
    return `${soiCoordinate.lat}-${soiCoordinate.lon}`;
}
