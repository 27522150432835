import { createReducer, on } from '@ngrx/store';
import * as TagsActions from '../actions/tags.actions';
import { initialState } from '../site-wizard-store.constants';

export const tagsReducer = createReducer(
    initialState.tags,
    on(TagsActions.AddTag, (state, { tagId }) => ({
        ...state,
        data: {
            ...state.data,
            ids: state.data?.ids ? [...state.data?.ids, tagId] : [tagId]
        }
    })),
    on(TagsActions.DeleteTag, (state, { tagId }) => ({
        ...state,
        data: {
            ...state.data,
            ids: [...state.data!.ids!].filter((tag) => tag !== tagId)
        }
    })),
    on(TagsActions.UpdateKeywords, (state, { keywordString }) => ({
        ...state,
        data: {
            ...state.data,
            searchKeywords: [...keywordString.split(',')]
        }
    })),
    on(TagsActions.TagsComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus,
            complete: true
        }
    }))
);
