import { createReducer } from '@ngrx/store';
import * as generalActions from '../actions/general-details.actions';
import * as LanguageActions from '../actions/language.actions';
import { initialState } from '../site-wizard-store.constants';
import { immerOn } from 'ngrx-immer/store';
import { orderItems } from '../site-wizard-store.utils';
import { LocaleType } from '../../../shared/locales.models';
import { SiteGeneralDetails } from '../../models/site-wizard-general-details.model';

export const generalReducer = createReducer(
    initialState.general,
    immerOn(
        generalActions.UpdateDetails,
        (draft, { language, data, invalid }) => {
            if (!language) {
                return;
            }
            draft.data![language] = {
                ...draft.data![language],
                ...data
            };
            draft.sectionStatus.invalid = invalid;
            draft.sectionStatus.complete =
                draft.sectionStatus.complete && !invalid;
        }
    ),
    immerOn(generalActions.UpdateInvalid, (draft, { invalid }) => {
        draft.sectionStatus.invalid = invalid;
        draft.sectionStatus.complete = draft.sectionStatus.complete && !invalid;
    }),
    immerOn(generalActions.AddAudio, (draft, { language, audioUrl }) => {
        draft.data![language].audioUrl = audioUrl;
    }),
    immerOn(generalActions.DeleteAudio, (draft, { language }) => {
        draft.data![language].audioUrl = '';
    }),
    immerOn(
        generalActions.UpdateTopicsDetails,
        (draft, { language, details, invalid }) => {
            draft.data![language].additionalInformation = orderItems(details);
            draft.sectionStatus.invalid = invalid;
            draft.sectionStatus.complete =
                draft.sectionStatus.complete && !invalid;
        }
    ),
    immerOn(generalActions.GeneralComplete, (draft) => {
        draft.sectionStatus.complete = true;
    }),
    immerOn(LanguageActions.addSiteLanguage, (draft, { language }) => {
        if (!draft.data) {
            draft.data = {} as Record<LocaleType, SiteGeneralDetails>;
        }
        draft.data[language] = {};
        if (Object.values(draft.data).length === 1) {
            draft.sectionStatus.selectedLangTab = language;
        }
        draft.sectionStatus.complete = false;
        draft.sectionStatus.invalid = true;
    }),
    immerOn(LanguageActions.deleteSiteLanguage, (draft, { language }) => {
        const { [language]: value, ...withoutLang }: any = draft.data;
        draft.data = { ...withoutLang };
        if (draft.sectionStatus.selectedLangTab === language) {
            draft.sectionStatus.selectedLangTab = Object.keys(
                draft.data || {}
            )[0] as LocaleType;
        }
    }),
    immerOn(
        generalActions.SetGeneralInfoSelectedLangTab,
        (draft, { selectedLangTab }) => {
            draft.sectionStatus.selectedLangTab = selectedLangTab;
        }
    )
);
