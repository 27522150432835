export enum StatusLabel {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    IS_EDIT = 'IS_EDIT',
    PENDING = 'PENDING'
}

export const statusLabelColorMap = new Map<StatusLabel, string>([
    [StatusLabel.ACTIVE, '#43a047'], // colors.scss $live
    [StatusLabel.INACTIVE, '#ef5350'], // colors.scss $draft
    [StatusLabel.IS_EDIT, '#0097a7'], // colors.scss $unpublished-changes
    [StatusLabel.PENDING, '#7986cb'] // colors.scss $pending
]);
