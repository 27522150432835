import { createReducer } from '@ngrx/store';
import * as HoursActions from '../actions/hours.actions';
import { initialState } from '../site-wizard-store.constants';

import { immerOn } from 'ngrx-immer/store';
import {
    SiteHoursAdvance,
    SiteHoursDay,
    SiteHoursException
} from '../../models/site-wizard-hours.model';

export const hoursReducer = createReducer(
    initialState.hours,
    immerOn(HoursActions.UpdateHoursSettings, (draft, { settings }) => {
        draft.data!.settings = settings;
        // draft.sectionStatus.invalid = !(!settings.hasOpeningHours ||
        // settings.alwaysOpen);
    }),
    immerOn(HoursActions.AddExceptionsDateRange, (draft, { invalid }) => {
        if (!draft?.data?.exceptions?.length) {
            draft.data!.exceptions = [];
        }
        draft.data!.exceptions.push(new SiteHoursException());
        draft.sectionStatus.invalid = false; // invalid;
    }),
    immerOn(
        HoursActions.DeleteExceptionsDateRange,
        (draft, { index, invalid }) => {
            draft.data!.exceptions = draft.data?.exceptions?.filter(
                (lang, i) => i !== index
            );
            draft.sectionStatus.invalid = false; // invalid;
        }
    ),
    immerOn(
        HoursActions.UpdateExceptionsDateRange,
        (draft, { dateRangeException, index, invalid }) => {
            draft.data!.exceptions![index] = dateRangeException;
            draft.sectionStatus.invalid = false; // invalid;
        }
    ),
    immerOn(HoursActions.UpdateBasicDateRange, (draft, action) => {
        const isExistDay = draft.data?.basics?.find(
            (basic) => basic.dayInWeek === action.day
        );
        if (!isExistDay) {
            if (!draft.data?.basics?.length) {
                draft.data!.basics = [];
            }
            draft.data!.basics.push(new SiteHoursDay(action.day));
        }
        draft.data!.basics = draft.data!.basics!.map((basic) => {
            const newDay: SiteHoursDay = {
                dayInWeek: basic.dayInWeek,
                openingHours: [
                    ...((basic.dayInWeek === action.day
                        ? action.basicRange.openingHours
                        : basic.openingHours) || [])
                ]
            };
            return newDay;
        });
        draft.sectionStatus.invalid = false; // invalid;
    }),
    immerOn(HoursActions.AddAdvancedDateRange, (draft, { invalid }) => {
        if (!draft.data?.advanced?.length) {
            draft.data!.advanced = [];
        }
        draft.data!.advanced.push(new SiteHoursAdvance());
        draft.sectionStatus.invalid = false; // invalid;
    }),
    immerOn(
        HoursActions.DeleteAdvancedDateRange,
        (draft, { index, invalid }) => {
            draft.data!.advanced = draft.data?.advanced?.filter(
                (lang, i) => i !== index
            );
            draft.sectionStatus.invalid = false; // invalid;
        }
    ),
    immerOn(
        HoursActions.UpdateAdvancedDateRange,
        (draft, { dateRangeAdvanced, index, invalid }) => {
            draft.data!.advanced![index] = dateRangeAdvanced;
            draft.sectionStatus.invalid = false; // invalid;
        }
    ),
    immerOn(HoursActions.HoursComplete, (draft) => {
        draft.sectionStatus.complete = true;
    })
);
