import { OoiType } from '../site-wizard.model';

export enum CategoryDisplayMode {
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL'
}

export enum CategoryDisplaySize {
    REGULAR = 'REGULAR',
    LARGE = 'LARGE'
}

export class SiteCategory {
    id: string;
    displayMode: CategoryDisplayMode;
    displaySize: CategoryDisplaySize;
    order: number;
    itemsRandomOrder: boolean;
    items?: SiteCategoryItem[];

    constructor(id: string, items: SiteCategoryItem[] = []) {
        this.id = id;
        // Has to be horizontal for categories other than default to display
        // properly on the app
        this.displayMode = CategoryDisplayMode.HORIZONTAL;
        this.displaySize = CategoryDisplaySize.LARGE;
        this.order = 0;
        this.itemsRandomOrder = false;
        this.items = items;
    }
}

export interface SiteCategoryItem {
    type: OoiType;
    id: string;
    order: number;
    tempId?: string;
}

export class MapCategories {
    id?: string;
    displayName?: string;
    color?: string;
}

export interface CategoriesResponse {
    categories: MapCategories[];
    version: number;
}

export class MapCategoriesMap {
    [id: string]: {
        displayName: string;
        color: string;
    };
}
