import { createAction, props } from '@ngrx/store';

export enum ShowLogoActionTypes {
    TOGGLE_SHOW_LOGO = '[SiteWizard] Update Show Logo',
    SHOW_LOGO_COMPLETE = '[SiteWizard] Show Logo Complete'
}

export const ToggleShowLogo = createAction(
    ShowLogoActionTypes.TOGGLE_SHOW_LOGO
);

export const ShowLogoComplete = createAction(
    ShowLogoActionTypes.SHOW_LOGO_COMPLETE
);
