<mat-form-field
    appearance="outline"
    class="timepicker">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        [disabled]="!!disabled"
        [format]="timeFormat"
        [max]="max!"
        [min]="min!"
        [ngxTimepicker]="timepicker"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        matInput
        readonly />
    <ngx-material-timepicker
        #timepicker
        [appendToInput]="!isMobile"
        [enableKeyboardInput]="true"
        [theme]="ACCENT_TIMEPICKER_THEME"></ngx-material-timepicker>
</mat-form-field>
