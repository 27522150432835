import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, of } from 'rxjs';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ShardLink, SpotsAndTreksQrData } from '../../models/my-qr.model';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {
    WarningActions,
    WarningModalData
} from '../../../standalone/components/warning-dialog/warning-dialog.const';
import { WarningDialogComponent } from '../../../standalone/components/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SitesRoutePaths } from '../../sites-routing.module';

@Component({
    selector: 'app-my-qr-page',
    templateUrl: './my-qr-page.component.html',
    styleUrls: ['./my-qr-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyQrPageComponent implements OnInit {
    isMobile = window.innerWidth <= 600;
    shareLinksList$: BehaviorSubject<ShardLink[]> = new BehaviorSubject<
        ShardLink[]
    >([]);
    siteSpotsAndTreksSubject$: BehaviorSubject<SpotsAndTreksQrData> =
        new BehaviorSubject<SpotsAndTreksQrData>({
            siteSpots: [],
            siteTreks: []
        });
    siteId?: string;

    newLinkName = new FormControl('', { validators: Validators.required });

    constructor(
        private siteService: SiteService,
        private activatedRoute: ActivatedRoute,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initSiteSpotsAndTreks();
        this.initShareLink();
    }

    initSiteSpotsAndTreks() {
        (
            this.activatedRoute?.data as Observable<{
                spotsAndTreks: SpotsAndTreksQrData;
            }>
        )
            ?.pipe(
                take(1),
                map((activatedRouteData) => activatedRouteData.spotsAndTreks),
                tap(({ siteId, siteSpots, siteTreks }) => {
                    this.siteId = siteId;
                    this.siteSpotsAndTreksSubject$.next({
                        siteId,
                        siteSpots,
                        siteTreks
                    });
                })
            )
            .subscribe();
    }

    initShareLink() {
        this.siteService.getShareLinksForSite(this.siteId!).subscribe((res) => {
            this.shareLinksList$.next(
                res.sort((linkA, linkB) =>
                    linkA.isDefault ? -1 : linkB.isDefault ? 1 : 0
                )
            );
        });
    }

    generateQRForTrek(id: string) {
        this.siteService.getShareLinkForTrek(id).subscribe(({ url }) => {
            const currentValue = this.siteSpotsAndTreksSubject$.value; // currentValue
            this.siteSpotsAndTreksSubject$.next({
                ...currentValue,
                siteTreks: currentValue?.siteTreks?.map((trek) =>
                    trek.id === id ? { ...trek, shareLink: url } : trek
                )
            });
        });
    }

    generateQRForSpot(id: string) {
        this.siteService
            .getShareLinkForSpot(this.siteId!, id)
            .subscribe(({ url }) => {
                const currentValue = this.siteSpotsAndTreksSubject$.value; // currentValue
                this.siteSpotsAndTreksSubject$.next({
                    ...currentValue,
                    siteSpots: currentValue?.siteSpots?.map((spot) =>
                        spot.id === id ? { ...spot, shareLink: url } : spot
                    )
                });
            });
    }

    deleteQrLink(linkId: string | undefined) {
        if (!linkId) {
            return;
        }
        this.isQrAllowedToDelete(linkId)
            .pipe(
                concatMap((isAllowed) =>
                    isAllowed
                        ? this.siteService
                              .deleteShareLinkForSite(this.siteId!, linkId)
                              .pipe(
                                  tap(() => {
                                      this.snackbarService.open(
                                          this.translate.instant(
                                              'REQUEST_SENT_SUCCESSFULLY'
                                          )
                                      );
                                      const newList =
                                          this.shareLinksList$.value.filter(
                                              (qr) => qr.id !== linkId
                                          );
                                      this.shareLinksList$.next(newList);
                                  }),
                                  catchError(() => {
                                      this.snackbarService.open(
                                          this.translate.instant(
                                              'FAILED_TO_SEND_REQUEST'
                                          )
                                      );
                                      return of(false);
                                  })
                              )
                        : of(true)
                ),
                take(1)
            )
            .subscribe();
    }

    isQrAllowedToDelete(qrId: string): Observable<boolean> {
        return this.siteService.getSiteSettingsSms(this.siteId!).pipe(
            concatMap((smsSettings) => {
                const isUsedInSms = qrId === smsSettings.shareLinkId;
                const data: WarningModalData = {
                    title: 'ARE_YOU_SURE',
                    content: isUsedInSms
                        ? 'DELETE_QR_WARN'
                        : 'DELETE_UNUSED_QR_WARN',
                    image: '<i class="WTicon-are_you_sure"></i>',
                    deleteText: isUsedInSms
                        ? 'DELETE_QR_DELETE_BTN'
                        : 'DELETE_UNUSED_QR_DELETE_BTN',
                    cancelText: isUsedInSms
                        ? 'DELETE_QR_ACTIVE_BTN'
                        : 'DELETE_UNUSED_QR_CANCEL_BTN',
                    showExitButton: isUsedInSms
                };
                const dialogRef = this.matDialog.open<
                    WarningDialogComponent,
                    WarningModalData,
                    WarningActions
                >(WarningDialogComponent, {
                    maxWidth: '650px',
                    disableClose: false,
                    data
                });
                return dialogRef.afterClosed().pipe(
                    map((res) => {
                        if (res === WarningActions.DELETE) {
                            return true;
                        }
                        if (res === WarningActions.CANCEL && isUsedInSms) {
                            this.router.navigate(
                                ['..', SitesRoutePaths.SETTINGS],
                                {
                                    relativeTo: this.activatedRoute,
                                    state: { tabIndex: 1 }
                                }
                            );
                            return false;
                        }
                        return false;
                    }),
                    take(1)
                );
            })
        );
    }

    createNewLink() {
        const linkName = this.newLinkName.value;
        this.siteService
            .createShareLinkForSite(this.siteId!, linkName!)
            .subscribe({
                next: (res) => {
                    this.snackbarService.open(
                        this.translate.instant('REQUEST_SENT_SUCCESSFULLY')
                    );
                    const newList = this.shareLinksList$.value;
                    newList.splice(1, 0, res);
                    this.shareLinksList$.next(newList);
                },
                error: () =>
                    this.snackbarService.open(
                        this.translate.instant('FAILED_TO_SEND_REQUEST')
                    ),
                complete: () => this.newLinkName.reset()
            });
    }
}
