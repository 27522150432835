import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInput, MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ErrorDotDirective } from '../../directives/error-dot.directive';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-custom-textarea-standalone',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        TranslateModule,
        FormsModule,
        ErrorDotDirective,
        TypographyDirective
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomTextareaStandaloneComponent),
            multi: true
        }
    ],
    templateUrl: './custom-textarea-standalone.component.html',
    styleUrls: ['./custom-textarea-standalone.component.scss']
})
export class CustomTextareaStandaloneComponent
    implements ControlValueAccessor, OnInit
{
    @Input() label = '';
    @Input() hint?: string;
    @Input() value = '';
    @Input() maxLength!: string | number | null;
    @Input() rows = 4;
    @Input() placeholder = '';
    @Output() valueChange = new EventEmitter<string>();
    @ViewChild(MatInput) inputElement!: MatInput;
    isInputFocus$ = new BehaviorSubject(false);

    ngOnInit() {
        if (this.placeholder.length) {
            this.isInputFocus$.next(true);
        }
    }

    get _value() {
        return this.value;
    }

    set _value(val: string) {
        this.value = val;
        this.onChange(this.value);
        this.valueChange.emit(val);
    }

    onChange = (_: string) => {};
    onTouched = () => {};

    writeValue(value: string) {
        if (value) {
            this.isInputFocus$.next(true);
        } else if (this.inputElement) {
            this.inputElement.value = '';
        }
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    setDisabledState(isDisabled: boolean) {}

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}
