import * as mapboxgl from 'mapbox-gl';
import { MapboxMap } from './MapboxMap';
import { getHeatmapPaintOptions, getPointsSource } from './geojson';
import { PointData } from '../typings/mapbox-utils';
import { DensityPoint } from '../../site-wizard/site-wizard.model';

export class HeatMapLayer {
    private mapboxMap: MapboxMap;
    private heatmapLayer!: mapboxgl.Layer;

    constructor(mapboxMap: MapboxMap) {
        this.mapboxMap = mapboxMap;
    }

    createHeatmapLayer(
        map: mapboxgl.Map,
        density: DensityPoint[],
        min: number,
        max: number,
        type: string
    ): void {
        const data = this.parseDensityData(density, min, max);
        const color =
            type === 'duration' ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 128, 128, 1)';
        map.addSource('density', {
            type: 'geojson',
            data: getPointsSource(data)
        });

        map.addLayer({
            id: 'density',
            type: 'heatmap',
            source: 'density',
            maxzoom: 24,
            paint: getHeatmapPaintOptions(color)
        });
    }

    parseDensityData(
        data: DensityPoint[],
        min: number,
        max: number
    ): PointData[] {
        return data.map((coord) => ({
            coordinate: [
                coord.lon,
                coord.lat,
                (coord.total - min) / (max - min)
            ],
            properties: {
                total: (coord.total - min) / (max - min)
            }
        }));
    }
}
