import { getUndoableWizardStore } from '../../store/undoable/undoable-store.reducer';
import { ActionReducer, combineReducers } from '@ngrx/store';
import { DcfWizardState } from './dcf-wizard-store.model';
import { DcfWizardActionTypes } from './dcf-wizard.actions';
import { dcfStagesReducer } from './reducers/dcf-wizard-stages.reducer';
import { AppActionTypes } from '../../store/app-store.actions';
import { dcfLanguagesReducer } from './reducers/dcf-wizard-languages.reducer';
import { dcfWizardDetailsReducer } from './reducers/dcf-wizard-details.reducer';
import { dcfWizardTypeReducer } from './reducers/dcf-wizard-type.reducer';
import { dcfWizardActivateReducer } from './reducers/dcf-wizard-activate.reducer';
import { dcfWizardDonationsReducer } from './reducers/dcf-wizard-donations.reducer';

export const dcfWizardMainReducer = combineReducers<DcfWizardState>({
    id: (state) => state!,
    siteId: (state) => state!,
    activeStage: dcfStagesReducer,
    languages: dcfLanguagesReducer,
    campaignType: dcfWizardTypeReducer,
    details: dcfWizardDetailsReducer,
    internalName: (state) => state!,
    activate: dcfWizardActivateReducer,
    isEdit: (state) => state!,
    isOverlapping: (state) => state!,
    isPublished: (state) => state!,
    ooiId: (state) => state!,
    donations: dcfWizardDonationsReducer,
    type: (state) => state!
});

const _dcfWizardRootReducer: ActionReducer<DcfWizardState> = (
    state: DcfWizardState | undefined,
    action: any
) => {
    switch (action.type) {
        case AppActionTypes.RESET_APP_STATE:
        case DcfWizardActionTypes.RESET_WIZARD_STATE:
            return undefined as unknown as DcfWizardState;
        case DcfWizardActionTypes.SET_WIZARD_STATE:
            return dcfWizardMainReducer(action.state, action);
        default:
            return dcfWizardMainReducer(state, action);
    }
};

export const cdfWizardReducer = getUndoableWizardStore(
    _dcfWizardRootReducer,
    DcfWizardActionTypes,
    [
        DcfWizardActionTypes.SET_WIZARD_STATE,
        AppActionTypes.RESET_APP_STATE,
        DcfWizardActionTypes.RESET_WIZARD_STATE
    ],
    [AppActionTypes.RESET_APP_STATE, DcfWizardActionTypes.RESET_WIZARD_STATE]
)();
