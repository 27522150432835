<div class="wizard-map-block">
    <mat-expansion-panel
        #panel
        hideToggle
        disabled
        class="container-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h4 appTypography="SECONDARY_BOLD">
                    <span
                        class="required"
                        *ngIf="invalid"></span
                    >{{ containerTitle | translate }}
                </h4>
                <i
                    class="WTicon-round-edit edit-settings"
                    (click)="openStage.emit()"
                    [ngClass]="{ disabled: open }"
                    *ngIf="!hideEdit"
                    [id]="'edit-' + id">
                    <span class="path1"></span><span class="path2"></span>
                </i>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
            <ng-content></ng-content>
        </div>
    </mat-expansion-panel>
    <div *ngIf="open">
        <app-standalone-custom-button
            class="save-btn"
            (clicked)="nextStage.emit()"
            *ngIf="!hideEdit"
            [id]="'save-' + id"
            buttonSize="MEDIUM">
            {{ 'SAVE' | translate }}
        </app-standalone-custom-button>
        <div
            (click)="panel.toggle(); toggled.emit()"
            class="toggle-expansion"
            [id]="'toggle-' + id">
            <i class="WTicon-arrow-{{ panel.expanded ? 'top' : 'bottom' }}"></i>
        </div>
    </div>
</div>
