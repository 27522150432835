import { createAction, props } from '@ngrx/store';
import { SiteWizardStages } from '../site-wizard-store.model';

export enum SiteStagesActionTypes {
    CHANGE_STAGE = '[SiteWizard]: Change Stage',
    NEXT_STAGE = '[SiteWizard] Next Stage',
    PREVIOUS_STAGE = '[SiteWizard]: Previous Stage',
    UPDATE_STEP_VALID = '[SiteWizard] Update Step Valid'
}

export const ChangeSiteStage = createAction(
    SiteStagesActionTypes.CHANGE_STAGE,
    props<{ stage?: SiteWizardStages }>()
);

export const SiteNextStage = createAction(SiteStagesActionTypes.NEXT_STAGE);

export const SitePrevStage = createAction(SiteStagesActionTypes.PREVIOUS_STAGE);
