import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import { SiteService } from '../../services/site.service';
import { SiteCard, SitePrivateLink } from '../../models/site.model';
import { finalize, map, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../../store/app-store.model';
import { Store } from '@ngrx/store';
import { getUser } from '../../../auth/store/auth-store.selectors';
import { environment } from '@env/environment';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CustomInputStandaloneComponent } from '../../../standalone/components/custom-input/custom-input-standalone.component';

@Component({
    selector: 'app-privately',
    templateUrl: './privately.component.html',
    styleUrls: ['./privately.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivatelyComponent implements OnInit {
    @ViewChild('linkInput') linkInput?: CustomInputStandaloneComponent;
    siteId?: string;
    businessId?: string;
    userId!: string;
    privateLinks: SitePrivateLink[] = [];
    privateLinks$!: Observable<SitePrivateLink[]>;
    sp = `${environment.ASSETS_BASE_URL}/assets/images/private/SP_1.jpg`;

    constructor(
        private siteService: SiteService,
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private spinnerService: ToggleSpinnerService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private clipboard: Clipboard
    ) {}

    ngOnInit(): void {
        this.initSiteAndBusinessId();
        this.initUserId();
        this.spinnerService.toggle(true);
        this.initPrivateLinks();
    }

    initSiteAndBusinessId(): void {
        (this.route.parent?.data as Observable<{ siteInfo: SiteCard }>)
            .pipe(take(1))
            .subscribe(({ siteInfo }) => {
                this.siteId = siteInfo.id;
                this.businessId = siteInfo.businessId;
            });
    }

    initUserId(): void {
        this.store
            .select(getUser)
            .pipe(take(1))
            .subscribe((user) => {
                this.userId = user!.id;
            });
    }

    initPrivateLinks(): void {
        this.privateLinks$ = this.siteService
            .getPrivateLinks(this.businessId!, this.siteId!, this.userId)
            .pipe(
                tap((privateLinks: SitePrivateLink[]) => {
                    this.privateLinks.push(...privateLinks);
                }),
                finalize(() => this.spinnerService.toggle(false))
            );
    }

    generatePrivateLink(): void {
        this.spinnerService.toggle(true);
        this.privateLinks$ = this.siteService
            .generatePrivateLink(
                this.businessId!,
                this.siteId!,
                this.userId,
                this.linkInput?._value
            )
            .pipe(
                map((privateLink: SitePrivateLink) => {
                    this.linkInput!._value = '';
                    this.privateLinks.push(privateLink);
                    return [...this.privateLinks];
                }),
                finalize(() => {
                    this.spinnerService.toggle(false);
                })
            );
    }

    deletePrivateLink(link: SitePrivateLink): void {
        this.privateLinks$ = this.siteService
            .deletePrivateLink(
                this.businessId!,
                this.siteId!,
                this.userId,
                link.token
            )
            .pipe(
                map(() => {
                    this.privateLinks = this.privateLinks.filter(
                        (item) => item !== link
                    );
                    return [...this.privateLinks];
                })
            );
    }

    copyLink(url: string): void {
        this.clipboard.copy(url);
        this.snackbarService.open(
            this.translate.instant('LINK_COPIED_TO_CLIPBOARD')
        );
    }
}
