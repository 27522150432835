import { TrekWizardStages } from '../../store/trek-wizard.model';
import {
    MapWizardStageDetails,
    WizardPageLayout,
    WizardSectionDetails
} from '../../../standalone/components/wizard/wizars-template/wizard-template.model';

export enum StationDialogStages {
    STATIONS = TrekWizardStages.STATIONS,
    MEDIA = TrekWizardStages.MEDIA,
    AUDIOS = TrekWizardStages.AUDIOS,
    TEXT = TrekWizardStages.TEXT,
    POIS = TrekWizardStages.POIS
}

export const stationTrekWizardStageDetails: MapWizardStageDetails<StationDialogStages> =
    new Map<StationDialogStages, WizardSectionDetails<StationDialogStages>>([
        [
            StationDialogStages.STATIONS,
            {
                stage: StationDialogStages.STATIONS,
                titleKey: '',
                descriptionKey: '',
                genieTextKey: '',
                genieVideoUrl: '',
                navBarTextKey: 'STATION',
                icon: ' WTicon-trek-menu-spot',
                children: [
                    StationDialogStages.MEDIA,
                    StationDialogStages.AUDIOS,
                    StationDialogStages.TEXT,
                    StationDialogStages.POIS
                ]
            }
        ],
        [
            StationDialogStages.MEDIA,
            {
                stage: StationDialogStages.MEDIA,
                titleKey: 'TREK_WIZARD_TITLE_STATION_MEDIA',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_STATION_MEDIA',
                genieTextKey: 'TREK_WIZARD_GENIE_STATION_MEDIA',
                genieVideoUrl: '48AkmoN02w8',
                navBarTextKey: 'MEDIA',
                icon: 'WTicon-trek-menu-media',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.FULL_ON_DIALOG,
                hidePreviousBtn: true
            }
        ],
        [
            StationDialogStages.AUDIOS,
            {
                stage: StationDialogStages.AUDIOS,
                titleKey: 'TREK_WIZARD_TITLE_STATION_AUDIO',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_STATION_AUDIO',
                genieTextKey: 'TREK_WIZARD_GENIE_STATION_AUDIO',
                genieVideoUrl: '48AkmoN02w8',
                navBarTextKey: 'AUDIO',
                icon: 'WTicon-trek-menu-audio',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG
            }
        ],
        [
            StationDialogStages.TEXT,
            {
                stage: StationDialogStages.TEXT,
                titleKey: 'TREK_WIZARD_TITLE_STATION_TEXT',
                descriptionKey: 'TREK_WIZARD_DESCRIPTION_STATION_TEXT',
                genieTextKey: 'TREK_WIZARD_GENIE_STATION_TEXT',
                genieVideoUrl: '48AkmoN02w8',
                navBarTextKey: 'TEXT',
                icon: 'WTicon-trek-menu-free-text',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG
            }
        ],
        [
            StationDialogStages.POIS,
            {
                stage: StationDialogStages.POIS,
                titleKey: 'TREK_WIZARD_TITLE_STATION_POINTS_OF_INTEREST',
                descriptionKey:
                    'TREK_WIZARD_DESCRIPTION_STATION_POINTS_OF_INTEREST',
                genieTextKey: 'TREK_WIZARD_GENIE_STATION_POINTS_OF_INTEREST',
                genieVideoUrl: '48AkmoN02w8',
                navBarTextKey: 'POINTS_OF_INTEREST',
                icon: 'WTicon-trek-menu-poi',
                isAChild: true,
                pageLayoutStatus: WizardPageLayout.HALF_ON_DIALOG,
                hideNextBtn: true
            }
        ]
    ]);
