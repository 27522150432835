import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { concatMap, map, tap } from 'rxjs/operators';
import { SiteWizardStages } from '../../../site-wizard/store/site-wizard-store.model';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { Router } from '@angular/router';
import { SiteService } from '../../services/site.service';
import { AppRoutePaths } from '../../../app-routing.module';
import { WizardMobile } from '../../../standalone/components/wizard/wizars-template/wizard-template.model';
import { AppStoreService } from '../../../store/app-store.service';
import { SitesRoutePaths } from '../../sites-routing.module';
import { AuthService } from 'src/app/services/auth.service';
import { UpdateUserActions } from 'src/app/auth/store/auth-store.actions';

@Component({
    selector: 'app-create-site',
    templateUrl: './create-site.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSiteComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    genieVideo = 'https://www.youtube.com/embed/gZs0F0QZSzg';
    wizardMobileData!: WizardMobile;

    constructor(
        private toggleSpinnerService: ToggleSpinnerService,
        private router: Router,
        private store: AppStoreService,
        private siteService: SiteService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.wizardMobileData = {
            link: ['/', AppRoutePaths.SITES],
            messageKey: 'MOBILE_TEXT',
            buttonTextKey: 'BACK_TO_WISHTRIP'
        };
    }

    createSite(): void {
        this.toggleSpinnerService.toggle(true);
        const businessId = this.store.state.auth.user?.businessId!;
        this.siteService
            .createSite(businessId)
            .pipe(
                map((result) => {
                    this.router
                        .navigate([
                            '/',
                            AppRoutePaths.SITES,
                            result.siteId,
                            SitesRoutePaths.SITE_WIZARD,
                            SiteWizardStages.LANGUAGES
                        ])
                        .finally(() => this.toggleSpinnerService.toggle(false));
                }),
                concatMap(() => this.authService.isLoggedIn()),
                tap((user) =>
                    this.store.store.dispatch(
                        UpdateUserActions({ userActions: user.actions })
                    )
                )
            )
            .subscribe({
                error: () => this.toggleSpinnerService.toggle(false)
            });
    }
}
