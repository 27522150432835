<div
    class="create-entity-container"
    [ngClass]="{ 'no-permission': !hasPermission }">
    <div class="text">
        <img
            [ngSrc]="iconsBaseUrl + 'create.svg'"
            height="27"
            width="27"
            alt="create" />
        <ng-content></ng-content>
    </div>
    <div class="overlay">
        <img
            [ngSrc]="iconsBaseUrl + 'lock.svg'"
            height="20"
            width="20"
            alt="lock" />
        <div>
            <h3
                appTypography="PRIMARY"
                translate="ACCESS_RESTRICTED"></h3>
            <p
                appTypography="TINY"
                translate="CONTACT_ADMIN_TO_UNLOCK"></p>
        </div>
    </div>
</div>
