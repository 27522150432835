import { AuthState } from './auth-store.model';
import { initialState } from './auth-store.constants';
import { AuthActionTypes } from './auth-store.actions';
import { AppActionTypes } from '../../store/app-store.actions';

export function AuthReducer(
    state: AuthState | undefined,
    action: any
): AuthState {
    switch (action.type) {
        case AppActionTypes.RESET_APP_STATE:
            return initialState;

        case AuthActionTypes.LOGIN:
            return {
                ...state,
                user: action.user
            };

        case AuthActionTypes.UPDATE_USER_DETAILS:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    ...action.user
                }
            };
        case AuthActionTypes.UPDATE_USER_NAME:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    name: action.name
                }
            };
        case AuthActionTypes.UPDATE_USER_PROFILE_IMAGE:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    userProfileUrl: action.image
                }
            };

        case AuthActionTypes.UPDATE_USER_ACTIONS:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    actions: action.userActions
                }
            };

        case AuthActionTypes.UPDATE_USER_BUSINESS_NAME:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    businessName: action.businessName
                }
            };

        case AuthActionTypes.UPDATE_USER_BUSINESS_LOGO_URL:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    businessLogoUrl: action.logo
                }
            };

        case AuthActionTypes.UPDATE_USER_BUSINESS_IS_COMPLETE:
            return {
                ...state,
                user: {
                    ...state?.user!,
                    isDataComplete: action.isDataComplete
                }
            };

        default:
            return state || { user: null };
    }
}
