<p
    *ngIf="label"
    class="label"
    [appTypography]="(labelSize$ | async) || 'SECONDARY'"
    [color]="
        _value || (isInputFocus$ | async)
            ? 'DEFAULT_COLOR'
            : 'DEFAULT_COLOR_DISABLED'
    "
    (click)="inputElement.focus()"
    [ngClass]="{
        'has-content': _value || _value === 0,
        selected: (isInputFocus$ | async),
        'select-input': autoCompleteOptions.length
    }">
    {{ label }}
</p>
<input
    #trigger="matAutocompleteTrigger"
    #inputElement
    (focusin)="isInputFocus$.next(true)"
    (focusout)="isInputFocus$.next(false)"
    [(ngModel)]="_value"
    [id]="id"
    [maxlength]="maxlength"
    [type]="type || 'text'"
    [min]="min"
    [autocomplete]="nativeAutocomplete"
    [matAutocomplete]="matAutocompleteList"
    [matAutocompleteDisabled]="!autoCompleteOptions.length"
    (keydown.enter)="keydownEnter.emit(_value)"
    [pattern]="pattern"
    [placeholder]="placeholder"
    [name]="name"
    [appTypography]="
        size === 'default'
            ? 'SMALL'
            : size === 'large'
            ? 'SUBTITLE'
            : size === 'small'
            ? 'TINY'
            : 'SMALL'
    " />
<i
    *ngIf="autoCompleteOptions.length"
    (click)="$event.stopPropagation(); trigger.openPanel()"
    class="WTicon-select-arrow"></i>
<div
    class="hint"
    *ngIf="hint"
    [color]="hintError ? 'WARN' : 'DEFAULT_COLOR'"
    appTypography="TINY">
    {{ hint }}
</div>

<mat-autocomplete
    #matAutocompleteList="matAutocomplete"
    [displayWith]="autoCompleteDisplayWithFn"
    (optionSelected)="onOptionSelected($event)">
    <mat-option
        *ngFor="let option of autoCompleteOptions || []"
        [value]="option"
        appTypography="SMALL">
        <span class="auto-complete-option">{{
            autoCompleteDisplayWithFn
                ? autoCompleteDisplayWithFn($any(option))
                : option
        }}</span>
    </mat-option>
</mat-autocomplete>
