import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupportRequest } from '../auth.model';
import { environment } from '@env/environment';

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) {}

    requestSupport(data: SupportRequest): Observable<{}> {
        return this.http.post(
            `${environment.BUSINESS_URL}/api/business/support-request`,
            data
        );
    }

    requestJoin(data: SupportRequest): Observable<{}> {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const config = {
            headers: {
                withCredentialsRemove: 'true'
            }
        };
        return this.http.post(
            `${environment.BUSINESS_URL}/api/business/join-request`,
            data,
            config
        );
    }
}
