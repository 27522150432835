import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToggleSpinnerService {
    private toggleSpinnerEvent?: BehaviorSubject<boolean>;
    toggleEvent$?: Observable<boolean>;

    init(): void {
        this.toggleSpinnerEvent = new BehaviorSubject<any>([]);
        this.toggleEvent$ = this.toggleSpinnerEvent.asObservable();
    }

    toggle(show: boolean): void {
        setTimeout(() => {
            this.toggleSpinnerEvent?.next(show);
        });
    }

    complete(): void {
        this.toggleSpinnerEvent?.complete();
        this.toggleSpinnerEvent = undefined;
        this.toggleEvent$ = undefined;
    }
}
