<div class="reset-password-page--main">
    <div class="reset-password-page--content">
        <div
            class="expired-token"
            *ngIf="expiredToken$ | async">
            <div class="icon">
                <img
                    [src]="imgToken"
                    alt="icon-token" />
            </div>
            <h3
                appTypography="PRIMARY_BOLD"
                translate="WELL_THIS_IS_AWKWARD"></h3>
            <p
                appTypography="SECONDARY"
                [innerHtml]="'PASSWORD_RESET_REQUEST_EXPIRED' | translate"></p>
            <app-standalone-custom-button
                class="btn"
                buttonType="submit"
                [link]="['..']">
                {{ 'HOME' | translate }}
            </app-standalone-custom-button>
        </div>

        <div *ngIf="resetPassword">
            <div class="icon">
                <img
                    [src]="imgReset"
                    alt="icon-reset" />
            </div>
            <form
                id="resetPasswordForm"
                (submit)="submitPassword()"
                [formGroup]="resetForm">
                <h3
                    appTypography="PRIMARY_BOLD"
                    translate="RESET_PASSWORD"></h3>
                <div class="reset-password-page__input">
                    <app-custom-input-standalone
                        [appErrorDot]="!!resetForm.controls.password.errors"
                        [label]="'ENTER_NEW_PASSWORD' | translate"
                        formControlName="password"
                        [type]="inputType"
                        [id]="'pw'"></app-custom-input-standalone>

                    <i
                        class="WTicon-round-show reset-password-page__password"
                        (click)="
                            inputType =
                                inputType === 'password' ? 'text' : 'password'
                        "
                        ><span class="path1"></span><span class="path3"></span
                    ></i>
                </div>
                <app-standalone-custom-button
                    class="reset-password-btn"
                    buttonType="submit"
                    [isDisabled]="resetForm.invalid">
                    {{ 'SAVE_PASSWORD' | translate }}
                </app-standalone-custom-button>
            </form>
        </div>

        <div
            class="password-success"
            *ngIf="passwordSuccess$ | async">
            <div class="icon">
                <img
                    [src]="imgSuccess"
                    alt="icon-success" />
            </div>
            <p appTypography="SECONDARY">
                <strong translate="PASSWORD_RESET"></strong>
            </p>
            <p
                appTypography="SECONDARY"
                translate="YOU_MAY_NOW_LOG_IN_TO_YOUR_ACCOUNT"></p>
            <app-standalone-custom-button
                buttonType="submit"
                [link]="['/', AppRoutePaths.AUTH, AuthRoutePaths.LOGIN]">
                {{ 'LOGIN' | translate }}
            </app-standalone-custom-button>
        </div>
    </div>
</div>
