// @ts-nocheck
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

export type FbqEventType = 'track' | 'trackCustom';
export type FbqEventName =
    'CompleteRegistration'
    | 'Contact'
    | 'wtInteraction'
    | 'CalendlySchedule';
export type FbqInteractionType = 'click' | 'dataEntry';

@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {
    constructor() {
    }

    initPixelEvents(): void {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !((f, b, e, v, n, t, s) => {
            if (f.fbq) {
                return;
            }
            n = f.fbq = function () {
                // eslint-disable-next-line
                // @typescript-eslint/no-unused-expressions
                n.callMethod ?
                    // eslint-disable-next-line prefer-rest-params,prefer-spread
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) {
                f._fbq = n;
            }
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];

            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', '1052368775600514');

    }


    fireFbqEvent(
        type: FbqEventType,
        name: FbqEventName,
        elementId: string,
        interactionType: FbqInteractionType
    ): void {
        if (window.fbq && environment.production) {
            window.fbq(type, name, {elementId, interactionType});
        }
    }
}
