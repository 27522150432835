import { ActionReducer, combineReducers } from '@ngrx/store';
import { SiteWizardState } from './site-wizard-store.model';
import { languageReducer } from './reducers/language.reducers';
import { getUndoableWizardStore } from '../../store/undoable/undoable-store.reducer';
import { SiteStagesActionTypes } from './actions/site-wizard-stages.actions';
import { generalReducer } from './reducers/general-details.reducers';
import { mediaReducer } from './reducers/media.reducers';
import { countryCodeReducer } from './reducers/country.reducers';
import { SiteWizardActionTypes } from './site-wizard.actions';
import { contactInformationReducer } from './reducers/contact.reducers';
import { mapReducer } from './reducers/map.reducers';
import { MapActionTypes } from './actions/map.actions';
import { privacyReducer } from './reducers/private.reducer';
import { siteStagesReducer } from './reducers/site-wizard-stages.reducers';
import { routesReducer } from './reducers/routes.reducers';
import { categoriesReducer } from './reducers/categories.reducers';
import { tagsReducer } from './reducers/tags.reducers';
import { hoursReducer } from './reducers/hours.reducers';
import { spotsReducer } from './reducers/spots.reducers';
import { promotedReducer } from './reducers/promoted.reducers';
import { showLogoReducer } from './reducers/showLogo.reducer';
import { greetingReducer } from './reducers/greeting.reducers';
import { lifecycleReducer } from './reducers/site-lifesycle.reducers';
import { SiteLifeCycleActionTypes } from './actions/site-lifecycle.actions';
import { AppActionTypes } from '../../store/app-store.actions';

export const siteWizardFeatureKey = 'siteWizard';

export const siteWizardMainReducer = combineReducers<SiteWizardState>({
    businessId: (state) => state,
    id: (state) => state,
    mode: (state) => state,
    activeStage: siteStagesReducer,
    languages: languageReducer,
    lifecycle: lifecycleReducer,
    media: mediaReducer,
    general: generalReducer,
    contact: contactInformationReducer,
    countryCode: countryCodeReducer,
    hours: hoursReducer,
    tags: tagsReducer,
    map: mapReducer,
    siteRoutes: routesReducer,
    spots: spotsReducer,
    categories: categoriesReducer,
    greeting: greetingReducer,
    promotedTreks: promotedReducer,
    showLogoInMovie: showLogoReducer,
    private: privacyReducer
});

// TODO add complete section actions

const _siteWizardRootReducer: ActionReducer<SiteWizardState> = (
    state: SiteWizardState | undefined,
    action: any // WizardActions | AppActions
) => {
    switch (action.type) {
        case AppActionTypes.RESET_APP_STATE:
        case SiteWizardActionTypes.RESET_WIZARD_STATE:
            return undefined as unknown as SiteWizardState;
        case SiteWizardActionTypes.SET_WIZARD_STATE:
            return siteWizardMainReducer(action.state, action);

        default:
            return siteWizardMainReducer(state, action);
    }
};

const undoableIgnoredActions = [
    SiteStagesActionTypes.UPDATE_STEP_VALID,
    MapActionTypes.FIX_POLYGON,
    AppActionTypes.RESET_APP_STATE,
    SiteWizardActionTypes.SET_WIZARD_STATE,
    SiteWizardActionTypes.RESET_WIZARD_STATE,
    SiteLifeCycleActionTypes.SET_SITE_LIFECYCLE
];
const resetHistoryActions = [
    AppActionTypes.RESET_APP_STATE,
    SiteWizardActionTypes.RESET_WIZARD_STATE
];

export const SiteWizardReducer = getUndoableWizardStore<SiteWizardState>(
    _siteWizardRootReducer,
    SiteWizardActionTypes,
    undoableIgnoredActions,
    resetHistoryActions
)();
