import { createAction, props } from '@ngrx/store';
import { CampaignTypes, ScheduledCampaignDay } from '../../dcf-wizard.model';

export enum DcfWizardTypeActionTypes {
    UPDATE_DCF_TYPE = '[DcfWizard]: Type Update',
    UPDATE_DCF_SCHEDULED_TYPE = '[DcfWizard]: Update Scheduled Type',
    UPDATE_DCF_TARGET_AMOUNT = '[DcfWizard]: Update Target Amount',
    UPDATE_DCF_TARGET_SHOW_TO_USER = '[DcfWizard]: Update Target Show To User'
}

export const updateDcfType = createAction(
    DcfWizardTypeActionTypes.UPDATE_DCF_TYPE,
    props<{ campaignType: CampaignTypes }>()
);
export const updateDcfTargetAmount = createAction(
    DcfWizardTypeActionTypes.UPDATE_DCF_TARGET_AMOUNT,
    props<{ amount: number }>()
);

export const updateDcfTargetShowToUser = createAction(
    DcfWizardTypeActionTypes.UPDATE_DCF_TARGET_SHOW_TO_USER,
    props<{ showToUser: boolean }>()
);

export const updateDcfScheduledTypeDetails = createAction(
    DcfWizardTypeActionTypes.UPDATE_DCF_SCHEDULED_TYPE,
    props<{
        campaignTypeDetails: ScheduledCampaignDay[];
    }>()
);
