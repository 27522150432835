import { Component, OnInit } from '@angular/core';
import { SiteCard, SmsSettings } from '../../models/site.model';
import { SiteService } from '../../services/site.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    Validators
} from '@angular/forms';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { noWhitespaceValidator } from '../../../shared/shared-utils';
import { finalize, take, tap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { setSiteSettings } from '../../store/selections/site-settings.store';
import {
    Currency,
    SetSiteSetting,
    SiteSettings
} from '../../models/site-settings.model';
import { ShardLink } from '../../models/my-qr.model';
import { AppStoreService } from '../../../store/app-store.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [
        trigger('displayPhonePreview', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('1s ease-in', style({ opacity: 1 }))
            ])
        ])
    ]
})
export class SettingsComponent implements OnInit {
    siteId?: string;
    currencyOptionList = Object.values(Currency);
    currencyOptionDisplayWithFn = (val: string | null) =>
        val ? this.translateService.instant(val) : '';
    paymentSettingsForm!: FormGroup<{
        currency: FormControl<Currency>;
        clientId: FormControl<string>;
        secret: FormControl<string>;
    }>;
    smsSettings!: SmsSettings;
    siteSettings!: SiteSettings;
    isPhonePreviewDisplayed = window.innerWidth > 1200;
    shareLinks: ShardLink[] = [];
    shareLinkDefault?: ShardLink;
    smsSettingsForm!: FormGroup<{
        message: FormControl<string>;
        shareLink: FormControl<ShardLink>;
    }>;
    selectedIndex = history.state?.tabIndex || 0;

    constructor(
        private translateService: TranslateService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private siteService: SiteService,
        private toggleSpinnerService: ToggleSpinnerService,
        private fb: NonNullableFormBuilder,
        private appStoreService: AppStoreService
    ) {}

    ngOnInit(): void {
        this.initForms();
        this.initSiteId();
    }

    initSiteId(): void {
        (this.activatedRoute.parent?.data as Observable<{ siteInfo: SiteCard }>)
            .pipe(
                tap(({ siteInfo }) => {
                    this.siteId = siteInfo.id;
                }),
                concatMap(({ siteInfo }) => this.initShareLinks(siteInfo.id!)),
                concatMap(() => this.initSettingsData()),
                take(1)
            )
            .subscribe((routerData) => {
                this.smsSettings = routerData.settingsPageData;
            });
    }

    initShareLinks(siteId: string) {
        return this.siteService.getShareLinksForSite(siteId).pipe(
            tap((links) => {
                this.shareLinks = links;
                this.shareLinkDefault = links.find((link) => link.isDefault)!;
            })
        );
    }

    initSettingsData() {
        this.siteSettings = this.appStoreService.state.siteSettings;
        this.initPaymentsData();
        return (
            this.activatedRoute.data as Observable<{
                settingsPageData: SmsSettings;
            }>
        ).pipe(
            take(1),
            tap((routeData) => {
                const { message, shareLinkId } = routeData.settingsPageData;
                this.smsSettingsForm.controls.message.setValue(message!);
                this.smsSettingsForm.controls.shareLink.setValue(
                    this.shareLinks.find((link) => link.id === shareLinkId) ||
                        this.shareLinkDefault!
                );
            })
        );
    }

    initPaymentsData(): void {
        const siteSettings = this.siteSettings;
        this.paymentSettingsForm.controls.currency.setValue(
            siteSettings.currency || ('' as Currency)
        );

        this.paymentSettingsForm.controls.clientId.setValue(
            siteSettings.isExistClientId ? '********' : ''
        );

        this.paymentSettingsForm.controls.secret.setValue(
            siteSettings.isExistSecret ? '********' : ''
        );
        this.paymentSettingsForm.markAsPristine();
    }

    initForms(): void {
        this.paymentSettingsForm = this.fb.group({
            currency: this.fb.control('' as Currency),
            clientId: this.fb.control('', [noWhitespaceValidator]),
            secret: this.fb.control('', [noWhitespaceValidator])
        });
        this.smsSettingsForm = this.fb.group({
            message: ['', [Validators.required, noWhitespaceValidator]],
            shareLink: [this.shareLinkDefault!, Validators.required]
        });
    }

    savePaymentSettings(): void {
        const data: SetSiteSetting = {
            currency: this.paymentSettingsForm.controls.currency.value,
            clientId:
                this.paymentSettingsForm.controls.clientId.value || undefined,
            secret: this.paymentSettingsForm.controls.secret.value || undefined
        };
        this.paymentSettingsForm.controls.currency.markAsPristine();
        this.toggleSpinnerService.toggle(true);
        this.siteService
            .updateSiteSettings(this.siteId!, data)
            .pipe(
                finalize(() => {
                    this.toggleSpinnerService.toggle(false);
                })
            )
            .subscribe({
                next: (result) => {
                    this.siteSettings = result;
                    this.initPaymentsData();
                    this.snackbarService.open(
                        this.translate.instant(
                            'PAYMENT_SETTINGS_SAVED_SUCCESSFULLY'
                        )
                    );
                    this.appStoreService.store.dispatch(
                        setSiteSettings(result)
                    );
                },
                error: (error) => {
                    console.log('error', error);
                }
            });
    }

    saveSmsMessage(): void {
        this.smsSettingsForm.markAsPristine();
        this.toggleSpinnerService.toggle(true);
        const { message, shareLink } = this.smsSettingsForm.value;
        this.siteService
            .updateSiteSettingsSms(this.siteId!, message!, shareLink?.id!)
            .pipe(
                finalize(() => {
                    this.toggleSpinnerService.toggle(false);
                })
            )
            .subscribe({
                next: () => {
                    this.snackbarService.open(
                        this.translate.instant(
                            'SMS_SETTINGS_SAVED_SUCCESSFULLY'
                        )
                    );
                },
                error: (error) => {
                    console.log('error-to-save-sms-message', error);
                }
            });
    }
}
