import * as debug from 'debug';
import { MarkerLayer } from './MarkerLayer';
import { MapboxMap } from './MapboxMap';
import * as io from 'socket.io-client';
import { environment } from '@env/environment';

export class LiveMap {
    private mapboxMap: MapboxMap;
    private map: mapboxgl.Map;
    private excludedSites!: Array<string>;
    private liveUrl: string = environment.LIVE_DYNAMIC_MAP_URL;
    private $log = debug('service:map:LiveMap');
    private socket!: SocketIOClient.Socket | null;
    private markersLayer: MarkerLayer;
    private previousZoom?: number;
    private EVENTS = {
        CAMERA_CHANGE: 'camera_change',
        SOI_MAP_EMIT: 'soi_map'
    };
    private liveMapLayerName: string;

    constructor(
        mapboxMap: MapboxMap,
        map: mapboxgl.Map,
        liveMapLayerName: string
    ) {
        this.mapboxMap = mapboxMap;
        this.map = map;
        this.markersLayer = new MarkerLayer(mapboxMap, this.map);
        this.liveMapLayerName = liveMapLayerName;
    }

    init(excludedSites: Array<string> = []): void {
        this.excludedSites = excludedSites;
        this.markersLayer.showLayer();
        this.socket = io(this.liveUrl);
        this.socket.on('connect', this._onSocketConnect);
        this.socket.on('disconnect', this._onSocketDisconnect);

        this.mapboxMap.getLayerControl();
    }

    terminate(): void {
        if (!this.socket) {
            this.$log('Attempting to disconnect undefined socket');
            return;
        }

        this._onSocketDisconnect();
        this.socket.disconnect();
        this.socket = null;
        this.markersLayer.clearMarkers();
    }

    private _onSocketConnect = () => {
        this.$log('onSocketConnect');
        this.socket?.on(this.EVENTS.SOI_MAP_EMIT, this._drawSois);
        this.map.on('moveend', this._notifyCameraChange);
        this._notifyCameraChange();
    };

    private _onSocketDisconnect = () => {
        this.$log('onSocketDisconnect');
        this.map.off('moveend', this._notifyCameraChange);

        this.socket?.removeAllListeners();
        this.socket?.on('connect', this._onSocketConnect);
        this.socket?.on('disconnect', this._onSocketDisconnect);
    };

    private _drawSois = (sois: any) => {
        this.$log('drawSoisMarker', sois);
        const zoom = this.map.getZoom();

        if (zoom !== this.previousZoom) {
            this.markersLayer.clearMarkers();
        }

        const soiMarkers: Array<mapboxgl.Marker> = sois
            .filter((soi: any) => !this.excludedSites.includes(soi.site_id))
            .map((soi: any) => this.mapboxMap.marker.siteMarker(soi));
        this.markersLayer.addMarkers(soiMarkers);

        this.previousZoom = zoom;
    };

    private _notifyCameraChange = () => {
        const bounds = this.map.getBounds();
        const zoom = this.map.getZoom();

        // Get NorthWest and SouthEast bounds
        const nwBound = bounds.getNorthWest();
        const seBound = bounds.getSouthEast();

        // Convert L.LatLng objects to conform to server API.
        const nw = {
            lat: nwBound.lat,
            lon: nwBound.lng
        };
        const se = {
            lat: seBound.lat,
            lon: seBound.lng
        };

        this.$log('notifyCameraChange', nw, se, zoom);
        this.socket?.emit(this.EVENTS.CAMERA_CHANGE, nw, se, zoom);
    };
}
