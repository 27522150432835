import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { WizardMode } from '../../../site-wizard/store/site-wizard-store.model';
import { AppRoutePaths } from '../../../app-routing.module';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SiteCard } from '../../models/site.model';
import { TypographyDirective } from '../../../standalone/directives/typography/typography.directive';
import { environment } from '@env/environment';
import { BaseCardComponent } from '../../../standalone/components/base-card/base-card.component';
import { CardLabelComponent } from '../../../standalone/components/card-label/card-label.component';
import { StatusLabel } from '../../../standalone/components/card-label/card-label.consts';
import { siteStatusLabelMap } from '../../../site-wizard/store/site-wizard-store.utils';

@Component({
    selector: 'app-site-card',
    templateUrl: './site-card.component.html',
    styleUrls: ['./site-card.component.scss'],
    imports: [
        RouterLink,
        NgClass,
        TranslateModule,
        NgIf,
        TypographyDirective,
        BaseCardComponent,
        CardLabelComponent,
        NgOptimizedImage
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteCardComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    iconsBaseUrl = `${environment.ASSETS_BASE_URL}/assets/icons/`;
    statusLabelMap = siteStatusLabelMap;
    @Input() site!: SiteCard;
    WizardMode = WizardMode;
    statusLabel!: StatusLabel;
    backgroundImage!: string;

    ngOnInit(): void {
        this.statusLabel = this.statusLabelMap.get(this.site.mode!)!;
        this.backgroundImage =
            this.site.media ||
            `${environment.ASSETS_BASE_URL}/assets/images/card-placeholder.jpg`;
    }
}
