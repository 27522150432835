<div
    *ngIf="!isMobile || !wizardMobileData; else mobileView"
    class="create-entity-page"
    [ngClass]="{ 'is-mobile': isMobile }">
    <div class="genie-wrapper">
        <div class="genie-container">
            <div class="genie">
                <div class="genie-animation {{ genieState | async }}"></div>
            </div>
        </div>
        <div class="genie-content {{ genieState | async }}">
            <div class="content-area">
                <h2
                    class="title"
                    appTypography="TITLE_BOLD">
                    {{
                        titleTextKey
                            | translate : { name: (user | async)?.name }
                    }}
                </h2>
                <div class="description-area">
                    <div
                        class="text"
                        appTypography="SECONDARY"
                        [innerHTML]="contentTextKey | translate"></div>
                    <div
                        *ngIf="genieVideoUrl"
                        class="video">
                        <iframe
                            [src]="genieVideoUrl | safe : 'url'"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </div>
                </div>
                <div class="dtn-area">
                    <app-standalone-custom-button
                        (clicked)="createClicked.emit()">
                        {{ buttonTextKey | translate }}
                    </app-standalone-custom-button>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #mobileView>
    <div class="wizard-mobile">
        <img
            [src]="WT_LOGO_TRANSPARENT"
            alt="logo"
            class="logo"
            width="190" />
        <h2
            appTypography="SUBTITLE"
            color="WHITE"
            [innerHTML]="wizardMobileData!.messageKey | translate"></h2>
        <app-standalone-custom-button [link]="wizardMobileData!.link">
            {{ wizardMobileData!.buttonTextKey | translate }}
        </app-standalone-custom-button>
    </div>
</ng-template>
