<div class="main-component-content-wrapper">
    <div
        *ngIf="!isInsideDialog"
        class="business-info">
        <a
            *ngIf="businessLogoUrl$ | async as logoUrl"
            [routerLink]="['/', AppRoutePaths.BUSINESS]">
            <img
                [src]="logoUrl"
                class="business-logo"
                alt="business-logo" />
        </a>

        <div
            class="business-name"
            appTypography="PRIMARY">
            {{ businessName$ | async }}
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
