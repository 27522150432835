import { WizardMode } from '../../site-wizard/store/site-wizard-store.model';

export interface SiteSettings {
    freeTrial?: boolean;
    trialExpiration?: Date | null;
    currency?: Currency;
    isPrivate?: boolean;
    isExistSecret?: boolean;
    isExistClientId?: boolean;
    packageType?: string;
    tier?: string;
    mode?: WizardMode;
    allowPremiumFeatures?: boolean;
    timeFormat?: TimeFormat;
    dateFormat?: DateFormat;
    lengthType?: LengthType;
    csTrialExpiration?: Date | null;
    weekStart?: WeekStart;
}

export enum WeekStart {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY'
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD'
}

export const getCurrencySignMap = () =>
    new Map<Currency, '€' | '$'>([
        [Currency.USD, '$'],
        [Currency.EUR, '€']
    ]);

export enum TimeFormat {
    HR_12 = '12_hr',
    HR_24 = '24_hr'
}

export enum DateFormat {
    DD_MM_YYYY = 'dd/mm/yyyy',
    MM_DD_YYYY = 'mm/dd/yyyy',
    YYYY_MM_DD = 'yyyy/mm/dd'
}

export enum LengthType {
    IMPERIAL = 'imperial',
    METRIC = 'metric'
}

export type SetSiteSetting = { clientId?: string; secret?: string } & Pick<
    SiteSettings,
    'currency' | 'timeFormat' | 'dateFormat' | 'lengthType' | 'weekStart'
>;
