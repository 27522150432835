import { createAction, props } from '@ngrx/store';
import {
    CategoryDisplayMode,
    CategoryDisplaySize,
    SiteCategory
} from '../../models/site-wizard-categories.model';

export enum CategoriesActionTypes {
    ADD_CATEGORY = '[SiteWizard] Add Category',
    ORDER_CATEGORY = '[SiteWizard] Order Category',
    DELETE_CATEGORY = '[SiteWizard] Delete Category',
    UPDATE_CATEGORY_DISPLAY_MODE = '[SiteWizard] Update Category Display Mode',
    UPDATE_CATEGORY_DISPLAY_SIZE = '[SiteWizard] Update Category Display Size',
    UPDATE_CATEGORY_RANDOM = '[SiteWizard] Update Category Random',
    UPDATE_CATEGORIES_DATA = '[SiteWizard] Update Categories Data',
    CATEGORIES_COMPLETE = '[SiteWizard] Categories Complete'
}

export const AddCategory = createAction(
    CategoriesActionTypes.ADD_CATEGORY,
    props<{ id: string }>()
);

export const OrderCategory = createAction(
    CategoriesActionTypes.ORDER_CATEGORY,
    props<{ categories: SiteCategory[] }>()
);

export const DeleteCategory = createAction(
    CategoriesActionTypes.DELETE_CATEGORY,
    props<{ id: string }>()
);

export const UpdateCategoryDisplayMode = createAction(
    CategoriesActionTypes.UPDATE_CATEGORY_DISPLAY_MODE,
    props<{ id: string; displayMode: CategoryDisplayMode }>()
);

export const UpdateCategoryDisplaySize = createAction(
    CategoriesActionTypes.UPDATE_CATEGORY_DISPLAY_SIZE,
    props<{ id: string; displaySize: CategoryDisplaySize }>()
);

export const UpdateCategoryRandom = createAction(
    CategoriesActionTypes.UPDATE_CATEGORY_RANDOM,
    props<{ id: string; random: boolean }>()
);

export const UpdateCategoriesData = createAction(
    CategoriesActionTypes.UPDATE_CATEGORIES_DATA,
    props<{ categories: SiteCategory[] }>()
);

export const CategoriesComplete = createAction(
    CategoriesActionTypes.CATEGORIES_COMPLETE
);
