<main [dir]="(directionality | async)!">
    <app-header *ngIf="(headerMode$ | async) === HeaderMode.NAVIGATION">
        <app-header-links></app-header-links>
    </app-header>
    <div
        class="content"
        [ngClass]="{
            'header-padding': (headerMode$ | async) === HeaderMode.NAVIGATION
        }">
        <router-outlet></router-outlet>
    </div>
    <app-footer
        [isMinimizedFooter]="(headerMode$ | async) === HeaderMode.AUTH"
        *ngIf="!isMobile"></app-footer>
</main>
<app-spinner *ngIf="showSpinner$ | async"></app-spinner>
<app-hubspot-meeting-widget
    *ngIf="hubspotMeetingWidgetService.displayMeetingWidgetUrl$ | async">
</app-hubspot-meeting-widget>
<app-accessibility-icon
    [position]="(a11yIconPosition$ | async)!"></app-accessibility-icon>
