import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    ResolveFn,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { WizardMode } from '../../site-wizard/store/site-wizard-store.model';
import { SiteService } from '../services/site.service';
import { setSiteSettings } from '../store/selections/site-settings.store';
import { SiteInfo } from '../../site-wizard/site-wizard.model';
import { AppRoutePaths } from '../../app-routing.module';
import { SiteCard } from '../models/site.model';
import { AppStoreService } from '../../store/app-store.service';

export const SiteResolver: ResolveFn<SiteCard | null> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const siteId = route.paramMap.get('siteId')!;
    const siteService = inject(SiteService);
    const appStoreService = inject(AppStoreService);
    const router = inject(Router);

    return siteService.getSiteSettings(siteId).pipe(
        tap((siteSettings) =>
            appStoreService.store.dispatch(setSiteSettings(siteSettings))
        ),
        switchMap((siteSettings) => {
            let isExpired: any;
            if (siteSettings.freeTrial && siteSettings.trialExpiration) {
                isExpired = new Date() > new Date(siteSettings.trialExpiration);
            }

            if (
                siteSettings.mode === WizardMode.CREATE ||
                siteSettings.mode === WizardMode.REVIEW
            ) {
                return siteService.getState(siteId).pipe(
                    take(1),
                    map((result) => {
                        const siteState = result?.[0]?.state;
                        if (result) {
                            const defaultLang =
                                siteState.languages?.data?.defaultLanguage;
                            const siteInfo: SiteCard = {
                                ...siteSettings,
                                id: siteState.id || siteId,
                                name: siteState.general?.data?.[defaultLang!]
                                    ?.name,
                                media: siteState.media?.data?.[0]?.path,
                                mode: siteState.mode,
                                isPrivate: siteState.private?.data,
                                location:
                                    siteState.general?.data?.[defaultLang!]
                                        ?.locationDescription,
                                country: siteState.countryCode,
                                businessId: siteState.businessId,
                                isExpired
                            };
                            return siteInfo;
                        } else {
                            router.navigate([`/${AppRoutePaths.ERROR}`]);
                            return null;
                        }
                    })
                );
            } else {
                return siteService.getSiteInfo(siteId).pipe(
                    take(1),
                    map((result: SiteInfo) => {
                        if (result) {
                            const defaultLang =
                                result.languages?.defaultLanguage;
                            const siteInfo: SiteCard = {
                                ...siteSettings,
                                id: result.id,
                                name: result.general?.[defaultLang!].name,
                                media: result.media?.[0].path,
                                mode: result.wizardMode,
                                isPrivate: result.private,
                                location:
                                    result.general?.[defaultLang!]
                                        .locationDescription,
                                country: result.countryCode,
                                isExpired,
                                map: result.map,
                                spots: result?.spots,
                                defaultLanguage:
                                    result.languages?.defaultLanguage,
                                businessId: result.businessId
                            };
                            return siteInfo;
                        } else {
                            router.navigate([`/${AppRoutePaths.ERROR}`]);
                            return null;
                        }
                    })
                );
            }
        }),
        catchError(() => {
            router.navigate([`/${AppRoutePaths.ERROR}`]);
            return of(null);
        })
    );
};
