<div class="site-privately">
    <h2 appTypography="SUBTITLE">
        {{ 'SITE_SHARE_PRIVATELY' | translate }}
    </h2>
    <p appTypography="SECONDARY">
        {{ 'SHARE_PRIVATELY_MORE_DESCRIPTION1' | translate }}
    </p>
    <p appTypography="SECONDARY">
        {{ 'SHARE_PRIVATELY_MORE_DESCRIPTION2' | translate }}
    </p>

    <div class="header-table">
        <p
            appTypography="PRIMARY_BOLD"
            color="WHITE"
            class="header-text">
            {{ 'SHARE_MANAGER' | translate }}
        </p>
    </div>
    <div
        [style]="privateLinks.length ? '' : 'background-image:url(' + sp + ');'"
        class="body-table">
        <div class="header">
            <span appTypography="SECONDARY_BOLD">{{ 'NAME' | translate }}</span>
        </div>
        <div class="links-wrapper">
            <div class="list-of-links">
                <div
                    class="link"
                    *ngFor="let privateLinks of privateLinks$ | async">
                    <div
                        class="link-name"
                        appTypography="SECONDARY">
                        {{ privateLinks.name }}
                    </div>
                    <div class="delete-call">
                        <i
                            (click)="deletePrivateLink(privateLinks)"
                            class="WTicon-round-delete delete">
                            <span class="path2"></span>
                        </i>
                    </div>
                    <div>
                        <app-standalone-custom-button
                            buttonStyle="SECONDARY_OUTLINED"
                            buttonSize="MEDIUM"
                            (clicked)="copyLink(privateLinks.url)">
                            {{ 'COPY_LINK' | translate }}
                        </app-standalone-custom-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper-input">
        <app-custom-input-standalone
            [label]="'TYPE_LINK_NAME' | translate"
            #linkInput></app-custom-input-standalone>
        <div class="margin-button">
            <app-standalone-custom-button
                [isDisabled]="!linkInput._value"
                (clicked)="generatePrivateLink()">
                {{ 'CREATE_LINK' | translate }}
            </app-standalone-custom-button>
        </div>
    </div>
</div>
