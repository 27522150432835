import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NumberSms, SentSms, SiteCard } from '../../models/site.model';
import { CountryISO } from 'ngx-intl-tel-input/lib/enums/country-iso.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-sms-invite',
    templateUrl: './sms-invite.component.html',
    styleUrls: ['./sms-invite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsInviteComponent implements OnInit {
    phoneForm!: UntypedFormGroup;
    siteId?: string;
    businessId?: string;
    sentNumbers$!: Observable<SentSms[]>;
    sentNumbers: SentSms[] = [];
    sms: NumberSms = {
        phoneNumber: ''
    };
    countryCode!: CountryISO;
    displayForbiddenErrMsg$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );

    constructor(
        private siteService: SiteService,
        private activatedRoute: ActivatedRoute,
        private toggleSpinnerService: ToggleSpinnerService
    ) {}

    ngOnInit(): void {
        this.initSiteId();
        this.initPhoneForm();
    }

    initSiteId(): void {
        (this.activatedRoute.parent?.data as Observable<{ siteInfo: SiteCard }>)
            .pipe(take(1))
            .subscribe(({ siteInfo }) => {
                this.siteId = siteInfo.id;
                this.businessId = siteInfo.businessId;
                this.countryCode =
                    siteInfo.country?.toLowerCase() as CountryISO;
                console.log('this.countryCode', this.countryCode);
            });
    }

    initPhoneForm(): void {
        this.phoneForm = new UntypedFormGroup({
            phone: new UntypedFormControl('')
        });
    }

    onSubmit(): void {
        if (this.phoneForm.invalid) {
            return;
        }
        this.toggleSpinnerService.toggle(true);
        this.sentNumbers$ = this.siteService
            .sendSms(this.businessId!, this.siteId!, [
                this.phoneForm.value.phone.e164Number
            ])
            .pipe(
                map((res) => {
                    this.sentNumbers.push({
                        phoneNumber:
                            this.phoneForm.value.phone.internationalNumber,
                        date: new Date()
                    });
                    return [...this.sentNumbers];
                }),
                catchError((error: HttpErrorResponse) => {
                    console.error('send sms error');
                    if (error.status === 403) {
                        this.displayForbiddenErrMsg$.next(true);
                    }
                    return of([...this.sentNumbers]);
                }),
                finalize(() => {
                    this.toggleSpinnerService.toggle(false);
                    this.phoneForm.reset();
                }),
                shareReplay()
            );
    }
}
