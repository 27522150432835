import { createAction, props } from '@ngrx/store';
import { TrekWizardState } from './trek-wizard.model';

export enum TrekWizardActionTypes {
    SET_WIZARD_STATE = '[TrekWizard] Set Wizard State',
    RESET_WIZARD_STATE = '[TrekWizard] Reset Wizard State',
    UNDO = '[TrekWizard] Undo',
    REDO = '[TrekWizard] REDO'
}

export const setTrekWizardState = createAction(
    TrekWizardActionTypes.SET_WIZARD_STATE,
    props<{ state: TrekWizardState }>()
);

export const resetTrekWizardState = createAction(
    TrekWizardActionTypes.RESET_WIZARD_STATE
);

export const trekWizardUndo = createAction(TrekWizardActionTypes.UNDO);
export const trekWizardRedo = createAction(TrekWizardActionTypes.REDO);
