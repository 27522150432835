<div class="site-live">
    <div class="site-manager-page-header">
        <h2 appTypography="SUBTITLE">
            {{ 'SITE_LIVE_MAP' | translate }}
        </h2>
        <p appTypography="SECONDARY">
            {{ 'SITE_LIVE_MAP_DESCRIPTION' | translate }}
        </p>
    </div>
    <div
        #map
        class="map">
        <div class="map-additional-controls">
            <i
                (click)="fitBounds()"
                class="WTicon-round-center-map">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
            </i>
        </div>
        <div class="statistics-container">
            <mat-accordion
                [multi]="true"
                class="wizard-map-containers">
                <app-map-panel
                    [containerTitle]="
                        ('CURRENT_VISITORS_IN_SITE' | translate) +
                        ' ' +
                        (stats?.in_site || 0)
                    "
                    [hideEdit]="true"
                    [open]="true"
                    class="settings-container">
                    <div class="live-stats">
                        <div
                            *ngFor="
                                let locale of stats | keyvalue : originalOrder
                            "
                            appTypography="SECONDARY">
                            {{ $any(liveStats)[locale.key] | translate }}
                            {{ locale.value }}
                        </div>
                        <div
                            *ngFor="let locale of $any(localeStats) | keyvalue"
                            appTypography="SECONDARY">
                            {{ LOCALES[locale.key + ''] }}
                            {{ 'SPEAKING_VISITORS' | translate }}
                            {{ locale.value }}
                        </div>
                    </div>
                </app-map-panel>
            </mat-accordion>
        </div>
    </div>
</div>
