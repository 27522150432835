import {
    DateFormat,
    LengthType,
    TimeFormat,
    WeekStart
} from '../../models/site-settings.model';

export const timeFormatOptionsMap = new Map<TimeFormat, string>([
    [TimeFormat.HR_24, '24HR'],
    [TimeFormat.HR_12, '12HR']
]);

export const dateFormatOptionsMap = new Map<DateFormat, string>([
    [DateFormat.DD_MM_YYYY, 'DD/MM/YYYY'],
    [DateFormat.MM_DD_YYYY, 'MM/DD/YYYY'],
    [DateFormat.YYYY_MM_DD, 'YYYY/MM/DD']
]);

export const lengthTypeOptionsMap = new Map<LengthType, string>([
    [LengthType.IMPERIAL, 'IMPERIAL'],
    [LengthType.METRIC, 'METRIC']
]);

export const weekStartOptionsMap = new Map<WeekStart, string>([
    [WeekStart.SUNDAY, 'SUNDAY_SATURDAY'],
    [WeekStart.MONDAY, 'MONDAY_SUNDAY']
]);
