import { SheetSpot } from './spots-sheet.model';
import { SiteGeneralDetails } from '../../models/site-wizard-general-details.model';
import { ContactInformation } from '../../models/site-wizard-contact.model';
import { SoiSettings } from '../../models/site-wizard-spots.model';
import { LocaleType } from '../../../shared/locales.models';
import { LOCALES } from '../../../shared/locales.constants';

export const LANGUAGE_HEADERS: (keyof SiteGeneralDetails)[] = [
    'name',
    'description',
    'slogan',
    'accessibilityDescription',
    'recommendedTimeNeeded'
];

export const REQUIRED_HEADERS: (keyof SheetSpot)[] = ['lat', 'lon'];

export const CONTACT_HEADERS: (keyof ContactInformation)[] = [
    'phone',
    'email',
    'website',
    'facebook',
    'instagram',
    'whatsapp',
    'tripadvisor',
    'expedia'
];

export const SETTINGS_HEADERS: (keyof Omit<SoiSettings, 'radius'>)[] = [
    'navigationMode',
    'notificationMode',
    'isActive'
];
export const OPTIONAL_HEADERS: (keyof SheetSpot)[] = [
    ...(CONTACT_HEADERS as (keyof SheetSpot)[]),
    ...(SETTINGS_HEADERS as (keyof SheetSpot)[]),
    'category'
];

export const getMappedHeadersNames = (lang: LocaleType) =>
    new Map<keyof SheetSpot, string>([
        ['lat', 'Latitude'],
        ['lon', 'Longitude'],
        [`${lang}_name`, `${LOCALES[lang]} Spot Name`],
        [`${lang}_description`, `${LOCALES[lang]} Description`],
        [`${lang}_slogan`, `${LOCALES[lang]} Slogan`],
        [`${lang}_accessibilityDescription`, `${LOCALES[lang]} Accessibility`],
        [
            `${lang}_recommendedTimeNeeded`,
            `${LOCALES[lang]} Recommended Visit Duration`
        ],
        ['navigationMode', 'Navigation Mode'],
        ['notificationMode', 'Alert Mode'],
        ['isActive', 'Active / Inactive'],
        ['phone', 'Phone'],
        ['email', 'Email'],
        ['website', 'Website'],
        ['facebook', 'Facebook'],
        ['instagram', 'Instagram'],
        ['whatsapp', 'WhatsApp'],
        ['tripadvisor', 'TripAdvisor'],
        ['category', 'Category'],
        ['expedia', 'Expedia']
    ]);

export const getSheetSpotKeys = (lang: LocaleType) =>
    new Map<string, keyof SheetSpot>(
        Array.from(
            getMappedHeadersNames(lang),
            (i) => i.reverse() as [string, keyof SheetSpot]
        )
    );

export enum SheetSpotWarnings {
    UNEXPECTED_HEADERS_IGNORED = 'UNEXPECTED_HEADERS_IGNORED',
    DUPLICATED_HEADERS = 'DUPLICATED_HEADERS',
    INVALID_CATEGORY = 'INVALID_CATEGORY',
    INVALID_NAVIGATION_MODE = 'INVALID_NAVIGATION_MODE',
    INVALID_NOTIFICATION_MODE = 'INVALID_NOTIFICATION_MODE',
    INVALID_IS_ACTIVE_FIELD = 'INVALID_IS_ACTIVE_FIELD'
}

export enum SheetSpotRejections {
    PARSING_EXCEL_ERROR = 'PARSING_EXCEL_ERROR',
    EMPTY_DATA = 'EMPTY_DATA',
    REQUIRED_HEADER_MISSING = 'REQUIRED_HEADER_MISSING',
    LATITUDE_OUT_OF_RANGE = 'LATITUDE_OUT_OF_RANGE',
    LONGITUDE_OUT_OF_RANGE = 'LONGITUDE_OUT_OF_RANGE',
    LATITUDE_NOT_A_NUMBER = 'LATITUDE_NOT_A_NUMBER',
    LONGITUDE_NOT_A_NUMBER = 'LONGITUDE_NOT_A_NUMBER',
    DUPLICATED_SPOT_LOCATION = 'DUPLICATED_SPOT_LOCATION',
    SPOT_OVERLAPS_SITE_ANCHOR_POINT = 'SPOT_OVERLAPS_SITE_ANCHOR_POINT'
}
