import { createReducer } from '@ngrx/store';
import * as StationsActions from '../actions/stations.actions';
import * as MapActions from '../actions/map.actions';
import { immerOn } from 'ngrx-immer/store';
import { orderItems } from '../trek-wizard-store.utils';
import {
    TrekMedia,
    TrekStation,
    TrekStationMap
} from '../../models/trek-wizard-stations.model';
import {
    StationDialogStages,
    stationTrekWizardStageDetails
} from '../../pages/trek-wizard-station-dialog/station-dialog.const';
import { initialTrekState } from '../../constants/trek-wizard.const';
import { WizardSection } from '../../../standalone/components/wizard/wizars-template/wizard-template.model';

export const trekStationsReducer = createReducer(
    initialTrekState.stations,
    immerOn(StationsActions.AddStation, (draft, { id, coordinate }) => {
        draft.data = draft.data || {};
        draft.data[id] = new TrekStation(id, coordinate);
        draft.sectionStatus.selectedStationId = id;
        draft.data[draft.sectionStatus.selectedStationId].selectedStage =
            StationDialogStages.MEDIA;
        draft.sectionStatus.disableDependants = false;
    }),
    immerOn(StationsActions.DeleteStation, (draft, { id }) => {
        if (!draft.data?.[id]) {
            return;
        }
        delete draft.data[id];
        draft.sectionStatus.invalid = !checkIfAllStationsComplete(draft.data);
        draft.sectionStatus.selectedStationId = undefined;
    }),
    immerOn(StationsActions.SelectStation, (draft, { id }) => {
        draft.sectionStatus = { ...draft.sectionStatus };
        draft.sectionStatus.selectedStationId = id;
    }),
    immerOn(StationsActions.UnselectStation, (draft) => {
        if (draft.sectionStatus.selectedStationId) {
            draft.data![draft.sectionStatus.selectedStationId] = {
                ...draft.data![draft.sectionStatus.selectedStationId],
                complete: checkIfStationComplete(
                    draft.data![draft.sectionStatus.selectedStationId]
                )
            };
        }
        draft.sectionStatus.invalid =
            !draft.data ||
            (!!Object.keys(draft.data).length &&
                !checkIfAllStationsComplete(draft.data));
        draft.sectionStatus.complete =
            draft.sectionStatus.complete && !draft.sectionStatus.invalid;

        draft.sectionStatus.selectedStationId = undefined;
    }),
    immerOn(
        StationsActions.UpdateStationLocation,
        (draft, { updatedCoords }) => {
            Object.entries(updatedCoords).forEach(([id, coordinate]) => {
                draft.data![id].coordinate = coordinate;
            });
        }
    ),
    immerOn(
        StationsActions.ChangeSelectStationStage,
        (draft, { stage, completedSection }) => {
            const selectedStationId = draft.sectionStatus.selectedStationId;
            if (completedSection) {
                (draft.data as any)[selectedStationId!][
                    completedSection
                ].sectionStatus.complete = true;
            }
            const stageInfo = stationTrekWizardStageDetails.get(stage);
            const newState = stageInfo?.children
                ? stageInfo.children[0]
                : stage;
            draft.data![draft.sectionStatus.selectedStationId!] = {
                ...(draft.data![draft.sectionStatus.selectedStationId!] || {}),
                selectedStage: newState
            };
        }
    ),
    immerOn(StationsActions.StationsComplete, (draft) => {
        draft.sectionStatus.complete = true;
    }),
    immerOn(StationsActions.UpdateMedia, (draft, { media }) => {
        const selectedStationId = draft.sectionStatus.selectedStationId;
        draft.data![selectedStationId!] = {
            ...(draft.data![selectedStationId!] || {}),
            media: {
                ...(draft.data![selectedStationId!].media ||
                    ({} as WizardSection<TrekMedia[]>)),
                data: orderItems(media)
            }
        };
    }),
    immerOn(StationsActions.UpdateText, (draft, { text }) => {
        const selectedStationId = draft.sectionStatus.selectedStationId;
        if (draft.data?.[selectedStationId!]) {
            draft.data![selectedStationId!].text!.data = text;
        }
    }),
    immerOn(StationsActions.UpdateAudio, (draft, { audios }) => {
        const selectedStationId = draft.sectionStatus.selectedStationId;
        draft.data![selectedStationId!].audios!.data = audios;
    }),
    immerOn(StationsActions.AddPoi, (draft, { poiId }) => {
        const selectedStationId = draft.sectionStatus.selectedStationId;
        draft.data![selectedStationId!].pois!.data = draft.data?.[
            selectedStationId!
        ].pois?.data?.length
            ? [...draft.data[selectedStationId!].pois!.data!, poiId]
            : [poiId];
    }),
    immerOn(StationsActions.DeletePoi, (draft, { poiId }) => {
        const selectedStationId = draft.sectionStatus.selectedStationId;
        draft.data![selectedStationId!].pois!.data = draft.data?.[
            selectedStationId!
        ].pois?.data?.filter((poi: any) => poi !== poiId);
    }),
    immerOn(MapActions.CreateRoute, (draft, { route }) => {
        draft.data = {};
    }),
    immerOn(MapActions.DeleteRoute, (draft) => {
        draft.data = {};
    })
);

function checkIfStationComplete(station?: TrekStation): boolean {
    let complete = true;
    for (const i of Object.values(station || {})) {
        if ((i as any)?.sectionStatus?.invalid) {
            return (complete = false);
        }
    }
    if (
        !station?.media?.data?.length &&
        !station?.audios?.data?.length &&
        !station?.text?.data &&
        !station?.pois?.data?.length
    ) {
        return (complete = false);
    }

    return complete;
}

function checkIfAllStationsComplete(stations: TrekStationMap): boolean {
    let complete = true;
    for (const i of Object.values(stations)) {
        if (!i?.complete) {
            return (complete = false);
        }
    }
    return complete;
}
