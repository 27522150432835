import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export const ACCENT_TIMEPICKER_THEME: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#fff',
        buttonColor: '#6c8775',
        primaryFontFamily: 'ploni'
    },
    dial: {
        dialBackgroundColor: '#6c8775',
        dialActiveColor: '#403C3E',
        dialEditableActiveColor: '#403C3E'
    },
    clockFace: {
        clockFaceBackgroundColor: '#eaeaea', // '#6c8775',
        clockHandColor: '#403C3E',
        clockFaceTimeActiveColor: '#fff',
        clockFaceTimeInactiveColor: '#403C3E', // '#fff',
        clockFaceInnerTimeInactiveColor: '#403C3E',
        clockFaceTimeDisabledColor: '#989898'
    }
};
