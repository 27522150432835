import { createAction, props } from '@ngrx/store';
import { LocaleType } from '../../../shared/locales.models';
import { GameMedia } from '../../../games/models/game.interface';

export enum GameIntroductionActionTypes {
    ADD_MEDIA = '[GameWizard] Add Game Introduction Media',
    ORDER_MEDIA = '[GameWizard] Order Game Introduction Media',
    DELETE_MEDIA = '[GameWizard] Delete Game Introduction Media',
    UPDATE_INTRODUCTION_DESCRIPTION = '[GameWizard] Update Introduction Description',
    SET_SELECTED_LANG_TAB = '[GameWizard]: Introduction Set Selected Lang Tab'
}

export const AddGameIntroductionMedia = createAction(
    GameIntroductionActionTypes.ADD_MEDIA,
    props<{
        path: string;
        local: LocaleType;
        defaultLang: LocaleType;
    }>()
);

export const OrderGameIntroductionMedia = createAction(
    GameIntroductionActionTypes.ORDER_MEDIA,
    props<{
        introductionMedia: GameMedia[];
        local: LocaleType;
        defaultLang: LocaleType;
    }>()
);

export const DeleteGameIntroductionMedia = createAction(
    GameIntroductionActionTypes.DELETE_MEDIA,
    props<{ index: number; local: LocaleType; defaultLang: LocaleType }>()
);

export const updateIntroductionDescription = createAction(
    GameIntroductionActionTypes.UPDATE_INTRODUCTION_DESCRIPTION,
    props<{ introductionDescription: string; local: LocaleType }>()
);

export const setGameIntroductionSelectedLangTab = createAction(
    GameIntroductionActionTypes.SET_SELECTED_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);
