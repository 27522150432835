import { createReducer, on } from '@ngrx/store';
import * as MapActions from '../actions/map.actions';
import { initialState } from '../site-wizard-store.constants';

export const mapReducer = createReducer(
    initialState.map,
    on(MapActions.updateAnchorPoint, (state, { coordinate }) => ({
        ...state,
        data: {
            ...state?.data,
            anchorPoint: {
                coordinate,
                navigatable: state?.data?.anchorPoint?.navigatable || false
            }
        },
        sectionStatus: {
            ...state?.sectionStatus!,
            invalid: !state?.data?.polygon?.data,
            disableDependants: !state?.data?.polygon?.data
        }
    })),
    on(MapActions.deleteAnchorPoint, (state, {}) => ({
        ...state,
        data: {
            ...state?.data,
            anchorPoint: {
                navigatable: state?.data?.anchorPoint?.navigatable
            }
        },
        sectionStatus: {
            ...state?.sectionStatus,
            invalid: true,
            disableDependants: true
        }
    })),
    on(MapActions.updateNavigatable, (state, { navigatable }) => ({
        ...state,
        data: {
            ...state?.data,
            anchorPoint: {
                ...state?.data?.anchorPoint,
                navigatable
            }
        }
    })),
    on(MapActions.createPolygon, (state, { geoJson }) => ({
        ...state,
        data: {
            ...state?.data,
            polygon: {
                showPolygon: state?.data?.polygon?.showPolygon || false,
                data: geoJson,
                created: true
            }
        },
        sectionStatus: {
            ...state?.sectionStatus,
            invalid: !(
                state?.data?.anchorPoint?.coordinate?.lon &&
                state?.data?.anchorPoint?.coordinate?.lat
            ),
            disableDependants: !(
                state?.data?.anchorPoint?.coordinate?.lon &&
                state?.data?.anchorPoint?.coordinate?.lat
            )
        }
    })),
    on(MapActions.updatePolygon, (state, { coords }) => ({
        ...state,
        data: {
            ...state?.data,
            polygon: {
                showPolygon: state?.data?.polygon?.showPolygon || false,
                data: {
                    ...state?.data?.polygon?.data!,
                    coordinates: [coords]
                }
            }
        }
    })),
    on(MapActions.fixPolygon, (state, { coords }) => ({
        ...state,
        data: {
            ...state?.data,
            polygon: {
                showPolygon: state?.data?.polygon?.showPolygon || false,
                data: {
                    ...state?.data?.polygon?.data!,
                    coordinates: [coords]
                }
            }
        }
    })),
    on(MapActions.deletePolygon, (state, {}) => ({
        ...state,
        data: {
            ...state.data,
            polygon: {
                showPolygon: state?.data?.polygon?.showPolygon
            }
        },
        sectionStatus: {
            ...state?.sectionStatus,
            invalid: true,
            disableDependants: true
        }
    })),
    on(MapActions.updateShowPolygon, (state, { showPolygon }) => ({
        ...state,
        data: {
            ...state?.data,
            polygon: {
                ...state?.data?.polygon,
                showPolygon
            }
        }
    })),
    on(MapActions.updateMapStyle, (state, { mapStyle }) => ({
        ...state,
        data: {
            ...state?.data,
            mapStyle
        }
    })),
    on(MapActions.updateMapStage, (state, { stage }) => ({
        ...state,
        data: {
            ...state?.data,
            mapStage: stage
        }
    })),
    on(MapActions.MapComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus,
            complete: true
        }
    }))
);
