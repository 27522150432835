import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarRef,
    TextOnlySnackBar
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
    matSnackBarRef!: MatSnackBarRef<TextOnlySnackBar>;

    constructor(private matSnackBar: MatSnackBar) {}

    open(
        text: string,
        options: any = {},
        action?: string,
        callback?: any
    ): void {
        this.matSnackBarRef = this.matSnackBar.open(text, action, {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snackbar'],
            duration: options.duration || 3000
        });

        this.matSnackBarRef.onAction().subscribe(() => {
            callback();
        });
    }
}
