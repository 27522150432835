import { Component, OnInit } from '@angular/core';
import { GamesService } from './services/games.service';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { Game, GameData } from './models/game.interface';
import { Observable, of } from 'rxjs';
import { ToggleSpinnerService } from '../services/toggle-spinner.service';
import { WarningDialogComponent } from '../standalone/components/warning-dialog/warning-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-store.model';
import { resetGameWizardState } from '../game-wizard/store/game-wizard.actions';
import {
    WarningActions,
    WarningModalData
} from '../standalone/components/warning-dialog/warning-dialog.const';
import { MatDialog } from '@angular/material/dialog';
import { GamesRoutePaths } from './games-routing.module';
import { hubspotMeetingSupportUrl } from '../standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.const';
import { HubspotMeetingWidgetService } from '../standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.service';
import { AppRoutePaths } from '../app-routing.module';
import { HeaderService } from '../services/header.service';

@Component({
    selector: 'app-games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {
    gamesList$!: Observable<Game[]>;
    siteId = this.activatedRoute.snapshot.parent?.parent?.url[0].path;

    constructor(
        private gamesService: GamesService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private translateService: TranslateService,
        private toggleSpinnerService: ToggleSpinnerService,
        private store: Store<AppState>,
        private hubspotMeetingWidgetService: HubspotMeetingWidgetService,
        private headerService: HeaderService
    ) {}

    ngOnInit(): void {
        this.initGamesList();
        this.resetGameWizardState();
        this.initBreadcrumbs();
    }

    initBreadcrumbs() {
        this.headerService.breadcrumbs = [
            { text: 'SITES', link: ['/', AppRoutePaths.SITES] },
            {
                text:
                    this.activatedRoute.snapshot.parent?.parent?.data.siteInfo
                        ?.name || 'SITE',
                link: '../..',
                overflow: true
            },
            {
                text: 'GAMES'
            }
        ];
    }

    initGamesList(): void {
        this.gamesList$ = this.gamesService.getSiteGamesList(this.siteId!).pipe(
            concatMap((gamesList) => {
                const gamesIds = gamesList.map((game) => game.id);
                return this.gamesService.getGamesDetails(gamesIds);
            })
        );
    }

    resetGameWizardState() {
        this.store.dispatch(resetGameWizardState());
    }

    onCreateGameClicked() {
        this.router.navigate([GamesRoutePaths.CREATE_GAME], {
            relativeTo: this.activatedRoute
        });
    }

    onDeleteGame(gameData: GameData, gameId: string) {
        const gameName =
            (gameData?.name && gameData.name?.length > 25
                ? gameData?.name.slice(0, 25) + '...'
                : gameData?.name) || '';
        const dialogData: WarningModalData = {
            title: this.translateService.instant('DELETE_GAME_DIALOG_TITLE', {
                gameName
            }),
            content: this.translateService.instant(
                'DELETE_GAME_DIALOG_CONTENT',
                {
                    gameName
                }
            ),
            image: '<i class="WTicon-are_you_sure"></i>',
            deleteText: 'YES_DELETE_IT',
            cancelText: 'ACTUALLY_KEEP_IT'
        };
        const dialogRef = this.matDialog.open(WarningDialogComponent, {
            data: dialogData,
            width: '35%'
        });
        dialogRef
            .afterClosed()
            .pipe(
                concatMap((result: WarningActions) => {
                    if (result === WarningActions.DELETE) {
                        this.toggleSpinnerService.toggle(true);
                        return this.gamesService.deleteGame(gameId);
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe(() => {
                this.initGamesList();
                this.toggleSpinnerService.toggle(false);
            });
    }

    onGameCardClick(gameId: string) {
        this.router.navigate([gameId], { relativeTo: this.activatedRoute });
    }

    onDuplicateGameClick(gameId: string) {
        this.gamesService.duplicateGame(gameId).subscribe(() => {
            this.initGamesList();
            this.toggleSpinnerService.toggle(false);
        });
    }

    onSetupMeetingClick() {
        this.hubspotMeetingWidgetService.displayMeetingWidgetUrl =
            hubspotMeetingSupportUrl;
    }
}
