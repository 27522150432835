import { LatLon, PointData } from '../typings/mapbox-utils';
import {
    getLineStringSource,
    getMaskedPolygonData,
    getPointsSource
} from './geojson';
import { GeoJSON, Position } from 'geojson';

export function getPolygonFillLayer(
    layerId: string,
    geoJson: GeoJSON,
    color?: string,
    opacity?: number
): mapboxgl.Layer {
    const customColor = color ? color : '#2997a7';
    return {
        id: layerId,
        type: 'fill',
        source: {
            type: 'geojson',
            data: geoJson as any
        },
        layout: {},
        paint: {
            'fill-color': customColor,
            'fill-opacity': opacity || 0.2
        }
    };
}

export function getPolygonOutlineLayer(
    layerId: string,
    geoJson: GeoJSON,
    color?: string,
    opacity?: number
): mapboxgl.Layer {
    const customColor = color ? color : '#2997a7';
    return {
        id: layerId,
        type: 'line',
        source: {
            type: 'geojson',
            data: geoJson as any
        },
        layout: {},
        paint: {
            'line-color': customColor,
            'line-width': 5,
            'line-opacity': opacity || 1
        }
    };
}

export function getTrekPathLayer(
    layerId: string,
    coords: Array<LatLon>,
    geoJson: any,
    color?: string,
    thickness?: number,
    lineOpacity: number = 1
): mapboxgl.Layer {
    return {
        id: layerId,
        type: 'line',
        source: {
            type: 'geojson',
            data: geoJson || getLineStringSource(coords)
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': color || '#2997a7',
            'line-width': thickness || 10,
            // 'line-dasharray': [2, 2],
            'line-opacity': lineOpacity
        }
    };
}

export function getMaskedPolygonLayer(polygon: any): mapboxgl.Layer {
    return {
        id: 'masked',
        type: 'fill',
        source: {
            type: 'geojson',
            data: getMaskedPolygonData(polygon, [-180, -85, 180, 85])
        },
        layout: {},
        paint: {
            'fill-color': '#333',
            'fill-opacity': 0.5
        }
    };
}

export function getTrekPointsLayer(
    layerId: string,
    points: PointData[]
): mapboxgl.Layer {
    return {
        id: `${layerId}-points-layer`,
        type: 'symbol',
        source: {
            type: 'geojson',
            data: getPointsSource(points)
        },
        layout: {
            'icon-size': 1,
            'icon-image': 'station-icon',
            'text-field': '{title}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1],
            'text-anchor': 'top'
        }
    };
}

export function getPolygonSourceData(
    coordinates: Position[][]
): GeoJSON.Feature<GeoJSON.Geometry> {
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Polygon',
            coordinates
        }
    };
}

export function getClusteredSoiLayer(
    layerId: string,
    sourceId: string
): mapboxgl.Layer {
    return {
        id: `${layerId}`,
        type: 'circle',
        filter: ['has', 'point_count'],
        source: sourceId,
        paint: {
            'circle-color': '#fff',
            'circle-radius': 14,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#403c3d'
        }
    };
}

export function getClusteredSoiLabelLayer(
    layerId: string,
    sourceId: string
): mapboxgl.Layer {
    return {
        id: `${layerId}`,
        type: 'symbol',
        filter: ['has', 'point_count'],
        source: sourceId,
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 16
        }
    };
}

export function getUnclusteredSoiLayer(
    layerId: string,
    sourceId: string
): mapboxgl.Layer {
    return {
        id: `${layerId}`,
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        source: sourceId,
        layout: {
            'icon-image': ['get', 'icon'],
            'icon-size': 1,
            'icon-allow-overlap': true,
            'text-allow-overlap': true
        }
    };
}

export function getSoiClusterSource(
    points: Array<PointData>
): mapboxgl.GeoJSONSourceRaw {
    return {
        type: 'geojson',
        data: getPointsSource(points),
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points
        // (defaults to 50)
    };
}

export function getNavigationLayer(sourceId: string, layerId: string) {
    const layer: mapboxgl.Layer = {
        id: layerId,
        type: 'line',
        source: sourceId,
        layout: {
            visibility: 'visible',
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#43a047',
            'line-width': 8
        }
    };
    return layer;
}
