import { createAction, props } from '@ngrx/store';
import { UpdatedStationsCoordsMap } from '../trek-wizard.model';
import { LatLon } from '../../../mapbox/typings/mapbox-utils';
import { TrekMedia } from '../../models/trek-wizard-stations.model';
import { StationDialogStages } from '../../pages/trek-wizard-station-dialog/station-dialog.const';

export enum StationsActionTypes {
    STATIONS_ADD_NEW = '[TrekWizard] Stations Add New',
    STATIONS_DELETE = '[TrekWizard] Stations Delete',
    STATIONS_SELECT = '[TrekWizard] Stations Select',
    STATIONS_UNSELECT = '[TrekWizard] Stations Unselect',
    STATIONS_UPDATE_LOCATION = '[TrekWizard] Stations Update Location',
    STATIONS_CHANGE_SELECTED_STAGE = '[TrekWizard] Stations Change Selected Stage',
    STATIONS_COMPLETE = '[TrekWizard] Stations Complete',
    STATION_UPDATE_MEDIA = '[TrekWizard] Station Update Media',
    STATION_UPDATE_TEXT = '[TrekWizard] Station General Update Text',
    STATION_UPDATE_AUDIO = '[TrekWizard] Station Update audio',
    STATION_ADD_POI = '[TrekWizard] Station Add Poi',
    STATION_DELETE_POI = '[TrekWizard] Station Delete Poi'
}

export const AddStation = createAction(
    StationsActionTypes.STATIONS_ADD_NEW,
    props<{ id: string; coordinate: LatLon }>()
);

export const DeleteStation = createAction(
    StationsActionTypes.STATIONS_DELETE,
    props<{ id: string }>()
);

export const SelectStation = createAction(
    StationsActionTypes.STATIONS_SELECT,
    props<{ id: string }>()
);

export const UnselectStation = createAction(
    StationsActionTypes.STATIONS_UNSELECT
);

export const UpdateStationLocation = createAction(
    StationsActionTypes.STATIONS_UPDATE_LOCATION,
    props<{ updatedCoords: UpdatedStationsCoordsMap }>()
);

export const ChangeSelectStationStage = createAction(
    StationsActionTypes.STATIONS_CHANGE_SELECTED_STAGE,
    props<{
        stage: StationDialogStages;
        completedSection?: StationDialogStages;
    }>()
);

export const StationsComplete = createAction(
    StationsActionTypes.STATIONS_COMPLETE
);

export const UpdateMedia = createAction(
    StationsActionTypes.STATION_UPDATE_MEDIA,
    props<{ media: TrekMedia[] }>()
);

export const UpdateText = createAction(
    StationsActionTypes.STATION_UPDATE_TEXT,
    props<{ text: string }>()
);

export const UpdateAudio = createAction(
    StationsActionTypes.STATION_UPDATE_AUDIO,
    props<{ audios: TrekMedia[] }>()
);

export const AddPoi = createAction(
    StationsActionTypes.STATION_ADD_POI,
    props<{ poiId: string }>()
);

export const DeletePoi = createAction(
    StationsActionTypes.STATION_DELETE_POI,
    props<{ poiId: string }>()
);
