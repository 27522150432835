import {
    combineReducers,
    createAction,
    createReducer,
    createSelector,
    on,
    props
} from '@ngrx/store';
import { AppState } from '../../store/app-store.model';
import {
    BusinessPolygon,
    BusinessTrek
} from '../models/trek-wizard-route.model';

export interface BusinessDataState {
    businessPolygons: BusinessPolygon[];
    businessTreks: BusinessTrek[];
}

export enum BusinessDataActionTypes {
    SET_POLYGONS = '[BusinessData] Set Polygons',
    SET_TREKS = '[BusinessData] Set Treks'
}

export const setBusinessPolygons = createAction(
    BusinessDataActionTypes.SET_POLYGONS,
    props<{ polygons: BusinessPolygon[] }>()
);

export const setBusinessTreks = createAction(
    BusinessDataActionTypes.SET_TREKS,
    props<{ treks: BusinessTrek[] }>()
);

export const getBusinessPolygons = createSelector(
    (state: AppState) => state.businessData.businessPolygons,
    (state) => state
);
export const getBusinessTreks = createSelector(
    (state: AppState) => state.businessData.businessTreks,
    (state) => state
);

export const BusinessPolygonsReducer = createReducer(
    [] as BusinessPolygon[],
    on(setBusinessPolygons, (state, { polygons }) => polygons)
);

export const BusinessTreksReducer = createReducer(
    [] as BusinessTrek[],
    on(setBusinessTreks, (state, { treks }) => treks)
);

export const BusinessDataReducer = combineReducers({
    businessPolygons: BusinessPolygonsReducer,
    businessTreks: BusinessTreksReducer
});
