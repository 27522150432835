import * as mapboxgl from 'mapbox-gl';
import MapboxDraw, { DrawMode } from '@mapbox/mapbox-gl-draw';
import { MapboxMap } from './MapboxMap';
import polygonArea from '@turf/area';
import { getPolygonDrawerSource } from './geojson';
import {
    Feature,
    FeatureCollection,
    LineString,
    Point,
    Polygon,
    Position
} from 'geojson';

export class MapDrawer {
    private mapboxMap: MapboxMap;
    private mapboxDraw!: MapboxDraw;
    private featureId!: string | number;

    constructor(mapboxMap: MapboxMap) {
        this.mapboxMap = mapboxMap;
    }

    createMapboxDrawer(map: mapboxgl.Map): void {
        const modes = { ...MapboxDraw.modes };
        modes.simple_select.onDrag = (stats, e) => {};
        const onDragFunction = modes.direct_select.onDrag;
        modes.direct_select.onDrag = function (this, stats, e) {
            const onDragFunctionBind = onDragFunction!.bind(this);
            if (stats.selectedCoordPaths?.length) {
                onDragFunctionBind!(stats, e);
            }
        };
        const config = getPolygonDrawerSource();
        config.modes = modes;
        this.mapboxDraw = new MapboxDraw(config);
        map.addControl(this.mapboxDraw);
    }

    getMode(): string {
        return this.mapboxDraw.getMode();
    }

    changeMode(mode: DrawMode): void {
        if ('direct_select' === mode) {
            this.mapboxDraw.changeMode('direct_select', {
                featureId: this.featureId as string
            });
        } else {
            this.mapboxDraw.changeMode(mode as any);
        }
    }

    setDrawerPolygon(polygon: Polygon): void {
        this.featureId = 'POLYGON';
        const featureCollection: FeatureCollection = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    id: this.featureId,
                    geometry: polygon
                }
            ]
        };
        this.mapboxDraw.set(featureCollection);
    }

    setDrawerFeature(feature: Feature): void {
        this.featureId = feature.id || 'FEATURE';
        const featureCollection: FeatureCollection = {
            type: 'FeatureCollection',
            features: [
                {
                    ...feature,
                    properties: feature.properties || {},
                    id: this.featureId
                }
            ]
        };
        this.mapboxDraw.set(featureCollection);
    }

    getDrawerPolygonArea(): number {
        const area =
            polygonArea(this.mapboxDraw.getAll() as FeatureCollection) / 1000;
        return Math.round(area);
    }

    deleteDrawerElements(): any {
        return this.mapboxDraw.deleteAll();
    }

    getSelectedPoints() {
        return this.mapboxDraw.getSelectedPoints();
    }

    deleteSelectedPoints(): Feature<LineString | Polygon> | undefined {
        const selectedPoints = this.mapboxDraw.getSelectedPoints();
        if (selectedPoints?.features?.length) {
            const currentFeature = this.mapboxDraw.get(
                this.featureId.toString()
            ) as Feature<LineString | Polygon>;
            selectedPoints.features.forEach((feature) => {
                const featureCoords = currentFeature!.geometry.coordinates;
                const coordinates = Array.isArray(featureCoords[0][0])
                    ? featureCoords[0]
                    : featureCoords;
                const index = (coordinates as Position[]).findIndex(
                    (point) =>
                        point[0] ===
                            (feature.geometry as Point).coordinates[0] &&
                        point[1] === (feature.geometry as Point).coordinates[1]
                );
                if (index >= 0) {
                    coordinates.splice(index, 1);
                }
            });
            return currentFeature;
        }
        return undefined;
    }
}
