import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewContainerRef
} from '@angular/core';

import { scheduledAnnouncementDialogComponent } from '../scheduled-announcement-dialog/scheduled-announcement-dialog.component';
import {
    WarningActions,
    WarningModalData
} from '../../../standalone/components/warning-dialog/warning-dialog.const';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    take,
    tap
} from 'rxjs/operators';
import { SiteService } from '../../services/site.service';
import {
    Announcement,
    AnnouncementAlert,
    AnnouncementDialogData,
    AnnouncementDialogResult
} from '../../models/announcement.model';
import { WarningDialogComponent } from '../../../standalone/components/warning-dialog/warning-dialog.component';
import { ToggleSpinnerService } from '../../../services/toggle-spinner.service';
import { LocaleType } from '../../../shared/locales.models';
import { MatDialog } from '@angular/material/dialog';
import { SiteCard } from '../../models/site.model';

@Component({
    selector: 'app-scheduled-announcement',
    templateUrl: './scheduled-announcement.component.html',
    styleUrls: ['./scheduled-announcement.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledAnnouncementComponent implements OnInit {
    siteInfo!: SiteCard;
    announcement$!: Observable<Announcement | null>;
    private _timeZone!: string;

    constructor(
        private matDialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private siteService: SiteService,
        private viewContainerRef: ViewContainerRef,
        private toggleSpinnerService: ToggleSpinnerService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.announcement$ = this.getAnnouncement();
    }

    getAnnouncement() {
        return (
            this.activatedRoute.parent?.data as Observable<{
                siteInfo: SiteCard;
            }>
        ).pipe(
            take(1),
            concatMap(({ siteInfo }) => {
                this.siteInfo = siteInfo;
                return this.siteService.getSiteScheduledAlerts(
                    this.siteInfo.id!
                );
            }),
            tap((announcement) => {
                this._timeZone = announcement.timezone;
            }),
            finalize(() => {
                this.toggleSpinnerService.toggle(false);
            }),
            catchError(() => {
                console.log('error getting site settings');
                return of(null);
            })
        );
    }

    openAnnouncementDialog(alert?: AnnouncementAlert): void {
        const dialogData: AnnouncementDialogData = {
            defaultLanguage: this.siteInfo.defaultLanguage as LocaleType,
            spots: this.siteInfo.spots?.filter(
                (spot) => spot?.settings?.isActive !== false
            ),
            timezone: this._timeZone,
            alert
        };

        const dialogRef = this.matDialog.open(
            scheduledAnnouncementDialogComponent,
            {
                height: '574px',
                width: '1200px',
                disableClose: true,
                viewContainerRef: this.viewContainerRef,
                autoFocus: 'dialog',
                data: dialogData
            }
        );
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((result?: AnnouncementDialogResult) => {
                if (result?.newAlert) {
                    this.saveAnnouncement(result);
                } else if (result?.updatedAlert) {
                    this.updateAnnouncement(result);
                } else if (result?.deletedAlert) {
                    this.deleteAnnouncement(result.deletedAlert);
                }
            });
    }

    saveAnnouncement(result: AnnouncementDialogResult): void {
        this.toggleSpinnerService.toggle(true);
        this.announcement$ = this.siteService
            .postSiteScheduledAlerts(
                this.siteInfo.id!,
                result!.newAlert.soiId,
                result!.newAlert.name,
                result!.newAlert.message,
                result!.newAlert.days,
                result!.newAlert.everyDay
            )
            .pipe(concatMap(() => this.getAnnouncement()));
        this.cdr.detectChanges();
    }

    updateAnnouncement(result: AnnouncementDialogResult): void {
        this.toggleSpinnerService.toggle(true);
        this.announcement$ = this.siteService
            .updateSiteScheduledAlerts(
                this.siteInfo.id!,
                result!.updatedAlert.id,
                result!.updatedAlert.soiId,
                result!.updatedAlert.name,
                result!.updatedAlert.message,
                result!.updatedAlert.days,
                result!.updatedAlert.everyDay
            )
            .pipe(concatMap(() => this.getAnnouncement()));
        this.cdr.detectChanges();
    }

    deleteAnnouncement(alert: AnnouncementAlert): void {
        const data: WarningModalData = {
            title: 'DELETE_ANNOUNCEMENT',
            content: 'DELETE_ANNOUNCEMENT_DESCRIPTION',
            image: '<i class="WTicon-are_you_sure"></i>',
            deleteText: 'YES_DELETE_IT',
            cancelText: 'ACTUALLY_LETS_KEEP_IT'
        };
        const dialogRef = this.matDialog.open(WarningDialogComponent, {
            maxWidth: '550px',
            disableClose: true,
            data
        });

        dialogRef
            .afterClosed()
            .pipe(
                take(1),
                filter((result) => result === WarningActions.DELETE),
                concatMap(() => {
                    this.toggleSpinnerService.toggle(true);
                    return this.siteService.deleteAlerts(
                        this.siteInfo.id!,
                        alert.id
                    );
                })
            )
            .subscribe(() => {
                this.announcement$ = this.getAnnouncement();
                this.cdr.detectChanges();
            });
    }
}
