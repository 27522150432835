import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SiteSharedService } from '../../services/site-shared.service';
import { PermissionsService } from '../../../services/permissions.service';
import { USER_ACTIONS } from '../../../users/users.model';
import { Router } from '@angular/router';
import { SCREEN_BREAK_POINTS } from '../../../shared/shared.constants';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutePaths } from '../../../app-routing.module';
import { SitesRoutePaths } from '../../sites-routing.module';
import { BehaviorSubject } from 'rxjs';
import { HeaderService } from '../../../services/header.service';
import { SiteCard } from '../../models/site.model';

@Component({
    selector: 'app-sites',
    templateUrl: './sites.component.html',
    styleUrls: ['./sites.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitesComponent implements OnInit, OnDestroy {
    sites: SiteCard[] = [];
    displaySites$ = new BehaviorSubject<SiteCard[]>([]);
    showSpinner$ = new BehaviorSubject(false);
    hasAccessCreateSite?: boolean;
    isMobile = window.innerWidth < SCREEN_BREAK_POINTS.tablet;
    sitesFirstLoad = true;

    constructor(
        private siteSharedService: SiteSharedService,
        private permissionsService: PermissionsService,
        private router: Router,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private headerService: HeaderService
    ) {}

    ngOnInit(): void {
        this.headerService.breadcrumbs = [];
        this.initSitesList();
        this.hasAccessCreateSite =
            this.permissionsService.checkIfUserHasPermission(
                USER_ACTIONS.CREATE_SITE
            );
    }

    ngOnDestroy(): void {
        this.siteSharedService.sitesListNextOffset = 0;
    }

    initSitesList(): void {
        this.getSitesList();
    }

    getSitesList(): void {
        this.showSpinner$.next(true);
        this.siteSharedService
            .getSitesList(2)
            .pipe(finalize(() => this.showSpinner$.next(false)))
            .subscribe((result) => {
                if (result) {
                    this.sites.push(...result);
                    this.displaySites$.next(this.sites);
                }
                if (this.sitesFirstLoad) {
                    this.sitesFirstLoad = false;
                    this.getSitesList();
                }
            });
    }

    filerSites(searchTerm: string): void {
        this.displaySites$.next(
            !searchTerm || searchTerm === ''
                ? this.sites
                : this.sites?.filter((site) =>
                      site?.name
                          ?.toLowerCase()
                          ?.includes(searchTerm?.toLowerCase())
                  )
        );
    }

    createSite(): void {
        if (this.isMobile) {
            return this.snackbarService.open(
                this.translate.instant('MOBILE_SITE_TOAST')
            );
        }
        if (!this.hasAccessCreateSite) {
            return;
        }
        this.router.navigate([
            `./${AppRoutePaths.SITES}`,
            SitesRoutePaths.CREATE_SITE
        ]);
    }

    onScrollDown(): void {
        if (this.showSpinner$.value) {
            return;
        }
        this.getSitesList();
    }
}
