import {
    createAction,
    createReducer,
    createSelector,
    on,
    props
} from '@ngrx/store';
import { AppState } from '../../store/app-store.model';
import { MapCategories } from '../models/site-wizard-categories.model';

export interface SiteCategoriesState {
    [id: string]: {
        color: string;
        displayName: string;
    };
}

export enum SiteCategoriesActionTypes {
    SET_CATEGORIES = '[SiteCategories] Set Categories'
}

export const setSiteCategories = createAction(
    SiteCategoriesActionTypes.SET_CATEGORIES,
    props<{ categories: MapCategories }>()
);

export const getSiteCategories = createSelector(
    (state: AppState) => state.siteCategories,
    (state) => state
);

export const SiteCategoriesReducer = createReducer(
    {},
    on(setSiteCategories, (state, { categories }) => ({
        ...state,
        ...categories
    }))
);
