import * as mapboxgl from 'mapbox-gl';
import { environment } from '@env/environment';
import { MapboxMap } from './MapboxMap';
import {
    getClusteredSoiLabelLayer,
    getClusteredSoiLayer,
    getSoiClusterSource,
    getUnclusteredSoiLayer
} from './layers';
import { PointData } from '../typings/mapbox-utils';
import { getPointsSource } from './geojson';

import { first } from 'rxjs/operators';
import { DEFAULT_OOI_CATEGORY } from '../../shared/shared.constants';
import * as SiteCategoriesSelectors from '../../site-wizard/store/site-categories-store';
import { SiteCategoriesState } from '../../site-wizard/store/site-categories-store';

export class ClusteredSois {
    private mapboxMap;
    private map: mapboxgl.Map;
    private layerShown: boolean;
    private pendingSources: Array<mapboxgl.GeoJSONSourceRaw>;
    private pendingLayers: Array<mapboxgl.Layer>;
    private isMapLoaded?: boolean;
    // private soiPopup!: mapboxgl.Popup;
    private soisPoints: PointData[] = [];
    private isSite?: boolean;
    private store: any;
    SOI_CLUSTER_SOURCE_ID = 'CLUSTERS_SOURCE';
    private SOI_CLUSTER_LAYER_ID = 'CLUSTERS_LAYER';
    private SOI_CLUSTERS_LAYER_ID = 'CLUSTERS_LABELS';
    private SOI_UNCLUSTERED_LAYER_ID = 'UNCLUSTERED_LAYER';
    private SOI_DEFAULT_ICON = 'SOI_ICON';
    private TOI_DEFAULT_ICON = 'TOI_ICON';
    private SOI_CATEGORY_ICON_PERFIX = 'SOI_CATEGORY_ICON_';
    private TOI_CATEGORY_ICON_PERFIX = 'TOI_CATEGORY_ICON_';
    private categories: any;
    private categoryIconNames: string[] = [];

    constructor(mapboxMap: MapboxMap, map: mapboxgl.Map, store: any) {
        this.mapboxMap = mapboxMap;
        this.map = map;
        this.layerShown = false;
        this.pendingSources = [];
        this.pendingLayers = [];
        this.store = store;
        this.map.on('load', this._loadMap.bind(this));
        this.map.on('style.load', this._onMapStyleChange.bind(this));
    }

    init(points: Array<PointData>, isSite: boolean): void {
        this.isSite = isSite;
        this.store
            .select(SiteCategoriesSelectors.getSiteCategories)
            .pipe(
                first(
                    (categories: SiteCategoriesState) =>
                        !!(categories && Object.keys(categories).length)
                )
            )
            .subscribe((categories: any) => (this.categories = categories));
        this.pendingSources.push(
            getSoiClusterSource(this.getIconProperty(points))
        );
        this.pendingLayers.push(
            getClusteredSoiLayer(
                this.SOI_CLUSTER_LAYER_ID,
                this.SOI_CLUSTER_SOURCE_ID
            )
        );
        this.pendingLayers.push(
            getClusteredSoiLabelLayer(
                this.SOI_CLUSTERS_LAYER_ID,
                this.SOI_CLUSTER_SOURCE_ID
            )
        );
        this.pendingLayers.push(
            getUnclusteredSoiLayer(
                this.SOI_UNCLUSTERED_LAYER_ID,
                this.SOI_CLUSTER_SOURCE_ID
            )
        );

        if (!this.isMapLoaded) {
            return;
        }

        this.addLayersAndSourcesToMap();
    }

    addLayersAndSourcesToMap(): void {
        this.pendingSources.forEach((source: any) =>
            this.mapboxMap.addSource(this.SOI_CLUSTER_SOURCE_ID, source)
        );
        this.pendingSources = [];
        this.pendingLayers.forEach((layer) => this.mapboxMap.addLayer(layer));
        this.pendingLayers = [];
    }

    initListeners(): void {
        this.map.on('click', this.SOI_CLUSTER_LAYER_ID, (e) => {
            this.zoomInToCluster(e);
        });

        this.map.on('mouseenter', this.SOI_CLUSTER_LAYER_ID, (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', this.SOI_CLUSTER_LAYER_ID, () => {
            this.map.getCanvas().style.cursor = '';
        });

        this.map.on('click', this.SOI_UNCLUSTERED_LAYER_ID, (e) => {
            if (this.isSite) {
                const properties = e.features?.[0].properties;
                this.mapboxMap.notifySpotMarkerClick(properties as any);
            } else {
                const properties = e.features?.[0].properties;
                this.mapboxMap.notifySiteMarkerClick(properties as any);
            }
        });

        // this.map.on('mouseenter', this.SOI_UNCLUSTERED_LAYER_ID, (e) => {
        //     this.map.getCanvas().style.cursor = 'pointer';
        //     this.createSoiPopup(e);
        // });
        //
        // this.map.on('mouseleave', this.SOI_UNCLUSTERED_LAYER_ID, () => {
        //     this.map.getCanvas().style.cursor = '';
        //     if (this.soiPopup)
        //         this.soiPopup.remove();
        // });
    }

    // createSoiPopup(e): void {
    //     if (window.innerWidth <= DIMENS.screen_medium) {
    //         return;
    //     }
    //
    //     const coordinates = e.features[0].geometry.coordinates.slice();
    //     const soiProperties = e.features[0].properties;
    //
    //     // Ensure that if the trek-wizard-site-wizard-map-setup is zoomed
    // out such that multiple // copies of the feature are visible, the popup
    // appears // over the copy being pointed to. while (Math.abs(e.lngLat.lng
    // - coordinates[0]) > 180) { coordinates[0] += e.lngLat.lng >
    // coordinates[0] ? 360 : -360; }  this.soiPopup = new mapboxgl.Popup({
    // closeButton: false, closeOnClick: false, anchor: 'bottom', offset: [1,
    // -10] }) .setHTML( `<div class="mapbox__cluster__popup"
    // style="background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%,
    // #000000), url('${ soiProperties.bg_image }');"><div
    // class="mapbox__cluster__popup__info"> <div
    // class="mapbox__cluster__popup__icon"><i class="WTicon-${
    // soiProperties.type === 'toi' ? 'trek' : 'soi' }-icon" style="color:${
    // Object.keys(this.categories).length &&
    // this.categories[soiProperties.categoryId] ?
    // this.categories[soiProperties.categoryId].color : '#6fa681'
    // }"></i></div><div class="mapbox__cluster__popup__title">${
    // soiProperties.name }</div></div></div>` ) .setLngLat(coordinates)
    // .addTo(this.trek-wizard-site-wizard-map-setup); }

    zoomInToCluster(e: any): void {
        const features = this.map.queryRenderedFeatures(e.point, {
            layers: [this.SOI_CLUSTER_LAYER_ID]
        });
        const clusterId = features[0].properties?.cluster_id;
        (
            this.map.getSource(this.SOI_CLUSTER_SOURCE_ID) as any
        ).getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
            if (err) {
                return;
            }

            this.map.easeTo({
                center: (features[0].geometry as any).coordinates,
                zoom
            });
        });
    }

    updateClusteredData(points: Array<PointData>): any {
        points = this.getIconProperty(points);
        this.soisPoints = points;
        if (!this.isMapLoaded) {
            return;
        }
        const geometry = getPointsSource(points);
        if (this.map.getSource(this.SOI_CLUSTER_SOURCE_ID)) {
            (
                this.map.getSource(
                    this.SOI_CLUSTER_SOURCE_ID
                ) as mapboxgl.GeoJSONSource
            ).setData(geometry);
        }
    }

    getIconProperty(points?: PointData[]): any {
        return points?.map((point) => {
            point.properties!.icon = this.getCategoryIcon(
                point.properties!.categoryId,
                point.properties!.type
            );
            return point;
        });
    }

    getCategoryIcon(categoryId?: string, type?: string): string {
        if (!categoryId || categoryId === DEFAULT_OOI_CATEGORY) {
            if (type === 'toi') {
                return this.TOI_DEFAULT_ICON;
            }
            return this.SOI_DEFAULT_ICON;
        }

        const categoryIconName =
            type === 'toi'
                ? this.TOI_CATEGORY_ICON_PERFIX + categoryId
                : this.SOI_CATEGORY_ICON_PERFIX + categoryId;

        if (this.map.hasImage(categoryIconName)) {
            return categoryIconName;
        }

        if (this.categoryIconNames.includes(categoryIconName)) {
            return categoryIconName;
        }

        this.categoryIconNames.push(categoryIconName);
        const categoryIconUrl = `${
            environment.CATEGORIES_DATA_URL
        }/icons/${categoryId}_${type === 'toi' ? 'TOI' : 'SOI'}.svg`;

        const svg = new XMLHttpRequest();
        svg.open('GET', categoryIconUrl, true);
        svg.setRequestHeader('Cache-Control', 'no-cache');
        svg.send();
        svg.onload = () => {
            const blob = new Blob([svg.responseText], {
                type: 'image/svg+xml'
            });
            const url = URL.createObjectURL(blob);
            const image = document.createElement('img');
            image.src = url;
            image.onload = () => {
                if (!this.map.hasImage(categoryIconName)) {
                    this.map.addImage(categoryIconName, image);
                }
            };
            image.onerror = () => {
                console.log('Error uploading icon', categoryId);
            };
        };
        return categoryIconName;
    }

    /**
     * Add image to use in soi layers
     */
    addImageToMap(): void {
        const iconName = this.SOI_DEFAULT_ICON;
        const svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="37" height="49" viewBox="0 0 27 39">' +
            '<g fill="none" fill-rule="evenodd" >' +
            // eslint-disable-next-line max-len
            '<path fill="#333" d="M21.773 37.572c0 .565-3.827 1.025-8.55 1.025-4.72 0-8.55-.46-8.55-1.025s3.83-1.025 8.55-1.025c4.723 0 8.55.46 8.55 1.025" opacity=".4"/>' +
            // eslint-disable-next-line max-len
            '<path fill="#6fa681" stroke="#403C3E" stroke-width="1.75" d="M13.39 33.905c1.074 0 2.107-.54 2.726-1.408 3.633-5.016 9.664-14.133 9.664-19.064C25.78 6.553 20.204 1 13.39 1 6.575 1 1 6.595 1 13.433c0 4.89 6.07 14.048 9.663 19.064a3.375 3.375 0 0 0 2.727 1.408z"/>' +
            '<path  transform="translate(4.5,4.5), scale(.9)"  fill="#fff"  d=""></path>' +
            '</g>' +
            '</svg>';
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        const image = document.createElement('img');
        image.src = url;
        image.onload = () => {
            if (!this.map.hasImage(iconName)) {
                this.map.addImage(iconName, image);
            }
        };

        const toiIconName = this.TOI_DEFAULT_ICON;
        const toiSvg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="37" height="49" viewBox="0 0 27 39">' +
            '<g fill="none" fill-rule="evenodd" >' +
            // eslint-disable-next-line max-len
            '<path fill="#333" d="M21.773 37.572c0 .565-3.827 1.025-8.55 1.025-4.72 0-8.55-.46-8.55-1.025s3.83-1.025 8.55-1.025c4.723 0 8.55.46 8.55 1.025" opacity=".4"/>' +
            // eslint-disable-next-line max-len
            '<path fill="#6fa681" stroke="#403C3E" stroke-width="2" d="M23.146 1H2.854A1.86 1.86 0 0 0 1 2.866v9.693h.014A12.24 12.24 0 0 0 1 13.09c0 4.756 5.88 13.662 9.359 18.54.6.846 1.6 1.369 2.64 1.369H13c1.041 0 2.04-.524 2.64-1.37C19.16 26.753 25 17.886 25 13.09c0-.177-.006-.355-.014-.531H25V2.866A1.86 1.86 0 0 0 23.146 1"/>' +
            '<path  transform="translate(4.5,4.5), scale(.9)"  fill="#fff"  d=""></path>' +
            '</g>' +
            '</svg>';
        const toiBlob = new Blob([toiSvg], { type: 'image/svg+xml' });
        const toiUrl = URL.createObjectURL(toiBlob);
        const toiImage = document.createElement('img');
        toiImage.src = toiUrl;
        toiImage.onload = () => {
            if (!this.map.hasImage(toiIconName)) {
                this.map.addImage(toiIconName, image);
            }
        };
    }

    private _loadMap(): void {
        setTimeout(() => {
            this.isMapLoaded = true;
            this.addLayersAndSourcesToMap();
            this.initListeners();
            if (this.soisPoints) {
                this.updateClusteredData(this.soisPoints);
            }
            this.addImageToMap();
        }, 100);
    }

    private _onMapStyleChange(): void {
        if (!this.isMapLoaded) {
            return;
        }
        this.categoryIconNames = [];
        setTimeout(() => {
            this.updateClusteredData(this.soisPoints);
            this.addImageToMap();
        });
    }
}
