import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';

export enum AuthRoutePaths {
    LOGIN = 'login',
    REGISTER = 'register',
    RESET_PASSWORD = 'reset-password',
    FORGOT_PASSWORD = 'forgot-password',
    CAMPAIGN_REGISTER = 'campaign-register'
}

const AUTH_ROUTERS: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: AuthRoutePaths.LOGIN,
                pathMatch: 'full'
            },
            {
                path: AuthRoutePaths.LOGIN,
                component: LoginComponent
            },
            {
                path: AuthRoutePaths.REGISTER,
                component: RegisterComponent
            },
            {
                path: AuthRoutePaths.RESET_PASSWORD,
                component: ResetPasswordPageComponent
            },
            {
                path: AuthRoutePaths.FORGOT_PASSWORD,
                component: ForgotPasswordComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(AUTH_ROUTERS)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
