import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from '@angular/router';
import { concatMap, Observable, of } from 'rxjs';
import { SiteService } from '../services/site.service';
import { map } from 'rxjs/operators';
import { LocaleType } from '../../shared/locales.models';
import { SpotsAndTreksQrData } from '../models/my-qr.model';

@Injectable()
export class QRPageResolver implements Resolve<SpotsAndTreksQrData> {
    constructor(private siteService: SiteService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<SpotsAndTreksQrData> {
        const siteId = route?.parent?.data?.siteInfo?.id;

        return this.siteService.getSiteInfo(siteId).pipe(
            map((siteInfo) => {
                const siteDefaultLanguage = siteInfo.languages
                    ?.defaultLanguage! as LocaleType;
                return {
                    trekIds: siteInfo?.siteRoutes!?.map(
                        (trek) => trek?.trekId!
                    ),
                    siteSpots: siteInfo?.spots
                        ?.filter((spot) => spot?.settings?.isActive !== false)
                        ?.map((spot) => ({
                            id: spot.id,
                            name: spot?.general?.[siteDefaultLanguage]?.name
                        }))
                };
            }),
            concatMap(({ trekIds, siteSpots }) => {
                if (!trekIds) {
                    return of({
                        siteId,
                        siteSpots,
                        siteTreks: undefined
                    });
                }
                return this.siteService.getTrekName(trekIds).pipe(
                    map((treksInfo) => ({
                        siteId,
                        siteSpots,
                        siteTreks: treksInfo?.map((trek) => ({
                            id: trek?.id || trek?.trekId,
                            name: trek?.details?.name || ''
                        }))
                    }))
                );
            })
        );
    }
}
