import { createReducer, on } from '@ngrx/store';
import * as ContactActions from '../actions/contact.actions';
import { initialState } from '../site-wizard-store.constants';

export const contactInformationReducer = createReducer(
    initialState.contact,
    on(ContactActions.UpdateContact, (state, { contactInfo, invalid }) => ({
        ...state,
        data: {
            ...contactInfo
        },
        sectionStatus: {
            ...state?.sectionStatus,
            invalid,
            complete: !!state?.sectionStatus?.complete && !invalid
        }
    })),
    on(ContactActions.ContactComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus!,
            complete: true
        }
    }))
);
