import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardMode } from '../../../site-wizard/store/site-wizard-store.model';
import { SiteMenuService } from '../../services/site-menu.service';
import { SiteCard, SiteMenu } from '../../models/site.model';
import { HubspotMeetingWidgetService } from '../../../standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.service';
import { hubspotMeetingSupportUrl } from '../../../standalone/components/hubspot-meeting-widget/hubspot-meeting-widget.const';
import { AppStoreService } from '../../../store/app-store.service';
import { AppRoutePaths } from '../../../app-routing.module';
import { environment } from '@env/environment';

@Component({
    selector: 'app-site-index',
    templateUrl: './site-index.component.html',
    styleUrls: ['./site-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteIndexComponent implements OnInit {
    protected readonly AppRoutePaths = AppRoutePaths;
    WizardMode = WizardMode;
    user = this.appStateStore.state.auth.user!;
    site!: SiteCard;
    siteMenu!: SiteMenu;
    iconsBaseUrl = `${environment.ASSETS_BASE_URL}/assets/icons/`;

    constructor(
        private activatedRouter: ActivatedRoute,
        private appStateStore: AppStoreService,
        private siteMenuService: SiteMenuService,
        private hubspotMeetingWidgetService: HubspotMeetingWidgetService
    ) {}

    ngOnInit(): void {
        this.site = this.activatedRouter.snapshot.data.siteInfo;
        this.siteMenu = this.siteMenuService.getSiteMenu(this.site);
    }

    onSetupMeetingClick() {
        this.hubspotMeetingWidgetService.displayMeetingWidgetUrl =
            hubspotMeetingSupportUrl;
    }
}
