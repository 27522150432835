import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationSkipped,
    Router
} from '@angular/router';
import { WizardMode } from '../../../site-wizard/store/site-wizard-store.model';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { LiveService } from '../../services/live.service';
import { BehaviorSubject, Observable, of, shareReplay, Subject } from 'rxjs';
import { Alert, SiteCard } from '../../models/site.model';
import { SOCKET_EVENTS } from '../../sites.constants';
import { SnackbarService } from '../../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '../../../services/permissions.service';
import { USER_ACTIONS } from '../../../users/users.model';
import { NewAlertComponent } from '../../components/alerts-dialog/new-alert/new-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutePaths } from '../../../app-routing.module';
import { SitesRoutePaths } from '../../sites-routing.module';
import { GamesRoutePaths } from '../../../games/games-routing.module';
import { BreadcrumbsStage } from '../../../standalone/components/breadcrumbs/breadcrumbs.model';
import { HeaderService } from '../../../services/header.service';

@Component({
    selector: 'app-site-main-menu',
    templateUrl: './site-main-menu.component.html',
    styleUrls: ['./site-main-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteMainMenuComponent implements OnInit, OnDestroy {
    protected readonly AppRoutePaths = AppRoutePaths;
    private unsubscribe$ = new Subject<void>();
    WizardMode = WizardMode;
    site!: SiteCard;
    alertsOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    newAlertOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    notificationsList: Alert[] = [];
    notificationsList$!: Observable<Alert[]>;
    hasAccessAlerts?: boolean;
    hideSitesMenuView$!: Observable<boolean>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private liveService: LiveService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private permissionsService: PermissionsService,
        private matDialog: MatDialog,
        private headerService: HeaderService
    ) {}

    ngOnInit(): void {
        this.initHideSitesMenuView();
        this.initBreadcrumbs();
        this.site = this.activatedRoute.snapshot.data.siteInfo;
        this.hasAccessAlerts = this.permissionsService.checkIfUserHasPermission(
            USER_ACTIONS.ALERTS,
            this.site.id
        );

        if (
            this.site.mode !== WizardMode.CREATE &&
            this.site.mode !== WizardMode.REVIEW
        ) {
            this.liveService.init(this.site.id!);
            this.notificationsList$ =
                this.liveService.liveEvent$?.pipe(
                    map((eventData) => {
                        switch (eventData[0]) {
                            case SOCKET_EVENTS.ALERT_HISTORY_ADD:
                                const firstAlert = this.notificationsList[0];
                                if (!firstAlert) {
                                    this.notificationsList.push(
                                        ...eventData[1]
                                    );
                                    break;
                                }
                                const firstAlertTimestamp = new Date(
                                    this.notificationsList[0].date
                                ).getTime();
                                const currentAlertTimestamp = new Date(
                                    eventData[1][0].date
                                ).getTime();
                                if (
                                    eventData[1].length === 1 &&
                                    currentAlertTimestamp > firstAlertTimestamp
                                ) {
                                    this.notificationsList.unshift(
                                        ...eventData[1]
                                    );
                                    break;
                                }
                                this.notificationsList.push(...eventData[1]);
                                break;
                        }
                        return this.notificationsList || [];
                    })
                ) || of([]);
        }
    }

    ngOnDestroy(): void {
        this.liveService.disconnectSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initBreadcrumbs() {
        this.activatedRoute.url
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                const routerData = this.activatedRoute.snapshot.data;
                const childBreadcrumb =
                    this.activatedRoute.snapshot.children?.[0]?.data
                        ?.breadcrumb;
                const breadcrumbs: BreadcrumbsStage[] = [
                    {
                        text: 'SITES',
                        link: ['/', AppRoutePaths.SITES]
                    },
                    {
                        text: routerData?.siteInfo?.name || 'SITE_NAME',
                        link: childBreadcrumb
                            ? [
                                  '/',
                                  AppRoutePaths.SITES,
                                  routerData?.siteInfo?.id
                              ]
                            : undefined
                    }
                ];
                if (childBreadcrumb) {
                    breadcrumbs.push({ text: childBreadcrumb });
                }

                this.headerService.breadcrumbs = breadcrumbs;
            });
    }

    initHideSitesMenuView() {
        this.hideSitesMenuView$ = this.router.events.pipe(
            filter(
                (e) =>
                    e instanceof NavigationEnd || e instanceof NavigationSkipped
            ),
            map(() => {
                const splitUrl = this.router.url.split('/');
                const isCampaignsOrGamesPage =
                    splitUrl[3] === SitesRoutePaths.CAMPAIGNS ||
                    splitUrl[3] === SitesRoutePaths.GAMES;
                const isCampaignOrGameWizard = splitUrl.length > 4;
                const isCreateGamePage =
                    splitUrl[4] === GamesRoutePaths.CREATE_GAME;
                const isSiteWizard =
                    splitUrl[3] === SitesRoutePaths.SITE_WIZARD;
                return (
                    isSiteWizard ||
                    (isCampaignsOrGamesPage &&
                        isCampaignOrGameWizard &&
                        !isCreateGamePage)
                );
            }),
            distinctUntilChanged(),
            shareReplay()
        );
    }

    toggleAlerts(): void {
        if (
            this.site.isExpired ||
            this.site.mode === WizardMode.CREATE ||
            this.site.mode === WizardMode.REVIEW
        ) {
            return;
        }
        if (!this.hasAccessAlerts) {
            return this.snackbarService.open(
                this.translate.instant('YOU_DONT_HAVE_PERMISSION_TO_ACCESS')
            );
        }
        this.alertsOpened$.next(!this.alertsOpened$.value);
    }

    loadMoreAlerts(): void {
        this.liveService.requestAlertHistory(
            this.notificationsList[this.notificationsList.length - 1]?.id
        );
    }

    toggleNewAlert(): void {
        const dialogRef = this.matDialog.open(NewAlertComponent, {
            data: {
                defaultLanguage: this.site.defaultLanguage,
                spots: this.site.spots?.filter(
                    (spot) => spot?.settings?.isActive !== false
                )
            }
        });
        const sub = dialogRef.componentInstance.addAlert.subscribe((event) => {
            this.liveService.sendNewAlert({
                msg: event.text,
                soi_id: event.spot
            });
        });
        dialogRef.afterClosed().subscribe(() => {
            sub.unsubscribe();
        });
    }
}
