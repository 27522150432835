import { createAction, props } from '@ngrx/store';
import { LatLon } from '../../../mapbox/typings/mapbox-utils';
import { NavigationModeTypes } from '../../../standalone/components/navigation-settings/navigation-settings.model';
import { ContactInformation } from '../../models/site-wizard-contact.model';
import {
    SiteHoursAdvance,
    SiteHoursDay,
    SiteHoursException,
    SiteHoursSettings
} from '../../models/site-wizard-hours.model';
import { SiteGeneralDetails } from '../../models/site-wizard-general-details.model';
import { SiteMedia } from '../../models/site-wizard-media.model';
import { NotificationMode } from '../../models/site-wizard-spots.model';
import { LocaleType } from '../../../shared/locales.models';
import { SpotDialogStages } from '../../pages/spot-dialog/spot-dialog.const';
import { SheetSpot } from '../../components/spots-sheet-dialog/spots-sheet.model';

export enum SpotsActionTypes {
    SPOTS_ADD_NEW = '[SiteWizard] Spots Add New',
    SPOTS_DELETE = '[SiteWizard] Spots Delete',
    SPOTS_SELECT = '[SiteWizard] Spots Select',
    SPOTS_UNSELECT = '[SiteWizard] Spots Unselect',
    SPOTS_UPDATE_LOCATION = '[SiteWizard] Spots Update Location',
    SPOTS_CHANGE_SELECTED_STAGE = '[SiteWizard] Spots Change Selected Stage',
    SPOTS_COMPLETE = '[SiteWizard] Spots Complete',
    SPOT_UPDATE_MEDIA = '[SiteWizard] Spot Update Media',
    SPOT_UPDATE_DETAILS = '[SiteWizard] Spot General Update Details',
    SPOT_ADD_AUDIO = '[SiteWizard] Spot Add audio',
    SPOT_DELETE_AUDIO = '[SiteWizard] Spot Delete audio',
    SPOT_UPDATE_NAVIGATION_MODE = '[SiteWizard] Spot Update Navigation Mode',
    SPOT_UPDATE_RADIUS = '[SiteWizard] Spot Update Radius',
    SPOT_UPDATE_CONTACT = '[SiteWizard] Spot Update Contact',
    SPOT_ADD_TAG = '[SiteWizard] Spot Add Tag',
    SPOT_DELETE_TAG = '[SiteWizard] Spot Delete Tag',
    SPOT_UPDATE_KEYWORD = '[SiteWizard] Spot Update Keyword',
    SPOT_UPDATE_HOURS_SETTINGS = '[SiteWizard] Spot Hours Update Settings',
    SPOT_BASIC_UPDATE_DATE_RANGE = '[SiteWizard] Spot Hours Basic Update Date Range',
    SPOT_EXCEPTIONS_ADD_DATE_RANGE = '[SiteWizard] Spot Hours Exception Add Date Range',
    SPOT_EXCEPTIONS_DELETE_DATE_RANGE = '[SiteWizard] Spot Hours Exception Delete Date Range',
    SPOT_EXCEPTIONS_UPDATE_DATE_RANGE = '[SiteWizard] Spot Hours Exception Update Date Range',
    SPOT_ADVANCED_ADD_DATE_RANGE = '[SiteWizard] Spot Hours Advanced Add Date Range',
    SPOT_ADVANCED_DELETE_DATE_RANGE = '[SiteWizard] Spot Hours Advanced Delete Date Range',
    SPOT_ADVANCED_UPDATE_DATE_RANGE = '[SiteWizard] Spot Hours Advanced Update Date Range',
    SPOT_ALERTS_OPTIONS_UPDATE = '[SiteWizard] Spot Alert Option Update',
    SPOT_ACTIVE_SPOT_UPDATE = '[SiteWizard] Spot Active Spot Update',
    SPOT_SET_GENERAL_LANG_TAB = '[SiteWizard] Spot Set General Lang Tab',
    SPOT_HANDLE_SPOTS_FROM_SHEET = '[SiteWizard] Spot Handle Spots From Sheet'
}

export const AddSpot = createAction(
    SpotsActionTypes.SPOTS_ADD_NEW,
    props<{ coordinates: LatLon; languages: LocaleType[] }>()
);

export const DeleteSpot = createAction(
    SpotsActionTypes.SPOTS_DELETE,
    props<{ id: string }>()
);

export const SelectSpot = createAction(
    SpotsActionTypes.SPOTS_SELECT,
    props<{ id: string }>()
);

export const UnselectSpot = createAction(SpotsActionTypes.SPOTS_UNSELECT);

export const UpdateSpotLocation = createAction(
    SpotsActionTypes.SPOTS_UPDATE_LOCATION,
    props<{ id: string; coordinate: LatLon }>()
);

export const ChangeSelectSpotStage = createAction(
    SpotsActionTypes.SPOTS_CHANGE_SELECTED_STAGE,
    props<{ stage: SpotDialogStages; completedSection?: SpotDialogStages }>()
);

export const SpotsComplete = createAction(SpotsActionTypes.SPOTS_COMPLETE);

export const UpdateMedia = createAction(
    SpotsActionTypes.SPOT_UPDATE_MEDIA,
    props<{ media: SiteMedia[] }>()
);

export const UpdateDetails = createAction(
    SpotsActionTypes.SPOT_UPDATE_DETAILS,
    props<{
        language: LocaleType;
        data: SiteGeneralDetails;
        invalid: boolean;
    }>()
);

export const AddAudio = createAction(
    SpotsActionTypes.SPOT_ADD_AUDIO,
    props<{ language: LocaleType; audioUrl: string }>()
);

export const DeleteAudio = createAction(
    SpotsActionTypes.SPOT_DELETE_AUDIO,
    props<{ language: LocaleType }>()
);

export const UpdateNavigationMode = createAction(
    SpotsActionTypes.SPOT_UPDATE_NAVIGATION_MODE,
    props<{ mode: NavigationModeTypes }>()
);

export const UpdateRadius = createAction(
    SpotsActionTypes.SPOT_UPDATE_RADIUS,
    props<{ radius: number }>()
);

export const UpdateContact = createAction(
    SpotsActionTypes.SPOT_UPDATE_CONTACT,
    props<{ contactInfo: ContactInformation; invalid: boolean }>()
);

export const AddTag = createAction(
    SpotsActionTypes.SPOT_ADD_TAG,
    props<{ tagId: string }>()
);

export const DeleteTag = createAction(
    SpotsActionTypes.SPOT_DELETE_TAG,
    props<{ tagId: string }>()
);

export const UpdateKeyword = createAction(
    SpotsActionTypes.SPOT_UPDATE_KEYWORD,
    props<{ keywordString: string }>()
);

export const UpdateHoursSettings = createAction(
    SpotsActionTypes.SPOT_UPDATE_HOURS_SETTINGS,
    props<{ settings: SiteHoursSettings }>()
);

export const UpdateBasicDateRange = createAction(
    SpotsActionTypes.SPOT_BASIC_UPDATE_DATE_RANGE,
    props<{ basicRange: SiteHoursDay; day: number; invalid: boolean }>()
);

export const AddExceptionsDateRange = createAction(
    SpotsActionTypes.SPOT_EXCEPTIONS_ADD_DATE_RANGE,
    props<{ invalid: boolean }>()
);

export const DeleteExceptionsDateRange = createAction(
    SpotsActionTypes.SPOT_EXCEPTIONS_DELETE_DATE_RANGE,
    props<{ index: number; invalid: boolean }>()
);

export const UpdateExceptionsDateRange = createAction(
    SpotsActionTypes.SPOT_EXCEPTIONS_UPDATE_DATE_RANGE,
    props<{
        dateRangeException: SiteHoursException;
        index: number;
        invalid: boolean;
    }>()
);

export const AddAdvancedDateRange = createAction(
    SpotsActionTypes.SPOT_ADVANCED_ADD_DATE_RANGE,
    props<{ invalid: boolean }>()
);

export const DeleteAdvancedDateRange = createAction(
    SpotsActionTypes.SPOT_ADVANCED_DELETE_DATE_RANGE,
    props<{ index: number; invalid: boolean }>()
);

export const UpdateAdvancedDateRange = createAction(
    SpotsActionTypes.SPOT_ADVANCED_UPDATE_DATE_RANGE,
    props<{
        dateRangeAdvanced: SiteHoursAdvance;
        index: number;
        invalid: boolean;
    }>()
);
export const UpdateAlertOptions = createAction(
    SpotsActionTypes.SPOT_ALERTS_OPTIONS_UPDATE,
    props<{ notificationMode: NotificationMode }>()
);
export const UpdateIsActiveSpot = createAction(
    SpotsActionTypes.SPOT_ACTIVE_SPOT_UPDATE,
    props<{ isActiveSpot: boolean }>()
);

export const SetSpotGeneralSelectedLangTab = createAction(
    SpotsActionTypes.SPOT_SET_GENERAL_LANG_TAB,
    props<{ selectedLangTab: LocaleType }>()
);

export const HandleSpotsFromSheet = createAction(
    SpotsActionTypes.SPOT_HANDLE_SPOTS_FROM_SHEET,
    props<{ sheetSpotsList: SheetSpot[]; languagesList: LocaleType[] }>()
);
