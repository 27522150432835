import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-store.model';
import { Observable } from 'rxjs';
import { getSiteSettings } from '../../store/selections/site-settings.store';
import { map, take } from 'rxjs/operators';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

enum FreeTrialStatus {
    FREE = 'FREE',
    WARN = 'WARN',
    INFO = 'INFO',
    MORE = 'MORE'
}

@Component({
    selector: 'app-free-trial-indicator',
    templateUrl: './free-trial-indicator.component.html',
    styleUrls: ['./free-trial-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeTrialIndicatorComponent implements OnInit {
    daysLeft$!: Observable<{
        status: FreeTrialStatus;
        days: number;
    }>;
    infoStatus = 30;
    warnStatus = 7;

    constructor(private state: Store<AppState>) {}

    ngOnInit(): void {
        this.initFreeTrialStatus();
    }

    initFreeTrialStatus() {
        this.daysLeft$ = this.state.select(getSiteSettings).pipe(
            take(1),
            map((siteSettings) => {
                if (!siteSettings.csTrialExpiration) {
                    return { status: FreeTrialStatus.FREE, days: 0 };
                } else {
                    const difference = differenceInCalendarDays(
                        new Date(siteSettings.csTrialExpiration),
                        Date.now()
                    );
                    const freeTrialStatus =
                        difference > this.infoStatus
                            ? FreeTrialStatus.MORE
                            : difference > this.warnStatus
                            ? FreeTrialStatus.INFO
                            : FreeTrialStatus.WARN;
                    return {
                        status: freeTrialStatus,
                        days: difference < 0 ? 0 : difference
                    };
                }
            })
        );
    }
}
