import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    HostListener,
    inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import { HubspotMeetingWidgetService } from './hubspot-meeting-widget.service';
import { HubspotMeetingConfirmedEvent } from './hubspot-meeting-widget.model';
import { FacebookPixelService } from '../../../services/facebook-pixel.service';
import { DIMENS } from '../../../shared/shared.constants';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-hubspot-meeting-widget',
    standalone: true,
    imports: [NgIf],
    templateUrl: './hubspot-meeting-widget.component.html',
    styleUrls: ['./hubspot-meeting-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubspotMeetingWidgetComponent implements OnInit, OnDestroy {
    hubspotMeetingWidgetService = inject(HubspotMeetingWidgetService);
    facebookPixelService = inject(FacebookPixelService);
    widgetScript!: HTMLScriptElement;
    isMobile = window.innerWidth <= DIMENS.screen_XS;
    @HostBinding('class.success-screen') isSucceedScreen = false;

    @HostListener('window:message', ['$event'])
    onWindowMessage(event: MessageEvent<HubspotMeetingConfirmedEvent>) {
        const isHubspotScheduleSuccessMessage = event.data.meetingBookSucceeded;
        if (isHubspotScheduleSuccessMessage) {
            this.isSucceedScreen = true;
            window.twq('event', 'tw-o0yn3-obyak', {
                value: null
            });
            this.facebookPixelService.fireFbqEvent(
                'trackCustom',
                'CalendlySchedule',
                'calendly',
                'dataEntry'
            );
            window.lintrk('track', { conversion_id: 10250049 });
            window.dataLayer.push({
                event: 'Calendly Scheduled'
            });
        }
    }

    ngOnInit(): void {
        this.widgetScript = document.createElement('script');
        this.widgetScript.type = 'text/javascript';
        this.widgetScript.src =
            'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        document.body.appendChild(this.widgetScript);
    }

    ngOnDestroy(): void {
        document.body.removeChild(this.widgetScript);
    }

    closeWidget() {
        this.hubspotMeetingWidgetService.displayMeetingWidgetUrl = '';
    }
}
