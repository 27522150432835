import { LatLon } from '../../mapbox/typings/mapbox-utils';
import { TrekDetails } from '../models/trek-wizard-details.model';
import {
    TrekInfoStation,
    TrekStationMap,
    TrekWizardStationsSection
} from '../models/trek-wizard-stations.model';
import { TrekMap } from '../models/trek-wizard-route.model';
import { TrekTags } from '../models/trek-wizard-tags.model';
import { TrekWizardCoverMedia } from '../models/trek-wizard-cover-image.model';
import { WizardSection } from '../../standalone/components/wizard/wizars-template/wizard-template.model';

export interface TrekWizardState {
    activeStage?: TrekWizardStages;
    id?: string;
    trekId?: string;
    businessId?: string;
    mode?: TrekWizardMode;
    map: WizardSection<TrekMap>;
    details: WizardSection<TrekDetails>;
    tags: WizardSection<TrekTags>;
    stations: TrekWizardStationsSection<TrekStationMap>;
    coverMedia: WizardSection<TrekWizardCoverMedia>;
}

export interface TrekInfo {
    activeStage?: TrekWizardStages;
    id?: string;
    trekId?: string;
    businessId?: string;
    mode?: TrekWizardMode;
    map?: TrekMap;
    details?: TrekDetails;
    tags?: TrekTags;
    stations?: TrekInfoStation[];
    coverMedia?: TrekWizardCoverMedia;
    coverInnerMedia?: number;
}

export interface UpdatedStationsCoordsMap {
    [id: string]: LatLon;
}

export enum TrekWizardMode {
    CREATED = 'CREATED',
    EDITED = 'EDITED',
    PUBLISHED = 'PUBLISHED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export const TrekType = {
    900900000190041: 'CIRCULAR',
    900900000190046: 'OUT_AND_BACK',
    900900000190042: 'ONE_WAY'
};

export enum TrekWizardStages {
    CREATE_ROUTE = 'map',
    DETAILS = 'details',
    DETAILS_OPTIONS = 'detailsOptions',
    TAGS = 'tags',
    STATIONS = 'stations',
    PUBLISH = 'publish',
    MEDIA = 'media',
    AUDIOS = 'audios',
    TEXT = 'text',
    POIS = 'pois',
    COVER_IMAGE = 'coverMedia'
}
