import { createReducer, on } from '@ngrx/store';
import * as MediaActions from '../actions/media.actions';
import { initialState } from '../site-wizard-store.constants';
import { orderItems } from '../site-wizard-store.utils';
import { SiteMedia } from '../../models/site-wizard-media.model';

export const mediaReducer = createReducer(
    initialState.media,
    on(MediaActions.AddMedia, (state, { media }) => {
        const newMedia: SiteMedia[] = state.data
            ? [...state.data, ...media]
            : [...media];
        return {
            ...state,
            data: orderItems(newMedia),
            sectionStatus: {
                ...state.sectionStatus,
                invalid: false
            }
        };
    }),
    on(MediaActions.OrderMedia, (state, { media }) => ({
        ...state,
        data: orderItems(media)
    })),
    on(MediaActions.DeleteMedia, (state, { index }) => {
        const newMedia: SiteMedia[] = state.data?.filter(
            (media, i) => i !== index
        )!;
        return {
            ...state,
            data: orderItems(newMedia),
            sectionStatus: {
                ...state?.sectionStatus,
                invalid: state?.data?.length! <= 1,
                complete:
                    state.sectionStatus?.complete && state?.data?.length! > 1
            }
        };
    }),
    on(MediaActions.MediaComplete, (state) => ({
        ...state,
        sectionStatus: {
            ...state?.sectionStatus,
            complete: true
        }
    }))
);
