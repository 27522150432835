import { LocaleType } from '../../shared/locales.models';

export interface SiteGreeting {
    messages?: GreetingMessageByLang;
    entryMessageEnabled?: boolean;
    exitMessageEnabled?: boolean;
    messageAppearance?: MessageAppearance;
}

export interface GreetingMessage {
    entryMessage?: string;
    exitMessage?: string;
}

export type GreetingMessageByLang = Record<LocaleType, GreetingMessage>;

export enum MessageAppearance {
    ALWAYS = 'ALWAYS',
    FOREGROUND = 'FOREGROUND',
    RECENT_FOREGROUND = 'RECENT_FOREGROUND'
}
