import { createAction, props } from '@ngrx/store';
import { SiteMedia } from '../../models/site-wizard-media.model';

export enum MediaActionTypes {
    ADD_MEDIA = '[SiteWizard] Add Media',
    ORDER_MEDIA = '[SiteWizard] Order Media',
    DELETE_MEDIA = '[SiteWizard] Delete Media',
    MEDIA_COMPLETE = '[SiteWizard] Media Complete'
}

export const AddMedia = createAction(
    MediaActionTypes.ADD_MEDIA,
    props<{ media: SiteMedia[] }>()
);

export const OrderMedia = createAction(
    MediaActionTypes.ORDER_MEDIA,
    props<{ media: SiteMedia[] }>()
);

export const DeleteMedia = createAction(
    MediaActionTypes.DELETE_MEDIA,
    props<{ index: number }>()
);

export const MediaComplete = createAction(MediaActionTypes.MEDIA_COMPLETE);
